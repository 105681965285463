import { useCallback, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import NumberFormat, {
  NumberFormatProps,
  NumberFormatValues,
} from 'react-number-format'
import { getSymbolFromCurrency } from '@fe/common/utils/currencyUtils'
import { Input, InputProps } from './Input'

type ParentProps = Omit<InputProps, 'type' | 'max' | 'value' | 'defaultValue'> &
  Omit<NumberFormatProps, 'onBlur'>

export interface FormattedInputProps extends ParentProps {
  isFormatted?: boolean
  isFloat?: boolean
  currency?: string
  max?: number
}

export const FormattedInput: React.FC<FormattedInputProps> = ({
  name,
  label,
  placeholder,
  isDisabled,
  isOptional,
  onBlur,
  isSmall,
  autoComplete,
  isFormatted,
  isFloat,
  currency,
  max,
  isFullWidth,
  isFocused,
  className,
  ...rest
}) => {
  const [isEmptyValueShown, setIsEmptyValueShown] = useState(false)

  const formContext = useFormContext()

  const errors = formContext?.errors || rest.errors
  const formState = formContext?.formState || rest.formState
  const control = formContext?.control || rest.control

  const handleChange = useCallback(
    (onChange: (...event: any[]) => void) =>
      ({ formattedValue, floatValue, value }: NumberFormatValues) => {
        let selectedValue = isFormatted
          ? formattedValue
          : isFloat
          ? floatValue
          : value

        if (selectedValue === 0) {
          selectedValue = '0' // TODO: 0 is not accepted
        }

        onChange(selectedValue)
      },
    []
  )

  const handleBlur = useCallback(
    (controllerOnBlur: () => void) => () => {
      setIsEmptyValueShown(false)

      controllerOnBlur?.()
      onBlur?.(name)
    },
    [onBlur]
  )

  const withValueLimit = (inputData: NumberFormatValues) => {
    if (inputData.floatValue === undefined) {
      return true
    }

    return inputData.floatValue <= max
  }

  const currencyPrefix = currency ? getSymbolFromCurrency(currency) : undefined

  const isAllowed = rest.isAllowed || max ? withValueLimit : undefined

  return (
    <Controller
      render={({ onChange, onBlur, value, ref: _ref, ...props }) => (
        <NumberFormat
          customInput={Input}
          allowEmptyFormatting={isEmptyValueShown}
          onValueChange={handleChange(onChange)}
          isAllowed={isAllowed}
          prefix={currencyPrefix}
          label={label}
          placeholder={placeholder}
          isDisabled={isDisabled}
          isOptional={isOptional}
          isSmall={isSmall}
          isFullWidth={isFullWidth}
          isFocused={isFocused}
          isIndependant={false}
          autoComplete={autoComplete}
          errors={errors}
          formState={formState}
          onFocus={() => setIsEmptyValueShown(true)}
          onBlur={handleBlur(onBlur)}
          className={className}
          value={value || ''}
          data-test-id={name}
          {...props}
          {...rest}
        />
      )}
      name={name}
      control={control}
    />
  )
}
