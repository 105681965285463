import {
  createAsyncThunk,
  createSlice,
  createAction,
  PayloadAction,
} from '@reduxjs/toolkit'
import { CancelToken } from 'axios'
import { HYDRATE } from 'next-redux-wrapper'
import { ApplicationQuestionTypeEnum } from '@fe/common/api/models/enums/ApplicationQuestionTypeEnum'
import { asyncForEach } from '@fe/common/utils/arrayUtils'
import {
  fetchStorageFiles,
  formatPersistedFiles,
} from '@fe/common/utils/fileUtils'
import { CompanyDocsApi } from 'src/api/CompanyDocsApi'
import { AmlData } from 'src/components/corporateVerification/AMLQuestionnaireStep'
import {
  CompanyDocumentInfo,
  CompanyDocumentsData,
} from 'src/components/corporateVerification/CompanyDocumentsStep'
import { CompanyInfoData } from 'src/components/corporateVerification/CompanyInfoStep'
import { CompanyFundsInfoData } from 'src/components/corporateVerification/CorpFundsInfoStep'
import {
  RepresentativesData,
  Representative,
} from 'src/components/corporateVerification/RepresentativesStep'
import { AppState } from 'src/state/store'
import {
  B64FormDocuments,
  FormDocuments,
  PersistedDocument,
} from 'src/types/DocumentTypes'
import { filesToB64Docs } from 'src/utils/stateUtils'

export const getCompanyDocuments = createAsyncThunk<
  {
    newB64Docs: B64FormDocuments
    companyDocsData: CompanyDocumentsData
  },
  CancelToken,
  {
    state: AppState
  }
>(
  'corporateVerification/getCompanyDocuments',
  async (cancelToken, { getState }) => {
    const {
      session: { currentEntityId },
      corporateVerification: {
        companyDocuments: persistedDocuments,
        companyDocsCount,
        companyDocsInfo,
      },
    } = getState()

    try {
      const { data: companyDocuments } = await CompanyDocsApi.getDocuments(
        currentEntityId,
        {
          cancelToken,
        }
      )

      const formattedInitialDocuments = formatPersistedFiles(persistedDocuments)

      const newCompanyDocs: FormDocuments = { ...formattedInitialDocuments }

      await asyncForEach(companyDocuments, async (document, index) => {
        const name = `companyDocsInfo[${index}]`

        const docFiles = await fetchStorageFiles(document)

        if (!newCompanyDocs[name]?.files) {
          newCompanyDocs[name] = { files: [] }
        }

        const unuploadedFiles =
          newCompanyDocs[name]?.files.slice(
            newCompanyDocs[name].uploadedCount
          ) || []

        newCompanyDocs[name].files = [...docFiles, ...unuploadedFiles]
        newCompanyDocs[name].id = document.id
        newCompanyDocs[name].uploadedCount = docFiles.length
      })

      const serverCompanyDocsInfo = companyDocuments.map(
        ({ type, issue_date }) => ({
          type,
          issue_date,
        })
      )

      const unuploadedDocumentInfo =
        companyDocsInfo?.slice(Object.keys(formattedInitialDocuments).length) ||
        []

      const newDocsCount = Math.max(companyDocsCount, companyDocuments?.length)

      const companyDocsData = {
        companyDocsCount: newDocsCount,
        companyDocsInfo: [
          ...(serverCompanyDocsInfo || []),
          ...unuploadedDocumentInfo,
        ],
      }

      const newB64Docs = await filesToB64Docs(newCompanyDocs)

      return { newB64Docs, companyDocsData }
    } catch (error) {
      console.error(error)
    }
  }
)

export enum CorporateVerificationStepEnum {
  CompanyInfo = 1,
  FundsInfo,
  Representatives,
  CorporateOwners,
  SelfieId,
  AMLQuestionnaire,
  CompanyDocuments,
  Declaration,
  Completed = 10,
}

export interface CorporateVerificationState {
  currentStep: CorporateVerificationStepEnum
  availableStep: CorporateVerificationStepEnum
  isCompleted: boolean
  formValues: CompanyInfoData
  applicationValues: CompanyFundsInfoData
  repCount: number
  reps: Array<Representative>
  repDocuments: B64FormDocuments
  areCompanyDocsLoading: boolean
  companyDocsCount: number
  companyDocsInfo: Array<CompanyDocumentInfo>
  companyDocuments: B64FormDocuments
  amlValues: Partial<AmlData>
  hasNoEuBank: boolean
}

export const initialCorporateVerificationState: CorporateVerificationState = {
  currentStep: CorporateVerificationStepEnum.CompanyInfo,
  availableStep: CorporateVerificationStepEnum.CompanyInfo,
  isCompleted: false,
  formValues: {
    name: '',
    legal_form: '',
    address: '',
    city: '',
    zip: '',
    country_id: null,
    email: '',
    phone: '',
    number: '',
    website: '',
    fatca: null,
    company_interests: null,
  },
  applicationValues: {
    type: [],
    type_details: [],
    activity: [],
    activity_details: [],
    other_activity: null,
    private_sector: [],
    other_private_sector: null,
  },
  repCount: 1,
  reps: [
    {
      id: null,
      personId: null,
      first_name: '',
      last_name: '',
      birth_place: '',
      birth_country_id: null,
      birth_date: null,
      gender: null,
      fatca: null,
      share: null,
      pep: null,
      director: null,
      has_nominee: null,
      is_nominee: null,
      idType: null,
      isOneIdSide: false,
    },
  ],
  repDocuments: {},
  areCompanyDocsLoading: false,
  companyDocsCount: 1,
  companyDocsInfo: [
    {
      type: null,
      issue_date: null,
    },
  ],
  companyDocuments: {},
  amlValues: {
    monthly_turnover: '',
    monthly_inward_turnover: '',
    monthly_outward_turnover: '',
    outward_transaction: '',
    inward_transaction: '',
    [ApplicationQuestionTypeEnum.OutgoingGeography]: [],
    [ApplicationQuestionTypeEnum.IncommingGeography]: [],
    [ApplicationQuestionTypeEnum.IncomingDescription]: '',
    [ApplicationQuestionTypeEnum.OutgoingDescription]: '',
    [ApplicationQuestionTypeEnum.MainPartners]: '',
    [ApplicationQuestionTypeEnum.ActivityDetails]: '',
    [ApplicationQuestionTypeEnum.BanksList]: '',
  },
  hasNoEuBank: false,
}

const hydrate = createAction<AppState>(HYDRATE)

const corporateVerificationSlice = createSlice({
  name: 'corporateVerification',
  initialState: initialCorporateVerificationState,
  reducers: {
    changeCurrentStep(
      state,
      action: PayloadAction<CorporateVerificationStepEnum>
    ) {
      const nextStep = action.payload
      state.currentStep = nextStep
    },
    changeAvailableStep(
      state,
      action: PayloadAction<CorporateVerificationStepEnum>
    ) {
      const nextStep = action.payload
      state.availableStep = nextStep
    },
    progressToStep(
      state,
      action: PayloadAction<CorporateVerificationStepEnum>
    ) {
      const nextStep = action.payload

      state.currentStep = nextStep

      if (nextStep > state.availableStep) {
        state.availableStep = nextStep
      }
    },
    completeCorpVerification() {
      return { ...initialCorporateVerificationState, isCompleted: true }
    },
    addFormValues(state, action: PayloadAction<CompanyInfoData>) {
      state.formValues = action.payload
    },
    addApplicationValues(state, action: PayloadAction<CompanyFundsInfoData>) {
      state.applicationValues = action.payload
    },
    addRepresentatives(state, action: PayloadAction<RepresentativesData>) {
      // if the action is not triggered after entity change & flush
      if (state.applicationValues.activity.length) {
        state.repCount = action.payload.repCount

        const newReps =
          action.payload.reps?.map((rep, index) => ({
            ...state.reps[index],
            ...rep,
          })) || []

        state.reps = [...newReps]
      }
    },
    addRepDocuments(state, action: PayloadAction<B64FormDocuments>) {
      state.repDocuments = action.payload
    },
    addRepDocument(
      state,
      action: PayloadAction<{ key: string; document: PersistedDocument }>
    ) {
      state.repDocuments = {
        ...state.repDocuments,
        [action.payload.key]: action.payload.document,
      }
    },
    addCompanyDocumentsData(
      state,
      action: PayloadAction<CompanyDocumentsData>
    ) {
      state.companyDocsCount = action.payload.companyDocsCount

      const newDocs = action.payload.companyDocsInfo.map((docInfo, index) => ({
        ...state.companyDocsInfo[index],
        ...docInfo,
      }))

      state.companyDocsInfo = newDocs
    },
    addCompanyDocuments(state, action: PayloadAction<B64FormDocuments>) {
      state.companyDocuments = action.payload
    },
    addCompanyDocument(
      state,
      action: PayloadAction<{ key: string; document: PersistedDocument }>
    ) {
      state.companyDocuments = {
        ...state.companyDocuments,
        [action.payload.key]: action.payload.document,
      }
    },
    addAmlValues(state, action: PayloadAction<AmlData>) {
      state.amlValues = action.payload
    },
    setHasNoEuBank(state, action: PayloadAction<boolean>) {
      state.hasNoEuBank = action.payload
    },
    flushCorporate() {
      return initialCorporateVerificationState
    },
  },
  extraReducers: builder => {
    builder.addCase(getCompanyDocuments.pending, state => ({
      ...state,
      areCompanyDocsLoading: true,
    }))
    builder.addCase(getCompanyDocuments.fulfilled, (state, action) => ({
      ...state,
      areCompanyDocsLoading: false,
      companyDocsCount: action.payload?.companyDocsData.companyDocsCount,
      companyDocsInfo: action.payload?.companyDocsData.companyDocsInfo,
      companyDocuments: action.payload?.newB64Docs,
    }))
    builder.addCase(getCompanyDocuments.rejected, state => ({
      ...state,
      areCompanyDocsLoading: false,
      companyDocsCount: null,
      companyDocsInfo: null,
      companyDocuments: null,
    }))
    builder.addCase(hydrate, state => state)
  },
})

const { actions, reducer } = corporateVerificationSlice

export const {
  changeCurrentStep,
  changeAvailableStep,
  progressToStep,
  completeCorpVerification,
  addFormValues,
  addApplicationValues,
  addRepresentatives,
  addRepDocuments,
  addRepDocument,
  addCompanyDocumentsData,
  addCompanyDocuments,
  addCompanyDocument,
  addAmlValues,
  setHasNoEuBank,
  flushCorporate,
} = actions

export default reducer
