import styled from 'styled-components'
import { rotation } from '@fe/common/constants/animations'
import SpinnerIcon from '@fe/common/src/icons/spinner.svg'

export const Spinner = styled(SpinnerIcon)<{ fill?: string }>`
  display: block;
  width: 2rem;
  height: 2rem;
  margin: 0 auto;
  animation: ${rotation} 1s infinite;

  path {
    fill: ${({ fill }) => fill};
  }
`
