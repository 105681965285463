import { i18n } from 'next-i18next'
import { LocaleName } from '../types/LocaleName'

export const getOrdinal = (number: number) => {
  const lang = i18n.language as LocaleName

  const pluralRules = new Intl.PluralRules(lang, { type: 'ordinal' })

  const postfixes: Record<LocaleName, Record<Intl.LDMLPluralRule, string>> = {
    en: {
      zero: 'th',
      one: 'st',
      two: 'nd',
      few: 'rd',
      other: 'th',
      many: 'th',
    },
    cs: {
      zero: '.',
      one: '.',
      two: '.',
      few: '.',
      other: '.',
      many: '.',
    },
    ru: {
      zero: 'й',
      one: 'й',
      two: 'й',
      few: 'й',
      other: 'й',
      many: 'й',
    },
  }

  return `${number}${postfixes[lang][pluralRules.select(number)]}`
}

export const isDefined = (variable: number) => variable != null
