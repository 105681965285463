import { yupResolver } from '@hookform/resolvers/yup'
import { Trans, useTranslation } from 'next-i18next'
import { useCallback, useMemo, useRef, useState } from 'react'
import { DeepMap, FieldError, FieldErrors, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { CardSendRequest } from '@fe/common/api/models/RequestModels/SendRequests/CardSendRequest'
import { CryptoSendRequest } from '@fe/common/api/models/RequestModels/SendRequests/CryptoSendRequest'
import { CzkSendRequest } from '@fe/common/api/models/RequestModels/SendRequests/CzkSendRequest'
import { InterbankSendRequest } from '@fe/common/api/models/RequestModels/SendRequests/InterbankSendRequest'
import { InternalSendRequest } from '@fe/common/api/models/RequestModels/SendRequests/InternalSendRequest'
import { MtSendRequest } from '@fe/common/api/models/RequestModels/SendRequests/MtSendRequest'
import { SepaSendRequest } from '@fe/common/api/models/RequestModels/SendRequests/SepaSendRequest'
import { WireSendRequest } from '@fe/common/api/models/RequestModels/SendRequests/WireSendRequest'
import { RequestTypeEnum } from '@fe/common/api/models/enums/RequestTypeEnum'
import { TransactionTypeEnum } from '@fe/common/api/models/enums/TransactionTypeEnum'
import { Modal } from '@fe/common/components/Modal'
import { DropdownOptions } from '@fe/common/components/inputs/CustomSelect'
import { useCancelToken } from '@fe/common/hooks/useCancelToken'
import { useDidUpdate } from '@fe/common/hooks/useDidUpdate'
import { useDidUpdateLayout } from '@fe/common/hooks/useDidUpdateLayout'
import { ValidationApi } from '@fe/common/src/api/ValidationApi'
import { Button } from '@fe/common/src/components/Button'
import { SmartForm } from '@fe/common/src/components/SmartForm'
import { ButtonContainer } from '@fe/common/src/components/formStyles'
import { COLOR } from '@fe/common/src/constants/main'
import { ActionTypeEnum } from '@fe/common/types/enums/ActionTypeEnum'
import { SendStepsEnum } from '@fe/common/types/enums/SendStepsEnum'
import { formatResponseError } from '@fe/common/utils/axiosUtils'
import { getDefinedFields, jsonEqual } from '@fe/common/utils/objectUtils'
import { ActionParams } from '../ActionParams'
import { AmountSection } from '../AmountSection'
import { getDefaultTransactionValues } from '../getDefaultTransactionValues'
import { useOffer } from '../useOffer'
import { SendSteps } from './RequestTypeSteps'
import { MfaStep } from './RequestTypeSteps/MfaStep'
import { SuccessStep } from './RequestTypeSteps/SuccessStep'
import { SummaryStep } from './RequestTypeSteps/SummaryStep'
import {
  getStepsConfig,
  MtCashFields,
  MtCardFields,
  SendData,
  InternalFields,
} from './RequestTypeSteps/stepsConfig'
import { SendButtons } from './SendButtons'
import { Stepper, stepNames } from './Stepper'
import { sendSchema, WithdrawContext } from './sendSchema'
import { useMtData } from './useMtData'
import { WithdrawApi } from 'src/api/WithdrawApi'
import { MfaData } from 'src/components/MfaInput'
import { updateTransations } from 'src/state/actions/globalDataActions'
import { updateBalances } from 'src/state/reducers/globalDataReducer'
import { closeSendModal } from 'src/state/reducers/sendModalReducer'
import {
  currencyDetailsSelector,
  detailsLoadingSelector,
} from 'src/state/selectors/globalDataSelectors'
import {
  selectedSendCurrIdSelector,
  selectedSendTransSelector,
} from 'src/state/selectors/sendModalSelectors'

const StepHeader = styled.h3`
  margin: 2rem 0;
  text-align: center;
`

const RiskModal = styled(Modal)`
  p + p {
    margin-top: 1rem;
  }
`

const partialDefaultValues: Omit<SendData, 'settings_id' | 'request_type'> = {
  amount_in: null,
  amount_out: null,
  payment_date: null,
  process_manager_id: false,
  requestTypeFields: {
    address_line: null,
    address: null,
    bank_account_number: null,
    bank_address: null,
    bank_city: null,
    bank_code: null,
    bank_country_id: null,
    bank_id: null,
    bank_name: null,
    bank_zip: null,
    beneficiary_name: null,
    beneficiary_phone: null,
    city: null,
    constant_symbol: null,
    country_id: null,
    first_name: null,
    last_name: null,
    mt_branch_id: null,
    card_number: null,
    mt_city_id: null,
    mt_country_id: null,
    mt_payment_purpose: null,
    mt_payment_system_id: null,
    payment_details: null,
    specific_symbol: null,
    variable_symbol: null,
    zip: null,
  },
}

export const SendModal: React.FC = () => {
  const { t } = useTranslation('transactions')

  const currencyDetails = useSelector(currencyDetailsSelector, jsonEqual)
  const areDetailsLoading = useSelector(detailsLoadingSelector)
  const prefilledCurrencyId = useSelector(selectedSendCurrIdSelector)
  const prefilledTransaction = useSelector(selectedSendTransSelector, jsonEqual)

  const [step, setStep] = useState(SendStepsEnum.General)
  const [mfaData, setMfaData] = useState<MfaData>()
  const [mfaError, setMfaError] = useState('')
  const [interbankBankOptions, setInterbankBanksOptions] =
    useState<DropdownOptions>([])
  const [isLiabilityWarningShown, setIsLiabilityWarningShown] = useState(false)
  const [liabilityName, setLiabilityName] = useState('')

  const defaultValues = getDefaultTransactionValues<SendData>(
    TransactionTypeEnum.Withdraw,
    currencyDetails,
    prefilledCurrencyId,
    areDetailsLoading,
    partialDefaultValues,
    prefilledTransaction
  )

  const [context, setContext] = useState<WithdrawContext>({
    targetSettingId: defaultValues.settings_id,
    isMtCityRequired: null,
    isMtBranchRequired: null,
    max_amount_in: null,
    max_amount_out: null,
    isStandingOrder: null,
    isLiabilityAccepted: null,
  })

  const formMethods = useForm<SendData>({
    resolver: yupResolver(sendSchema),
    mode: 'onBlur',
    context,
    reValidateMode: 'onBlur',
    shouldUnregister: false,
    defaultValues,
  })

  const {
    watch,
    reset,
    formState,
    errors,
    trigger,
    clearErrors,
    getValues,
    setValue,
    setError,
    handleSubmit,
  } = formMethods

  const { isSubmitting } = formState

  const selectedCurrencyId = watch('settings_id')
  const requestType = watch('request_type')
  const internalNumber = watch('requestTypeFields.bank_account_number')

  const modalContentRef = useRef<HTMLDivElement>()
  const dispatch = useDispatch()
  const cancelToken = useCancelToken()
  const offerMethods = useOffer({
    actionType: ActionTypeEnum.Withdraw,
    getValues,
    setValue,
    setError,
    clearErrors,
  })
  const isWithOffer = !!offerMethods.offer || offerMethods.isOfferLoading
  const mtData = useMtData(
    context?.targetSettingId,
    requestType,
    watch,
    setValue,
    setError,
    setContext
  )

  useDidUpdateLayout(() => {
    if (!selectedCurrencyId && !areDetailsLoading) {
      reset(defaultValues)
    }
  }, [areDetailsLoading])

  useDidUpdateLayout(() => {
    reset({
      settings_id: selectedCurrencyId,
      request_type: requestType,
      ...partialDefaultValues,
    })

    setContext(current => ({ ...current, isLiabilityAccepted: null }))
    setLiabilityName('')
  }, [requestType])

  useDidUpdateLayout(() => {
    modalContentRef.current?.scrollTo({ top: 0 })

    setMfaData(null)
    setMfaError('')
  }, [step])

  useDidUpdate(() => {
    if (context.isStandingOrder) {
      clearErrors('amount_in')
    } else {
      trigger('amount_in')
    }
  }, [context.isStandingOrder])

  useDidUpdate(() => {
    if (requestType === RequestTypeEnum.Internal) {
      setContext(current => ({ ...current, isLiabilityAccepted: null }))
      setLiabilityName('')
    }
  }, [internalNumber])

  useDidUpdate(() => {
    if (context.isLiabilityAccepted) {
      setIsLiabilityWarningShown(false)

      handleSubmit(onSubmit, incrementStep)()
    }
  }, [context.isLiabilityAccepted])

  const typeSteps = useMemo(
    () =>
      requestType
        ? Object.keys(getStepsConfig()[requestType]).map(
            type => Number(type) as SendStepsEnum
          )
        : null,
    [requestType]
  )

  const decrementStep = useCallback(() => {
    const currentTypeConfig = getStepsConfig()[requestType]

    const availableSteps = Object.keys(currentTypeConfig).map(key =>
      Number(key)
    ) as SendStepsEnum[]

    let previousStep

    switch (step) {
      case SendStepsEnum.Mfa:
        previousStep = SendStepsEnum.Summary
        break

      case SendStepsEnum.Summary:
        previousStep = availableSteps[availableSteps.length - 1]
        break

      default:
        previousStep = step - 1
        break
    }

    setStep(previousStep)
  }, [requestType, step])

  const incrementStep = useCallback(
    async (submitErrors?: FieldErrors<SendData>) => {
      if (submitErrors) {
        if (step === SendStepsEnum.Success) {
          return
        }

        const currentTypeConfig = getStepsConfig()[requestType]

        let currentStepFields = currentTypeConfig[step].map(
          fieldConfig => fieldConfig.name as keyof SendData
        )

        if (step === SendStepsEnum.General) {
          currentStepFields = [...currentStepFields, 'amount_in']
        }

        const { requestTypeFields: requestTypeErrors, ...generalErrors } =
          submitErrors

        const errorKeys = Object.keys({
          ...generalErrors,
          ...requestTypeErrors,
        })

        const isInvalid = errorKeys.some(error =>
          currentStepFields.includes(error as keyof SendData)
        )

        const internalNumberError = (
          requestTypeErrors as DeepMap<InternalFields, FieldError>
        )?.bank_account_number

        const isLiabilityEror =
          errorKeys.length === 1 &&
          internalNumberError &&
          internalNumberError.type === 'receiver-liability'

        if (isInvalid && !isLiabilityEror) {
          return
        }

        if (isLiabilityEror && !context.isLiabilityAccepted) {
          const internalNumber = getValues(
            'requestTypeFields.bank_account_number'
          ) as string

          const {
            data: { name },
          } = await ValidationApi.internalNumber(
            selectedCurrencyId,
            internalNumber
          )

          setLiabilityName(name)
          setIsLiabilityWarningShown(true)

          return
        }
      }

      const isLastAvailableStep = step + 1 === typeSteps.length // 1 for summary 1 for conversion

      const nextStep = isLastAvailableStep ? SendStepsEnum.Summary : step + 1

      clearErrors()
      setStep(nextStep)
    },
    [step, requestType, errors, context]
  )

  const onSubmit = async (formData: SendData) => {
    if (step < SendStepsEnum.Summary) {
      incrementStep()

      return
    }

    const { amount_in, requestTypeFields, ...restData } = formData

    const modifiedData = {
      amount: amount_in,
      ...restData,
      ...requestTypeFields,
      ...mfaData,
    }

    const relevantFields = getDefinedFields(modifiedData)

    try {
      if (!isWithOffer) {
        const withdrawRequest = { preparation: !mfaData, ...relevantFields }

        switch (requestType) {
          case RequestTypeEnum.WireTransfer:
            await WithdrawApi.wire(withdrawRequest as WireSendRequest, {
              cancelToken,
            })
            break

          case RequestTypeEnum.Sepa:
            await WithdrawApi.sepa(withdrawRequest as SepaSendRequest, {
              cancelToken,
            })
            break

          case RequestTypeEnum.CzkPayments:
            await WithdrawApi.czk(withdrawRequest as CzkSendRequest, {
              cancelToken,
            })
            break

          case RequestTypeEnum.Internal:
            const internalRequest = {
              ...withdrawRequest,
              sender_liability_warning: context.isLiabilityAccepted,
            } as InternalSendRequest

            await WithdrawApi.internal(internalRequest, {
              cancelToken,
            })
            break

          case RequestTypeEnum.Crypto:
            await WithdrawApi.crypto(withdrawRequest as CryptoSendRequest, {
              cancelToken,
            })
            break

          case RequestTypeEnum.Interbank:
            await WithdrawApi.interbank(
              withdrawRequest as InterbankSendRequest,
              { cancelToken }
            )
            break

          case RequestTypeEnum.MtCash: {
            const {
              mt_country_id,
              mt_payment_system_id,
              mt_city_id,
              mt_branch_id,
            } = getValues('requestTypeFields') as MtCashFields

            const mt_country_name = mtData.cashCountries.find(
              country => country?.id === mt_country_id
            )?.name
            const mt_payment_system_name = mtData.cashSystems.find(
              system => system?.id === mt_payment_system_id
            )?.name
            const mt_city_name = mtData.cashCities.find(
              city => city?.id === mt_city_id
            )?.name
            const mt_branch_name = mtData.cashBranches.find(
              branch => branch?.id === mt_branch_id
            )?.name

            const mtCashRequest = {
              ...withdrawRequest,
              mt_country_name,
              mt_payment_system_name,
              mt_city_name,
              mt_branch_name,
            } as unknown as MtSendRequest

            await WithdrawApi.mt(selectedCurrencyId, mtCashRequest, {
              cancelToken,
            })

            break
          }
          case RequestTypeEnum.MtCards: {
            const { mt_country_id } = getValues(
              'requestTypeFields'
            ) as MtCardFields

            const mt_country_name = mtData.cardsCountries.find(
              country => country?.id === mt_country_id
            )?.name

            const mtCardRequest = {
              ...withdrawRequest,
              mt_country_name,
            } as unknown as CardSendRequest

            await WithdrawApi.card(
              selectedCurrencyId,
              mtCardRequest as CardSendRequest,
              {
                cancelToken,
              }
            )

            break
          }
        }
      } else {
        const orderRequest = {
          offer_id: offerMethods.offer.id,
          ...relevantFields,
        }

        await WithdrawApi.makeOrder(orderRequest, { cancelToken })
      }

      reset(defaultValues)
      setMfaData(undefined)
      setStep(SendStepsEnum.Success)
      offerMethods.resetOffer()

      dispatch(updateTransations())
      dispatch(updateBalances())
    } catch (error) {
      setMfaError(formatResponseError(error, t))

      console.error(error)
    }
  }

  const setTargetSettingsId = (id: number) =>
    setContext(current => ({
      ...current,
      targetSettingId: id,
    }))

  const handleModalClose = useCallback(() => dispatch(closeSendModal()), [])

  if (step === SendStepsEnum.Success) {
    return <SuccessStep handleRepeat={() => setStep(SendStepsEnum.General)} />
  }

  return (
    <Modal
      title={t('send-money')}
      onClose={handleModalClose}
      hasWarningOnClose
      maxWidth="70rem"
      ref={modalContentRef}
    >
      <SmartForm
        formMethods={formMethods}
        onSubmit={onSubmit}
        onInvalid={incrementStep}
      >
        <ActionParams partialDefaultValues={partialDefaultValues} step={step} />

        <Stepper steps={typeSteps} currentStep={step} setStep={setStep} />

        <StepHeader>
          {t(stepNames[step]) || t(stepNames[SendStepsEnum.General])}
        </StepHeader>

        {step === SendStepsEnum.General && (
          <AmountSection
            transactionType={TransactionTypeEnum.Withdraw}
            offerMethods={offerMethods}
            setTargetSettingsId={setTargetSettingsId}
            setContext={setContext}
          />
        )}

        {step < SendStepsEnum.Summary && (
          <SendSteps
            step={step}
            mtData={mtData}
            interbankBankOptions={interbankBankOptions}
            setInterbankBanksOptions={setInterbankBanksOptions}
          />
        )}

        {step === SendStepsEnum.Summary && (
          <SummaryStep
            interbankBankOptions={interbankBankOptions}
            offerMethods={offerMethods}
            mtData={mtData}
            isLiabilityAccepted={context.isLiabilityAccepted}
          />
        )}

        {step === SendStepsEnum.Mfa && (
          <MfaStep
            setMfaData={setMfaData}
            errorMessage={mfaError}
            isOrder={isWithOffer}
          />
        )}

        <SendButtons
          step={step}
          decrementStep={decrementStep}
          incrementStep={incrementStep}
          isSubmitting={isSubmitting}
          isMfaEntered={!!mfaData}
          isWithPrepare={!isWithOffer}
        />

        {isLiabilityWarningShown && (
          <RiskModal title={t('risk-warning')} isWithoutHeaderSeparator>
            <Trans
              t={t}
              i18nKey="liability-risk-warning"
              components={{ p: <p />, b: <b /> }}
              values={{ name: liabilityName }}
            />

            <ButtonContainer>
              <Button
                onClick={() => setIsLiabilityWarningShown(false)}
                isFullWidth
                color={COLOR.RED}
              >
                {t('common:decline')}
              </Button>
              <Button
                onClick={() => {
                  setContext(current => ({
                    ...current,
                    isLiabilityAccepted: true,
                  }))
                }}
                isLoading={isSubmitting}
                isFullWidth
              >
                {t('common:confirm')}
              </Button>
            </ButtonContainer>
          </RiskModal>
        )}
      </SmartForm>
    </Modal>
  )
}
