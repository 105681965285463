import styled, { keyframes } from 'styled-components'

const dotMove = keyframes`
  from, to, 20%, 80% {
    transform:translateY(0);
  }

  50% {
    transform:translateY(-0.15rem);
  } 
`

const Dot = styled.span`
  display: inline-block;
  animation: ${dotMove} 0.6s ease-in infinite;

  :nth-child(2) {
    animation-delay: 0.15s;
  }

  :nth-child(3) {
    animation-delay: 0.3s;
  }
`

export const LoadingDots: React.FC = () => (
  <span>
    <Dot>.</Dot>
    <Dot>.</Dot>
    <Dot>.</Dot>
  </span>
)
