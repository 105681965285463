import { ReactElement } from 'react'
import styled from 'styled-components'
import { COLOR, FONT_WEIGHT } from '@fe/common/constants/main'
import { formatTestId } from '../utils/stringUtils'
import { Separator } from './Separator'

const Tab = styled.div<{ isActive: boolean }>`
  position: relative;
  display: inline-block;
  padding: 1rem 1rem;
  border-bottom: 3px solid
    ${({ isActive, theme }) => (isActive ? theme.primary : 'transparent')};
  color: ${({ isActive, theme }) => (isActive ? theme.primary : COLOR.GREY)};
  font-weight: ${FONT_WEIGHT.SEMIBOLD};

  cursor: pointer;

  :not(:last-child) {
    margin-right: 1rem;
  }

  :hover {
    border-color: ${({ isActive }) => !isActive && COLOR.GREY};
  }

  span {
    display: block;
    transform: ${({ isActive }) => isActive && 'scale(1.02) translateY(-2px)'};
    transition: transform 0.1s ease-in-out;
  }
`

const StyledSeparator = styled(Separator)<{ hasSmallMargin: boolean }>`
  margin: ${({ hasSmallMargin }) => (hasSmallMargin ? '0 0 2rem' : '0 0 3rem')};
`

interface TabsProps<T extends string | number> {
  currentTab: T
  tabs: Map<T, { title: string; value: React.ReactElement }>
  onTabChange: (selectedTab: T) => void
  hasSmallMargin?: boolean
}

export function Tabs<T extends string | number>({
  currentTab,
  tabs,
  onTabChange,
  hasSmallMargin,
}: TabsProps<T>): ReactElement {
  return (
    <div>
      <div>
        {Array.from(tabs).map(([key, { title }]) => (
          <Tab
            isActive={key === currentTab}
            onClick={() => onTabChange(key)}
            key={key}
            data-test-id={`${formatTestId(title)}_tab`}
          >
            <span>{title}</span>
          </Tab>
        ))}

        <StyledSeparator hasSmallMargin={!!hasSmallMargin} />
      </div>

      {tabs.get(currentTab).value}
    </div>
  )
}
