import styled, { css } from 'styled-components'
import {
  BORDER,
  COLOR,
  FONT_SIZE,
  FONT_WEIGHT,
  SCREEN,
} from '@fe/common/constants/main'
import { Button } from './Button'

export const ButtonContainer = styled.div<{
  isCentered?: boolean
  isForModal?: boolean
  isStacked?: boolean
  isInline?: boolean
}>`
  display: flex;
  justify-content: ${({ isCentered }) => isCentered && 'center'};
  margin-top: ${({ isInline }) => !isInline && '5rem'};
  margin-left: ${({ isInline }) => isInline && 'auto'};

  ${({ isForModal }) =>
    isForModal &&
    css`
      margin: 3rem -3rem 0;
      padding: 3rem 3rem 0;
      border-top: ${BORDER.DEFAULT};
    `};

  > :not(:last-child) {
    margin-right: 1.5rem;
  }

  ${SCREEN.BELOW_TABLET} {
    ${({ isStacked }) =>
      isStacked &&
      css`
        flex-direction: column;

        button {
          width: 100%;

          :not(:last-child) {
            margin: 0 0 1rem 0;
          }
        }
      `};
  }
`

export const BackButton = styled(Button)`
  background: ${COLOR.WHITE};
  color: ${COLOR.GREY};

  :hover {
    background: ${COLOR.LIGHTER_GREY};
    opacity: 1;
  }
`

export const LoadingText = styled.span`
  font-size: ${FONT_SIZE.EXTRA_SMALL};
  align-self: center;
  color: ${COLOR.GREEN};
  margin-left: 2rem;
`

export interface FieldGroupProps {
  isBlock?: boolean
  gridColumns?: string
}

export const FieldGroup = styled.div<FieldGroupProps>`
  display: ${({ isBlock }) => (isBlock ? 'block' : 'grid')};

  ${SCREEN.ABOVE_MOBILE} {
    grid-template-columns: ${({ gridColumns }) => gridColumns || '1fr 1fr'};
    grid-gap: 0 2rem;
  }

  & + & {
    margin-top: 3rem;
  }
`

export const Question = styled.p`
  margin-bottom: 1rem;
  font-weight: ${FONT_WEIGHT.SEMIBOLD};

  ${SCREEN.ABOVE_MOBILE} {
    grid-column: 1/3;
  }
`

export const QuestionNumber = styled.span`
  margin-right: 1rem;
  color: ${COLOR.GREY};
`
