export enum ApplicationQuestionTypeEnum {
  BeneficiariesNumber = 1,
  Activity = 2,
  TransactionsPurpose = 3,
  FundsSource = 4,
  IncommingGeography = 5,
  OutgoingGeography = 6,
  ActivityDetails = 7,
  IncomingDescription = 8,
  OutgoingDescription = 9,
  MainPartners = 10,
  PaymentsType = 11,
  BanksList = 12,
  Employees = 13,
  TaxResidence = 14,
  RepresentsOwnInterests = 15,
  RepresentsCompanyInterests = 16,
  CorporateLawForm = 17,
  PrivateSector = 18,
  EntityBranches = 19,
  CorporateOwners = 20,
  WealthSource = 21,
}
