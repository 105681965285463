import Axios, { AxiosPromise } from 'axios'
import { History } from '@fe/common/api/models/History'
import { HistoryRequest } from '@fe/common/api/models/RequestModels/HistoryRequest'
import { StatementRequest } from '@fe/common/api/models/RequestModels/StatementRequest'
import { Transaction } from '@fe/common/api/models/Transaction'
import { RequestConfig } from '@fe/common/axiosConfig'

export class HistoryApi {
  static getHistory(
    settingsId: number,
    params: HistoryRequest,
    config?: RequestConfig
  ): AxiosPromise<History> {
    return Axios({
      ...config,
      url: `/history/settings/${settingsId}`,
      method: 'GET',
      params,
    })
  }

  static getAccountHistory(
    accountId: number,
    params?: HistoryRequest,
    config?: RequestConfig
  ): AxiosPromise<Array<Transaction>> {
    return Axios({
      ...config,
      url: `/history/account/${accountId}`,
      method: 'GET',
      params,
    })
  }

  static attachFiles(
    requestId: number,
    uploadedFiles: string[],
    config?: RequestConfig
  ): AxiosPromise<null> {
    return Axios({
      ...config,
      url: `/history/file/${requestId}`,
      method: 'POST',
      data: {
        uploaded_files: uploadedFiles,
      },
    })
  }

  static getPaymentConfirmation(
    transactionId: number,
    lang: string,
    config?: RequestConfig
  ): AxiosPromise<Blob> {
    return Axios({
      ...config,
      url: `/history/payment-confirmation/${transactionId}/${lang}`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  static getStatements(
    settingsId: number,
    params?: StatementRequest,
    config?: RequestConfig
  ): AxiosPromise<History> {
    return Axios({
      ...config,
      url: `/statement/${settingsId}`,
      method: 'GET',
      params,
    })
  }

  static exportStatements(
    settingsId: number,
    lang: string,
    executed_timestamp_from: number,
    executed_timestamp_to: number,
    config?: RequestConfig
  ): AxiosPromise<Blob> {
    return Axios({
      ...config,
      url: `/statement/export/${settingsId}/${lang}`,
      method: 'GET',
      responseType: 'blob',
      params: {
        executed_timestamp_from,
        executed_timestamp_to,
      },
    })
  }
}
