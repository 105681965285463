export const b64toBlob = (
  b64Data: string,
  contentType = '',
  sliceSize = 512
): Blob => {
  let finalString = b64Data

  if (b64Data.startsWith('data:')) {
    const dataStartIndex = b64Data.indexOf('base64,') + 7
    finalString = b64Data.substring(dataStartIndex)
  }

  const byteCharacters = atob(finalString)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })

  return blob
}

export const getBase64String = (type: string, data: string) =>
  `data:${type};base64,${data}`

export const fileToBase64 = async (file: File) => {
  const arrayBuffer = await file.arrayBuffer()

  let binary = ''
  const bytes = new Uint8Array(arrayBuffer)
  const len = bytes.byteLength

  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }

  const b64String = getBase64String(file.type, btoa(binary))

  return b64String
}

export const base64ToFile = (base64String: string, filename: string): File => {
  const arr = base64String.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bitString = atob(arr[1])
  let n = bitString.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bitString.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}
