import { useTranslation } from 'next-i18next'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { shallowEqual, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Fee } from '@fe/common/api/models/Fee'
import { Amount } from '@fe/common/components/Amount'
import { LabeldList } from '@fe/common/components/LabeldList'
import { LoadingDots } from '@fe/common/components/LoadingDots'
import { DropdownOptions } from '@fe/common/components/inputs/CustomSelect'
import {
  COLOR,
  FONT_SIZE,
  FONT_WEIGHT,
  SCREEN,
} from '@fe/common/constants/main'
import { useCancelToken } from '@fe/common/hooks/useCancelToken'
import Warning from '@fe/common/icons/warning-solid.svg'
import { formatDateToString } from '@fe/common/utils/dateUtils'
import { getWithdrawFee } from '../../../AmountSection'
import { ExchangeOffer } from '../../../ExchangeOffer'
import { MtData } from '../../useMtData'
import { getStepsConfig, SendData } from '../stepsConfig'
import { getSummaryConfig } from './summaryConfig'
import { OfferMethods } from 'src/components/actionModals/useOffer'
import {
  countriesSelector,
  currentAccountSelector,
} from 'src/state/selectors/globalDataSelectors'
import { SendStepsEnum } from 'src/types/enums/SendStepsEnum'

const StyledExchangeOffer = styled(ExchangeOffer)`
  margin: 0 auto;
  margin-bottom: 0;
`

const Container = styled.div`
  ${SCREEN.ABOVE_MOBILE} {
    padding: 0 4rem;
  }
`

const RiskNote = styled.p`
  display: flex;
  align-self: center;
  margin-top: 3rem;
  color: ${COLOR.RED};
  font-size: ${FONT_SIZE.SMALL};
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
`

const WarningIcon = styled(Warning)`
  width: 2.2rem;
  height: 2.2rem;
  margin-right: 1rem;
`

interface SummaryStepProps {
  interbankBankOptions: DropdownOptions
  offerMethods: OfferMethods
  mtData: MtData
  isLiabilityAccepted: boolean
}

export const SummaryStep: React.FC<SummaryStepProps> = ({
  interbankBankOptions,
  offerMethods,
  mtData,
  isLiabilityAccepted,
}) => {
  const { t } = useTranslation('transactions')
  const countries = useSelector(countriesSelector, shallowEqual)
  const { currencies } = useSelector(currentAccountSelector)

  const { offer, isOfferLoading } = offerMethods
  const defaultFee: Fee = offer && {
    fee: offer?.fee_in,
    precision: offer?.precision_in,
    amount: null,
    total_amount: null,
  }

  const [fee, setFee] = useState<Fee>(defaultFee)
  const [isFeeLoading, setIsFeeLoading] = useState(false)

  const { watch, getValues } = useFormContext<SendData>()

  const { settings_id, request_type } = watch(['settings_id', 'request_type'])
  const { amount_in, amount_out } = watch(['amount_in', 'amount_out'])

  const cancelToken = useCancelToken()

  useEffect(() => {
    getWithdrawFee(getValues, setIsFeeLoading, setFee, cancelToken)
  }, [])

  const selectedCurrency = currencies.find(({ id }) => id === settings_id)
  const { code: selectedCurrencyCode, precision: selectedCurrencyPrecision } =
    selectedCurrency

  const allFieldsConfig = Object.keys(getStepsConfig()[request_type]).map(
    step => Number(step)
  ) as Array<SendStepsEnum>

  const formValues = getValues()

  const summaryFields = useMemo(
    () =>
      allFieldsConfig.map(step => (
        <LabeldList key={step}>
          {getSummaryConfig(
            formValues,
            countries,
            interbankBankOptions,
            mtData
          )[request_type][step].map(({ label, value }) => (
            <Fragment key={label}>
              <span>{t(label)}</span>
              <span data-test-id={label}>{value}</span>
            </Fragment>
          ))}
        </LabeldList>
      )),
    []
  )

  return (
    <>
      {(offerMethods.offer || offerMethods.isOfferLoading) && (
        <StyledExchangeOffer offerMethods={offerMethods} />
      )}

      <Container>
        <LabeldList>
          <span>{amount_out ? t('amount-charged') : t('amount')}</span>
          {isOfferLoading ? (
            <LoadingDots />
          ) : (
            <Amount
              testId="amount-in"
              value={amount_in}
              decimals={offer?.precision_in || selectedCurrencyPrecision}
              code={selectedCurrencyCode}
            />
          )}

          <span>{t('fee')}</span>
          <span>
            {isFeeLoading || isOfferLoading ? (
              <LoadingDots />
            ) : offer ? (
              <Amount
                value={offer?.fee_in}
                code={selectedCurrencyCode}
                decimals={offer?.precision_in}
              />
            ) : fee ? (
              <Amount
                testId="fee"
                value={fee.fee}
                code={selectedCurrencyCode}
                decimals={fee.precision}
              />
            ) : (
              '-'
            )}
          </span>

          {amount_out && (
            <>
              <span>{t('amount-sent')}</span>
              {isOfferLoading ? (
                <LoadingDots />
              ) : (
                <Amount
                  testId="amount-out"
                  value={amount_out}
                  decimals={offer?.precision_out}
                  code={offer?.currency_out}
                />
              )}
            </>
          )}

          {typeof formValues.process_manager_id === 'boolean' && (
            <>
              <span>{t('prepared-payment')}</span>
              <span data-test-id="process-manager-id">
                {formValues.process_manager_id
                  ? t('common:yes')
                  : t('common:no')}
              </span>
            </>
          )}

          {formValues.payment_date && (
            <>
              <span>{t('prepared-payment')}</span>
              <span data-test-id="payment-date">
                {formatDateToString(formValues.payment_date)}
              </span>
            </>
          )}
        </LabeldList>

        {summaryFields}

        {isLiabilityAccepted && (
          <RiskNote>
            <WarningIcon /> {t('summary-risk-note')}
          </RiskNote>
        )}
      </Container>
    </>
  )
}
