import { DropdownOptions } from '@fe/common/components/inputs/CustomSelect'

export enum FieldTypesEnum {
  Text,
  Number,
  Date,
  Dropdown,
}

export type FieldConfig<T> = {
  type: FieldTypesEnum
  name: keyof T
  label: string
  options?: DropdownOptions
  isOptional?: boolean
  tip?: string
}
