import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import { Rate } from '@fe/common/components/Rate'
import { Spinner } from '@fe/common/components/Spinner'
import { COLOR, FONT_SIZE, FONT_WEIGHT } from '@fe/common/constants/main'
import { OfferMethods } from './useOffer'

const Container = styled.div<{ isShown: boolean }>`
  display: flex;
  align-items: center;
  width: fit-content;
  min-width: 20rem;
  height: ${({ isShown }) => (isShown ? '3.5rem' : 0)};
  margin: 0 0 0 auto;
  margin-bottom: ${({ isShown }) => isShown && '3rem'};
  padding: 0 1.5rem;
  background: ${COLOR.OFF_WHITE};
  border-radius: 3px;
  font-size: ${FONT_SIZE.SMALL};
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
  transition: height 0.2s ease-in-out, margin-bottom 0.2s ease-in-out;
  overflow: hidden;

  > :nth-child(2) {
    margin: 0 1rem;
    color: ${COLOR.GREY};
  }
`

interface ExchangeOfferProps {
  offerMethods: OfferMethods
  isShown?: boolean
  className?: string
}

export const ExchangeOffer: React.FC<ExchangeOfferProps> = ({
  offerMethods: { offer, timeLeft, isOfferLoading },
  isShown = true,
  className,
}) => {
  const { t } = useTranslation('transactions')

  return (
    <Container isShown={isShown} className={className}>
      {isOfferLoading || !timeLeft ? (
        <Spinner />
      ) : (
        <>
          {offer?.rate && <Rate rate={offer.rate} />}
          <span>•</span>
          <span>
            {t('available-for')} {timeLeft}
          </span>
        </>
      )}
    </Container>
  )
}
