import { ObjectSchema, TestContext } from 'yup'
import { ObjectShape } from 'yup/lib/object'
import { RequestTypeEnum } from '@fe/common/api/models/enums/RequestTypeEnum'
import { Shape } from '@fe/common/src/types/Shape'
import yup from '@fe/common/yup/yupConfig'
import {
  CryptoFields,
  CzkFields,
  InterbankFields,
  InternalFields,
  MtCardFields,
  MtCashFields,
  SepaFields,
  WireFields,
  SendData,
} from './RequestTypeSteps/stepsConfig'

export interface WithdrawContext {
  targetSettingId: number
  isMtCityRequired: boolean
  isMtBranchRequired: boolean
  max_amount_in: number
  max_amount_out: number
  isStandingOrder: boolean
  isLiabilityAccepted: boolean
}

export interface ValidationContext {
  targetSettingId: number
  isMtCityRequired: boolean
  isMtBranchRequired: boolean
}

const wireSchema: Shape<WireFields> = {
  bank_account_number: yup.string().required(),
  bank_code: yup.string().bicSwift().required(),
  payment_details: yup.string().max(60).required(),
  beneficiary_name: yup.string().max(140).required(),
  country_id: yup.number().required(),
  city: yup.string().max(140).required(),
  address_line: yup.string().max(140).required(),
  zip: yup.string().max(140).required(),
  bank_name: yup.string().max(140).required(),
  bank_country_id: yup.number().required(),
  bank_city: yup.string().max(140).required(),
  bank_address: yup.string().max(140).required(),
  bank_zip: yup.string().max(140).required(),
}

const sepaSchema: Shape<SepaFields> = {
  bank_account_number: yup
    .string()
    .iban()
    .matches(/^[a-zA-Z\d\s~!@#\/$%^()_+,.|=-]*$/, 'string.sepa-characters')
    .required(),
  bank_code: yup
    .string()
    .bicSwift()
    .matches(/^[a-zA-Z\d\s~!@#\/$%^()_+,.|=-]*$/, 'string.sepa-characters')
    .required(),
  payment_details: yup
    .string()
    .max(60)
    .matches(/^[a-zA-Z\d\s~!@#\/$%^()_+,.|=-]*$/, 'string.sepa-characters')
    .required(),
  beneficiary_name: yup
    .string()
    .max(140)
    .matches(/^[a-zA-Z\d\s~!@#\/$%^()_+,.|=-]*$/, 'string.sepa-characters')
    .required(),
  country_id: yup.number().required(),
  city: yup
    .string()
    .max(140)
    .matches(/^[a-zA-Z\d\s~!@#\/$%^()_+,.|=-]*$/, 'string.sepa-characters')
    .required(),
  address_line: yup
    .string()
    .max(70)
    .matches(/^[a-zA-Z\d\s~!@#\/$%^()_+,.|=-]*$/, 'string.sepa-characters')
    .required(),
  zip: yup
    .string()
    .max(140)
    .matches(/^[a-zA-Z\d\s~!@#\/$%^()_+,.|=-]*$/, 'string.sepa-characters')
    .required(),
}

const czkSchema: Shape<CzkFields> = {
  bank_account_number: yup.string().czeNumber().required(),
  bank_code: yup.string().czeCode().required(),
  payment_details: yup.string().max(60).required(),
  beneficiary_name: yup.string().max(140).required(),
  variable_symbol: yup.string().max(10).nullable(),
  constant_symbol: yup.string().max(10).nullable(),
  specific_symbol: yup.string().max(10).nullable(),
}

const internalSchema: Shape<InternalFields> = {
  bank_account_number: yup.string().internalNumber().required(),
  payment_details: yup.string().max(60).required(),
}

const cryptoSchema: Shape<CryptoFields> = {
  address: yup.string().cryptoAddress().required(),
  payment_details: yup.string().max(60),
}

const interbankSchema: Shape<InterbankFields> = {
  bank_id: yup.number().required(),
  bank_account_number: yup.string().required(),
  beneficiary_name: yup.string().max(140).required(),
}

const mtCashSchema: Shape<MtCashFields> = {
  first_name: yup.string().max(140).required(),
  last_name: yup.string().max(140).required(),
  beneficiary_phone: yup.string().phone().required(),
  mt_payment_purpose: yup.string().max(140).required(),
  mt_country_id: yup.number().required(),
  mt_payment_system_id: yup.number().required(),
  mt_city_id: yup
    .number()
    .nullable()
    .test(
      'isRequired',
      'mixed.required',
      (value, context: TestContext<WithdrawContext>) => {
        if (context.options.context.isMtCityRequired) {
          return !!value
        }

        return true
      }
    ),
  mt_branch_id: yup
    .number()
    .nullable()
    .test(
      'isRequired',
      'mixed.required',
      (value, context: TestContext<WithdrawContext>) => {
        if (context.options.context.isMtBranchRequired) {
          return !!value
        }

        return true
      }
    ),
}

const mtCardSchema: Shape<MtCardFields> = {
  first_name: yup.string().max(140).required(),
  last_name: yup.string().max(140).required(),
  beneficiary_phone: yup.string().phone().required(),
  mt_payment_purpose: yup.string().max(140).required(),
  card_number: yup.string().cardNumber().required(),
  mt_country_id: yup.number().required(),
}

const schemaMap: Record<number, ObjectShape> = {
  [RequestTypeEnum.WireTransfer]: wireSchema,
  [RequestTypeEnum.Sepa]: sepaSchema,
  [RequestTypeEnum.Internal]: internalSchema,
  [RequestTypeEnum.Crypto]: cryptoSchema,
  [RequestTypeEnum.CzkPayments]: czkSchema,
  [RequestTypeEnum.MtCards]: mtCardSchema,
  [RequestTypeEnum.MtCash]: mtCashSchema,
  [RequestTypeEnum.Interbank]: interbankSchema,
}

const requestEnumValues = Object.values(RequestTypeEnum).slice(
  Object.values(RequestTypeEnum).length / 2
) as unknown as Array<RequestTypeEnum>

export const sendSchema = yup.object().shape<Shape<SendData>>({
  settings_id: yup.number().required(),
  request_type: yup.mixed().oneOf(requestEnumValues).required(),
  amount_in: yup
    .number()
    .test(
      'maxAmount',
      'number.exceeds-max',
      (value, context: TestContext<WithdrawContext>) => {
        if (value != undefined && !context.options.context.isStandingOrder) {
          return value <= context.options.context.max_amount_in
        }

        return true
      }
    )
    .required(),
  amount_out: yup.number().nullable(),
  process_manager_id: yup.bool().nullable(),
  payment_date: yup.date().nullable(),
  requestTypeFields: yup
    .object()
    .when(
      'request_type',
      (request_type: RequestTypeEnum, schema: ObjectSchema<ObjectShape>) =>
        schema.shape(schemaMap[request_type])
    ),
})
