import { Transaction } from '@fe/common/api/models/Transaction'
import { AppState } from 'src/state/store'

export const sendModalStateSelector = (state: AppState): boolean =>
  state.sendModal.isOpened

export const selectedSendTransSelector = (state: AppState): Transaction =>
  state.sendModal.repeatTransaction

export const selectedSendCurrIdSelector = (state: AppState): number =>
  state.sendModal.selectedCurrencyId
