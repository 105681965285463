import styled, { keyframes, css } from 'styled-components'
import { blink } from '@fe/common/constants/animations'
import {
  COLOR,
  FONT_SIZE,
  FONT_WEIGHT,
  SCREEN,
} from '@fe/common/constants/main'

export const iconReveal = keyframes`
  0% {
    transform: scale(0.7);
    opacity: 0.8;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
`

interface InputContainerProps {
  withError: boolean
  isFullWidth?: boolean
  isLoading?: boolean
}

export const InputContainer = styled.div<InputContainerProps>`
  position: relative;
  width: 100%;
  min-width: 15rem;
  padding-bottom: ${({ withError }) => withError && '2.8rem'};

  ${({ isLoading }) =>
    isLoading &&
    css`
      pointer-events: none;
      ${blink}

      * {
        color: grey;
      }
    `};

  ${SCREEN.ABOVE_MOBILE} {
    max-width: ${({ isFullWidth }) => (isFullWidth ? '100%' : '30rem')};
  }
`

export interface StyledInputProps {
  hasError?: boolean
  isDisabled?: boolean
  isSmall?: boolean
}

export const baseInputStyle = css<StyledInputProps>`
  width: 100%;
  height: ${({ isSmall }) => (isSmall ? '5.5rem' : '6.5rem')};
  padding: 0 3rem;
  border: 1px solid
    ${({ hasError }) => (hasError ? COLOR.RED : COLOR.LIGHTER_GREY)};
  border-radius: 3px;
  line-height: 6;

  @media not all and (min-resolution: 0.001dpcm) {
    @media {
      line-height: unset;
    }
  }
`

export const disabledInputStyle = css`
  background: ${COLOR.OFF_WHITE};
  color: ${COLOR.GREY};
  pointer-events: none;
`

export const StyledInput = styled.input<StyledInputProps>`
  ${baseInputStyle}

  background: ${COLOR.WHITE};
  transition: background 0.2s ease-in-out;
  -moz-appearance: textfield;

  ${({ isDisabled }) => isDisabled && disabledInputStyle};

  ::placeholder {
    color: ${COLOR.GREY};
    transition: opacity 0.2s ease;
  }

  :focus {
    color: ${COLOR.OFF_BLACK};
    outline: none;

    ::placeholder {
      opacity: 0;
    }
  }

  :hover:not(:disabled) {
    ::placeholder {
      opacity: 0;
    }
  }

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }
`

export const Label = styled.label<{ hasError: boolean; isShown?: boolean }>`
  position: absolute;
  left: 2rem;
  top: -0.8rem;
  width: calc(100% - 2rem);
  color: ${({ hasError }) => (hasError ? COLOR.RED : COLOR.GREY)};
  font-size: ${FONT_SIZE.EXTRA_SMALL};
  font-weight: ${FONT_WEIGHT.SEMIBOLD};

  > :first-child {
    padding: 0 0.3rem;
    background: ${COLOR.WHITE};
    border-radius: 2px;
    opacity: ${({ isShown }) => (isShown ? 1 : 0)};
    transition: opacity 0.2s ease;
  }

  ${StyledInput}:focus-within ~ &, ${StyledInput}:not(:placeholder-shown) ~ & {
    > :first-child {
      opacity: 1;
    }
  }

  @media (hover: hover) {
    ${StyledInput}:hover:not(:disabled) ~ & {
      > :first-child {
        opacity: 1;
      }
    }
  }
`

export const OptionalTag = styled.span`
  position: absolute;
  right: 1rem;
  padding: 0 0.3rem;
  background: ${COLOR.WHITE};
`

export const StatusBar = styled.div<{
  isSuccessfullyFilled: boolean
  isSmall?: boolean
}>`
  position: absolute;
  top: 1px;
  left: 1px;
  height: ${({ isSmall }) => (isSmall ? '5.3rem' : '6.3rem')};
  border-radius: 2px 0 0 2px;
  background: ${({ isSuccessfullyFilled, theme }) =>
    isSuccessfullyFilled ? theme.primary : COLOR.BACKGROUND};
  width: ${({ isSuccessfullyFilled }) => (isSuccessfullyFilled ? '0.5rem' : 0)};
  transform-origin: left;
  transition: width 0.2s ease-in-out, background 0.2s ease;

  ${StyledInput}:focus-within ~ & {
    width: 1rem;
  }
`
