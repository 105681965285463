import Axios, { AxiosPromise } from 'axios'
import { Currency } from '@fe/common/api/models/Currency'
import { Offer } from '@fe/common/api/models/Offer'
import { Order } from '@fe/common/api/models/Order'
import { ExchangeOrderRequest } from '@fe/common/api/models/RequestModels/ExchangeOrderRequest'
import { OfferRequest } from '@fe/common/api/models/RequestModels/OfferRequest'
import { OfferTypeEnum } from '@fe/common/api/models/enums/OfferTypeEnum'
import { RequestConfig } from '@fe/common/axiosConfig'

export class ExchangeApi {
  static getSettings(
    accountId: number,
    offer_type: OfferTypeEnum,
    account_setting_id?: number,
    config?: RequestConfig
  ): AxiosPromise<Array<Currency>> {
    return Axios({
      ...config,
      url: `/exchange/settings/${accountId}`,
      method: 'GET',
      params: {
        offer_type,
        account_setting_id,
      },
    })
  }

  static getOffer(
    data: OfferRequest,
    config?: RequestConfig
  ): AxiosPromise<Offer> {
    return Axios({
      ...config,
      url: `/exchange/offer`,
      method: 'POST',
      data,
    })
  }

  static getOrder(
    data: ExchangeOrderRequest,
    config?: RequestConfig
  ): AxiosPromise<Order> {
    return Axios({
      ...config,
      url: `/exchange/order`,
      method: 'POST',
      data,
    })
  }
}
