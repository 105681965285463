import { useTranslation } from 'next-i18next'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import { CardOrder } from '@fe/common/api/models/CardOrder'
import { MfaActionTypeEnum } from '@fe/common/api/models/enums/MfaActionTypeEnum'
import { Amount } from '@fe/common/components/Amount'
import { Button } from '@fe/common/components/Button'
import { LabeldList } from '@fe/common/components/LabeldList'
import { ButtonContainer } from '@fe/common/components/formStyles'
import { FormattedInput } from '@fe/common/components/inputs/FormattedInput'
import { ReceiveData } from '../..'
import { MfaProps } from '../MtDetails'
import { MfaInput } from 'src/components/MfaInput'

const Header = styled.h3`
  margin: 3rem 0;
  text-align: center;
`

interface MfaStepProps extends MfaProps {
  order: CardOrder
  decrementStep: () => void
  currentCurrencyCode: string
}

export const MfaStep: React.FC<MfaStepProps> = ({
  order,
  decrementStep,
  currentCurrencyCode,
  isMfaFilled,
  setMfaData,
  mfaError,
}) => {
  const { t } = useTranslation('transactions')

  const { formState } = useFormContext<ReceiveData>()

  return (
    <>
      <Header>{t('summary')}</Header>

      <LabeldList>
        <span>{t('amount')}</span>
        <Amount value={order.amount} code={currentCurrencyCode} />

        <span>{t('card-holder-name')}</span>
        <span>{order.card_data.cardholder_name}</span>

        <span>{t('card-number')}</span>
        <span>{order.card_data.card_number}</span>
      </LabeldList>

      <Header>{t('confirm-transaction')}</Header>

      <FormattedInput
        name="requestTypeFields.csc"
        label="CVC"
        isSmall
        format="###"
        autoComplete="cc-csc"
      />

      <MfaInput
        actionType={MfaActionTypeEnum.DepositByCardOrder}
        isFocusedOnMount={false}
        onCodeEnter={setMfaData}
        onRefocusInput={() => setMfaData(undefined)}
        externalError={mfaError}
        isPreparingOnMount={false}
      />

      <ButtonContainer isForModal isCentered>
        <Button isSecondary onClick={decrementStep}>
          {t('common:back')}
        </Button>

        <Button
          isSubmit
          isDisabled={!isMfaFilled}
          isLoading={formState.isSubmitting}
        >
          {t('receive')}
        </Button>
      </ButtonContainer>
    </>
  )
}
