import { FormState, FieldValues, UseFormMethods, get } from 'react-hook-form'
import { Error, Errors } from '@fe/common/types/Errors'

export const getErrorStatus = (
  name: string,
  errors: Errors,
  formState: FormState<FieldValues>
) => {
  if (!name || !errors || !formState) {
    return {
      error: undefined,
      isErrorDisplayed: undefined,
      isSuccessfullyFilled: undefined,
    }
  }

  const error = get(errors, name)
  const formattedError = error instanceof Array ? error[0] : error

  const isDirty = get(formState.dirtyFields, name)

  const isErrorDisplayed =
    !!error &&
    ((isDirty && formattedError.type !== 'required') ||
      formState.submitCount > 0)

  const isSuccessfullyFilled = isDirty && !error

  return { error, isErrorDisplayed, isSuccessfullyFilled }
}

export const getErrorData = (
  error: Error
): { type: string; message: string; params?: unknown } => {
  if (Array.isArray(error)) {
    const type = error[0].type

    const message =
      typeof error[0].message === 'object'
        ? error[0].message?.text
        : error[0].message

    const params =
      typeof error[0].message === 'object' ? error[0].message?.params : null

    return { type, message, params }
  }

  const message =
    typeof error.message === 'object' ? error.message.text : error.message
  const params =
    typeof error.message === 'object' ? error.message?.params : null

  return { type: error.type, message, params }
}

export const getLockedStatus = (
  formContext: UseFormMethods<Record<string, any>>,
  name: string
) => (formContext?.getValues()._meta?.locked as string)?.includes(name)
