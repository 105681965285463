import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { DepositMethod } from '@fe/common/api/models/DepositMethod'
import { WithdrawMethod } from '@fe/common/api/models/WithdrawMethod'
import { Dropdown } from '@fe/common/components/inputs/Dropdown'
import { BORDER, COLOR, SCREEN } from '@fe/common/constants/main'
import {
  getCurrencyWithBalanceOptions,
  getRequestTypeOptions,
} from '@fe/common/constants/options'
import { useDidUpdate } from '@fe/common/hooks/useDidUpdate'
import { useDidUpdateLayout } from '@fe/common/hooks/useDidUpdateLayout'
import { jsonEqual } from '@fe/common/utils/objectUtils'
import { ReceiveData } from './ReceiveModal'
import {
  SendableRequestTypes,
  SendData,
} from './SendModal/RequestTypeSteps/stepsConfig'
import {
  currencyDetailsSelector,
  detailsLoadingSelector,
} from 'src/state/selectors/globalDataSelectors'

const SendParams = styled.div`
  display: grid;
  grid-gap: 0 2rem;
  grid-template-columns: 1fr;
  justify-content: center;
  margin: -4rem -3rem 0rem;
  padding: 3rem 5rem 0.5rem;
  background: ${COLOR.OFF_WHITE};
  border-bottom: ${BORDER.DEFAULT};

  > :first-child {
    margin-right: 2rem;
  }

  ${SCREEN.ABOVE_MOBILE} {
    grid-template-columns: 1fr 1fr;
    padding: 3rem 7rem 0.5rem;
  }
`

interface ActionParamsProps {
  partialDefaultValues: Omit<
    ReceiveData | SendData,
    'settings_id' | 'request_type'
  >
  step?: number
  isDisabled?: boolean
  className?: string
}

export const ActionParams: React.FC<ActionParamsProps> = ({
  partialDefaultValues,
  step,
  isDisabled,
  className,
}) => {
  const { t } = useTranslation('transactions')
  const areDetailsLoading = useSelector(detailsLoadingSelector)
  const currencyDetails = useSelector(currencyDetailsSelector, jsonEqual)

  const isWithdraw = typeof step === 'number'
  const methodsName = isWithdraw ? 'withdraw_methods' : 'deposit_methods'

  const areFieldsDisabled = isWithdraw
    ? step > 0 || areDetailsLoading
    : isDisabled || areDetailsLoading

  const { watch, reset, control, formState, errors, setValue } =
    useFormContext()

  const selectedCurrencyId = watch('settings_id')
  const requestType = watch('request_type')

  const selectedCurrency = useMemo(
    () => currencyDetails?.find(({ id }) => id === selectedCurrencyId),
    [currencyDetails, selectedCurrencyId]
  )

  useDidUpdateLayout(() => {
    const currentCurrencyMethodIds = selectedCurrency?.[methodsName].map(
      (method: DepositMethod | WithdrawMethod) => method.id
    ) as SendableRequestTypes[]

    reset({
      settings_id: selectedCurrencyId,
      request_type: currentCurrencyMethodIds[0],
      ...partialDefaultValues,
    })
  }, [selectedCurrencyId])

  useDidUpdate(() => {
    setValue('amount_in', null) // does not work with useLayout()
  }, [selectedCurrencyId])

  useDidUpdateLayout(
    () =>
      reset({
        settings_id: selectedCurrencyId,
        request_type: requestType,
        ...partialDefaultValues,
      }),
    [requestType]
  )

  const currencyOptions = useMemo(() => {
    if (areDetailsLoading) {
      return null
    }

    const selectableCurrencyIds = currencyDetails.reduce(
      (acc: number[], details) => {
        if (details[methodsName].length) {
          acc.push(details.id)
        }

        return acc
      },
      []
    )

    return getCurrencyWithBalanceOptions(currencyDetails).map(option =>
      selectableCurrencyIds.includes(option.value)
        ? option
        : { ...option, isDisabled: true }
    )
  }, [currencyDetails, areDetailsLoading])

  const requestTypeOptions = useMemo(() => {
    if (areDetailsLoading) {
      return null
    }

    const allOptions = getRequestTypeOptions(t)

    const currentCurrencyMethodIds = selectedCurrency?.[methodsName].map(
      (method: DepositMethod | WithdrawMethod) => method.id
    )

    const filteredOptions = allOptions.filter(({ value }) =>
      currentCurrencyMethodIds?.includes(value)
    )

    return filteredOptions
  }, [currencyDetails, areDetailsLoading, selectedCurrencyId])

  return (
    <SendParams className={className}>
      <Dropdown
        name="settings_id"
        label={t('currency')}
        options={currencyOptions}
        isDisabled={areFieldsDisabled}
        isLoading={areDetailsLoading}
        control={control}
        formState={formState}
        errors={errors}
        isHighlighted
      />
      <Dropdown
        name="request_type"
        label={t('request-type')}
        options={requestTypeOptions}
        isDisabled={areFieldsDisabled}
        isLoading={areDetailsLoading}
        control={control}
        formState={formState}
        errors={errors}
        isHighlighted
      />
    </SendParams>
  )
}
