import Axios, { AxiosPromise } from 'axios'
import { Account } from '@fe/common/api/models/Account'
import { AccountDetails } from '@fe/common/api/models/AccountDetails'
import { CurrencyFeePlan } from '@fe/common/api/models/CurrencyFeePlan'
import { AccountRequestParams } from '@fe/common/api/models/RequestModels/AccountRequestParams'
import { RequestConfig } from '@fe/common/axiosConfig'

export class AccountsApi {
  static getAccounts(
    params: AccountRequestParams,
    config?: RequestConfig
  ): AxiosPromise<Array<Account>> {
    return Axios({
      ...config,
      url: `/accounts`,
      method: 'GET',
      params,
    })
  }

  static getAccountDetails(
    accountId: number,
    config?: RequestConfig
  ): AxiosPromise<AccountDetails> {
    return Axios({
      ...config,
      url: `/accounts/${accountId}`,
      method: 'GET',
    })
  }

  static getAccountFeePlan(
    accountId: number,
    config?: RequestConfig
  ): AxiosPromise<Array<CurrencyFeePlan>> {
    return Axios({
      ...config,
      url: `/accounts/fees/${accountId}`,
      method: 'GET',
    })
  }

  static getAccountMaintenance(
    accountId: number,
    lang: string,
    config?: RequestConfig
  ): AxiosPromise<Blob> {
    return Axios({
      ...config,
      url: `/accounts/maintenance/${accountId}/${lang}`,
      method: 'GET',
      responseType: 'blob',
    })
  }
}
