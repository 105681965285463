import { useRef, useLayoutEffect } from 'react'

export const useDidUpdateLayout = (
  callback: () => void,
  dependencies: any[]
) => {
  const isMounted = useRef(false)

  useLayoutEffect(() => {
    if (isMounted.current) {
      callback()
    } else {
      isMounted.current = true
    }
  }, dependencies)
}
