import React from 'react'
import { Control } from 'react-hook-form'
import { NumberFormatProps } from 'react-number-format'
import { FormattedInput } from './FormattedInput'
import { InputProps } from './Input'

type ParentProps = Omit<
  InputProps,
  'type' | 'max' | 'min' | 'value' | 'defaultValue'
> &
  Omit<NumberFormatProps, 'onBlur'>

interface NumberInputProps extends ParentProps {
  control?: Control
  min?: number
  max?: number
}

export const NumberInput: React.FC<NumberInputProps> = props => (
  <FormattedInput decimalScale={0} allowNegative={false} {...props} />
)
