import Link from 'next/link'
import { useEffect, useRef, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { COLOR, FONT_WEIGHT, FONT_SIZE } from '@fe/common/constants/main'
import { Spinner } from './Spinner'

interface ContainerProps {
  width: number
  disabled: boolean
  isHidden: boolean
  isSmall: boolean
  isSmallest: boolean
  isSecondary: boolean
  isLoading: boolean
  isFullWidth: boolean
  isCentered: boolean
  color: string
}

const Container = styled.button<ContainerProps>`
  display: ${({ isHidden }) => isHidden ? 'none' : 'flex'};
  justify-content: center;
  align-items: center;
  width: ${({ isFullWidth, width }) =>
    width ? `${width}px` : isFullWidth ? '100%' : 'fit-content'};
  min-width: ${({ width }) => width && width};
  height: ${({ isSmall, isSmallest }) =>
    isSmall ? '3.5rem' : isSmallest ? '3rem' : '5rem'};
  margin: ${({ isCentered }) => isCentered && '0 auto'};
  padding: ${({ isSmall, isSmallest }) =>
    isSmall ? '0 1.5rem' : isSmallest ? '0 1rem' : '0 2.7rem'};
  border-radius: 3px;
  background: ${({ color, isSecondary, theme }) =>
    color ? color : isSecondary ? theme.light : theme.primary};
  color: ${({ isSecondary, theme }) =>
    isSecondary ? theme.primary : COLOR.WHITE};
  text-align: start;
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
  font-size: ${({ isSmall }) => isSmall && FONT_SIZE.SMALL};
  line-height: 1;
  transition: background 0.1s ease-out, opacity 0.1s ease-out,
    transform 0.1s ease-out;
  pointer-events: ${({ isLoading }) => isLoading && 'none'};
  text-align: center;

  :hover:not(:disabled) {
    opacity: ${({ isLoading, isSecondary }) =>
      !isLoading && !isSecondary && '0.8'};
    background: ${({ isSecondary, theme }) =>
      isSecondary && `${theme.light}70`};
  }

  :focus {
    opacity: 0.8;
  }

  :disabled {
    color: ${COLOR.WHITE};
    background: ${COLOR.LIGHT_GREY};
  }

  :active:not(:disabled) {
    transform: scale(0.98);
  }

  :not(:disabled) > svg {
    color: ${({ isSecondary, theme }) =>
      isSecondary ? theme.primary : COLOR.WHITE};
  }

  > svg {
    height: ${({ isSmall, isLoading }) =>
      !isLoading && (isSmall ? '1.4rem' : '2rem')};

    :nth-child(2) {
      margin-left: ${({ isSmall }) => (isSmall ? '1.5rem' : '2rem')};
    }
  }

  path {
    transition: fill 0.1s ease-in-out;
  }
`

const LinkContainer = styled.a<{ isFullWidth?: boolean }>`
  display: inline-block;
  width: ${({ isFullWidth }) => isFullWidth && '100%'};
`

export interface ButtonProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  href?: string
  isDisabled?: boolean
  isHidden?: boolean
  isSubmit?: boolean
  isSmall?: boolean
  isSmallest?: boolean
  isSecondary?: boolean
  isLoading?: boolean
  isFullWidth?: boolean
  isCentered?: boolean
  testId?: string
  icon?: React.ReactNode
  color?: string
  className?: string
}

export const Button: React.FC<ButtonProps> = ({
  onClick,
  href,
  isDisabled,
  isHidden,
  isSubmit,
  isSmall,
  isSmallest,
  isSecondary,
  isLoading,
  isFullWidth,
  isCentered,
  testId,
  icon,
  color,
  className,
  children,
}) => {
  const [width, setWidth] = useState<number>()

  const theme = useTheme()
  const ref = useRef<HTMLButtonElement>()

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth)
    }
  }, [])

  const externalLinkRegex =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/

  const component = (
    <Container
      width={isLoading ? width : undefined}
      onClick={onClick}
      disabled={isDisabled}
      isHidden={isHidden}
      type={isSubmit ? 'submit' : 'button'}
      isSmall={isSmall}
      isSmallest={isSmallest}
      isSecondary={isSecondary}
      isLoading={isLoading}
      isFullWidth={isFullWidth}
      data-test-id={testId}
      isCentered={isCentered}
      color={color}
      className={className}
      ref={ref}
    >
      {isLoading ? (
        <Spinner fill={isSecondary ? theme.primary : COLOR.WHITE} />
      ) : (
        <>
          {children && <span>{children}</span>}
          {icon && icon}
        </>
      )}
    </Container>
  )

  if (href && (href.includes('mailto:') || externalLinkRegex.test(href))) {
    return (
      <LinkContainer href={href} rel="noopener noreferrer" target="_blank">
        {component}
      </LinkContainer>
    )
  }

  if (href) {
    return (
      <Link href={href} passHref>
        <LinkContainer isFullWidth={isFullWidth}>{component}</LinkContainer>
      </Link>
    )
  }

  return component
}
