import { AxiosResponse } from 'axios'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { CardOrder } from '@fe/common/api/models/CardOrder'
import { CardOrderStatusEnum } from '@fe/common/api/models/enums/CardOrderStatusEnum'
import { CardStatusEnum } from '@fe/common/api/models/enums/CardStatusEnum'
import { Button } from '@fe/common/components/Button'
import { CardNumber } from '@fe/common/components/CardNumber'
import { Modal } from '@fe/common/components/Modal'
import { ButtonContainer } from '@fe/common/components/formStyles'
import { Dropdown } from '@fe/common/components/inputs/Dropdown'
import { FONT_WEIGHT, SCREEN } from '@fe/common/constants/main'
import { useCancelToken } from '@fe/common/hooks/useCancelToken'
import { IframeModal } from '@fe/common/src/components/IframeModal'
import { jsonEqual } from '@fe/common/utils/objectUtils'
import { poll } from '@fe/common/utils/requestUtils'
import { ReceiveData } from '../..'
import { CardsApi } from 'src/api/CardsApi'
import { PROFILE_INFO } from 'src/constants/routes'
import { closeReceiveModal } from 'src/state/reducers/receiveModalReducer'
import { cardsSelector } from 'src/state/selectors/globalDataSelectors'

const DropdownContainer = styled.div`
  margin-bottom: 4rem;

  ${SCREEN.ABOVE_MOBILE} {
    display: flex;
  }
`

const AddCardButton = styled(Button)`
  width: 100%;
  height: 4rem;

  ${SCREEN.ABOVE_MOBILE} {
    flex-shrink: 0;
    width: fit-content;
    height: 6.5rem;
    margin-left: 2rem;
    padding: 2rem;
  }
`

const CardOption = styled.span`
  > :first-child {
    margin-right: 2rem;
  }

  > :last-child {
    margin-top: 1rem;
  }

  ${SCREEN.ABOVE_MOBILE} {
    display: grid;
    grid-template-columns: 0.7fr 1fr;
    align-items: center;

    > :last-child {
      margin-top: 0;
    }
  }
`

const LeaveWarning = styled.p`
  margin-top: 4rem;
  text-align: center;
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
`

interface CardsStepProps {
  incrementStep: () => void
  setOrder: Dispatch<SetStateAction<CardOrder>>
}

export const CardsStep: React.FC<CardsStepProps> = ({
  incrementStep,
  setOrder,
}) => {
  const { t } = useTranslation('transactions')
  const entityCards = useSelector(cardsSelector, jsonEqual)
  const [isLoading, setIsLoading] = useState(false)
  const [isLeaving, setIsLeaving] = useState(false)
  const [frameSrc, setFrameSrc] = useState('')

  const { formState, errors, control, watch, getValues, trigger, setError } =
    useFormContext<ReceiveData>()

  const cardId = watch('requestTypeFields.entity_card_id') as number
  const { push } = useRouter()
  const dispatch = useDispatch()
  const cancelToken = useCancelToken()

  const navigateToProfile = () => {
    dispatch(closeReceiveModal())

    push(PROFILE_INFO)
  }

  const cardsOptions = useMemo(
    () =>
      entityCards
        .filter(({ status }) => status === CardStatusEnum.Verified)
        .map(({ id, card_name, card_number }) => ({
          label: (
            <CardOption>
              <span>{card_name}</span> <CardNumber cardNumber={card_number} />
            </CardOption>
          ),
          value: id,
        })),
    []
  )

  const createOrder = async () => {
    const isAmountInValid = await trigger('amount_in')
    const isAmountOutValid = await trigger('amount_out')

    if (!isAmountInValid) {
      setError('amount_in', { message: 'mixed.required', shouldFocus: true })

      return
    }

    if (!isAmountOutValid) {
      setError('amount_out', { message: 'mixed.required', shouldFocus: true })

      return
    }

    try {
      setIsLoading(true)

      const { settings_id, amount_in } = getValues()

      const { data } = await CardsApi.createCardDepositOrder(
        {
          settings_id,
          entity_card_id: cardId,
          amount: amount_in,
        },
        { cancelToken }
      )

      if (data.url_3d) {
        setFrameSrc(data.url_3d)
      }

      const validateOrder = ({ data: { status } }: AxiosResponse<CardOrder>) =>
        status === CardOrderStatusEnum.Sent

      await poll({
        requestFn: () => CardsApi.getOrderInfo(data.id, { cancelToken }),
        validate: validateOrder,
      })

      setOrder(data)
      incrementStep()
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <DropdownContainer>
        <Dropdown
          name="requestTypeFields.entity_card_id"
          label={t('card')}
          options={cardsOptions}
          isFullWidth
          formState={formState}
          errors={errors}
          control={control}
        />

        <AddCardButton onClick={() => setIsLeaving(true)}>
          {t('add-card')}
        </AddCardButton>
      </DropdownContainer>

      <ButtonContainer isForModal isCentered>
        <Button
          onClick={createOrder}
          isDisabled={!cardId || !!Object.keys(errors).length}
          isLoading={isLoading}
        >
          {t('common:continue')}
        </Button>
      </ButtonContainer>

      {isLeaving && (
        <Modal maxWidth="30rem">
          <LeaveWarning>{t('leave-page-warning')}</LeaveWarning>

          <ButtonContainer isCentered isStacked>
            <Button isSecondary isFullWidth onClick={() => setIsLeaving(false)}>
              {t('common:abort')}
            </Button>

            <Button onClick={navigateToProfile} isFullWidth>
              {t('common:leave')}
            </Button>
          </ButtonContainer>
        </Modal>
      )}

      {frameSrc && (
        <IframeModal
          title={t('confirm-transaction')}
          frameSrc={frameSrc}
          onClose={() => dispatch(closeReceiveModal())}
        />
      )}
    </>
  )
}
