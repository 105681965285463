import Axios, { AxiosPromise } from 'axios'
import { CompanyDocument } from '@fe/common/api/models/CompanyDocument'
import { CompanyDocRequest } from '@fe/common/api/models/RequestModels/CompanyDocRequest'
import { RequestConfig } from '@fe/common/axiosConfig'

export class CompanyDocsApi {
  static uploadDocument(
    documentData: CompanyDocRequest,
    config?: RequestConfig
  ): AxiosPromise<CompanyDocument> {
    return Axios({
      ...config,
      url: `/documents/company`,
      method: 'POST',
      data: documentData,
    })
  }

  static updateDocument(
    id: number,
    documentData: CompanyDocRequest,
    config?: RequestConfig
  ): AxiosPromise<CompanyDocument> {
    return Axios({
      ...config,
      url: `/documents/company/${id}`,
      method: 'PUT',
      data: documentData,
    })
  }

  static getDocuments(
    entityId: number,
    config?: RequestConfig
  ): AxiosPromise<Array<CompanyDocument>> {
    return Axios({
      ...config,
      url: `/documents/company/${entityId}`,
      method: 'GET',
    })
  }
}
