import localForage from 'localforage'
import { extendPrototype } from 'localforage-observable'
import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { getStoredState, REHYDRATE } from 'redux-persist'
import { Observable } from 'rxjs'
import { isServer } from '@fe/common/utils/nextUtils'
import { DASHBOARD, RECEIVE_STATUS } from 'src/constants/routes'
import {
  deauthenticate,
  loadGlobalData,
} from 'src/state/actions/sessionActions'
import { SessionState, setTokenData } from 'src/state/reducers/sessionReducer'
import { persistConfig, AppState } from 'src/state/store'

export const useTabStateSync = () => {
  const dispatch = useDispatch()
  const { pathname, reload, push } = useRouter()
  const previousTokenRef = useRef<string>()
  const previousExpireAtRef = useRef<number>()
  const oldValueRef = useRef<string>()

  useEffect(() => {
    const setInitialState = async () => {
      const persistedSessionState = (
        (await getStoredState(persistConfig)) as AppState
      )?.session

      previousTokenRef.current = persistedSessionState?.accessToken
      previousExpireAtRef.current = persistedSessionState?.expireAt
    }

    if (isServer()) {
      return
    }

    setInitialState()
  }, [])

  const rehydrateState = async (newState: string) => {
    if (!newState) {
      return
    }

    const oldToken = previousTokenRef.current
    const oldExpireAt = previousExpireAtRef.current

    const parsedNewState = JSON.parse(newState)
    const parsedSessionState = JSON.parse(
      parsedNewState.session
    ) as SessionState
    const newToken = parsedSessionState?.accessToken
    const newExpireAt = parsedSessionState?.expireAt

    previousTokenRef.current = newToken
    previousExpireAtRef.current = newExpireAt

    if (oldToken && !newToken) {
      if (pathname.includes(RECEIVE_STATUS)) {
        reload()
      } else {
        dispatch(deauthenticate())
      }

      return
    }

    if (!oldToken && newToken) {
      const currentStoredState = await getStoredState(persistConfig)

      dispatch({
        type: REHYDRATE,
        key: persistConfig.key,
        payload: currentStoredState,
      })

      dispatch(loadGlobalData())

      if (pathname.includes(RECEIVE_STATUS)) {
        reload()
      } else {
        push(DASHBOARD)
      }

      return
    }

    if (oldExpireAt && newExpireAt && oldExpireAt !== newExpireAt) {
      dispatch(setTokenData({ expireAt: newExpireAt, accessToken: newToken }))
    }
  }

  if (isServer()) {
    return
  }

  const observableLocalForage = extendPrototype(localForage)

  observableLocalForage.ready().then(() => {
    observableLocalForage.newObservable.factory = subscribeFn =>
      new Observable(subscribeFn)

    observableLocalForage.configObservables({
      crossTabNotification: true,
      crossTabChangeDetection: true,
    })

    const observable = observableLocalForage.newObservable({
      crossTabNotification: true,
      crossTabChangeDetection: true,
      key: `persist:${persistConfig.key}`,
    })

    observable.subscribe({
      error(err) {
        console.error(err)
      },
      next({ newValue, crossTabNotification }) {
        if (
          crossTabNotification === 'StorageEvent' &&
          newValue !== oldValueRef.current
        ) {
          oldValueRef.current = newValue

          rehydrateState(newValue)
        }
      },
    })
  })
}
