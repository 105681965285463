import { useTranslation } from 'next-i18next'
import { useMemo, useState } from 'react'
import { Order } from '@fe/common/api/models/Order'
import { Tabs } from '@fe/common/components/Tabs'
import { Summary } from 'src/components/receiveStatus/Summary'
import { TransactionList } from 'src/components/receiveStatus/TransactionList'

enum StatusTab {
  OVERVIEW = 1,
  TRANSACTIONS = 2,
}

interface SummaryTabsProps {
  order: Order
}

export const SummaryTabs: React.FC<SummaryTabsProps> = ({ order }) => {
  const [selectedTab, setSelectedTab] = useState(StatusTab.OVERVIEW)

  const { t } = useTranslation('receive-status')

  const {
    created_at,
    total_amount_in,
    precision_in,
    currency_in,
    uuid,
    total_amount_out,
    precision_out,
    currency_out,
    transactions,
    rate,
  } = order

  const tabsOptions = useMemo(() => {
    const summaryTabs = new Map([
      [
        StatusTab.OVERVIEW,
        {
          title: t('overview-tab-label'),
          value: (
            <Summary
              created_at={created_at}
              uuid={uuid}
              total_amount_in={total_amount_in}
              total_amount_out={total_amount_out}
              precision_in={precision_in}
              precision_out={precision_out}
              currency_in={currency_in}
              currency_out={currency_out}
              rate={rate}
              key={StatusTab.OVERVIEW}
            />
          ),
        },
      ],
    ])

    if (transactions?.length > 0) {
      summaryTabs.set(StatusTab.TRANSACTIONS, {
        title: `${t('transactions-tab-label')} (${transactions?.length})`,
        value: (
          <TransactionList
            key={StatusTab.TRANSACTIONS}
            transactions={transactions}
            precision_in={precision_in}
            currency_in={currency_in}
          />
        ),
      })
    }

    return summaryTabs
  }, [t, transactions])

  return (
    <Tabs
      currentTab={selectedTab}
      tabs={tabsOptions}
      onTabChange={tab => setSelectedTab(tab)}
      hasSmallMargin
    />
  )
}
