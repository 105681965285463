import { useTranslation } from 'next-i18next'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { debounce } from 'throttle-debounce'
import { Currency } from '@fe/common/api/models/Currency'
import { OfferTypeEnum } from '@fe/common/api/models/enums/OfferTypeEnum'
import { Amount } from '@fe/common/components/Amount'
import { CurrencyDropdownInput } from '@fe/common/components/inputs/CurrencyDropdownInput'
import { GroupedOptions } from '@fe/common/components/inputs/CustomSelect'
import { ErrorText } from '@fe/common/components/typography/ErrorText'
import { opacityReveal } from '@fe/common/constants/animations'
import {
  COLOR,
  FONT_SIZE,
  FONT_WEIGHT,
  SCREEN,
} from '@fe/common/constants/main'
import { useCancelToken } from '@fe/common/hooks/useCancelToken'
import { useDidUpdate } from '@fe/common/hooks/useDidUpdate'
import Exchange from '@fe/common/src/icons/exchange.svg'
import { formatDataToOptions } from '@fe/common/utils/arrayUtils'
import { formatResponseError } from '@fe/common/utils/axiosUtils'
import { jsonEqual } from '@fe/common/utils/objectUtils'
import { ExchangeOffer } from '../ExchangeOffer'
import { OfferMethods } from '../useOffer'
import { ExchangeData } from '.'
import { ExchangeApi } from 'src/api/ExchangeApi'
import { currentAccountSelector } from 'src/state/selectors/globalDataSelectors'

const Container = styled.div`
  height: 45rem;

  ${SCREEN.ABOVE_MOBILE} {
    height: 26rem;
  }
`

const FieldsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding: 3rem 3rem 2rem;
  border-radius: 3px;
  background: ${COLOR.OFF_WHITE};

  ${SCREEN.ABOVE_MOBILE} {
    flex-direction: row;
  }
`

const FieldContainer = styled.div``

const FieldName = styled.span`
  display: inline-block;
  margin-bottom: 1rem;
  padding-left: 1rem;
  font-weight: ${FONT_WEIGHT.SEMIBOLD};

  > span {
    margin-left: 1rem;
    color: ${COLOR.GREY};
    font-size: ${FONT_SIZE.SMALL};
    animation: ${opacityReveal} 0.2s ease-in-out;
  }
`

const FieldDetails = styled.div`
  display: flex;
  justify-content: space-between;
  height: 2rem;
  padding-left: 1rem;
  font-size: ${FONT_SIZE.SMALL};
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
`

const SettingsError = styled(ErrorText)`
  position: absolute;
  left: 0;
  bottom: 1rem;
`

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 4rem;
  height: 4rem;
  margin: 1rem 0 2rem;
  border-radius: 50%;
  transform: rotate(90deg);
  cursor: pointer;

  :hover {
    background: ${COLOR.LIGHTER_GREY};
  }

  ${SCREEN.ABOVE_MOBILE} {
    margin: 0 2rem;
    transform: none;
  }
`

const ExchangeIcon = styled(Exchange)`
  width: 2.3rem;
  height: 2.3rem;
  color: ${COLOR.OFF_BLACK};
`

interface ExchangeAmountSecitonProps {
  offerMethods: OfferMethods
}

export const ExchangeAmountSeciton: React.FC<ExchangeAmountSecitonProps> = ({
  offerMethods,
}) => {
  const { t } = useTranslation('transactions')
  const { currencies: accCurrencies, id: currentAccId } = useSelector(
    currentAccountSelector,
    jsonEqual
  )
  const [allInCurrencies, setAllInCurrencies] = useState<Array<Currency>>([])
  const [allOutCurrencies, setAllOutCurrencies] = useState<Array<Currency>>([])
  const [inCurrencies, setInCurrencies] = useState<Array<Currency>>([])
  const [outCurrencies, setOutCurrencies] = useState<Array<Currency>>([])
  const [areInOptionsLoading, setAreInOptionsLoading] = useState(false)
  const [areOutOptionsLoading, setAreOutOptionsLoading] = useState(false)
  const [inCurrencyId, setInCurrencyId] = useState<number>()
  const [outCurrencyId, setOutCurrencyId] = useState<number>()
  const [settingsError, setSettingsError] = useState('')

  const { getValues, setValue } = useFormContext<ExchangeData>()
  const cancelToken = useCancelToken()

  const { isOfferLoading, offer, getOffer, previousRequest, resetOffer } =
    offerMethods

  const getOptions = useCallback(
    async (type: OfferTypeEnum, settingsId?: number) => {
      const isIn = type === OfferTypeEnum.In

      try {
        if (isIn) {
          setAreOutOptionsLoading(true)
        } else {
          setAreInOptionsLoading(true)
        }

        const { data } = await ExchangeApi.getSettings(
          currentAccId,
          type,
          settingsId,
          { cancelToken }
        )

        if (isIn) {
          if (settingsId) {
            setAreOutOptionsLoading(false)

            setInCurrencies(data)
          } else {
            setAllInCurrencies(data)
          }
        } else {
          setOutCurrencies(data)

          if (settingsId) {
            setAreInOptionsLoading(false)
            setOutCurrencies(data)
          } else {
            setAllOutCurrencies(data)
          }
        }

        return data
      } catch (error) {
        setSettingsError(formatResponseError(error, t))
        console.error(formatResponseError(error, t))
      }
    },
    []
  )

  const prepareFields = useCallback(async () => {
    const [allInCurr] = await Promise.all([
      getOptions(OfferTypeEnum.In),
      getOptions(OfferTypeEnum.Out),
    ])

    setInCurrencyId(allInCurr[0]?.id)
  }, [])

  const handleCurrencyChange = useCallback(
    async (type: OfferTypeEnum) => {
      const isIn = type === OfferTypeEnum.In

      const sourceCurrencyId = isIn ? inCurrencyId : outCurrencyId
      let targetCurrencyId = isIn ? outCurrencyId : inCurrencyId

      const newTargetCurrencies = await getOptions(type, sourceCurrencyId)

      const shouldResetTargetId = !newTargetCurrencies.find(
        ({ id }) => id === targetCurrencyId
      )

      if (shouldResetTargetId) {
        targetCurrencyId = newTargetCurrencies[0].id

        if (isIn) {
          setOutCurrencyId(targetCurrencyId)
        } else {
          setInCurrencyId(targetCurrencyId)
        }
      }

      if (!previousRequest) {
        return
      }

      const { amount_in, amount_out } = getValues(['amount_in', 'amount_out'])

      const { type: previousOfferType } = previousRequest

      const offerAmount =
        previousOfferType === OfferTypeEnum.In ? amount_in : amount_out

      if (!offerAmount) {
        return
      }

      const inOfferSettingId =
        shouldResetTargetId && !isIn ? targetCurrencyId : inCurrencyId
      const outOfferSettingId =
        shouldResetTargetId && isIn ? targetCurrencyId : outCurrencyId

      await getOffer(
        offerAmount,
        previousOfferType,
        inOfferSettingId,
        outOfferSettingId
      )
    },
    [inCurrencyId, outCurrencyId]
  )

  useEffect(() => {
    prepareFields()
  }, [])

  useDidUpdate(() => {
    handleCurrencyChange(OfferTypeEnum.In)
  }, [inCurrencyId])

  useDidUpdate(() => {
    handleCurrencyChange(OfferTypeEnum.Out)
  }, [outCurrencyId])

  const handleAmountInChange = useCallback(
    debounce(500, async () => {
      const amountIn = getValues('amount_in')

      await getOffer(amountIn, OfferTypeEnum.In, inCurrencyId, outCurrencyId)
    }),
    [getValues, getOffer, inCurrencyId, outCurrencyId]
  )

  const handleAmountOutChange = useCallback(
    debounce(500, async () => {
      const amountOut = getValues('amount_out')

      await getOffer(amountOut, OfferTypeEnum.Out, inCurrencyId, outCurrencyId)
    }),
    [getValues, getOffer, inCurrencyId, outCurrencyId]
  )

  const handleSwapCurrencies = useCallback(() => {
    setValue('amount_in', null)
    setValue('amount_out', null)
    resetOffer()

    const newInCurrency = outCurrencyId
    const newOutCurrency = inCurrencyId

    setInCurrencyId(newInCurrency)
    setOutCurrencyId(newOutCurrency)
  }, [outCurrencyId])

  const getGroupedOptions = useCallback(
    (
      type: OfferTypeEnum,
      allCurrencies: Array<Currency>,
      availableCurrencies: Array<Currency>,
      destinationCode: string
    ) => {
      const nonAvailableCurrencies = allCurrencies.filter(
        currency =>
          !availableCurrencies.find(
            availableCurrency => availableCurrency.id === currency.id
          )
      )

      const availableOptions = formatDataToOptions(availableCurrencies, {
        nameKey: 'name',
        valueKey: 'id',
      })

      const nonAvailableOptions = formatDataToOptions(nonAvailableCurrencies, {
        nameKey: 'name',
        valueKey: 'id',
      })

      const isIn = type === OfferTypeEnum.In
      const availableGroupLabel = isIn ? t('to') : t('from')

      const groupedOptions: GroupedOptions = [
        {
          label: `${availableGroupLabel} ${destinationCode}`,
          options: availableOptions,
        },
        { label: t('common:other') as string, options: nonAvailableOptions },
      ]

      return groupedOptions
    },
    [t]
  )

  const availableBalanceIn = useMemo(
    () =>
      accCurrencies.find(({ id }) => id === inCurrencyId)?.balances?.available,
    [inCurrencyId]
  )

  const availableBalanceOut = useMemo(
    () =>
      accCurrencies.find(({ id }) => id === outCurrencyId)?.balances?.available,
    [outCurrencyId]
  )

  const inCurrency = useMemo(
    () => allInCurrencies.find(({ id }) => id === inCurrencyId),
    [inCurrencies, inCurrencyId]
  )

  const outCurrency = useMemo(
    () => allOutCurrencies.find(({ id }) => id === outCurrencyId),
    [outCurrencies, outCurrencyId]
  )

  const inOptions = useMemo(
    () =>
      getGroupedOptions(
        OfferTypeEnum.In,
        allInCurrencies,
        outCurrencies,
        outCurrency?.code
      ),
    [allInCurrencies, outCurrencies, outCurrency]
  )

  const outOptions = useMemo(
    () =>
      getGroupedOptions(
        OfferTypeEnum.Out,
        allOutCurrencies,
        inCurrencies,
        inCurrency?.code
      ),
    [allOutCurrencies, inCurrencies, inCurrency]
  )

  return (
    <Container>
      <FieldsContainer>
        <FieldContainer>
          <FieldName>
            {t('sell')}

            {availableBalanceIn && (
              <span>
                ({t('balance')}{' '}
                <Amount
                  value={availableBalanceIn.amount}
                  code={inCurrency?.code}
                  decimals={availableBalanceIn.precision}
                />
                )
              </span>
            )}
          </FieldName>

          <CurrencyDropdownInput
            name="amount_in"
            label={t('amount')}
            selectedCurrencyId={inCurrencyId}
            onCurrencySelect={setInCurrencyId}
            currencyOptions={inOptions}
            onChange={handleAmountInChange}
            isDisabled={!inCurrencyId}
            decimalScale={inCurrency?.precision}
            currency={inCurrency?.code}
            isLoading={areInOptionsLoading}
          />

          <FieldDetails>
            {!!offer?.fee_in && (
                <>
                  <span>
                    {`${t('fee')}: `}

                    <Amount
                      value={offer.fee_in}
                      code={offer.currency_in}
                      decimals={offer.precision_in}
                    />
                  </span>
                  <span>
                    {`${t('inc-fee')}: `}

                    <Amount
                      value={offer.total_amount_in}
                      code={offer.currency_in}
                      decimals={offer.precision_in}
                    />
                  </span>
                </>
            ) }
          </FieldDetails>
        </FieldContainer>

        <IconContainer onClick={handleSwapCurrencies}>
          <ExchangeIcon />
        </IconContainer>

        <FieldContainer>
          <FieldName>
            {t('buy')}

            {availableBalanceOut && (
              <span>
                ({t('balance')}{' '}
                <Amount
                  value={availableBalanceOut.amount}
                  code={outCurrency?.code}
                  decimals={availableBalanceOut.precision}
                />
                )
              </span>
            )}
          </FieldName>

          <CurrencyDropdownInput
            name="amount_out"
            label={t('amount')}
            selectedCurrencyId={outCurrencyId}
            onCurrencySelect={setOutCurrencyId}
            currencyOptions={outOptions}
            onChange={handleAmountOutChange}
            isDisabled={!outCurrencyId}
            decimalScale={outCurrency?.precision}
            currency={outCurrency?.code}
            isLoading={areOutOptionsLoading}
          />

          <FieldDetails>
            {!!offer?.fee_out && (
              <>
                <span>
                  {`${t('fee')}: `}
                  <Amount
                    value={offer.fee_out}
                    code={offer.currency_out}
                    decimals={offer.precision_out}
                  />
                </span>
                <span>
                  {`${t('exc-fee')}: `}
                    <Amount
                      value={offer.total_amount_out}
                      code={offer.currency_out}
                      decimals={offer.precision_out}
                    />
                </span>
              </>
            ) }
          </FieldDetails>
        </FieldContainer>

        {settingsError && (
          <SettingsError>{t('exchange-settings-error')}</SettingsError>
        )}
      </FieldsContainer>

      <ExchangeOffer
        isShown={isOfferLoading || !!offer}
        offerMethods={offerMethods}
      />
    </Container>
  )
}
