import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import { Button } from '@fe/common/components/Button'
import { ButtonContainer } from '@fe/common/components/formStyles'
import { COLOR } from '@fe/common/constants/main'
import Arrow from '@fe/common/src/icons/arrow.svg'
import Check from '@fe/common/src/icons/check.svg'
import Prepare from '@fe/common/src/icons/prepare-payment.svg'
import Send from '@fe/common/src/icons/send.svg'
import { SendStepsEnum } from 'src/types/enums/SendStepsEnum'

const StyledSend = styled(Send)`
  width: 1.5rem;
  height: 1.5rem;
`

const StyledCheck = styled(Check)`
  width: 1.5rem;
  height: 1.5rem;
`

const StyledPrepare = styled(Prepare)`
  width: 2rem;
  height: 2rem;
`

interface SendButtonsProps {
  step: SendStepsEnum
  decrementStep: () => void
  incrementStep: () => void
  isSubmitting: boolean
  isMfaEntered: boolean
  isWithPrepare: boolean
}

export const SendButtons: React.FC<SendButtonsProps> = ({
  step,
  decrementStep,
  incrementStep,
  isSubmitting,
  isMfaEntered,
  isWithPrepare,
}) => {
  const { t } = useTranslation('transactions')

  return (
    <ButtonContainer isCentered isForModal isStacked>
      {step > SendStepsEnum.General && (
        <Button isSecondary onClick={decrementStep} testId="back_button">
          {t('common:back')}
        </Button>
      )}

      {step < SendStepsEnum.Summary && (
        <Button
          icon={<Arrow />}
          isSubmit
          isLoading={isSubmitting}
          testId="continue_button"
        >
          {t('common:continue')}
        </Button>
      )}

      {step === SendStepsEnum.Summary && (
        <>
          <Button
            icon={<StyledSend />}
            onClick={() => incrementStep()}
            testId="send_button"
          >
            {t('send')}
          </Button>

          {isWithPrepare && (
            <Button
              icon={<StyledPrepare />}
              isSubmit
              isLoading={isSubmitting}
              color={COLOR.ORANGE}
              testId="prepare_button"
            >
              {t('prepare-payment')}
            </Button>
          )}
        </>
      )}

      {step === SendStepsEnum.Mfa && (
        <Button
          icon={<StyledCheck />}
          isSubmit
          isLoading={isSubmitting}
          isDisabled={!isMfaEntered}
          testId="confirm_button"
        >
          {t('confirm')}
        </Button>
      )}
    </ButtonContainer>
  )
}
