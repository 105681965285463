import { Transaction } from '@fe/common/api/models/Transaction'
import { BeneficiaryTypeEnum } from '@fe/common/api/models/enums/BeneficiaryTypeEnum'
import { RequestStatusEnum } from '@fe/common/api/models/enums/RequestStatusEnum'
import { RequestTypeEnum } from '@fe/common/api/models/enums/RequestTypeEnum'
import { COLOR } from '@fe/common/constants/main'

export const getCounterparty = (transaction: Transaction) => {
  const {
    beneficiary_type,
    first_name,
    last_name,
    corporate_name,
    beneficiary_name,
  } = transaction

  let counterparty: string

  if (beneficiary_type !== null) {
    counterparty =
      beneficiary_type === BeneficiaryTypeEnum.Individual
        ? `${first_name || '-'} ${last_name || '-'}`
        : corporate_name
  } else {
    counterparty = beneficiary_name
  }

  return counterparty
}

export const getAccountNumber = (
  request_type: RequestTypeEnum,
  account_number: string,
  bank_code: string
) => {
  let accountNumber: string

  if (
    request_type === RequestTypeEnum.MtCash ||
    request_type === RequestTypeEnum.Exchange
  ) {
    accountNumber = null
  } else if (bank_code) {
    accountNumber = account_number + ' / ' + bank_code
  } else {
    accountNumber = account_number
  }

  return accountNumber
}

export const getTransactionColorText = (status: RequestStatusEnum): string => {
  let color: string

  switch (status) {
    case RequestStatusEnum.New:
    case RequestStatusEnum.InProgress:
    case RequestStatusEnum.Prepared:
      color = COLOR.BLACK
      break
    case RequestStatusEnum.Pended:
      color = COLOR.ORANGE
      break
    case RequestStatusEnum.Rejected:
      color = COLOR.RED
      break
    default:
      color = COLOR.GREEN
      break
  }

  return color
}

export const formatLegacyPhone = (phone: string) =>
  !phone.startsWith('+') ? '+' + phone : phone
