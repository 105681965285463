import Axios, { AxiosPromise } from 'axios'
import { Bank } from '@fe/common/api/models/Bank'
import { RequestConfig } from '@fe/common/axiosConfig'

export class BanksApi {
  static getBanks(
    settings_id: number,
    config?: RequestConfig
  ): AxiosPromise<Array<Bank>> {
    return Axios({
      ...config,
      url: `/banks/list/interbank/${settings_id}`,
      method: 'GET',
    })
  }
}
