import { RequestMethod } from '@fe/common/api/models/RequestMethod'
import { Transaction } from '@fe/common/api/models/Transaction'
import { RequestTypeEnum } from '@fe/common/api/models/enums/RequestTypeEnum'
import { TransactionTypeEnum } from '@fe/common/api/models/enums/TransactionTypeEnum'
import { removeSpaces } from '@fe/common/utils/stringUtils'
import { formatLegacyPhone } from '@fe/common/utils/transactionUtils'
import {
  SendableRequestTypes,
  SendData,
} from './SendModal/RequestTypeSteps/stepsConfig'
import { AccountCurrencyWithDetails } from 'src/types/AccountCurrencyWithDetails'

const formatPrefilledToDefaults = (transaction: Transaction) => {
  const defaultValues: SendData = {
    settings_id: transaction.settings_id,
    request_type: transaction.request_type as SendableRequestTypes,
    amount_in: transaction.amount,
    amount_out: null,
    payment_date: null,
    process_manager_id: null,
    requestTypeFields: {
      address_line: transaction.address,
      address: transaction.account_number,
      bank_account_number: transaction.account_number,
      bank_address: transaction.bank_address,
      bank_city: transaction.bank_address,
      bank_code: transaction.bank_code,
      bank_country_id: transaction.bank_country_id,
      bank_id: transaction.interbank_id,
      bank_name: transaction.bank_name,
      bank_zip: transaction.bank_zip,
      beneficiary_name: transaction.beneficiary_name,
      beneficiary_phone:
        transaction.mt?.beneficiary_phone &&
        removeSpaces(formatLegacyPhone(transaction.mt?.beneficiary_phone)),
      city: transaction.city,
      constant_symbol: transaction.constant_symbol,
      country_id: transaction.country_id,
      first_name: transaction.first_name,
      last_name: transaction.last_name,
      mt_branch_id: transaction.mt?.branch_id,
      card_number: transaction.account_number,
      mt_city_id: transaction.mt?.city_id,
      mt_country_id: transaction.mt?.country_id,
      mt_payment_purpose: transaction.mt?.purpose,
      mt_payment_system_id: transaction.mt?.payment_system_id,
      payment_details: transaction.payment_details,
      specific_symbol: transaction.specific_symbol,
      variable_symbol: transaction.variable_symbol,
      zip: transaction.zip,
    },
  }

  return defaultValues
}

export const getDefaultTransactionValues = <
  T extends {
    [x: string]: any
    settings_id: number
    request_type: RequestTypeEnum
  }
>(
  transactionType: TransactionTypeEnum,
  currencyDetails: Array<AccountCurrencyWithDetails>,
  prefilledCurrencyId: number,
  areDetailsLoading: boolean,
  partialDefaultValues: Record<string, any>,
  prefilledValues?: Transaction
): T => {
  if (prefilledValues) {
    const defaultValues = formatPrefilledToDefaults(
      prefilledValues
    ) as unknown as T

    return defaultValues
  }

  const methodName =
    transactionType === TransactionTypeEnum.Deposit
      ? 'deposit_methods'
      : 'withdraw_methods'

  const defaultRequestType = areDetailsLoading
    ? null
    : currencyDetails
        .find(currency => currency.id === prefilledCurrencyId)
        ?.[methodName]?.map((method: RequestMethod) => method.id)[0]

  const defaultValues = {
    settings_id: areDetailsLoading
      ? null
      : prefilledCurrencyId || currencyDetails[0].id,
    request_type: areDetailsLoading
      ? null
      : (defaultRequestType as SendableRequestTypes) ||
        (currencyDetails[0][methodName][0].id as SendableRequestTypes),
    ...partialDefaultValues,
  } as T

  return defaultValues
}
