import Axios, { AxiosPromise } from 'axios'
import { BankDepositDetails } from '@fe/common/api/models/BankDepositDetails'
import { CryptoAddresses } from '@fe/common/api/models/CryptoAddresses'
import { Fee } from '@fe/common/api/models/Fee'
import { InterbankDepositDetails } from '@fe/common/api/models/InterbankDepositDetails'
import { InternalDepositDetails } from '@fe/common/api/models/InternalDepositDetails'
import { Offer } from '@fe/common/api/models/Offer'
import { Order } from '@fe/common/api/models/Order'
import { PaymentSystem } from '@fe/common/api/models/PaymentSystem'
import { DepositOfferRequest } from '@fe/common/api/models/RequestModels/DepositOfferRequest'
import { FeeRequest } from '@fe/common/api/models/RequestModels/FeeRequest'
import { MtReceiveRequest } from '@fe/common/api/models/RequestModels/MtReceiveRequest'
import { RequestTypeEnum } from '@fe/common/api/models/enums/RequestTypeEnum'
import { RequestConfig } from '@fe/common/axiosConfig'

export class DepositsApi {
  static getFee(data: FeeRequest, config?: RequestConfig): AxiosPromise<Fee> {
    return Axios({
      ...config,
      url: `/deposits/fee`,
      method: 'POST',
      data,
    })
  }

  static getOffer(
    data: DepositOfferRequest,
    config?: RequestConfig
  ): AxiosPromise<Offer> {
    return Axios({
      ...config,
      url: `/deposits/offer`,
      method: 'POST',
      data,
    })
  }

  static getOrder(
    offer_id: number,
    config?: RequestConfig
  ): AxiosPromise<Order> {
    return Axios({
      ...config,
      url: `/deposits/order`,
      method: 'POST',
      data: {
        offer_id,
      },
    })
  }

  static receiveMt(
    data: MtReceiveRequest,
    config?: RequestConfig
  ): AxiosPromise<Offer> {
    return Axios({
      ...config,
      url: `/deposits/mt`,
      method: 'POST',
      data,
    })
  }

  static getBankDetails(
    requestType: RequestTypeEnum,
    settingsId: number,
    config?: RequestConfig
  ): AxiosPromise<BankDepositDetails> {
    return Axios({
      ...config,
      url: `/deposits/${requestType}/${settingsId}`,
      method: 'GET',
    })
  }

  static getVerificationDetails(
    entityId: number,
    config?: RequestConfig
  ): AxiosPromise<BankDepositDetails> {
    return Axios({
      ...config,
      url: `/deposits/verification/${entityId}`,
      method: 'GET',
    })
  }

  static getInternalDetails(
    settingsId: number,
    config?: RequestConfig
  ): AxiosPromise<InternalDepositDetails> {
    return Axios({
      ...config,
      url: `/deposits/${RequestTypeEnum.Internal}/${settingsId}`,
      method: 'GET',
    })
  }

  static getInterbankDetails(
    settingsId: number,
    bank_id: number,
    amount: number,
    config?: RequestConfig
  ): AxiosPromise<InterbankDepositDetails> {
    return Axios({
      ...config,
      url: `/deposits/${RequestTypeEnum.Interbank}/${settingsId}`,
      method: 'GET',
      params: {
        bank_id,
        amount,
      },
    })
  }

  static getCryptoDetails(
    settingsId: number,
    isNew?: boolean,
    config?: RequestConfig
  ): AxiosPromise<CryptoAddresses> {
    return Axios({
      ...config,
      url: `/deposits/${RequestTypeEnum.Crypto}/${settingsId}/${Number(isNew)}`,
      method: 'GET',
    })
  }

  static getPaymentSystems(
    accountId: number,
    config?: RequestConfig
  ): AxiosPromise<Array<PaymentSystem>> {
    return Axios({
      ...config,
      url: `/deposits/mt/payment-systems/${accountId}`,
      method: 'GET',
    })
  }
}
