import { format, intervalToDuration } from 'date-fns'
import addSeconds from 'date-fns/addSeconds'
import { enGB, cs, ru } from 'date-fns/locale'
import { TFunction } from 'next-i18next'
import Router from 'next/router'
import { LocaleName } from '@fe/common/types/LocaleName'

const locales: Record<LocaleName, Locale> = {
  cs,
  en: enGB,
  ru,
}

export const getTranslatedDayAndHourLeft = (
  expireAt: number,
  t: TFunction
): string | null => {
  const { days, hours, minutes, seconds } = intervalToDuration({
    start: new Date().getTime(),
    end: expireAt * 1000,
  })

  const noTimeLeft = new Date().getTime() - expireAt * 1000 >= 0

  if (noTimeLeft) {
    return t('time-left-minutes', { minutes: 0, seconds: 0 })
  }

  if (days > 0) {
    return t('time-left-days', { count: days })
  }

  if (hours > 0) {
    return t('time-left-hours', { hours, minutes })
  }

  return t('time-left-minutes', { minutes, seconds })
}

export const getMinEligibleDate = () => {
  const now = new Date()

  const minLegalDate = new Date(
    now.getFullYear() - 18,
    now.getMonth(),
    now.getDate()
  )

  return minLegalDate
}

export const timestampToDateString = (timestamp: number) =>
  format(timestamp * 1000, 'dd.MM.yyyy')

export const formatDateToString = (date: Date) => {
  const code = Router.locale as LocaleName

  const locale = locales[code]

  const dateString = date ? format(date, 'd MMM, yyyy', { locale }) : '-'

  return dateString
}

export const dateToTimestamp = (date: Date | undefined): number | null =>
  date?.getTime() / 1000 || null

export const timestampToDate = (timestamp: number | undefined): Date | null =>
  timestamp ? new Date(timestamp * 1000) : null

export const getLocalExpireAt = (expireInSeconds: number) =>
  addSeconds(new Date(), expireInSeconds).getTime()
