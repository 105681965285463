import { useContext } from 'react'
import Select, {
  components,
  ContainerProps,
  GroupBase,
  Props as SelectProps,
  OptionProps,
  Options,
  Theme,
} from 'react-select'
import styled, { ThemeContext } from 'styled-components'
import { COLOR } from '@fe/common/constants/main'
import { Spinner } from '../Spinner'
import { StyledInputProps } from './baseInputStyles'

export const StyledSelect = styled(Select)<StyledInputProps>`
  .react-select__control {
    height: ${({ isSmall }) => (isSmall ? '5.5rem' : '6.5rem')};
    padding: 0 3rem;
    border: 1px solid
      ${({ hasError }) => (hasError ? COLOR.RED : COLOR.LIGHTER_GREY)};
    box-shadow: none;
    cursor: pointer;

    :hover {
      border-color: ${COLOR.LIGHTER_GREY};

      .react-select__placeholder {
        opacity: 0;
      }
    }

    :focus-within {
      .react-select__dropdown-indicator {
        transform: rotate(180deg);
      }
    }
  }

  .react-select__placeholder {
    margin: 0;
    color: ${COLOR.GREY};
    transition: opacity 0.2s ease-in-out;

    div:focus-within > & {
      opacity: 0;
    }
  }

  .react-select__value-container {
    height: 3.6rem;
    padding: 0;
    overflow: visible;

    > :nth-child(2) {
      margin: 0;
    }
  }

  .react-select__value-container--is-multi.react-select__value-container--has-value {
    flex-wrap: nowrap;
    overflow-x: scroll;
    scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .react-select__single-value {
    margin: 0;
  }

  .react-select__multi-value {
    flex-shrink: 0;
    margin: 0 2px;
  }

  .react-select__input {
    input {
      margin: 0;
    }
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__clear-indicator {
    padding: 0 1rem;
  }

  .react-select__dropdown-indicator {
    padding: 0;
    transition: transform 0.1s ease-in-out;
  }

  :hover {
    .react-select__dropdown-indicator {
      color: hsl(0, 0%, 60%);
    }
  }
`

const StyledSpinner = styled(Spinner)`
  margin-right: 0.7rem;
`

const getSelectedValue = (
  selected: string | number | Array<string | number>,
  options: readonly any[]
) => {
  if (!options || !selected) {
    return null
  }

  if (selected instanceof Array) {
    return options.filter((option: DropdownOption | GroupedOption) =>
      selected.includes((option as DropdownOption).value)
    )
  }

  const selectedOption: GroupedOption | DropdownOption = options.find(
    (option: DropdownOption | GroupedOption) => {
      if ((option as DropdownOption).value) {
        return (option as DropdownOption).value === selected
      } else if ((option as GroupedOption).options) {
        return (option as GroupedOption).options.find(
          (groupedOption: DropdownOption) => groupedOption.value === selected
        )
      }
    }
  )

  return (selectedOption as GroupedOption)?.options
    ? (selectedOption as GroupedOption).options.find(
        (groupedOption: DropdownOption) => groupedOption.value === selected
      )
    : selectedOption
}

export const Option = ({
  innerProps,
  ...rest
}: OptionProps<DropdownOption, boolean>) => (
  <components.Option
    {...rest}
    innerProps={
      {
        ...innerProps,
        onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          e.stopPropagation()
          innerProps.onClick?.(e)
        },
        'data-test-id': rest.label,
      } as any
    }
  />
)

const SelectContainer = ({
  innerProps,
  ...rest
}: ContainerProps<DropdownOption, boolean>) => (
  <components.SelectContainer
    {...rest}
    innerProps={
      {
        ...innerProps,
        'data-test-id': rest.selectProps.name,
      } as any
    }
  />
)

const LoadingIndicator = () => <StyledSpinner />

export type DropdownOption<T = string | number> = {
  value: T
  label: string | React.ReactNode
  isDisabled?: boolean
}

export type GroupedOption = {
  label: string
  options: DropdownOptions
}

export type DropdownOptions<T = string | number> = Options<DropdownOption<T>>
export type GroupedOptions<T = string | number> = ReadonlyArray<
  GroupBase<DropdownOption<T>>
>

export type CustomSelectProps = Omit<
  SelectProps<DropdownOption, boolean>,
  'value' | 'options'
> &
  StyledInputProps & {
    value: string | number | Array<string | number>
    options: DropdownOptions | GroupedOptions
  }

export const CustomSelect: React.FC<CustomSelectProps> = ({
  value,
  options,
  isDisabled,
  className,
  ...selectProps
}) => {
  const themeContext = useContext(ThemeContext)

  return (
    <StyledSelect
      value={getSelectedValue(value, options)}
      options={options}
      classNamePrefix="react-select"
      blurInputOnSelect
      components={{
        Option,
        LoadingIndicator,
        SelectContainer,
      }}
      menuPortalTarget={document.querySelector('#dropdown')}
      theme={
        ((theme: Theme) => ({
          ...theme,
          borderRadius: 3,
          colors: {
            ...theme.colors,
            primary: themeContext.primary,
            primary25: COLOR.LIGHTER_GREY,
          },
        })) as any
      }
      isDisabled={isDisabled || !options?.length}
      {...selectProps}
      styles={{
        ...selectProps.styles,
        menuPortal: base => ({
          ...base,
          zIndex: 5,
        }),
      }}
      data-test-id={selectProps.name}
      className={className}
    />
  )
}
