import { dateToTimestamp } from './dateUtils'

export function deepEqual(
  object1: Record<string, any>,
  object2: Record<string, any>
) {
  if (!object1 || !object2) {
    return false
  }

  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (const key of keys1) {
    const val1 = object1[key]
    const val2 = object2[key]

    const areObjects = isObject(val1) && isObject(val2)

    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false
    }
  }

  return true
}

export const jsonEqual = <T>(a: T, b: T) =>
  JSON.stringify(a) === JSON.stringify(b)

export const isObject = (object: Record<any, unknown>) =>
  object != null && typeof object === 'object'

export const formatToQueryObject = (
  obj: Record<any, unknown>
): Record<any, number | string> => {
  const formattedParams: Record<any, number | string> = {}

  for (const [key, value] of Object.entries(obj)) {
    formattedParams[key] =
      value instanceof Date
        ? dateToTimestamp(value)
        : (value as string | number)
  }

  return formattedParams
}

export const isObjectEmpty = (object: object): boolean =>
  object && Object.values(object).length === 0

export const hasFalsyValues = (object: object): boolean => {
  const objectValues = Object.values(object)

  return (
    objectValues.includes(null) ||
    objectValues.includes(undefined) ||
    objectValues.includes('')
  )
}

export const getDefinedFields = <T extends object>(object: T): T => {
  const newObject: Partial<T> = {}

  for (const [key, value] of Object.entries(object)) {
    if (value !== null && value !== undefined && value !== '') {
      newObject[key as keyof T] =
        typeof value === 'object' &&
        !Array.isArray(value) &&
        !(value instanceof Date)
          ? getDefinedFields(value)
          : (value as T[keyof T])
    }
  }

  return newObject as T
}
