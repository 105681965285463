import Axios, { AxiosPromise } from 'axios'
import { City } from '@fe/common/api/models/City'
import { Country } from '@fe/common/api/models/Country'
import { Fee } from '@fe/common/api/models/Fee'
import { MtBranch } from '@fe/common/api/models/MtBranch'
import { Offer } from '@fe/common/api/models/Offer'
import { Order } from '@fe/common/api/models/Order'
import { PaymentSystem } from '@fe/common/api/models/PaymentSystem'
import { ConfirmWithdrawRequest } from '@fe/common/api/models/RequestModels/ConfirmWithdrawRequest'
import { FeeRequest } from '@fe/common/api/models/RequestModels/FeeRequest'
import { CardSendRequest } from '@fe/common/api/models/RequestModels/SendRequests/CardSendRequest'
import { CryptoSendRequest } from '@fe/common/api/models/RequestModels/SendRequests/CryptoSendRequest'
import { CzkSendRequest } from '@fe/common/api/models/RequestModels/SendRequests/CzkSendRequest'
import { InterbankSendRequest } from '@fe/common/api/models/RequestModels/SendRequests/InterbankSendRequest'
import { InternalSendRequest } from '@fe/common/api/models/RequestModels/SendRequests/InternalSendRequest'
import { MtSendRequest } from '@fe/common/api/models/RequestModels/SendRequests/MtSendRequest'
import { SepaSendRequest } from '@fe/common/api/models/RequestModels/SendRequests/SepaSendRequest'
import { WireSendRequest } from '@fe/common/api/models/RequestModels/SendRequests/WireSendRequest'
import { WithdrawCardFeeRequest } from '@fe/common/api/models/RequestModels/WithdrawCardFeeRequest'
import { WithdrawMtFeeRequest } from '@fe/common/api/models/RequestModels/WithdrawMtFeeRequest'
import { WithdrawOfferRequest } from '@fe/common/api/models/RequestModels/WithdrawOfferRequest'
import { WithdrawOrderRequest } from '@fe/common/api/models/RequestModels/WithdrawOrderRequest'
import { Transaction } from '@fe/common/api/models/Transaction'
import { RequestTypeEnum } from '@fe/common/api/models/enums/RequestTypeEnum'
import { RequestConfig } from '@fe/common/axiosConfig'

export class WithdrawApi {
  static wire(
    data: WireSendRequest,
    config?: RequestConfig
  ): AxiosPromise<Transaction> {
    return Axios({
      ...config,
      url: `/withdraws/${RequestTypeEnum.WireTransfer}`,
      method: 'POST',
      data,
    })
  }

  static sepa(
    data: SepaSendRequest,
    config?: RequestConfig
  ): AxiosPromise<Transaction> {
    return Axios({
      ...config,
      url: `/withdraws/${RequestTypeEnum.Sepa}`,
      method: 'POST',
      data,
    })
  }

  static czk(
    data: CzkSendRequest,
    config?: RequestConfig
  ): AxiosPromise<Transaction> {
    return Axios({
      ...config,
      url: `/withdraws/${RequestTypeEnum.CzkPayments}`,
      method: 'POST',
      data,
    })
  }

  static internal(
    data: InternalSendRequest,
    config?: RequestConfig
  ): AxiosPromise<Transaction> {
    return Axios({
      ...config,
      url: `/withdraws/${RequestTypeEnum.Internal}`,
      method: 'POST',
      data,
    })
  }

  static crypto(
    data: CryptoSendRequest,
    config?: RequestConfig
  ): AxiosPromise<Transaction> {
    return Axios({
      ...config,
      url: `/withdraws/${RequestTypeEnum.Crypto}`,
      method: 'POST',
      data,
    })
  }

  static interbank(
    data: InterbankSendRequest,
    config?: RequestConfig
  ): AxiosPromise<Transaction> {
    return Axios({
      ...config,
      url: `/withdraws/${RequestTypeEnum.Interbank}`,
      method: 'POST',
      data,
    })
  }

  static mt(
    settingsId: number,
    data: MtSendRequest,
    config?: RequestConfig
  ): AxiosPromise<Transaction> {
    return Axios({
      ...config,
      url: `/withdraws/mt/${settingsId}`,
      method: 'POST',
      data,
    })
  }

  static card(
    settingsId: number,
    data: CardSendRequest,
    config?: RequestConfig
  ): AxiosPromise<Transaction> {
    return Axios({
      ...config,
      url: `/withdraws/card/${settingsId}`,
      method: 'POST',
      data,
    })
  }

  static makeOrder(
    data: WithdrawOrderRequest,
    config?: RequestConfig
  ): AxiosPromise<Order> {
    return Axios({
      ...config,
      url: `/withdraws/order`,
      method: 'POST',
      data,
    })
  }

  static getFee(data: FeeRequest, config?: RequestConfig): AxiosPromise<Fee> {
    return Axios({
      ...config,
      url: `/withdraws/fee`,
      method: 'POST',
      data,
    })
  }

  static getMtFee(
    data: WithdrawMtFeeRequest,
    config?: RequestConfig
  ): AxiosPromise<Fee> {
    return Axios({
      ...config,
      url: `/withdraws/mt/fee`,
      method: 'POST',
      data,
    })
  }

  static getCardFee(
    data: WithdrawCardFeeRequest,
    config?: RequestConfig
  ): AxiosPromise<Fee> {
    return Axios({
      ...config,
      url: `/withdraws/card/fee`,
      method: 'POST',
      data,
    })
  }

  static getOffer(
    data: WithdrawOfferRequest,
    config?: RequestConfig
  ): AxiosPromise<Offer> {
    return Axios({
      ...config,
      url: `/withdraws/offer`,
      method: 'POST',
      data,
    })
  }

  static confirm(
    data: ConfirmWithdrawRequest,
    config?: RequestConfig
  ): AxiosPromise<null> {
    return Axios({
      ...config,
      url: `/withdraws/confirm`,
      method: 'POST',
      data,
    })
  }

  static cancel(requestId: number, config?: RequestConfig): AxiosPromise<null> {
    return Axios({
      ...config,
      url: `/withdraws/${requestId}`,
      method: 'DELETE',
    })
  }

  static getMtCardsCountries(
    settingsId: number,
    config?: RequestConfig
  ): AxiosPromise<Array<Country>> {
    return Axios({
      ...config,
      url: `/withdraws/card/countries/${settingsId}`,
      method: 'GET',
    })
  }

  static getMtCashCountries(
    settingsId: number,
    config?: RequestConfig
  ): AxiosPromise<Array<Country>> {
    return Axios({
      ...config,
      url: `/withdraws/mt/countries/${settingsId}`,
      method: 'GET',
    })
  }

  static getMtCashSystems(
    settingsId: number,
    countryId: number,
    config?: RequestConfig
  ): AxiosPromise<Array<PaymentSystem>> {
    return Axios({
      ...config,
      url: `/withdraws/mt/payment-systems/${settingsId}/${countryId}`,
      method: 'GET',
    })
  }

  static getMtCashCities(
    paymentSystemId: number,
    countryId: number,
    config?: RequestConfig
  ): AxiosPromise<Array<City>> {
    return Axios({
      ...config,
      url: `/withdraws/mt/cities/${paymentSystemId}/${countryId}`,
      method: 'GET',
    })
  }

  static getMtCashBranches(
    cityId: number,
    config?: RequestConfig
  ): AxiosPromise<Array<MtBranch>> {
    return Axios({
      ...config,
      url: `/withdraws/mt/branches/${cityId}`,
      method: 'GET',
    })
  }
}
