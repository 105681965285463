import { ReactNode } from 'react'
import {
  FieldValues,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  UseFormMethods,
} from 'react-hook-form'
import styled from 'styled-components'

const StyledForm = styled.form`
  position: relative;
`

interface SmartFormProps<T> {
  formMethods: UseFormMethods<T>
  onSubmit: SubmitHandler<T>
  onInvalid?: SubmitErrorHandler<T>
  isLockable?: boolean
  className?: string
  children: ReactNode
}

export const SmartForm = <T extends FieldValues>({
  formMethods,
  onSubmit,
  onInvalid,
  isLockable,
  className,
  children,
}: SmartFormProps<T>) => (
  <FormProvider {...formMethods}>
    <StyledForm
      onSubmit={formMethods.handleSubmit(onSubmit, onInvalid)}
      className={className}
    >
      {children}

      {isLockable && (
        <input name="_meta.locked" type="hidden" ref={formMethods.register} />
      )}
    </StyledForm>
  </FormProvider>
)
