export const poll = async <T>({
  requestFn,
  validate,
  interval = 1500,
  maxAttempts,
}: {
  requestFn: () => Promise<T>
  validate: (data: T) => boolean
  interval?: number
  maxAttempts?: number
}) => {
  let attempts = 0

  const executePoll = async (
    resolve: (value: T) => void,
    reject: (reason: Error) => void
  ) => {
    const result = await requestFn()

    attempts++

    if (validate(result)) {
      return resolve(result)
    } else if (attempts === maxAttempts) {
      return reject(new Error('Exceeded max attempts'))
    }

    setTimeout(executePoll, interval, resolve, reject)
  }

  return new Promise(executePoll)
}
