import { useTranslation } from 'next-i18next'
import { forwardRef, useState, useRef, useEffect, useCallback } from 'react'
import { FormState, FieldValues, useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import { COLOR } from '@fe/common/constants/main'
import SlashedEye from '@fe/common/icons/eye-slash.svg'
import Eye from '@fe/common/icons/eye.svg'
import { Errors } from '@fe/common/types/Errors'
import { getErrorStatus, getLockedStatus } from '@fe/common/utils/formUtils'
import { InputError } from './InputError'
import {
  iconReveal,
  InputContainer,
  Label,
  StatusBar,
  StyledInput,
  OptionalTag,
} from './baseInputStyles'

const EyeContainer = styled.div<{ isShifted: boolean }>`
  position: absolute;
  right: 3rem;
  top: 2.5rem;
  width: 2rem;
  cursor: pointer;

  svg {
    color: ${COLOR.GREY};
    animation: ${iconReveal} 0.1s ease-out;

    :hover {
      color: ${COLOR.OFF_BLACK};
    }
  }
`

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  name?: string
  label?: string
  type?: 'text' | 'email' | 'password' | 'number'
  placeholder?: string
  isDisabled?: boolean
  isOptional?: boolean
  extraLabel?: string
  onBlur?: (name?: unknown) => void
  isSmall?: boolean
  autoComplete?: string
  defaultValue?: string
  errors?: Errors
  formState?: FormState<FieldValues>
  isIndependant?: boolean
  isFullWidth?: boolean
  isFocused?: boolean
  isLoading?: boolean
  className?: string
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      name,
      label,
      type = 'text',
      placeholder,
      isDisabled,
      isOptional,
      extraLabel,
      onBlur,
      isSmall,
      autoComplete,
      defaultValue,
      isFullWidth,
      isIndependant = true,
      isFocused,
      isLoading,
      className,
      ...inputProps
    },
    ref
  ) => {
    const { t } = useTranslation()
    const [isPasswordVisible, setIsPasswordVisible] = useState(false)

    const inputRef = useRef<HTMLInputElement>()
    const formContext = useFormContext()

    useEffect(() => {
      if (isFocused) {
        inputRef.current.focus()
      }
    }, [])

    const togglePassword = useCallback(() => {
      setIsPasswordVisible(current => !current)
    }, [])

    const getPlaceholder = useCallback(() => {
      switch (type) {
        case 'password':
          return label || t('password')
        case 'email':
          return 'Email'
        default:
          return placeholder || label
      }
    }, [])

    const getType = useCallback(() => {
      if (type === 'password') {
        if (isPasswordVisible) {
          return 'text'
        }

        return 'password'
      }

      return type
    }, [isPasswordVisible])

    const handleBlur = useCallback(() => onBlur?.(name), [onBlur])

    const errors = inputProps.errors || formContext?.errors
    const formState = inputProps.formState || formContext?.formState
    const register = isIndependant ? ref || formContext?.register : null

    const { error, isErrorDisplayed, isSuccessfullyFilled } = getErrorStatus(
      name,
      errors,
      formState
    )

    const formattedAutoComplete =
      type === 'email' ? 'on' : autoComplete ? autoComplete : 'off'

    const isLocked = getLockedStatus(formContext, name)

    return (
      <InputContainer
        isFullWidth={isFullWidth}
        withError={!!errors}
        isLoading={isLoading}
        className={className}
      >
        <StyledInput
          name={name}
          id={name}
          autoComplete={formattedAutoComplete}
          isDisabled={isDisabled || isLocked}
          type={getType()}
          step={type === 'number' ? '.01' : undefined}
          placeholder={getPlaceholder()}
          hasError={isErrorDisplayed}
          onBlur={handleBlur}
          defaultValue={defaultValue}
          isSmall={isSmall}
          ref={e => {
            if (!register) {
              return
            }

            inputRef.current = e
            ;(register as (instance: HTMLInputElement) => void)(e)
          }}
          data-test-id={name}
          {...inputProps}
        />
        <Label
          htmlFor={name}
          hasError={isErrorDisplayed}
          isShown={inputProps.value && !inputProps.onChange}
        >
          <span>{label || getPlaceholder()}</span>
          {(isOptional || extraLabel) && (
            <OptionalTag>{extraLabel || t('common:optional')}</OptionalTag>
          )}
        </Label>
        <StatusBar
          isSuccessfullyFilled={isSuccessfullyFilled}
          isSmall={isSmall}
        />

        {type === 'password' && (
          <EyeContainer
            onClick={togglePassword}
            isShifted={isSuccessfullyFilled}
          >
            {isPasswordVisible ? <Eye /> : <SlashedEye />}
          </EyeContainer>
        )}

        {isErrorDisplayed && <InputError name={name} error={error} />}
      </InputContainer>
    )
  }
)
