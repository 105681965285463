import { createAction, createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'
import { AppState } from 'src/state/store'

const hydrate = createAction<AppState>(HYDRATE)

export interface ExchangeModalState {
  isOpened: boolean
}

export const initialExchangeModalState: ExchangeModalState = {
  isOpened: false,
}

const exchangeModalSlice = createSlice({
  name: 'exchangeModal',
  initialState: initialExchangeModalState,
  reducers: {
    flushExchangeModal() {
      return initialExchangeModalState
    },
    openExchangeModal(state) {
      state.isOpened = true
    },
    closeExchangeModal(state) {
      state.isOpened = false
    },
  },
  extraReducers: builder => {
    builder.addCase(hydrate, state => state)
  },
})

const { actions, reducer } = exchangeModalSlice

export const { flushExchangeModal, openExchangeModal, closeExchangeModal } =
  actions

export default reducer
