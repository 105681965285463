import QRCodeGenerator from 'qrcode'
import { useCallback, useEffect, useState } from 'react'

const cryptoPrefixes: Record<string, string> = {
  BTC: 'bitcoin',
  BCH: 'bitcoincash',
  ETH: 'etherium',
  LTC: 'litecoin',
}

export const useCryptoQr = (
  address: string,
  amount: number,
  currency: string
) => {
  const [qrCodeSrc, setQrCodeSrc] = useState('')

  const currencyPrefix = cryptoPrefixes[currency] || currency

  const getQr = useCallback(
    async (address: string, amount: number, currencyPrefix: string) => {
      const text = amount
        ? `${currencyPrefix}:${address}?amount=${amount}`
        : '✌️'

      const dataUrl = await QRCodeGenerator.toDataURL(text, {
        color: { light: '#00000000' },
        margin: 0,
      })

      setQrCodeSrc(dataUrl)
    },
    []
  )

  useEffect(() => {
    getQr(address, amount, currencyPrefix)
  }, [address, amount, currency])

  return qrCodeSrc
}
