import { useEffect, useState } from 'react'
import { throttle } from 'throttle-debounce'
import { BREAKPOINT } from '@fe/common/constants/main'

const getIsTablet = (window: Window) =>
  window.innerWidth >= BREAKPOINT.MOBILE &&
  window.innerWidth < BREAKPOINT.TABLET

const getIsDesktop = (window: Window) => window.innerWidth >= BREAKPOINT.TABLET

const useDeviceType = () => {
  const [isTablet, setIsTablet] = useState(getIsTablet(window))
  const [isDesktop, setIsDesktop] = useState(getIsDesktop(window))

  useEffect(() => {
    const handleSetWidth = throttle(300, () => {
      if (window) {
        setIsTablet(getIsTablet(window))
        setIsDesktop(getIsDesktop(window))
      }
    })

    window.addEventListener('resize', handleSetWidth)

    return () => window.removeEventListener('resize', handleSetWidth)
  }, [])

  return {
    isMobile: isTablet === false && isDesktop === false,
    isTablet,
    isDesktop,
  }
}

export default useDeviceType
