import { forwardRef, ReactNode } from 'react'
import { useFormContext } from 'react-hook-form'
import styled, { css } from 'styled-components'
import { blink } from '@fe/common/constants/animations'
import { BORDER, COLOR } from '@fe/common/constants/main'
import CheckIcon from '@fe/common/icons/check-1.svg'

const Container = styled.label<{ isDisabled?: boolean; isLoading?: boolean }>`
  display: flex;
  align-items: center;
  width: fit-content;
  opacity: ${({ isDisabled }) => isDisabled && 0.6};
  pointer-events: ${({ isDisabled }) => isDisabled && 'none'};
  cursor: pointer;

  ${({ isLoading }) =>
    isLoading &&
    css`
      pointer-events: none;
      ${blink}

      * {
        color: grey;
      }
    `};

  a {
    text-decoration: underline;
    margin: 0 0.5rem;

    :hover {
      opacity: 0.7;
    }
  }

  > span {
    line-height: 1.7;
    white-space: normal;
  }
`

const CheckContainer = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 2rem;
  border: ${BORDER.DEFAULT};
  border-radius: 3px;
  background: ${COLOR.WHITE};

  input {
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
`

const Check = styled.span`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background: ${COLOR.WHITE};
  opacity: 0;
  transition: opacity 0.1s, background 0.1s;
  pointer-events: none;

  input:checked ~ & {
    opacity: 1;
    background: ${({ theme }) => theme.primary};
  }

  input:focus ~ &,
  input:hover ~ & {
    opacity: 1;
    box-shadow: 0 0 0px 1px ${({ theme }) => theme.primary};
  }

  svg {
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    transition: opacity 0.1s;
  }
`

const LabelText = styled.span`
  display: inline-flex;
  align-items: flex-end;
`

interface CheckboxProps {
  name: string
  value?: string
  label: ReactNode
  checked?: boolean
  isDisabled?: boolean
  defaultValue?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  isLoading?: boolean
  className?: string
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      name,
      value,
      label,
      checked,
      isDisabled,
      defaultValue,
      onChange,
      isLoading,
      className,
    },
    ref
  ) => {
    const formContext = useFormContext()

    const register = ref || formContext?.register

    return (
      <Container
        className={className}
        onMouseDown={e => e.preventDefault()}
        isDisabled={isDisabled}
        isLoading={isLoading}
      >
        <CheckContainer>
          <input
            name={name}
            value={value}
            id={value || name}
            type="checkbox"
            checked={checked}
            defaultChecked={defaultValue}
            onChange={onChange}
            data-test-id={`${name}_${value}`}
            ref={e => (register as (instance: HTMLInputElement) => void)?.(e)}
          />
          <Check>
            <CheckIcon />
          </Check>
        </CheckContainer>

        <LabelText>{label}</LabelText>
      </Container>
    )
  }
)
