import { useTranslation } from 'next-i18next'
import { useDispatch } from 'react-redux'
import { Button } from '@fe/common/components/Button'
import { SuccessModal } from '@fe/common/components/SuccessModal'
import { closeSendModal } from 'src/state/reducers/sendModalReducer'

interface SuccessStepProps {
  handleRepeat: () => void
}

export const SuccessStep: React.FC<SuccessStepProps> = ({ handleRepeat }) => {
  const { t } = useTranslation('transactions')

  const dispatch = useDispatch()

  const handleClose = () => dispatch(closeSendModal())

  return (
    <SuccessModal
      successText={t('sent-successfully')}
      handleClose={handleClose}
    >
      <Button isSecondary onClick={handleRepeat} testId="repeat_button">
        {t('another-transaction')}
      </Button>

      <Button onClick={handleClose} testId="close_button">
        {t('common:close')}
      </Button>
    </SuccessModal>
  )
}
