import { useTranslation } from 'next-i18next'
import {
  Controller,
  Control,
  FieldValues,
  FormState,
  useFormContext,
} from 'react-hook-form'
import { OnChangeValue } from 'react-select'
import styled from 'styled-components'
import { Errors } from '@fe/common/types/Errors'
import { getErrorStatus, getLockedStatus } from '@fe/common/utils/formUtils'
import {
  CustomSelect,
  DropdownOption,
  DropdownOptions,
  CustomSelectProps,
} from './CustomSelect'
import { InputError } from './InputError'
import {
  InputContainer,
  StatusBar,
  Label,
  OptionalTag,
} from './baseInputStyles'

const DropdownLabel = styled(Label)<{ isShown: boolean }>`
  > :first-child {
    opacity: ${({ isShown }) => (isShown ? 1 : 0)};
  }

  div:focus-within ~ &,
  div:hover ~ & {
    opacity: 1;

    > :first-child {
      opacity: 1;
    }
  }
`

const DropdownStatusBar = styled(StatusBar)`
  div:focus-within ~ & {
    width: 1rem;
  }
`

export const handleDropdownChange = (
  selected: OnChangeValue<DropdownOption, boolean>
) => {
  if (selected === undefined || selected === null) {
    return null
  }

  if (selected instanceof Array) {
    return selected.map(option => option.value)
  }

  return selected.value
}

interface DropdownProps
  extends Omit<CustomSelectProps, 'onBlur' | 'theme' | 'value'> {
  name: string
  label: string
  options: DropdownOptions
  isFullWidth?: boolean
  isDisabled?: boolean
  onBlur?: (name?: unknown) => void
  isSmall?: boolean
  isHighlighted?: boolean
  isOptional?: boolean
  extraLabel?: string
  isWithLoader?: boolean
  control?: Control
  errors?: Errors
  formState?: FormState<FieldValues>
  className?: string
}

export const Dropdown: React.FC<DropdownProps> = ({
  name,
  label,
  options,
  isFullWidth,
  isDisabled,
  isMulti,
  onBlur,
  isSmall,
  isHighlighted,
  isOptional,
  extraLabel,
  isLoading,
  isWithLoader = true,
  className,
  ...rest
}) => {
  const { t } = useTranslation()

  const formContext = useFormContext()

  const handleBlur = (controlOnBlur: () => void) => {
    if (onBlur) {
      onBlur(name)
    }

    controlOnBlur()
  }

  const errors = rest.errors || formContext?.errors
  const formState = rest.formState || formContext?.formState
  const control = rest.control || formContext?.control

  const { error, isErrorDisplayed, isSuccessfullyFilled } = getErrorStatus(
    name,
    errors,
    formState
  )

  const isLocked = getLockedStatus(formContext, name)

  return (
    <InputContainer
      isFullWidth={isFullWidth}
      isLoading={!isWithLoader && isLoading}
      withError={!!errors}
      className={className}
    >
      <Controller
        render={({ value, onChange, onBlur: controlOnBlur, name }) => (
          <CustomSelect
            name={name}
            value={value}
            options={options}
            onChange={(value: OnChangeValue<DropdownOption, typeof isMulti>) =>
              onChange(handleDropdownChange(value))
            }
            onBlur={() => handleBlur(controlOnBlur)}
            hasError={isErrorDisplayed}
            placeholder={label}
            isSearchable
            isMulti={isMulti}
            blurInputOnSelect
            isDisabled={isDisabled || isLocked}
            isSmall={isSmall}
            isLoading={isWithLoader && isLoading}
            {...rest}
          />
        )}
        name={name}
        control={control}
      />

      <DropdownLabel
        htmlFor={name}
        hasError={isErrorDisplayed}
        isShown={!!control.getValues(name)}
      >
        <span>{label}</span>
        {(isOptional || extraLabel) && (
          <OptionalTag>{extraLabel || t('common:optional')}</OptionalTag>
        )}
      </DropdownLabel>
      <DropdownStatusBar
        isSuccessfullyFilled={isHighlighted || isSuccessfullyFilled}
        isSmall={isSmall}
      />

      {isErrorDisplayed && <InputError name={name} error={error} />}
    </InputContainer>
  )
}
