import Axios from 'axios'
import { useEffect, useRef } from 'react'

export const useCancelToken = (dependancy?: any) => {
  const axiosCancelSource = useRef(Axios.CancelToken.source())

  useEffect(
    () => () => {
      axiosCancelSource.current.cancel()

      axiosCancelSource.current = Axios.CancelToken.source()
    },
    [dependancy]
  )

  return axiosCancelSource.current.token
}
