import { useTranslation } from 'next-i18next'
import { useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Button } from '@fe/common/components/Button'
import { Modal } from '@fe/common/components/Modal'
import { ButtonContainer } from '@fe/common/components/formStyles'
import { COLOR, SCREEN } from '@fe/common/constants/main'
import { useCountdown } from '@fe/common/src/hooks/useCountdown'
import { deauthenticate } from 'src/state/actions/sessionActions'
import {
  refreshToken,
  showLogoutWarning,
} from 'src/state/reducers/sessionReducer'
import {
  expirationDateSelector,
  logoutModalStateSelector,
} from 'src/state/selectors/sessionSelectors'

const LogoutButton = styled(Button)`
  flex-shrink: 2;

  ${SCREEN.ABOVE_MOBILE} {
    flex-shrink: 1;
  }
`

export const LogoutWarningModal: React.FC = () => {
  const { t } = useTranslation()
  const isLogoutModalShown = useSelector(logoutModalStateSelector)
  const expireAt = useSelector(expirationDateSelector)

  const { timeLeft } = useCountdown({
    expireAt,
  })

  const dispatch = useDispatch()

  useLayoutEffect(() => {
    if (!timeLeft) {
      dispatch(showLogoutWarning(false))
    }
  }, [])

  const handleRefresh = () => {
    dispatch(refreshToken())
    dispatch(showLogoutWarning(false))
  }

  const handleLogout = () => {
    dispatch(deauthenticate())
  }

  if (!isLogoutModalShown) {
    return null
  }

  return (
    <Modal
      title={t('warning')}
      isWithoutHeaderSeparator
      isLogoutWarning
      maxWidth="53rem"
    >
      <p>{t('logout-warning', { timeLeft })}</p>

      <ButtonContainer isForModal isCentered>
        <Button onClick={handleRefresh} isFullWidth>
          {t('refresh-session')}
        </Button>

        <LogoutButton
          color={COLOR.LIGHT_GREY}
          onClick={handleLogout}
          isFullWidth
        >
          {t('logout')}
        </LogoutButton>
      </ButtonContainer>
    </Modal>
  )
}
