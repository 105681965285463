import { useTranslation } from 'next-i18next'
import { useCallback, useEffect, useMemo } from 'react'
import { DeepMap, FieldError, useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { RequestTypeEnum } from '@fe/common/api/models/enums/RequestTypeEnum'
import { Spinner } from '@fe/common/components/Spinner'
import { DropdownOptions } from '@fe/common/components/inputs/CustomSelect'
import { Dropdown } from '@fe/common/components/inputs/Dropdown'
import { Input } from '@fe/common/components/inputs/Input'
import { NumberInput } from '@fe/common/components/inputs/NumberInput'
import { SCREEN } from '@fe/common/constants/main'
import { formatDataToOptions } from '@fe/common/utils/arrayUtils'
import { MtData } from '../useMtData'
import {
  getStepsConfig,
  InternalFields,
  SendData,
  SendFieldConfig,
} from './stepsConfig'
import { BanksApi } from 'src/api/BanksApi'
import { countryOptionsSelector } from 'src/state/selectors/globalDataSelectors'
import { FieldTypesEnum } from 'src/types/FieldConfig'
import { SendStepsEnum } from 'src/types/enums/SendStepsEnum'

const Container = styled.div<{ isFirstStep: boolean }>`
  ${SCREEN.ABOVE_MOBILE} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 2rem;

    ${({ isFirstStep }) =>
      isFirstStep &&
      css`
        > div:first-child:nth-last-child(2) {
          grid-column: 1/3;
        }

        > :last-child {
          grid-column: 1/3;
        }
      `};
  }
`

const StyledSpinner = styled(Spinner)`
  display: block;
  margin: 10rem auto;
`

interface SendStepsProps {
  step: SendStepsEnum
  mtData: MtData
  interbankBankOptions: DropdownOptions
  setInterbankBanksOptions: React.Dispatch<
    React.SetStateAction<DropdownOptions>
  >
}

export const SendSteps: React.FC<SendStepsProps> = ({
  step,
  mtData,
  interbankBankOptions,
  setInterbankBanksOptions,
}) => {
  const { t } = useTranslation('transactions')
  const countryOptions = useSelector(countryOptionsSelector)

  const { watch, formState, register, control, errors } =
    useFormContext<SendData>()

  if (
    (errors?.requestTypeFields as DeepMap<InternalFields, FieldError>)
      ?.bank_account_number?.type === 'receiver-liability'
  ) {
    ;(
      errors.requestTypeFields as DeepMap<InternalFields, FieldError>
    ).bank_account_number = null
  }

  const { submitCount, dirtyFields } = formState

  const { settings_id, request_type } = watch(['settings_id', 'request_type'])

  const fetchBanks = useCallback(async settingsId => {
    try {
      const { data } = await BanksApi.getBanks(settingsId)

      const bankOptions = formatDataToOptions(data)

      setInterbankBanksOptions(bankOptions)
    } catch (error) {
      console.error(error)
    }
  }, [])

  useEffect(() => {
    if (request_type === RequestTypeEnum.Interbank) {
      fetchBanks(settings_id)
    }
  }, [settings_id, request_type])

  const cardsCountriesOptions = useMemo(
    () => formatDataToOptions(mtData?.cardsCountries),
    [mtData?.cardsCountries]
  )
  const cashCountriesOptions = useMemo(
    () => formatDataToOptions(mtData?.cashCountries),
    [mtData?.cashCountries]
  )
  const cashSystemsOptions = useMemo(
    () => formatDataToOptions(mtData?.cashSystems),
    [mtData?.cashSystems]
  )
  const cashCitiesOptions = useMemo(
    () => formatDataToOptions(mtData?.cashCities),
    [mtData?.cashCities]
  )
  const cashBranchesOptions = useMemo(
    () => formatDataToOptions(mtData?.cashBranches),
    [mtData?.cashBranches]
  )

  const renderField = ({
    type,
    name,
    label,
    options,
    isOptional,
  }: SendFieldConfig) => {
    switch (type) {
      case FieldTypesEnum.Text:
        return (
          <Input
            key={name}
            name={`requestTypeFields.${name}`}
            label={t(label)}
            isOptional={isOptional}
            isFullWidth
            ref={register}
            formState={formState}
            errors={errors}
          />
        )

      case FieldTypesEnum.Number:
        return (
          <NumberInput
            key={name}
            name={`requestTypeFields.${name}`}
            label={t(label)}
            isOptional={isOptional}
            allowLeadingZeros
          />
        )

      case FieldTypesEnum.Dropdown:
        return (
          <Dropdown
            key={name}
            name={`requestTypeFields.${name}`}
            label={t(label)}
            options={options}
            isFullWidth
            control={control}
            formState={formState}
            errors={errors}
          />
        )
    }
  }

  const renderInputs = useCallback(() => {
    const stepsConfig = getStepsConfig(
      countryOptions,
      interbankBankOptions,
      cardsCountriesOptions,
      cashCountriesOptions,
      cashSystemsOptions,
      cashCitiesOptions,
      cashBranchesOptions
    )

    const stepConfig = stepsConfig[request_type]

    const stepFieldsConfig = stepConfig[step]

    return <>{stepFieldsConfig.map(fieldConfig => renderField(fieldConfig))}</>
  }, [
    request_type,
    step,
    dirtyFields,
    submitCount,
    interbankBankOptions,
    mtData,
  ])

  if (!settings_id || !request_type) {
    return <StyledSpinner />
  }

  return (
    <Container isFirstStep={step === SendStepsEnum.General}>
      {renderInputs()}
    </Container>
  )
}
