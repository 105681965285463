import ReactTooltip, { TooltipProps } from 'react-tooltip'
import styled from 'styled-components'
import { FONT_SIZE } from '@fe/common/constants/main'

const StyledReactTooltip = styled(ReactTooltip)<{ isNotLimited: boolean }>`
  &&& {
    max-width: ${({ isNotLimited }) => !isNotLimited && '35rem'};
    padding: 0.5rem 1.5rem;
    border-radius: 8px;
    font-size: ${FONT_SIZE.SMALL};
    white-space: normal;
    transition: opacity 0.2s ease-out;
  }
`

interface Props extends TooltipProps {
  id: string
  isNotLimited?: boolean
}

export const Tooltip: React.FC<Props> = ({
  id,
  isNotLimited,
  children,
  ...rest
}) => (
  <StyledReactTooltip
    id={id}
    place="top"
    offset={{ top: -10 }}
    effect="solid"
    arrowColor="transparent"
    isNotLimited={isNotLimited}
    {...rest}
  >
    {children}
  </StyledReactTooltip>
)
