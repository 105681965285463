import Axios, { AxiosPromise } from 'axios'
import { AccessToken } from '@fe/common/api/models/AccessToken'
import { ApiKey } from '@fe/common/api/models/ApiKey'
import { LoginStatusData } from '@fe/common/api/models/LoginStatusData'
import { MfaPreparationData } from '@fe/common/api/models/MfaPreparationData'
import { LoginStatusRequest } from '@fe/common/api/models/RequestModels/LoginStatusRequest'
import { PrepareTokenMfaRequest } from '@fe/common/api/models/RequestModels/PrepareTokenMfaRequest'
import { TokenRequest } from '@fe/common/api/models/RequestModels/TokenRequest'
import { VerifiedRequest } from '@fe/common/api/models/RequestModels/VerifiedRequest'
import { RequestConfig } from '@fe/common/axiosConfig'

export class TokenApi {
  static prepareTokenMfa(
    requestData: PrepareTokenMfaRequest,
    config?: RequestConfig
  ): AxiosPromise<MfaPreparationData> {
    return Axios({
      ...config,
      url: `/users/token/prepare-mfa`,
      method: 'POST',
      data: requestData,
    })
  }

  static getToken(
    requestData: TokenRequest,
    config?: RequestConfig
  ): AxiosPromise<AccessToken> {
    return Axios({
      ...config,
      url: `/users/token`,
      method: 'POST',
      data: requestData,
    })
  }

  static refreshToken(
    access_token: string,
    user_id: number,
    config?: RequestConfig
  ): AxiosPromise<AccessToken> {
    return Axios({
      ...config,
      url: `/users/token/refresh`,
      method: 'POST',
      data: { access_token, user_id },
    })
  }

  static getLoginStatus(
    data: LoginStatusRequest,
    config?: RequestConfig
  ): AxiosPromise<LoginStatusData> {
    return Axios({
      ...config,
      url: `/users/token/status`,
      method: 'POST',
      data,
    })
  }

  static generateApiKey(
    data: VerifiedRequest,
    config?: RequestConfig
  ): AxiosPromise<ApiKey> {
    return Axios({
      ...config,
      url: `/users/token/merchant`,
      method: 'POST',
      data,
    })
  }

  static getApiKeys(config?: RequestConfig): AxiosPromise<ApiKey> {
    return Axios({
      ...config,
      url: `/users/token/merchant`,
      method: 'GET',
    })
  }

  static deleteApiKey(
    data: VerifiedRequest,
    config?: RequestConfig
  ): AxiosPromise<null> {
    return Axios({
      ...config,
      url: `/users/token/merchant`,
      method: 'DELETE',
      data,
    })
  }
}
