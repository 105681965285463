import { yupResolver } from '@hookform/resolvers/yup'
import { TFunction, useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { ValidationApi } from '@fe/common/api/ValidationApi'
import { Order } from '@fe/common/api/models/Order'
import { MfaActionTypeEnum } from '@fe/common/api/models/enums/MfaActionTypeEnum'
import { Button } from '@fe/common/components/Button'
import { Input } from '@fe/common/components/inputs/Input'
import {
  BORDER,
  COLOR,
  FONT_WEIGHT,
  PADDING,
  SCREEN,
} from '@fe/common/constants/main'
import { isTokenCookieAlive } from '@fe/common/utils/cookies'
import yup from '@fe/common/yup/yupConfig'
import { OrderApi } from 'src/api/OrderApi'
import { MfaData, MfaInput } from 'src/components/MfaInput'
import { Summary } from 'src/components/receiveStatus/Summary'
import { LOGIN, RECEIVE_STATUS } from 'src/constants/routes'
import { ReceiveStatusStepEnum } from 'src/types/enums/ReceiveStatusStepEnum'

const Container = styled.div`
  padding: ${PADDING.MOBILE}rem;
  border-bottom: ${BORDER.DEFAULT};

  ${SCREEN.ABOVE_MOBILE} {
    padding: ${PADDING.DESKTOP}rem;
  }
`

const RefundSummary = styled(Summary)`
  margin-left: 0;
  padding-bottom: ${PADDING.MOBILE}rem;
  border-bottom: ${BORDER.DEFAULT};
`

const StyledInput = styled(Input)`
  max-width: unset;
  margin-top: ${PADDING.MOBILE}rem;
  margin: ${PADDING.MOBILE}rem 0;
  border-bottom: ${BORDER.DEFAULT};
`

const ConfirmRefundText = styled.div`
  margin: ${PADDING.MOBILE}rem 0;
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
  text-align: center;
`

const VerificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 2rem 3rem;
  margin-bottom: ${PADDING.MOBILE}rem;
  background-color: ${COLOR.BACKGROUND};

  ${SCREEN.ABOVE_TABLET} {
    justify-content: center;
    flex-direction: row;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${PADDING.MOBILE}rem 0;
`

const ValidationError = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
  width: 100%;
  padding: 1rem;
  text-align: right;
  color: ${COLOR.RED};
`

const getRequestSchema = (currency_in: string, t: TFunction) =>
  yup.object().shape({
    refundEmail: yup
      .string()
      .test(
        'isCorrectRefundAddress',
        t('refund-address-error'),
        async address => {
          try {
            await ValidationApi.cryptoAddressByCode(currency_in, address)
          } catch (error) {
            console.error(error)
          }

          return false
        }
      )
      .required(),
  })

interface RequestRefundProps {
  order: Order
  showSuccess: () => void
  showRefundError: () => void
}

export const RequestRefund: React.FC<RequestRefundProps> = ({
  order,
  showSuccess,
  showRefundError,
}) => {
  const [mfaData, setMfaData] = useState<MfaData>()
  const [validationError, setValidationError] = useState('')

  const [t] = useTranslation('receive-status')
  const router = useRouter()

  const {
    created_at,
    total_amount_in,
    precision_in,
    currency_in,
    uuid,
    total_amount_out,
    precision_out,
    currency_out,
    can_refund,
  } = order

  useEffect(() => {
    if (!isTokenCookieAlive()) {
      const redirectQuery = encodeURIComponent(
        `${RECEIVE_STATUS}?order_uuid=${uuid}&statusStep=${ReceiveStatusStepEnum.RequestRefund}`
      )
      router.push(`${LOGIN}?redirect=${redirectQuery}`)
    }
  }, [isTokenCookieAlive()])

  const { handleSubmit, register, errors, formState } = useForm<{
    refundEmail: string
  }>({
    mode: 'onChange',
    resolver: yupResolver(getRequestSchema(currency_in, t)),
  })

  const onFormSubmit = async (data: {
    refundEmail: string
    googleAuth: boolean
  }) => {
    try {
      const { mfa_code, mfa_type, mfa_action_code } = mfaData

      const successOrder = await OrderApi.postOrderRefund({
        order_uuid: uuid,
        address: data.refundEmail,
        mfa_code: Number(mfa_code),
        mfa_type,
        mfa_action_code,
      })

      if (successOrder?.status === 200) {
        showSuccess()

        return
      }
    } catch (error) {
      setValidationError(error.response.data?.[0]?.message?.[0])
    }
  }

  const handleCodeEnter = async (mfaData: MfaData) => {
    setMfaData(mfaData)
  }

  if (can_refund === 0) {
    showRefundError()
  }

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <Container>
        <RefundSummary
          created_at={created_at}
          uuid={uuid}
          total_amount_in={total_amount_in}
          total_amount_out={total_amount_out}
          precision_in={precision_in}
          precision_out={precision_out}
          currency_in={currency_in}
          currency_out={currency_out}
          isRefundStep
        />

        <StyledInput
          name="refundEmail"
          ref={register}
          errors={errors}
          formState={formState}
          label={t('refund-address-placeholder')}
        />

        <ConfirmRefundText>{t('confirm-refund-text')}</ConfirmRefundText>

        <VerificationContainer>
          <MfaInput
            onCodeEnter={handleCodeEnter}
            actionType={MfaActionTypeEnum.OrderRefund}
          />
          <ValidationError>{validationError}</ValidationError>
        </VerificationContainer>
      </Container>

      <ButtonContainer>
        <Button isSubmit>{t('send-request-request-label')}</Button>
      </ButtonContainer>
    </form>
  )
}
