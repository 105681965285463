import { Trans, useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { Order } from '@fe/common/api/models/Order'
import { OrderStatusEnum } from '@fe/common/api/models/enums/OrderStatusEnum'
import { Amount } from '@fe/common/components/Amount'
import { Button } from '@fe/common/components/Button'
import { ClipboardCopy } from '@fe/common/components/ClipboardCopy'
import {
  COLOR,
  FONT_SIZE,
  PADDING,
  SCREEN,
  FONT_WEIGHT,
} from '@fe/common/constants/main'
import { useCryptoQr } from '@fe/common/hooks/useCryptoQr'
import { CryptoAddresses } from '@fe/common/src/api/models/CryptoAddresses'
import { InterbankDepositDetails } from '@fe/common/src/api/models/InterbankDepositDetails'
import { CashDepositDetails } from '@fe/common/src/api/models/CashDepositDetails'
import { RequestTypeEnum } from '@fe/common/src/api/models/enums/RequestTypeEnum'
import Clock from '@fe/common/src/icons/clock.svg'
import Share from '@fe/common/src/icons/share.svg'
import { getTranslatedDayAndHourLeft } from '@fe/common/utils/dateUtils'
import { SummaryTabs } from 'src/components/receiveStatus/SummaryTabs'
import { RECEIVE_STATUS } from 'src/constants/routes'

const BankLink = styled.a`
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
  text-decoration: underline;

  :hover {
    opacity: 0.7;
  }
`

const BaseInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const Label = styled.div`
  font-size: ${FONT_SIZE.SMALL};
  color: ${COLOR.GREY};
`

const Status = styled.div<{ statusColor: string }>`
  color: ${({ statusColor }) => statusColor};
  font-size: ${FONT_SIZE.LARGE};
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
  margin-right: ${PADDING.MOBILE}rem;

  ${SCREEN.ABOVE_MOBILE} {
    margin-right: ${PADDING.DESKTOP}rem;
  }
`

const TimeLeftContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: ${FONT_SIZE.LARGE};
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
`

const TimeLeft = styled.div`
  color: ${COLOR.BLACK};
`

const ClockIcon = styled(Clock)`
  height: 1.6rem;
  width: 1.6rem;
  margin-right: 1rem;
  fill: ${COLOR.BLACK};
`

const ConfirmationsText = styled.div`
  display: flex;
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
  font-size: ${FONT_SIZE.LARGE};
`

const QrRow = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLOR.OFF_WHITE};
  margin: ${PADDING.MOBILE}rem 0;
  padding: ${PADDING.MOBILE}rem 0;

  ${SCREEN.ABOVE_MOBILE} {
    flex-direction: row;
    padding: 0;
  }
`

const QrCode = styled.img`
  width: 16rem;
  height: 16rem;
  margin-bottom: 2rem;
  padding: 1rem;
  align-self: center;

  ${SCREEN.ABOVE_MOBILE} {
    margin-bottom: 0;
  }
`

const QrInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0.5rem 2rem;
  word-break: break-all;

  span {
    font-weight: ${FONT_WEIGHT.SEMIBOLD};
  }

  > :first-child {
    margin-bottom: 1rem;
  }

  ${SCREEN.ABOVE_MOBILE} {
    margin: 1.5rem 2rem;

    > :first-child {
      margin-bottom: 0;
    }
  }
`

const Amounts = styled.div`
  > div {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-bottom: 1rem;

    > :first-child {
      margin-right: 1rem;
    }
  }

  ${SCREEN.ABOVE_MOBILE} {
    display: flex;

    > div {
      display: block;
      width: 15rem;
      margin-bottom: 0;

      > :first-child {
        margin-right: 0;
      }
    }
  }
`

const SummaryRowContainer = styled.div`
  position: relative;
  padding-top: ${PADDING.MOBILE}rem;
`

const RequestRefundButton = styled(Button)`
  position: absolute;
  bottom: 5rem;
  right: 0;

  ${SCREEN.ABOVE_MOBILE} {
    bottom: unset;
    top: 1.2rem;
  }
`

const NewTabLink = styled.a`
  display: flex;
  align-self: center;
  align-items: end;
  margin-left: auto;
  color: ${COLOR.BLUE};
  font-size: ${FONT_SIZE.SMALL};
  justify-content: flex-end;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`

const getStatusInfo = (status: number) => {
  switch (status) {
    case OrderStatusEnum.New:
      return { statusColor: COLOR.BLUE, statusTranslationKey: 'new-status' }
    case OrderStatusEnum.InProgress:
      return {
        statusColor: COLOR.GOLD,
        statusTranslationKey: 'in-progress-status',
      }
    case OrderStatusEnum.TimedOut:
      return {
        statusColor: COLOR.RED,
        statusTranslationKey: 'timed-out-status',
      }
    case OrderStatusEnum.Completed:
      return {
        statusColor: COLOR.GREEN,
        statusTranslationKey: 'completed-status',
      }
    case OrderStatusEnum.WaitingForPayment:
      return {
        statusColor: COLOR.GOLD,
        statusTranslationKey: 'waiting-for-payment-status',
      }
  }
}

interface ReceiveStatusStepProps {
  order: Order
  showRefund: () => void
}

export const ReceiveStatusStep: React.FC<ReceiveStatusStepProps> = ({
  order,
  showRefund,
}) => {
  const {
    timeout,
    created_at,
    status,
    deposit_details,
    total_amount_in,
    precision_in,
    currency_in,
    request_type_in,
    confirmations_need,
    confirmations_have,
    rest_amount,
    can_refund,
  } = order

  const { t } = useTranslation('receive-status')

  const { route } = useRouter()

  const { statusColor, statusTranslationKey } = getStatusInfo(status)
  const timeLeft = getTranslatedDayAndHourLeft(created_at + timeout, t)

  const isStandalone = route.includes(RECEIVE_STATUS)

  const qrCodeSrc = useCryptoQr(
    (deposit_details as CryptoAddresses).uid,
    total_amount_in,
    currency_in
  )

  // const accessToken = useSelector(accessTokenSelector) // canceled CL-614

  // Based on Order type (field request_type_in), use appropriate components to render right deposit details
  switch (request_type_in) {
    case RequestTypeEnum.Crypto:
      return (
        <div>
          <BaseInfoRow>
            <div>
              <Label>{t('main-status-label')}</Label>
              <Status statusColor={statusColor}>
                {t(statusTranslationKey)}
              </Status>
            </div>

            {status === OrderStatusEnum.New && (
              <div>
                <Label>{t('time-left-label')}</Label>
                <TimeLeftContainer>
                  <ClockIcon />

                  <TimeLeft>{timeLeft}</TimeLeft>
                </TimeLeftContainer>
              </div>
            )}

            {status === OrderStatusEnum.WaitingForPayment && (
              <div>
                <Label>{t('confirmations-label')}</Label>
                <ConfirmationsText>
                  {confirmations_have > confirmations_need
                    ? confirmations_need
                    : confirmations_have}
                  / {confirmations_need}
                </ConfirmationsText>
              </div>
            )}
          </BaseInfoRow>

          <QrRow>
            <QrCode src={qrCodeSrc} alt="QR Code" />

            <QrInfo>
              <div>
                <Label>{t('address-label')}</Label>
                <ClipboardCopy text={(deposit_details as CryptoAddresses).uid}>
                  {(deposit_details as CryptoAddresses).uid}
                </ClipboardCopy>
              </div>

              <Amounts>
                {status === OrderStatusEnum.New && (
                  <>
                    <div>
                      <Label>{t('amount-to-pay')}</Label>
                      <ClipboardCopy text={rest_amount}>
                        <Amount
                          value={rest_amount}
                          decimals={precision_in}
                          code={currency_in}
                        />
                      </ClipboardCopy>
                    </div>

                    <div>
                      <Label>{t('amount-paid')}</Label>
                      <ClipboardCopy text={total_amount_in - rest_amount}>
                        <Amount
                          value={total_amount_in - rest_amount}
                          decimals={precision_in}
                          code={currency_in}
                        />
                      </ClipboardCopy>
                    </div>
                  </>
                )}

                <div>
                  <Label>{t('total-amount')}</Label>
                  <ClipboardCopy text={total_amount_in}>
                    <Amount
                      value={total_amount_in}
                      decimals={precision_in}
                      code={currency_in}
                    />
                  </ClipboardCopy>
                </div>
              </Amounts>
            </QrInfo>
          </QrRow>

          {!isStandalone && (
            <NewTabLink
              href={`${RECEIVE_STATUS}/${order.uuid}`}
              target="_blank"
            >
              {t('open-in-tab')} <Share />
            </NewTabLink>
          )}

          <SummaryRowContainer>
            {!!can_refund && (
              // accessToken && // canceled CL-614
              <RequestRefundButton isSmall isSecondary onClick={showRefund}>
                {t('request-refund-label')}
              </RequestRefundButton>
            )}
            <SummaryTabs order={order} />
          </SummaryRowContainer>
        </div>
      )

    case RequestTypeEnum.Interbank:
      return (
        <div>
          <BaseInfoRow>
            <div>
              <Label>{t('main-status-label')}</Label>
              <Status statusColor={statusColor}>
                {t(statusTranslationKey)}
              </Status>
            </div>

            {status === OrderStatusEnum.New && (
              <div>
                <Label>{t('time-left-label')}</Label>
                <TimeLeftContainer>
                  <ClockIcon />

                  <TimeLeft>{timeLeft}</TimeLeft>
                </TimeLeftContainer>
              </div>
            )}
          </BaseInfoRow>

          <QrRow>
            <QrInfo>
              <div>
                <Trans
                  defaults={
                    (deposit_details as InterbankDepositDetails).description
                  }
                  t={t}
                  values={{
                    uid: (deposit_details as InterbankDepositDetails).data.uid,
                    account_number: (deposit_details as InterbankDepositDetails)
                      .data.account_number,
                    bank_name: (deposit_details as InterbankDepositDetails).data
                      .bank_name,
                    brand: (deposit_details as InterbankDepositDetails).data
                      .brand,
                    local_number: (deposit_details as InterbankDepositDetails)
                      .data.local_number,
                    payment_link: (deposit_details as InterbankDepositDetails)
                      .data.payment_link,
                    registration_url: (
                      deposit_details as InterbankDepositDetails
                    ).data.registration_url,
                  }}
                  components={{
                    p: <p />,
                    ol: <ol />,
                    li: <li />,
                    b: <b />,
                    a: <BankLink rel="noopener noreferrer" target="_blank" />,
                  }}
                />
              </div>

              <Amounts>
                {status === OrderStatusEnum.New && (
                  <>
                    <div>
                      <Label>{t('amount-to-pay')}</Label>
                      <ClipboardCopy text={rest_amount}>
                        <Amount
                          value={rest_amount}
                          decimals={precision_in}
                          code={currency_in}
                        />
                      </ClipboardCopy>
                    </div>

                    <div>
                      <Label>{t('amount-paid')}</Label>
                      <ClipboardCopy text={total_amount_in - rest_amount}>
                        <Amount
                          value={total_amount_in - rest_amount}
                          decimals={precision_in}
                          code={currency_in}
                        />
                      </ClipboardCopy>
                    </div>
                  </>
                )}

                <div>
                  <Label>{t('total-amount')}</Label>
                  <ClipboardCopy text={total_amount_in}>
                    <Amount
                      value={total_amount_in}
                      decimals={precision_in}
                      code={currency_in}
                    />
                  </ClipboardCopy>
                </div>
              </Amounts>
            </QrInfo>
          </QrRow>

          {!isStandalone && (
            <NewTabLink
              href={`${RECEIVE_STATUS}/${order.uuid}`}
              target="_blank"
            >
              {t('open-in-tab')} <Share />
            </NewTabLink>
          )}

          <SummaryRowContainer>
            <SummaryTabs order={order} />
          </SummaryRowContainer>
        </div>
      )

    case RequestTypeEnum.Cash:
      return (
        <div>
          <BaseInfoRow>
            <div>
              <Label>{t('main-status-label')}</Label>
              <Status statusColor={statusColor}>
                {t(statusTranslationKey)}
              </Status>
            </div>

            {status === OrderStatusEnum.New && (
              <div>
                <Label>{t('time-left-label')}</Label>
                <TimeLeftContainer>
                  <ClockIcon />

                  <TimeLeft>{timeLeft}</TimeLeft>
                </TimeLeftContainer>
              </div>
            )}
          </BaseInfoRow>

          <QrRow>
            <QrInfo>
              <div>
                <Label>{t('uid')}</Label>
                <ClipboardCopy text={(deposit_details as CashDepositDetails).uid}>
                  {(deposit_details as CashDepositDetails).uid}
                </ClipboardCopy>
              </div>

              <Amounts>
                {status === OrderStatusEnum.New && (
                  <>
                    <div>
                      <Label>{t('amount-to-pay')}</Label>
                      <ClipboardCopy text={rest_amount}>
                        <Amount
                          value={rest_amount}
                          decimals={precision_in}
                          code={currency_in}
                        />
                      </ClipboardCopy>
                    </div>

                    <div>
                      <Label>{t('amount-paid')}</Label>
                      <ClipboardCopy text={total_amount_in - rest_amount}>
                        <Amount
                          value={total_amount_in - rest_amount}
                          decimals={precision_in}
                          code={currency_in}
                        />
                      </ClipboardCopy>
                    </div>
                  </>
                )}

                <div>
                  <Label>{t('total-amount')}</Label>
                  <ClipboardCopy text={total_amount_in}>
                    <Amount
                      value={total_amount_in}
                      decimals={precision_in}
                      code={currency_in}
                    />
                  </ClipboardCopy>
                </div>
              </Amounts>
            </QrInfo>
          </QrRow>

          {!isStandalone && (
            <NewTabLink
              href={`${RECEIVE_STATUS}/${order.uuid}`}
              target="_blank"
            >
              {t('open-in-tab')} <Share />
            </NewTabLink>
          )}

          <SummaryRowContainer>
            <SummaryTabs order={order} />
          </SummaryRowContainer>
        </div>
      )
  }
}
