import styled from 'styled-components'
import {
  COLOR,
  FONT_SIZE,
  FONT_WEIGHT,
  SCREEN,
} from '@fe/common/constants/main'

export const LabeldList = styled.div`
  display: grid;
  grid-template-columns: 35% 1fr;
  grid-gap: 1rem 2rem;
  justify-content: start;
  align-items: start;
  padding: 2rem 0 4rem;
  font-size: ${FONT_SIZE.SMALL};
  font-weight: ${FONT_WEIGHT.SEMIBOLD};

  :not(:last-child) {
    border-bottom: 1px solid ${COLOR.LIGHTER_GREY};
  }

  > :nth-child(odd) {
    color: ${COLOR.GREY};
  }

  span {
    white-space: break-spaces;
  }

  ${SCREEN.ABOVE_MOBILE} {
    grid-template-columns: 20rem 1fr;
  }
`
