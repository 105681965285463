import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Order } from '@fe/common/api/models/Order'
import { FONT_SIZE, FONT_WEIGHT } from '@fe/common/constants/main'
import { useCancelToken } from '@fe/common/hooks/useCancelToken'
import { ReceiveStatusStep } from './ReceiveStatusStep'
import { RefundSuccess } from './RefundSuccessStep'
import { RequestRefund } from './RequestRefundStep'
import { OrderApi } from 'src/api/OrderApi'
import { ReceiveStatusStepEnum } from 'src/types/enums/ReceiveStatusStepEnum'

const Error = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10rem 1rem;
  font-size: ${FONT_SIZE.LARGE};
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
  text-align: center;
`

interface ReceiveStatusProps {
  initialOrder: Order
}

export const ReceiveStatus: React.FC<ReceiveStatusProps> = ({
  initialOrder,
}) => {
  const { t } = useTranslation('receive-status')
  const [order, setOrder] = useState(initialOrder)
  const { query } = useRouter()
  const [step, setStep] = useState(
    Number(query.statusStep) || ReceiveStatusStepEnum.ReceiveStatus
  )

  const cancelToken = useCancelToken()

  useEffect(() => {
    const fetchNewOrder = async () => {
      try {
        const { data } = await OrderApi.getOrder(initialOrder.uuid, {
          cancelToken,
        })

        setOrder(data)
      } catch (error) {
        console.error(error)
      }
    }

    const intervalRef = setInterval(fetchNewOrder, 7000)

    return () => clearInterval(intervalRef)
  }, [])

  const handleSuccess = () => setStep(ReceiveStatusStepEnum.RefundSuccess)

  const handleRefundError = () => setStep(ReceiveStatusStepEnum.RefundError)

  const renderCurrentStep = () => {
    switch (step) {
      case ReceiveStatusStepEnum.ReceiveStatus:
        return (
          <ReceiveStatusStep
            order={order}
            showRefund={() => setStep(ReceiveStatusStepEnum.RequestRefund)}
          />
        )

      case ReceiveStatusStepEnum.RequestRefund:
        return (
          <RequestRefund
            order={order}
            showSuccess={handleSuccess}
            showRefundError={handleRefundError}
          />
        )

      case ReceiveStatusStepEnum.RefundSuccess:
        return <RefundSuccess />

      case ReceiveStatusStepEnum.RefundError:
        return <Error>{t('refund-is-not-allowed')}</Error>
    }
  }

  return renderCurrentStep()
}
