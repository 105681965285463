import { useState } from 'react'
import styled from 'styled-components'
import TooltipIcon from '@fe/common/src/icons/tooltip.svg'
import { Modal } from './Modal'

const Container = styled.div`
  display: inline-flex;
  flex-shrink: 0;
  margin-left: 1rem;
  cursor: pointer;

  :hover {
    svg {
      opacity: 0.6;
    }
  }
`

interface TipModalProps {
  title: string
  className?: string
}

export const TipModal: React.FC<TipModalProps> = ({
  title,
  className,
  children,
}) => {
  const [isModalShown, setIsModalShown] = useState(false)

  const handleIconClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()

    setIsModalShown(true)
  }

  return (
    <>
      <Container onClick={handleIconClick} className={className}>
        <TooltipIcon />
      </Container>

      {isModalShown && (
        <Modal
          title={title}
          onClose={() => setIsModalShown(false)}
          isWithoutHeaderSeparator
          maxWidth="60rem"
        >
          {children}
        </Modal>
      )}
    </>
  )
}
