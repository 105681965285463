export enum CompanyDocTypeEnum {
  CertificateOfIncorporation = 1,
  CertificateOfIncumbency,
  PowerOfAttorney,
  MemorandumOfAssociation,
  MinutesOfTheFirstMeeting,
  ShareCertificate,
  CommercialRegisterCertificate,
  AppointmentOfFirstDirector,
  RegisterOfDirectors,
  RegisterOfMembers,
  RegisterOfShareholders,
  CorporateApplication,
  BankStatement,
  Deleted,
  Other,
  CertificateOfGoodStanding,
  CertificateOfCollation,
  ChangeOfDirector,
  ChangeOfShareholder,
  AmlPolicy,
  Licence,
  LegalOpinion,
  ChangeOfCompanyName,
  TaxDeclaration,
  AuditReport,
  OfficeRegistrationCertificate,
  RegisterOfUBOs,
}
