export const DASHBOARD = '/main'
export const SIGNUP = '/signup'
export const LOGIN = '/login'
export const CONFIRM_EMAIL = '/confirm-email'
export const RESET_PASSWORD = '/reset-password'
export const FORGOT_PASSWORD = '/forgot-password'
export const MFA_RECOVERY = '/mfa-recovery'
export const VERIFICATION = '/verification/1'
export const CORPORATE_VERIFICATION = '/verification/corporate/1'
export const INTERNAL_CORPORATE_VERIFICATION =
  '/verification/corporate/[[...step]]'
export const TRANSACTIONS = '/transactions/[[...tab]]'
export const HISTORY = '/transactions/history'
export const STATEMENTS = '/transactions/statements'
export const CARD_ORDERS = '/transactions/card-orders'
export const RECEIVE_STATUS = '/receive-status'
export const PROFILE = '/profile/[[...tab]]'
export const PROFILE_INFO = '/profile/info'
export const SETTINGS = '/profile/settings'
export const DOCUMENTS = '/profile/documents'
export const FEES = '/profile/fees'
export const CARD_ORDER_MESSAGE = '/card-order-message'

export const UNAUTH_PAGES = [
  SIGNUP,
  LOGIN,
  CONFIRM_EMAIL,
  RECEIVE_STATUS,
  RESET_PASSWORD,
  FORGOT_PASSWORD,
  MFA_RECOVERY,
]
