import Axios, { AxiosPromise } from 'axios'
import { StorageAddress } from '@fe/common/api/models/StorageAddress'
import { RequestConfig } from '@fe/common/axiosConfig'

export class StorageApi {
  static uploadFile(
    file: FormData,
    config?: RequestConfig
  ): AxiosPromise<Array<StorageAddress>> {
    return Axios({
      ...config,
      url: `/storage/upload`,
      method: 'POST',
      data: file,
      headers: {
        'Content-Type': 'multipart/form-data; boundary=${form._boundary}',
      },
    })
  }

  static downloadFile(url: string, config?: RequestConfig): AxiosPromise<Blob> {
    return Axios({
      ...config,
      url,
      method: 'GET',
      responseType: 'blob',
    })
  }
}
