import { RequestTypeEnum } from '@fe/common/api/models/enums/RequestTypeEnum'
import { DropdownOptions } from '@fe/common/components/inputs/CustomSelect'
import { FieldConfig, FieldTypesEnum } from 'src/types/FieldConfig'
import { UnionToIntersection } from 'src/types/UnionToIntersection'
import { SendStepsEnum } from 'src/types/enums/SendStepsEnum'

export type WireFields = {
  bank_account_number: string
  bank_code: string
  payment_details: string
  beneficiary_name: string
  country_id: number
  city: string
  address_line: string
  zip: string
  bank_name: string
  bank_country_id: number
  bank_city: string
  bank_address: string
  bank_zip: string
}

export type SepaFields = {
  bank_account_number: string
  bank_code: string
  payment_details: string
  beneficiary_name: string
  country_id: number
  city: string
  address_line: string
  zip: string
}

export type CzkFields = {
  bank_account_number: string
  bank_code: string
  payment_details: string
  beneficiary_name: string
  variable_symbol?: string
  constant_symbol?: string
  specific_symbol?: string
}

export type InternalFields = {
  bank_account_number: string
  payment_details: string
}

export type CryptoFields = {
  address: string
  payment_details: string
}

export type InterbankFields = {
  bank_id: number
  bank_account_number: string
  beneficiary_name: string
}

export type MtCashFields = {
  first_name: string
  last_name: string
  beneficiary_phone: string
  mt_payment_purpose: string
  mt_country_id: number
  mt_payment_system_id: number
  mt_city_id: number
  mt_branch_id: number
}

export type MtCardFields = {
  first_name: string
  last_name: string
  beneficiary_phone: string
  mt_payment_purpose: string
  card_number: string
  mt_country_id: number
}

export interface SendData {
  settings_id: number
  request_type: SendableRequestTypes
  amount_in: number
  amount_out?: number
  payment_date?: Date
  process_manager_id?: boolean
  requestTypeFields:
    | WireFields
    | SepaFields
    | CzkFields
    | InternalFields
    | CryptoFields
    | InterbankFields
    | MtCashFields
    | MtCardFields
}

export type SendableRequestTypes = Exclude<
  RequestTypeEnum,
  | RequestTypeEnum.Exchange
  | RequestTypeEnum.Fee
  | RequestTypeEnum.Cash
  | RequestTypeEnum.ReferralBonus
  | RequestTypeEnum.Refund
>

export type SendFieldConfig = FieldConfig<
  UnionToIntersection<SendData['requestTypeFields']>
>

export const getStepsConfig = (
  countryOptions?: DropdownOptions,
  bankOptions?: DropdownOptions,
  cardsCountries?: DropdownOptions,
  cashCountries?: DropdownOptions,
  cashSystems?: DropdownOptions,
  cashCities?: DropdownOptions,
  cashBranches?: DropdownOptions
): Record<SendableRequestTypes, Record<number, Array<SendFieldConfig>>> => ({
  [RequestTypeEnum.WireTransfer]: {
    [SendStepsEnum.General]: [
      {
        type: FieldTypesEnum.Text,
        name: 'bank_account_number',
        label: 'account-number',
        isOptional: false,
      },
      {
        type: FieldTypesEnum.Text,
        name: 'bank_code',
        label: 'swift',
        isOptional: false,
      },
      {
        type: FieldTypesEnum.Text,
        name: 'payment_details',
        label: 'payment-details',
        isOptional: false,
        tip: 'info-for-beneficiary',
      },
    ],
    [SendStepsEnum.Beneficiary]: [
      {
        type: FieldTypesEnum.Text,
        name: 'beneficiary_name',
        label: 'common:name',
        isOptional: false,
      },
      {
        type: FieldTypesEnum.Dropdown,
        name: 'country_id',
        label: 'country',
        options: countryOptions,
        isOptional: false,
      },
      {
        type: FieldTypesEnum.Text,
        name: 'city',
        label: 'city',
        isOptional: false,
      },
      {
        type: FieldTypesEnum.Text,
        name: 'address_line',
        label: 'address',
        isOptional: false,
      },
      {
        type: FieldTypesEnum.Text,
        name: 'zip',
        label: 'zip',
        isOptional: false,
      },
    ],
    [SendStepsEnum.Bank]: [
      {
        type: FieldTypesEnum.Text,
        name: 'bank_name',
        label: 'common:name',
        isOptional: false,
      },
      {
        type: FieldTypesEnum.Dropdown,
        name: 'bank_country_id',
        label: 'country',
        options: countryOptions,
        isOptional: false,
      },
      {
        type: FieldTypesEnum.Text,
        name: 'bank_city',
        label: 'city',
        isOptional: false,
      },
      {
        type: FieldTypesEnum.Text,
        name: 'bank_address',
        label: 'address',
        isOptional: false,
      },
      {
        type: FieldTypesEnum.Text,
        name: 'bank_zip',
        label: 'zip',
        isOptional: false,
      },
    ],
  },
  [RequestTypeEnum.Sepa]: {
    [SendStepsEnum.General]: [
      {
        type: FieldTypesEnum.Text,
        name: 'bank_account_number',
        label: 'IBAN',
        isOptional: false,
      },
      {
        type: FieldTypesEnum.Text,
        name: 'bank_code',
        label: 'swift',
        isOptional: false,
      },
      {
        type: FieldTypesEnum.Text,
        name: 'payment_details',
        label: 'payment-details',
        isOptional: false,
        tip: 'info-for-beneficiary',
      },
    ],
    [SendStepsEnum.Beneficiary]: [
      {
        type: FieldTypesEnum.Text,
        name: 'beneficiary_name',
        label: 'common:name',
        isOptional: false,
      },
      {
        type: FieldTypesEnum.Dropdown,
        name: 'country_id',
        label: 'country',
        options: countryOptions,
        isOptional: false,
      },
      {
        type: FieldTypesEnum.Text,
        name: 'city',
        label: 'city',
        isOptional: false,
      },
      {
        type: FieldTypesEnum.Text,
        name: 'address_line',
        label: 'address',
        isOptional: false,
      },
      {
        type: FieldTypesEnum.Text,
        name: 'zip',
        label: 'zip',
        isOptional: false,
      },
    ],
  },
  [RequestTypeEnum.CzkPayments]: {
    [SendStepsEnum.General]: [
      {
        type: FieldTypesEnum.Text,
        name: 'bank_account_number',
        label: 'account-number',
        isOptional: false,
      },
      {
        type: FieldTypesEnum.Text,
        name: 'bank_code',
        label: 'bank-code',
        isOptional: false,
      },
      {
        type: FieldTypesEnum.Text,
        name: 'beneficiary_name',
        label: 'beneficiary-name',
        isOptional: false,
      },
      {
        type: FieldTypesEnum.Number,
        name: 'variable_symbol',
        label: 'variable-symbol',
        isOptional: true,
      },
      {
        type: FieldTypesEnum.Number,
        name: 'constant_symbol',
        label: 'constant-symbol',
        isOptional: true,
      },
      {
        type: FieldTypesEnum.Number,
        name: 'specific_symbol',
        label: 'specific-symbol',
        isOptional: true,
      },
      {
        type: FieldTypesEnum.Text,
        name: 'payment_details',
        label: 'payment-details',
        isOptional: false,
        tip: 'info-for-beneficiary',
      },
    ],
  },
  [RequestTypeEnum.Internal]: {
    [SendStepsEnum.General]: [
      {
        type: FieldTypesEnum.Text,
        name: 'bank_account_number',
        label: 'account-number',
        isOptional: false,
      },
      {
        type: FieldTypesEnum.Text,
        name: 'payment_details',
        label: 'payment-details',
        isOptional: false,
        tip: 'info-for-beneficiary',
      },
    ],
  },
  [RequestTypeEnum.Crypto]: {
    [SendStepsEnum.General]: [
      {
        type: FieldTypesEnum.Text,
        name: 'address',
        label: 'address',
        isOptional: false,
      },
      {
        type: FieldTypesEnum.Text,
        name: 'payment_details',
        label: 'payment-details',
        isOptional: true,
        tip: 'info-not-for-beneficiary',
      },
    ],
  },
  [RequestTypeEnum.Interbank]: {
    [SendStepsEnum.General]: [
      {
        type: FieldTypesEnum.Dropdown,
        name: 'bank_id',
        label: 'bank-name',
        options: bankOptions,
        isOptional: false,
      },
      {
        type: FieldTypesEnum.Text,
        name: 'bank_account_number',
        label: 'account-number',
        isOptional: false,
      },
      {
        type: FieldTypesEnum.Text,
        name: 'beneficiary_name',
        label: 'beneficiary-name',
        isOptional: false,
      },
    ],
  },
  [RequestTypeEnum.MtCash]: {
    [SendStepsEnum.General]: [
      {
        type: FieldTypesEnum.Text,
        name: 'first_name',
        label: 'beneficiary-first-name',
        isOptional: false,
      },
      {
        type: FieldTypesEnum.Text,
        name: 'last_name',
        label: 'beneficiary-last-name',
        isOptional: false,
      },
      {
        type: FieldTypesEnum.Text,
        name: 'beneficiary_phone',
        label: 'beneficiary-phone-label',
        isOptional: false,
      },
      {
        type: FieldTypesEnum.Dropdown,
        name: 'mt_country_id',
        label: 'country',
        options: cashCountries,
        isOptional: false,
      },
      {
        type: FieldTypesEnum.Dropdown,
        name: 'mt_payment_system_id',
        label: 'payment-system',
        options: cashSystems,
        isOptional: false,
      },
      {
        type: FieldTypesEnum.Dropdown,
        name: 'mt_city_id',
        label: 'city',
        options: cashCities,
        isOptional: false,
      },

      {
        type: FieldTypesEnum.Dropdown,
        name: 'mt_branch_id',
        label: 'branch-name',
        options: cashBranches,
        isOptional: false,
      },
      {
        type: FieldTypesEnum.Text,
        name: 'mt_payment_purpose',
        label: 'payment-purpose',
        isOptional: false,
      },
    ],
  },
  [RequestTypeEnum.MtCards]: {
    [SendStepsEnum.General]: [
      {
        type: FieldTypesEnum.Text,
        name: 'first_name',
        label: 'beneficiary-first-name',
        isOptional: false,
      },
      {
        type: FieldTypesEnum.Text,
        name: 'last_name',
        label: 'beneficiary-last-name',
        isOptional: false,
      },
      {
        type: FieldTypesEnum.Text,
        name: 'beneficiary_phone',
        label: 'beneficiary-phone-label',
        isOptional: false,
      },
      {
        type: FieldTypesEnum.Text,
        name: 'card_number',
        label: 'card-number',
        isOptional: false,
      },
      {
        type: FieldTypesEnum.Dropdown,
        name: 'mt_country_id',
        label: 'country',
        options: cardsCountries,
        isOptional: false,
      },
      {
        type: FieldTypesEnum.Text,
        name: 'mt_payment_purpose',
        label: 'payment-purpose',
        isOptional: false,
      },
    ],
  },
})
