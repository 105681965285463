import { useTranslation } from 'next-i18next'
import { forwardRef } from 'react'
import {
  FieldValues,
  FormState,
  useFormContext,
  UseFormMethods,
} from 'react-hook-form'
import styled from 'styled-components'
import { Spinner } from '@fe/common/components/Spinner'
import { COLOR, FONT_WEIGHT, SCREEN } from '@fe/common/constants/main'
import { Errors } from '@fe/common/types/Errors'
import { getErrorStatus, getLockedStatus } from '@fe/common/utils/formUtils'
import { DropdownOptions } from './CustomSelect'
import { InputError } from './InputError'
import {
  InputContainer,
  baseInputStyle,
  disabledInputStyle,
} from './baseInputStyles'

const StyledInputContainer = styled(InputContainer)`
  width: 100%;
  max-width: unset;
  min-width: unset;

  ${SCREEN.ABOVE_MOBILE} {
    width: fit-content;
  }
`

const RadioContainer = styled.div<{ hasError: boolean; isDisabled: boolean }>`
  ${baseInputStyle}
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
  background: ${COLOR.WHITE};

  ${({ isDisabled }) => isDisabled && disabledInputStyle};
`

const Radio = styled.div`
  position: relative;
  padding: 0 1rem;

  :not(:last-child) {
    margin-right: 1rem;
  }
`

const InvisibleInput = styled.input`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`

const SpinnerIcon = styled(Spinner)`
  margin: 2.3rem auto 0;
`

const Value = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
  height: 100%;
  color: ${COLOR.GREY};
  font-weight: ${FONT_WEIGHT.SEMIBOLD};

  ::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 0.5rem;
    border-radius: 2px 2px 0 0;
    background: ${({ theme }) => theme.primary};
    opacity: 0;
  }

  ${InvisibleInput}:hover ~ &, ${InvisibleInput}:focus ~ & {
    ::after {
      opacity: 0.5;
    }
  }

  ${InvisibleInput}:checked ~ & {
    color: ${COLOR.OFF_BLACK};

    ::after {
      opacity: 1;
    }
  }
`

interface RadioPickerProps {
  name: string
  options: DropdownOptions
  defaultOption?: string | number
  onClick?: (value: string | number) => void
  isLoading?: boolean
  isDisabled?: boolean
  errors?: Errors
  formState?: FormState<FieldValues>
  className?: string
}

export const RadioPicker = forwardRef<HTMLInputElement, RadioPickerProps>(
  (
    {
      name,
      options,
      defaultOption,
      onClick,
      isLoading,
      isDisabled,
      className,
      ...rest
    },
    ref
  ) => {
    const { t } = useTranslation()

    const formContext = useFormContext()

    const handleOptionClick = (value: string | number) => {
      onClick?.(value)
    }

    const errors = rest.errors || formContext?.errors
    const formState = rest.formState || formContext?.formState
    const register = ref || formContext?.register

    const { error, isErrorDisplayed } = getErrorStatus(name, errors, formState)

    const isLocked = getLockedStatus(formContext, name)

    return (
      <StyledInputContainer
        withError={!!errors}
        isLoading={isLoading}
        className={className}
      >
        <RadioContainer
          hasError={isErrorDisplayed}
          isDisabled={isLocked || isDisabled}
        >
          {options?.length === 0 && <SpinnerIcon />}

          {options?.map(({ value, label }) => (
            <Radio
              key={label as string}
              onClick={() => handleOptionClick(value)}
            >
              <InvisibleInput
                name={name}
                value={value}
                defaultChecked={value == defaultOption}
                type="radio"
                onClick={() => onClick?.(name)}
                data-test-id={`${name}_${value}`}
                ref={
                  ref
                    ? register
                    : register && (register as UseFormMethods['register'])()
                }
              />

              <Value>{t(label as string)}</Value>
            </Radio>
          ))}
        </RadioContainer>

        {isErrorDisplayed && <InputError name={name} error={error} />}
      </StyledInputContainer>
    )
  }
)
