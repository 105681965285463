export enum RequestTypeEnum {
  WireTransfer = 1,
  Sepa = 2,
  Internal = 3,
  Crypto = 4,
  Exchange = 7,
  CzkPayments = 8,
  Refund = 9,
  MtCards = 10,
  MtCash = 11,
  Interbank = 12,
  Fee = 13,
  ReferralBonus = 14,
  Cash = 15,
}
