import { CancelToken } from 'axios'
import { useTranslation } from 'next-i18next'
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { useFormContext, UseFormMethods } from 'react-hook-form'
import { useSelector } from 'react-redux'
import styled, { keyframes } from 'styled-components'
import { debounce } from 'throttle-debounce'
import { Fee } from '@fe/common/api/models/Fee'
import { MethodCurrency } from '@fe/common/api/models/MethodCurrency'
import { WithdrawCurrency } from '@fe/common/api/models/WithdrawCurrency'
import { OfferTypeEnum } from '@fe/common/api/models/enums/OfferTypeEnum'
import { RequestTypeEnum } from '@fe/common/api/models/enums/RequestTypeEnum'
import { TransactionTypeEnum } from '@fe/common/api/models/enums/TransactionTypeEnum'
import { Amount } from '@fe/common/components/Amount'
import { Button } from '@fe/common/components/Button'
import { LoadingDots } from '@fe/common/components/LoadingDots'
import { CurrencyDropdownInput } from '@fe/common/components/inputs/CurrencyDropdownInput'
import { FormattedInput } from '@fe/common/components/inputs/FormattedInput'
import { opacityReveal } from '@fe/common/constants/animations'
import {
  BORDER,
  COLOR,
  FONT_SIZE,
  FONT_WEIGHT,
  SCREEN,
} from '@fe/common/constants/main'
import { getCurrencyOptions } from '@fe/common/constants/options'
import { useCancelToken } from '@fe/common/hooks/useCancelToken'
import { useDelayedValue } from '@fe/common/hooks/useDelayedValue'
import { useDidUpdate } from '@fe/common/hooks/useDidUpdate'
import Arrow from '@fe/common/icons/arrow.svg'
import { Tip } from '@fe/common/src/components/Tip'
import { isDefined } from '@fe/common/utils/numberUtils'
import { ExchangeOffer } from '../ExchangeOffer'
import { FeeInfo } from '../FeeInfo'
import { ReceiveContext } from '../ReceiveModal/receiveSchema'
import {
  MtCashFields,
  SendData,
} from '../SendModal/RequestTypeSteps/stepsConfig'
import { WithdrawContext } from '../SendModal/sendSchema'
import { OfferMethods } from '../useOffer'
import { DeferredOptions } from './DeferredOptions'
import { DepositsApi } from 'src/api/DepositsApi'
import { WithdrawApi } from 'src/api/WithdrawApi'
import { currencyDetailsSelector } from 'src/state/selectors/globalDataSelectors'
import { AccountCurrencyWithDetails } from 'src/types/AccountCurrencyWithDetails'

const SendAsToggle = styled(Button)<{ isShown: boolean }>`
  margin-left: auto;
  height: ${({ isShown }) => (isShown ? '3.5rem' : 0)};
  overflow: hidden;
  transition: height 0.2s ease-in-out;
`

const Container = styled.div<{ isExpanded: boolean }>`
  position: relative;
  height: ${({ isExpanded }) => (isExpanded ? '34.5rem' : '18.5rem')};
  margin: 2rem -2rem;
  background: ${COLOR.OFF_WHITE};
  border-radius: 3px;
  transition: height 0.3s cubic-bezier(0.49, 0.57, 0.33, 1.34);
  overflow: hidden;

  ${SCREEN.ABOVE_MOBILE} {
    height: ${({ isExpanded }) => (isExpanded ? '24rem' : '12rem')};
    margin: 2rem 0;
  }
`

const AmountRow = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: start;
  width: 100%;
  height: 18.5rem;
  padding: 2rem 2.5rem;
  will-change: opacity;
  z-index: 1;

  > :first-child {
    width: 15.5rem;
    margin-bottom: 1.5rem;
    padding-right: 1rem;
    font-weight: ${FONT_WEIGHT.SEMIBOLD};
    white-space: nowrap;
  }

  :last-child {
    top: 18.5rem;
    height: 16rem;
    animation: ${opacityReveal} 0.2s ease-in-out backwards;
  }

  ${SCREEN.ABOVE_MOBILE} {
    flex-direction: row;
    align-items: center;
    height: 12rem;
    padding: 0 4rem;

    > :first-child {
      margin-bottom: 0;
      white-space: unset;
    }

    > :nth-child(2) {
      top: 1.3rem;
      width: 24rem;
      margin-right: 2rem;
    }

    :last-child {
      top: 12rem;
      height: 12rem;
    }
  }
`

const AmountInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  font-size: ${FONT_SIZE.SMALL};
  font-weight: ${FONT_WEIGHT.SEMIBOLD};

  > span {
    display: flex;

    > :first-child {
      color: ${COLOR.GREY};
    }
  }

  ${SCREEN.ABOVE_MOBILE} {
    display: block;
    width: auto;
  }
`

const MaxAmount = styled.span<{ isShown: boolean }>`
  align-items: center;
  height: ${({ isShown }) => (isShown ? '2rem' : 0)};
  overflow: hidden;
  white-space: nowrap;
  transition: height 0.15s ease-in-out;

  > :first-child {
    margin-right: 0.4rem;
  }
`

const Separator = styled.div`
  position: absolute;
  top: 18.5rem;
  left: 0;
  width: calc(100% - 8rem);
  margin: 0 4rem;
  border-bottom: ${BORDER.DEFAULT};
  z-index: 0;

  ${SCREEN.ABOVE_MOBILE} {
    top: 12rem;
  }
`

const ArrowContianer = styled.div`
  position: absolute;
  top: -2.5rem;
  width: 100%;
  height: 5rem;
  overflow: hidden;
`

const arrowReveal = (isWithdraw: boolean) => keyframes`
  from {
    transform: ${
      isWithdraw ? 'rotate(90deg)' : 'rotate(-90deg)'
    } translateX(-18px);
    color: ${COLOR.OFF_WHITE};
  }

  to {
    transform: ${isWithdraw ? 'rotate(90deg)' : 'rotate(-90deg)'} translateX(0);
    color: ${COLOR.GREY};
  }
`

const ArrowIcon = styled(Arrow).withConfig({
  shouldForwardProp: prop => !['isWithdraw'].includes(prop as string),
})<{ isWithdraw: boolean }>`
  position: absolute;
  left: calc(50% - 2rem);
  width: 4rem;
  height: 5rem;
  padding: 1.3rem;
  background: ${COLOR.OFF_WHITE};
  border-radius: 100%;
  color: ${COLOR.GREY};
  transform: ${({ isWithdraw }) =>
    isWithdraw ? 'rotate(90deg)' : 'rotate(-90deg)'};
  animation: ${({ isWithdraw }) => arrowReveal(isWithdraw)} 0.7s ease-in-out
    backwards;
`

const StyledExchangeOffer = styled(ExchangeOffer)`
  margin-bottom: 2rem;
`

export const getWithdrawFee = async (
  getValues: UseFormMethods<SendData>['getValues'],
  setIsFeeLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setFee: React.Dispatch<React.SetStateAction<Fee>>,
  cancelToken: CancelToken
) => {
  const { amount_in, request_type, settings_id } = getValues([
    'amount_in',
    'request_type',
    'settings_id',
  ])

  if (!amount_in) {
    return
  }

  try {
    setIsFeeLoading(true)

    let newFee: Fee

    if (request_type === RequestTypeEnum.MtCash) {
      const { mt_country_id, mt_payment_system_id } = getValues(
        'requestTypeFields'
      ) as MtCashFields

      if (!mt_country_id || !mt_payment_system_id) {
        return
      }

      const { data } = await WithdrawApi.getMtFee(
        {
          amount: amount_in,
          country_id: mt_country_id,
          payment_system_id: mt_payment_system_id,
          settings_id,
        },
        {
          cancelToken,
        }
      )

      newFee = data
    } else if (request_type === RequestTypeEnum.MtCards) {
      const { data } = await WithdrawApi.getCardFee(
        {
          amount: amount_in,
          settings_id,
        },
        {
          cancelToken,
        }
      )

      newFee = data
    } else {
      const { data } = await WithdrawApi.getFee(
        {
          amount: amount_in,
          request_type,
          settings_id,
        },
        {
          cancelToken,
        }
      )

      newFee = data
    }

    setFee(newFee)
  } catch (error) {
    console.error(error)
  } finally {
    setIsFeeLoading(false)
  }
}

const getDepositFee = async (
  getValues: UseFormMethods<SendData>['getValues'],
  setIsFeeLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setFee: React.Dispatch<React.SetStateAction<Fee>>,
  cancelToken: CancelToken
) => {
  const { amount_in, request_type, settings_id } = getValues([
    'amount_in',
    'request_type',
    'settings_id',
  ])

  if (!amount_in) {
    return
  }

  try {
    setIsFeeLoading(true)

    const { data } = await DepositsApi.getFee(
      {
        amount: amount_in,
        request_type,
        settings_id,
      },
      { cancelToken }
    )

    setFee(data)
  } catch (error) {
    console.error(error)
  } finally {
    setIsFeeLoading(false)
  }
}

const getFee = async (
  type: TransactionTypeEnum,
  getValues: UseFormMethods<SendData>['getValues'],
  setIsFeeLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setFee: React.Dispatch<React.SetStateAction<Fee>>,
  cancelToken: CancelToken
) =>
  type === TransactionTypeEnum.Deposit
    ? await getDepositFee(getValues, setIsFeeLoading, setFee, cancelToken)
    : await getWithdrawFee(getValues, setIsFeeLoading, setFee, cancelToken)

const getCurrencySettings = (
  currencyDetails: AccountCurrencyWithDetails,
  currentMethodCurrencies: Array<MethodCurrency | WithdrawCurrency> | undefined,
  currentCurrencyId: number,
  targetCurrencyId: number
): {
  currentCurrencySettings: MethodCurrency | WithdrawCurrency
  targetCurrencySettings: MethodCurrency | WithdrawCurrency
} => {
  const targetCurrencySettings =
    currentMethodCurrencies?.find(({ id }) => id === targetCurrencyId) ||
    currentMethodCurrencies?.[0]

  const currentCurrencySettings = currentMethodCurrencies?.find(
    ({ id }) => id === currentCurrencyId
  ) || {
    ...currencyDetails,
    precision_in: currencyDetails?.precision,
    precision_out: currencyDetails?.precision,
    max_amount_in: (targetCurrencySettings as WithdrawCurrency)?.max_amount_in,
  }

  return { currentCurrencySettings, targetCurrencySettings }
}

const getSendAsOptions = (
  currentMethodCurrencies: Array<MethodCurrency | WithdrawCurrency> | undefined,
  currencyInId: number
) => {
  const hasSameCurrency = !!currentMethodCurrencies?.find(
    ({ id }) => id === currencyInId
  )
  const hasOtherCurrency = !!currentMethodCurrencies?.find(
    ({ id }) => id !== currencyInId
  )
  const isSendAsOptional = hasSameCurrency && hasOtherCurrency
  const isSendAsMandatory = !hasSameCurrency && hasOtherCurrency

  return { isSendAsOptional, isSendAsMandatory }
}

const getInitialTargetId = (
  currentMethodCurrencies: Array<MethodCurrency | WithdrawCurrency>,
  settingsId: number,
  isSendAsMandatory: boolean
) => {
  if (isSendAsMandatory) {
    return currentMethodCurrencies[0].id
  }

  return currentMethodCurrencies?.filter(({ id }) => id === settingsId)?.[0]?.id
}

const getDefaultTargetId = (
  currentMethodCurrencies: Array<MethodCurrency | WithdrawCurrency>,
  settingsId: number
) => currentMethodCurrencies?.filter(({ id }) => id !== settingsId)?.[0]?.id

interface AmountSectionProps {
  transactionType: TransactionTypeEnum
  offerMethods: OfferMethods
  setTargetSettingsId: (id: number) => void
  setContext?: React.Dispatch<
    React.SetStateAction<ReceiveContext | WithdrawContext>
  >
}

export const AmountSection: React.FC<AmountSectionProps> = ({
  transactionType,
  offerMethods,
  setTargetSettingsId,
  setContext,
}) => {
  const { t } = useTranslation('transactions')
  const currencyDetails = useSelector(currencyDetailsSelector)

  const isWithdraw = transactionType === TransactionTypeEnum.Withdraw
  const {
    offer,
    resetOffer,
    getOffer: getOfferFn,
    isOfferLoading,
    previousRequest,
  } = offerMethods

  const { watch, setValue, getValues, clearErrors, trigger } =
    useFormContext<SendData>()

  const { settings_id, request_type } = watch(['settings_id', 'request_type'])
  const payment_date = watch('payment_date')
  const process_manager_id = watch('process_manager_id')
  const watchResults = watch([
    'requestTypeFields.mt_country_id',
    'requestTypeFields.mt_payment_system_id',
  ])
  const mt_country_id = (watchResults as any)['requestTypeFields.mt_country_id']
  const mt_payment_system_id = (watchResults as any)[
    'requestTypeFields.mt_payment_system_id'
  ]

  const defaultFee: Fee = offer && {
    amount: null,
    total_amount: null,
    fee: offer?.fee_in,
    precision: offer?.precision_in,
  }

  const [fee, setFee] = useState<Fee>(defaultFee)
  const [isFeeLoading, setIsFeeLoading] = useState(false)
  const cancelToken = useCancelToken()

  const selectedCurrency = useMemo<AccountCurrencyWithDetails>(
    () => currencyDetails?.find(({ id }) => id === settings_id),
    [currencyDetails, settings_id]
  )

  const currentMethodCurrencies = useMemo<
    Array<MethodCurrency | WithdrawCurrency>
  >(() => {
    const methodsToUse = isWithdraw ? 'withdraw_methods' : 'deposit_methods'

    return selectedCurrency?.[methodsToUse].find(
      ({ id }) => id === request_type
    )?.currencies
  }, [selectedCurrency, request_type])

  const { isSendAsOptional, isSendAsMandatory } = useMemo(
    () => getSendAsOptions(currentMethodCurrencies, settings_id),
    [currentMethodCurrencies, settings_id, request_type]
  )

  const defaultSendAs = !!getValues('amount_out') || isSendAsMandatory

  const [isSendAs, setIsSendAs] = useState(defaultSendAs)
  const delayedIsSendAs = useDelayedValue(isSendAs)
  const [targetCurrencyId, setTargetCurrencyId] = useState(
    getInitialTargetId(currentMethodCurrencies, settings_id, isSendAsMandatory)
  )

  const { currentCurrencySettings, targetCurrencySettings } = useMemo<
    ReturnType<typeof getCurrencySettings>
  >(
    () =>
      getCurrencySettings(
        selectedCurrency,
        currentMethodCurrencies,
        settings_id,
        targetCurrencyId
      ),
    [
      selectedCurrency,
      currentMethodCurrencies,
      settings_id,
      targetCurrencyId,
      request_type,
    ]
  )

  const getOffer = useCallback(
    async (type: OfferTypeEnum) => {
      const { amount_in, amount_out } = getValues(['amount_in', 'amount_out'])

      const amount = type === OfferTypeEnum.In ? amount_in : amount_out
      const formattedType =
        type === OfferTypeEnum.In
          ? isWithdraw
            ? OfferTypeEnum.In
            : OfferTypeEnum.Out
          : isWithdraw
          ? OfferTypeEnum.Out
          : OfferTypeEnum.In

      if (isWithdraw) {
        await getOfferFn(amount, formattedType, settings_id, targetCurrencyId)
      } else {
        await getOfferFn(amount, formattedType, targetCurrencyId, settings_id)
      }
    },
    [isWithdraw, getOfferFn, settings_id, targetCurrencyId]
  )

  useDidUpdate(() => {
    if (isSendAsMandatory) {
      setIsSendAs(true)
    } else if (isSendAsOptional || (!isSendAsMandatory && !isSendAsOptional)) {
      setIsSendAs(false)
    }
  }, [isSendAsMandatory, isSendAsOptional])

  useDidUpdate(() => {
    setFee(null)
  }, [offer])

  useDidUpdate(() => {
    setContext?.(current => ({
      ...current,
      isStandingOrder: !!payment_date || process_manager_id,
    }))

    if (process_manager_id || !!payment_date) {
      clearErrors('amount_in')
    } else {
      trigger('amount_in')
    }
  }, [payment_date, process_manager_id])

  useDidUpdate(
    () =>
      setContext?.(current => ({
        ...current,
        isStandingOrder: !!payment_date || process_manager_id,
      })),
    [payment_date, process_manager_id]
  )

  useDidUpdate(() => {
    if (!isSendAs) {
      getFee(transactionType, getValues, setIsFeeLoading, setFee, cancelToken)
    }
  }, [mt_country_id, mt_payment_system_id])

  useEffect(() => {
    if (!offer) {
      getFee(transactionType, getValues, setIsFeeLoading, setFee, cancelToken)
    }

    const { min_amount_in, min_amount_out, max_amount_in, max_amount_out } =
      currentCurrencySettings

    if (isWithdraw) {
      setContext?.(current => ({
        ...current,
        min_amount_in,
        min_amount_out,
        max_amount_in,
        max_amount_out,
      }))
    } else {
      setContext?.(current => ({
        ...current,
        min_amount_in: min_amount_out,
        min_amount_out: min_amount_in,
        max_amount_in: max_amount_out,
        max_amount_out: max_amount_in,
      }))
    }
  }, [request_type])

  useLayoutEffect(() => {
    setTargetSettingsId(targetCurrencyId || settings_id)
  }, [settings_id, targetCurrencyId])

  useDidUpdate(async () => {
    if (isSendAs) {
      setTargetCurrencyId(
        getDefaultTargetId(currentMethodCurrencies, settings_id)
      )
    } else {
      resetOffer()
      setTargetCurrencyId(settings_id)
      getFee(transactionType, getValues, setIsFeeLoading, setFee, cancelToken)
    }
  }, [isSendAs])

  useDidUpdate(async () => {
    const amount = isWithdraw ? getValues('amount_in') : getValues('amount_in')

    const type = isWithdraw
      ? previousRequest?.type === OfferTypeEnum.Out
        ? OfferTypeEnum.Out
        : OfferTypeEnum.In
      : previousRequest?.type === OfferTypeEnum.Out
      ? OfferTypeEnum.In
      : OfferTypeEnum.Out

    if (isSendAs && amount && targetCurrencyId !== settings_id) {
      setIsFeeLoading(true)

      await getOffer(type)

      setIsFeeLoading(false)
    }
  }, [isSendAs, targetCurrencyId])

  useDidUpdate(() => {
    setFee(null)
    resetOffer()
    setTargetCurrencyId(
      getInitialTargetId(
        currentMethodCurrencies,
        settings_id,
        isSendAsMandatory
      )
    )
  }, [settings_id, request_type])

  const handleAmountInChange = useCallback(
    debounce(500, async () => {
      try {
        if (isSendAs) {
          setIsFeeLoading(true)

          await getOffer(OfferTypeEnum.In)
        } else {
          await getFee(
            transactionType,
            getValues,
            setIsFeeLoading,
            setFee,
            cancelToken
          )
        }
      } catch (error) {
        console.error(error)
      } finally {
        setIsFeeLoading(false)
      }
    }),
    [isSendAs, getOffer]
  )

  const handleAmountOutChange = useCallback(
    debounce(500, async () => {
      try {
        setIsFeeLoading(true)

        await getOffer(OfferTypeEnum.Out)
      } finally {
        setIsFeeLoading(false)
      }
    }),
    [getValues, getOffer]
  )

  const handleSendAsToggle = () => {
    if (isSendAsOptional) {
      setIsSendAs(current => {
        if (current) {
          setValue('amount_out', null)
        }

        return !current
      })
    }
  }

  const hasStanding = (targetCurrencySettings as WithdrawCurrency)
    ?.standing_order
  const hasPaymentDate = !!(targetCurrencySettings as WithdrawCurrency)
    ?.payment_date
  const minPaymentDate = useMemo(
    () =>
      new Date((targetCurrencySettings as WithdrawCurrency)?.payment_date?.min),
    [targetCurrencySettings]
  )
  const maxPaymentDate = useMemo(
    () =>
      new Date((targetCurrencySettings as WithdrawCurrency)?.payment_date?.max),
    [targetCurrencySettings]
  )

  const areDeferredOptionsShown =
    isWithdraw && (hasStanding || hasPaymentDate) && !isSendAs

  const currencyOutOptions = useMemo(
    () =>
      currentMethodCurrencies &&
      getCurrencyOptions(currentMethodCurrencies, false).filter(
        ({ value }) => value !== settings_id
      ),
    [currentMethodCurrencies, settings_id]
  )

  const minAmountIn = isWithdraw
    ? currentCurrencySettings?.min_amount_in
    : currentCurrencySettings?.min_amount_out
  const maxAmountIn = isWithdraw
    ? currentCurrencySettings?.max_amount_in
    : currentCurrencySettings?.max_amount_out
  const precisionIn = isWithdraw
    ? currentCurrencySettings?.precision_in
    : currentCurrencySettings?.precision_out

  return (
    <>
      <SendAsToggle
        isSmall
        isSecondary
        onClick={handleSendAsToggle}
        isShown={isSendAsOptional}
      >
        {isSendAs ? t('send-in-account') : t('send-in-different')}
      </SendAsToggle>

      <Container isExpanded={isSendAs}>
        <AmountRow>
          <span>{isWithdraw ? t('amount-sent') : t('amount-to-receive')}</span>

          <FormattedInput
            name="amount_in"
            label={t('amount')}
            onChange={handleAmountInChange}
            thousandSeparator
            allowNegative={false}
            isFloat
            decimalScale={precisionIn}
            currency={currentCurrencySettings?.code}
          />

          <AmountInfo>
            {isDefined(minAmountIn) && (
              <MaxAmount isShown={!process_manager_id}>
                <span>{`${t('min')}: `}</span>

                <Amount
                  value={minAmountIn}
                  code={currentCurrencySettings?.code}
                  decimals={precisionIn}
                />

                {!isWithdraw && <Tip>{t('min-warning-general')}</Tip>}
              </MaxAmount>
            )}
            {isDefined(maxAmountIn) && (
              <MaxAmount isShown={!process_manager_id}>
                <span>{`${t('max')}: `}</span>

                <Amount
                  value={maxAmountIn}
                  code={currentCurrencySettings?.code}
                  decimals={precisionIn}
                />
              </MaxAmount>
            )}

            <FeeInfo
              fee={fee}
              offer={offer}
              feeCurrCode={currentCurrencySettings?.code}
              isFeeLoading={isFeeLoading}
              isWithdraw={isWithdraw}
            />

            {!isWithdraw &&
              (isSendAs ? (
                <span>
                  <span>{`${t('exc-fee')}: `}</span>

                  {isFeeLoading ? (
                    <LoadingDots />
                  ) : offer ? (
                    <Amount
                      value={offer?.total_amount_out}
                      code={offer?.currency_out}
                      decimals={offer?.precision_out}
                    />
                  ) : (
                    '-'
                  )}
                </span>
              ) : (
                <span>
                  <span>{`${t('exc-fee')}: `}</span>

                  {isFeeLoading ? (
                    <LoadingDots />
                  ) : fee ? (
                    <Amount
                      value={fee?.total_amount}
                      code={currentCurrencySettings?.code}
                      decimals={fee?.precision}
                    />
                  ) : (
                    '-'
                  )}
                </span>
              ))}
          </AmountInfo>
        </AmountRow>

        <Separator>
          <ArrowContianer>
            {(isSendAs || delayedIsSendAs) && (
              <ArrowIcon isWithdraw={isWithdraw} />
            )}
          </ArrowContianer>
        </Separator>

        {delayedIsSendAs && (
          <AmountRow>
            <span>{isWithdraw ? t('amount-receieved') : t('will-send')}</span>

            <CurrencyDropdownInput
              name="amount_out"
              label={t('amount')}
              selectedCurrencyId={targetCurrencyId}
              onCurrencySelect={setTargetCurrencyId}
              currencyOptions={currencyOutOptions}
              onChange={handleAmountOutChange}
              decimalScale={
                isWithdraw
                  ? targetCurrencySettings?.precision_out
                  : targetCurrencySettings?.precision_in
              }
              currency={targetCurrencySettings?.code}
            />

            <AmountInfo>
              <FeeInfo
                  fee={null}
                  offer={offer}
                  feeCurrCode={''}
                  isFeeLoading={isFeeLoading}
                  isWithdraw={!isWithdraw}
              />

              {isSendAs && (
                      <span>
                        <span>{isWithdraw ? `${t('exc-fee')}: ` : `${t('inc-fee')}: `}</span>

                          {isFeeLoading ? (
                              <LoadingDots />
                          ) : offer ? (
                              <Amount
                                  value={isWithdraw ? offer?.total_amount_out : offer?.total_amount_in}
                                  code={isWithdraw ? offer?.currency_out : offer?.currency_in}
                                  decimals={isWithdraw ? offer?.precision_out : offer?.precision_in}
                              />
                          ) : (
                              '-'
                          )}
                      </span>
                  )}
            </AmountInfo>

          </AmountRow>
        )}
      </Container>

      <StyledExchangeOffer
        isShown={isSendAs && (isOfferLoading || !!offer)}
        offerMethods={offerMethods}
      />

      {areDeferredOptionsShown && (
        <DeferredOptions
          hasPaymentDate={hasPaymentDate}
          minPaymentDate={minPaymentDate}
          maxPaymentDate={maxPaymentDate}
          hasStanding={hasStanding}
        />
      )}
    </>
  )
}
