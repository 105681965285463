import { IncomingMessage } from 'http'
import accept from '@hapi/accept'
import { NextApiRequestCookies } from 'next/dist/server/api-utils'
import { ACCESS_TOKEN_COOKIE_KEY, NEXT_LOCALE } from '../constants/cookies'
import { AUTHORIZATION_HEADER } from '../constants/headers'

export const formatNextQuery = (query: string | string[]) =>
  Array.isArray(query) ? query[0] : query

export const isServer = () => typeof window === 'undefined'

export const getUserLocale = (
  req: IncomingMessage & {
    cookies: NextApiRequestCookies
  },
  locale: string,
  locales: string[]
) => {
  const headerLoacale = accept.language(req.headers['accept-language'], locales)

  const selectedLoacale = req
    ? req.cookies?.[NEXT_LOCALE] || headerLoacale || locale
    : null

  if (selectedLoacale === 'ru') {
    return 'en'
  }

  return selectedLoacale
}

export const parseCookies = (str: string): NextApiRequestCookies =>
  str
    ?.split(';')
    ?.map(v => v.split('='))
    ?.reduce((acc: Record<string, string>, value) => {
      acc[decodeURIComponent(value?.[0]?.trim())] = decodeURIComponent(
        value?.[1]?.trim()
      )

      return acc
    }, {})

export const getAuthHeader = (
  req: IncomingMessage & {
    cookies: NextApiRequestCookies
  }
) => {
  const token = req?.cookies[ACCESS_TOKEN_COOKIE_KEY]

  return {
    [AUTHORIZATION_HEADER]: `Bearer ${token}`,
  }
}
