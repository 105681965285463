// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { CaptureConsole } from '@sentry/integrations'
import * as Sentry from '@sentry/nextjs'
import { sentryDsn } from '@fe/common/constants/env'

Sentry.init({
  dsn: sentryDsn,
  debug: true,
  maxBreadcrumbs: 50,
  integrations: [
    new CaptureConsole({
      levels: ['debug'],
    }),
  ],
  // tunnel: '/api/sentryReport',
})
