import styled from 'styled-components'
import { Modal } from '@fe/common/components/Modal'
import { SCREEN } from '@fe/common/constants/main'

const StyledModal = styled(Modal)`
  width: 45rem;
`

const Iframe = styled.iframe`
  display: block;
  width: calc(100% + 6rem);
  height: 40rem;
  margin: 0 -3rem;
  border-radius: 3px;

  ${SCREEN.ABOVE_MOBILE} {
    width: 32rem;
    margin: 0 auto;
  }
`

interface IframeModalProps {
  title: string
  frameSrc: string
  onClose: () => void
}

export const IframeModal: React.FC<IframeModalProps> = ({
  title,
  frameSrc,
  onClose,
}) => (
  <StyledModal
    title={title}
    onClose={onClose}
    isWithoutHeaderSeparator
    isLogoutWarning
  >
    <Iframe src={frameSrc} />
  </StyledModal>
)
