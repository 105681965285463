import { useState } from 'react'
import { Collapse as ReactCollapse } from 'react-collapse'
import styled from 'styled-components'
import { BORDER, COLOR } from '@fe/common/constants/main'
import { useDidUpdate } from '@fe/common/hooks/useDidUpdate'
import Chevron from '@fe/common/src/icons/chevron.svg'

const Container = styled.div`
  .ReactCollapse--collapse {
    transition: height 0.3s cubic-bezier(0.49, -0.04, 0.28, 1.6);
  }

  .ReactCollapse--content {
    padding: 1rem 0;
  }
`

const TitleContainer = styled.div<{ isOpened: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  padding: 1.2rem 2rem 1rem;
  border-bottom: ${BORDER.DEFAULT};
  cursor: pointer;

  :hover {
    border-bottom-color: ${COLOR.OFF_WHITE};
    border-radius: 3px;
    background: ${COLOR.OFF_WHITE};

    svg {
      transform: ${({ isOpened }) => !isOpened && 'rotate(0deg)'};
    }
  }
`

const ChevronIcon = styled(Chevron).withConfig({
  shouldForwardProp: prop => !['isOpened'].includes(prop as string),
})<{ isOpened: boolean }>`
  height: 1.5rem;
  margin-left: 1.5rem;
  transform: ${({ isOpened }) =>
    isOpened ? 'rotate(-90deg)' : 'rotate(90deg)'};
  transition: transform 0.2s cubic-bezier(0.49, -0.04, 0.28, 1.84);
`

const Title = styled.div`
  display: flex;
  align-items: center;
`

interface CollapseProps {
  title: string
  onCollapse?: () => void
  indicators?: React.ReactNode
  className?: string
}

export const Collapse: React.FC<CollapseProps> = ({
  title,
  onCollapse,
  indicators,
  className,
  children,
}) => {
  const [isOpened, setIsOpened] = useState(false)

  useDidUpdate(() => {
    if (!isOpened) {
      onCollapse?.()
    }
  }, [isOpened])

  return (
    <Container className={className}>
      <TitleContainer
        isOpened={isOpened}
        onClick={() => setIsOpened(current => !current)}
      >
        <Title>
          <h4>{title}</h4>

          <ChevronIcon isOpened={isOpened} />
        </Title>

        {indicators}
      </TitleContainer>

      <ReactCollapse isOpened={isOpened}>{children}</ReactCollapse>
    </Container>
  )
}
