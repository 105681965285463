import styled, { css } from 'styled-components'
import { COLOR, SCREEN, SHADOW } from '@fe/common/constants/main'

export const cardStyle = css`
  background: ${COLOR.WHITE};
  box-shadow: ${SHADOW.MAIN};
  border-radius: 3px;
`

interface CardContainerProps {
  maxWidth?: string
  withSharpCorners?: boolean
}

export const CardContainer = styled.div<CardContainerProps>`
  ${cardStyle}

  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 4rem 3rem;
  border-radius: ${({ withSharpCorners }) => !withSharpCorners && '8px'};

  ${SCREEN.ABOVE_MOBILE} {
    max-width: ${({ maxWidth }) => maxWidth || '50rem'};
  }
`
