import Axios, { AxiosPromise } from 'axios'
import { Country } from '@fe/common/api/models/Country'
import { RequestConfig } from '@fe/common/axiosConfig'

export class CountriesApi {
  static getCountries(config?: RequestConfig): AxiosPromise<Array<Country>> {
    return Axios({
      ...config,
      url: `/countries`,
      method: 'GET',
      params: {
        page_size: 0,
      },
    })
  }
}
