import { useTranslation } from 'next-i18next'
import { useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import { Amount } from '@fe/common/components/Amount'
import { Button } from '@fe/common/components/Button'
import { DropdownOptions } from '@fe/common/components/inputs/CustomSelect'
import { Dropdown } from '@fe/common/components/inputs/Dropdown'
import {
  COLOR,
  FONT_SIZE,
  FONT_WEIGHT,
  SCREEN,
} from '@fe/common/constants/main'
import { useCryptoQr } from '@fe/common/hooks/useCryptoQr'
import { ClipboardCopy } from '@fe/common/src/components/ClipboardCopy'
import { ReceiveData } from '..'
import { DepositsApi } from 'src/api/DepositsApi'
import { AccountCurrencyWithDetails } from 'src/types/AccountCurrencyWithDetails'

const NewAddressButton = styled(Button)`
  height: 3.5rem;

  ${SCREEN.ABOVE_MOBILE} {
    height: 6rem;
    text-align: center;
  }
`

const AddressContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  > :last-child {
    width: 100%;
    margin-top: 2rem;
  }

  ${SCREEN.ABOVE_MOBILE} {
    flex-direction: row;

    > :first-child {
      margin-right: 2rem;
    }

    > :last-child {
      width: fit-content;
      margin-top: 0;
    }
  }
`

const DetailsContainer = styled.div`
  display: flex;
  margin-top: 4em;
`

const QrCode = styled.img`
  display: none;
  width: 15rem;
  height: 15rem;
  margin-right: 4rem;

  ${SCREEN.ABOVE_MOBILE} {
    display: block;
  }
`

const Rows = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  padding: 1rem 0;
`

const Row = styled.div`
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
  overflow-x: auto;

  span {
    display: block;
    white-space: pre;

    :first-child {
      margin-bottom: 1rem;
      color: ${COLOR.GREY};
      font-size: ${FONT_SIZE.SMALL};
    }
  }

  & + & {
    margin-top: 2rem;
  }
`

interface CryptoDetailsProps {
  currentCurrencyDetails: AccountCurrencyWithDetails
}

export const CryptoDetails: React.FC<CryptoDetailsProps> = ({
  currentCurrencyDetails,
}) => {
  const { t } = useTranslation('transactions')
  const [addresses, setAddresses] = useState<DropdownOptions>([])
  const [isLoading, setIsLoading] = useState(false)

  const { setValue, watch, control } = useFormContext<ReceiveData>()

  const amountIn = watch('amount_in')
  const crypto_address = watch('requestTypeFields.crypto_address') as string

  const qrCodeSrc = useCryptoQr(
    crypto_address,
    amountIn,
    currentCurrencyDetails?.code
  )

  const fetchAddresses = useCallback(
    async (cryptoSettingsId: number, isNew = false) => {
      try {
        setIsLoading(true)

        const {
          data: { addresses },
        } = await DepositsApi.getCryptoDetails(cryptoSettingsId, isNew)

        const addressOptions = addresses.map(address => ({
          value: address,
          label: address,
        }))

        setAddresses(addressOptions)
        setValue('requestTypeFields.crypto_address', addressOptions[0].value)
      } catch (error) {
        console.error()
      } finally {
        setIsLoading(false)
      }
    },
    []
  )

  useEffect(() => {
    fetchAddresses(currentCurrencyDetails?.id)
  }, [currentCurrencyDetails?.id])

  return (
    <>
      <AddressContainer>
        <Dropdown
          name="requestTypeFields.crypto_address"
          label={t('address')}
          options={addresses}
          control={control}
          isLoading={isLoading}
          isFullWidth
        />

        <NewAddressButton
          onClick={() => fetchAddresses(currentCurrencyDetails?.id, true)}
          isDisabled={isLoading}
        >
          {t('get-new-address')}
        </NewAddressButton>
      </AddressContainer>

      <DetailsContainer>
        <QrCode src={qrCodeSrc} alt="QR Code" />

        <Rows>
          <Row>
            <span>{t('address')}</span>
            <ClipboardCopy text={crypto_address}>
              {crypto_address || '-'}
            </ClipboardCopy>
          </Row>

          <Row>
            <span>{t('amount')}</span>
            {amountIn ? (
              <Amount
                value={amountIn}
                decimals={currentCurrencyDetails?.precision}
                code={currentCurrencyDetails?.code}
              />
            ) : (
              '-'
            )}
          </Row>
        </Rows>
      </DetailsContainer>
    </>
  )
}
