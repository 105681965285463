import creditCardType from 'credit-card-type'
import styled from 'styled-components'
import { SCREEN } from '@fe/common/constants/main'

const Container = styled.div`
  display: flex;
  align-items: end;
`

const CardIcon = styled.img`
  width: 2.8rem;
  min-width: 2.8rem;
  height: 1.8rem;
  margin-right: 1.5rem;

  ${SCREEN.ABOVE_MOBILE} {
    margin-right: 1rem;
  }
`

interface CardNumberProps {
  cardNumber: string
  className?: string
}

export const CardNumber: React.FC<CardNumberProps> = ({
  cardNumber,
  className,
}) => {
  const cardProvider = creditCardType(cardNumber)[0]?.type || 'generic'

  return (
    <Container className={className}>
      <CardIcon src={`/cardIcons/${cardProvider}.svg`} alt="card icon" />
      <span>{cardNumber}</span>
    </Container>
  )
}
