import { useState } from 'react'
import { useDidUpdate } from './useDidUpdate'

export const useDelayedValue = <T>(valueToDelay: T, delay = 200) => {
  const [delayedValue, setDelayedValue] = useState(valueToDelay)

  useDidUpdate(() => {
    const timeout = setTimeout(() => {
      setDelayedValue(valueToDelay)
    }, delay)

    return () => clearTimeout(timeout)
  }, [valueToDelay])

  return delayedValue
}
