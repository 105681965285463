import { createGlobalStyle } from 'styled-components'
import {
  COLOR,
  FONT_WEIGHT,
  FONT_SIZE,
  SHADOW,
} from '@fe/common/constants/main'

export const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;

    @supports (-moz-appearance: none) {
      letter-spacing: 0.3px;
    }
    
  }

  html {
    max-height: 100vh;
		font-size: 62.5%;
    overflow-x: hidden;
	}

  body {
    max-height: 100vh;
    color: ${COLOR.OFF_BLACK};
    font-family: 'Space Grotesk',   sans-serif;
    font-size: 1.6rem;
    font-feature-settings: 'tnum', 'lnum';
     text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .cyrillic {
      font-family:'Inter',  sans-serif;
      letter-spacing: -0.1px;
  }

  main {
    transition: filter 0.2s;
  }

  .blured {
    filter: blur(2px);
  }

  button {
    display: block;
    appearance: none;
    background-color: transparent;
    font-family: 'Space Grotesk',sans-serif;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;

    :focus {
      outline: none;
    }

    :disabled {
      cursor: default;
    }
  }

  input {
    color: ${COLOR.OFF_BLACK};
    font-family: 'Space Grotesk',sans-serif;
    font-size: ${FONT_SIZE.BASE};
    appearance: none;
  }
  
  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }

  p {
    line-height: 1.7;
  }

  ol {
    list-style-position: inside;
  }

  h1 {
    font-size: 3.6rem;
    line-height: 1.2;
  }

  h2 {
    font-size: 2.4rem;
  }

  h1, h2, h3, h4 {
    font-weight: ${FONT_WEIGHT.SEMIBOLD};
  }

  span {
    @supports (-moz-appearance: none) {
      line-height: 1.3;
    }
  }

  .react-datepicker-popper {
    z-index: 99;
  }

  .react-datepicker {
    font-family: 'Space Grotesk',sans-serif;
    border-color: ${COLOR.LIGHTER_GREY};
    box-shadow: ${SHADOW.MAIN};
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before {
    border-bottom-color: ${COLOR.LIGHTER_GREY};
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle {
    border-bottom-color: ${COLOR.BACKGROUND};
  }

  .react-datepicker-wrapper,
  .DayPickerInput {
    width: 100%;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 2.7rem;
    line-height: 2.7rem;
    font-size: 1.4rem;
  }

  .react-datepicker__header__dropdown,
  .react-datepicker__current-month {
    margin-bottom: 0.5rem;
    font-size: ${FONT_SIZE.EXTRA_SMALL};
  }

  .react-datepicker__header {
    background-color: ${COLOR.BACKGROUND};
    border-bottom: none;
  }

  .react-datepicker__year-dropdown {
    border-color: ${COLOR.LIGHTER_GREY};
    background-color: ${COLOR.WHITE};
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected {
    background-color: ${({ theme }) => theme?.primary};
  }

  .react-datepicker__year-option,
  .react-datepicker__month-option {
    line-height: 2.5rem;
  }

  .react-datepicker__year-read-view--down-arrow {
    top: 5px;
  }

  .react-select__control--is-disabled {
    && {
      background-color: ${COLOR.OFF_WHITE};
    }
  }

  .react-select__menu {
    && { 
      padding: 0.5rem 0;
      border: 1px solid ${COLOR.LIGHTER_GREY};
      box-shadow: ${SHADOW.MAIN};
    }
  }

  .react-select__menu-list {
    && {
      max-height: 327px;

      ::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .react-select__group-heading {
    && {
    margin: 0 0.5rem;
      padding: 0 0 0.7rem;
    border-bottom: 1px solid ${COLOR.LIGHT_GREY};
    font-weight: ${FONT_WEIGHT.SEMIBOLD};
    text-transform: none;
    }
  }

  .react-select__option--is-selected {
    && {
      background-color: ${({ theme }) => theme?.primary};
    }
  }
`
