import { DropdownOptions } from '@fe/common/components/inputs/CustomSelect'
import { KeysOfType } from '@fe/common/types/KeysOfType'

export const chunkArray = <T>(
  array: Array<T>,
  chunkSize: number
): Array<Array<T>> => {
  const results = []

  const copiedArray = [...array]

  while (copiedArray.length) {
    results.push(copiedArray.splice(0, chunkSize))
  }

  return results
}

export const removeFromArray = (
  array: Array<string | number>,
  value: string | number
) => {
  const indexOfValue = array.indexOf(value)
  array.splice(indexOfValue, 1)

  return array
}

export const awaitAll = async <T>(
  iterable: Array<T> | Record<any, T>,
  callback: (element: T, index: number, array: Array<T>) => Promise<void>
) =>
  await Promise.all(
    Object.values(iterable).map(
      async (element, index, array) => await callback(element, index, array)
    )
  )

export const asyncForEach = async <T>(
  array: Array<T>,
  callback: (element: T, index: number, array: Array<T>) => void
) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}

export const formatDataToOptions = <T extends Record<any, any>>(
  data: T[],
  keys: {
    nameKey?: KeysOfType<T, string>
    valueKey?: KeysOfType<T, string | number>
  } = {
    nameKey: 'name' as KeysOfType<T, string>,
    valueKey: 'id' as KeysOfType<T, string | number>,
  }
): DropdownOptions => {
  const nameKey = keys?.nameKey
  const valueKey = keys?.valueKey

  return data.map(element => ({
    label: element[nameKey],
    value: element[valueKey],
  }))
}
