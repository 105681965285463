import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'
import { AppState } from 'src/state/store'

const hydrate = createAction<AppState>(HYDRATE)

export interface ReceiveModalState {
  isOpened: boolean
  selectedCurrencyId: number
}

export const initialReceiveModalState: ReceiveModalState = {
  isOpened: false,
  selectedCurrencyId: null,
}

const receiveModalSlice = createSlice({
  name: 'receiveModal',
  initialState: initialReceiveModalState,
  reducers: {
    flushReceiveModal() {
      return initialReceiveModalState
    },
    openReceiveModal(state, { payload }: PayloadAction<number | undefined>) {
      state.isOpened = true

      if (payload) {
        state.selectedCurrencyId = payload
      }
    },
    closeReceiveModal(state) {
      state.isOpened = false
      state.selectedCurrencyId = null
    },
  },
  extraReducers: builder => {
    builder.addCase(hydrate, state => state)
  },
})

const { actions, reducer } = receiveModalSlice

export const { flushReceiveModal, openReceiveModal, closeReceiveModal } =
  actions

export default reducer
