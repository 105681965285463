export enum CardOrderStatusEnum {
  New = 1,
  InProgress = 2,
  Sending = 3,
  Sent = 4,
  Filled = 5,
  Completed = 6,
  Rejected = 7,
  Canceled = 8,
  Accepted = 9,
  Hold = 10,
  CancelRequested = 11,
}
