import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import { Rate as TRate } from '@fe/common/api/models/Rate'
import { Amount } from '@fe/common/components/Amount'
import { Rate } from '@fe/common/components/Rate'
import { COLOR, FONT_SIZE, SCREEN } from '@fe/common/constants/main'
import { timestampToDateString } from '@fe/common/utils/dateUtils'

const Container = styled.div`
  display: grid;
  grid-template-columns: 12rem 1fr;
  grid-row-gap: 0.5rem;
  margin-left: 2rem;

  ${SCREEN.ABOVE_MOBILE} {
    grid-template-columns: 20rem 1fr;
  }
`

const Label = styled.div`
  font-size: ${FONT_SIZE.SMALL};
  color: ${COLOR.GREY};
`

interface SummaryProps {
  uuid: string
  currency_in: string
  currency_out: string
  total_amount_in: number
  total_amount_out: number
  precision_in: number
  precision_out: number
  created_at: number
  rate?: TRate
  isRefundStep?: boolean
  className?: string
}

export const Summary: React.FC<SummaryProps> = ({
  uuid,
  total_amount_in,
  total_amount_out,
  precision_in,
  precision_out,
  currency_in,
  currency_out,
  created_at,
  rate,
  isRefundStep,
  className,
}) => {
  const [t] = useTranslation('receive-status')

  return (
    <Container className={className}>
      <Label>{t('id-label')}</Label>
      <div>{uuid}</div>

      <Label>{t('date-label')}</Label>
      <div>{timestampToDateString(created_at)}</div>

      <Label>{t('amount-in-label')}</Label>
      <Amount value={total_amount_in} decimals={precision_in} code={currency_in} />

      <Label>{t('amount-out-label')}</Label>
      <Amount value={total_amount_out} decimals={precision_out} code={currency_out} />

      {!isRefundStep && (
        <>
          <Label>{t('conversion-rate-label')}</Label>
          <Rate rate={rate} />
        </>
      )}
    </Container>
  )
}
