import { DefaultTheme } from 'styled-components'

export const COLOR = {
  BLACK: '#030303',
  OFF_BLACK: '#2F2F32',
  GREY: '#A3A3A9',
  LIGHT_GREY: '#DDDDDD',
  LIGHTER_GREY: '#E4E4E4',
  OFF_WHITE: '#F5F5F5',
  WHITE: '#FFF',
  BACKGROUND: '#F6F6FA',
  GREEN: '#0C9242',
  RED: '#FF4646',
  LIGHT_RED: '#FF464652',
  BLUE: '#2550E5',
  ORANGE: '#FF7F50',
  GOLD: '#BC7100',
}

export const FONT_SIZE = {
  EXTRA_SMALL: '1.3rem',
  SMALL: '1.4rem',
  BASE: '1.6rem',
  MEDIUM: '1.8rem',
  LARGE: '2.4rem',
  EXTRA_LARGE: '3.6rem',
}

export const FONT_WEIGHT = {
  BASE: '400',
  SEMIBOLD: ({ theme }: { theme: DefaultTheme }) =>
    theme.lang === 'ru' ? 500 : 600,
}

export const BORDER = {
  DEFAULT: `1px solid ${COLOR.LIGHT_GREY}`,
  ERROR: `1px solid ${COLOR.RED}`,
}

export const SHADOW = {
  MAIN: '0px 2px 4px rgba(0, 0, 0, 0.07)',
  LARGE: `0px 4px 10px 0px ${COLOR.LIGHTER_GREY}`,
}

export const PADDING = {
  MOBILE: 2,
  DESKTOP: 3.5,
}

export const MAX_CONTENT_WIDTH = 180

export const BREAKPOINT = {
  MOBILE: 430,
  TABLET: 851,
  LAPTOP: 1125,
  MAX_CONTENT_WIDTH: MAX_CONTENT_WIDTH * 10,
}

export const SCREEN = {
  ABOVE_MOBILE: `@media (min-width: ${BREAKPOINT.MOBILE}px)`,
  BELOW_TABLET: `@media (max-width: ${BREAKPOINT.MOBILE}px)`,
  ABOVE_TABLET: `@media (min-width: ${BREAKPOINT.TABLET}px)`,
  ABOVE_LAPTOP: `@media (min-width: ${BREAKPOINT.LAPTOP}px)`,
  ABOVE_MAX_CONTENT_WIDTH: `@media (min-width: ${BREAKPOINT.MAX_CONTENT_WIDTH}px)`,
}

export const Z_INDEX = {
  BACKGROUND: -1,
  ABOVE: 1,
  HEADER: 3,
  MODAL: 4,
}
