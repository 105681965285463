import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useRef, useState } from 'react'
import NumberFormat from 'react-number-format'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { MfaPreparationData } from '@fe/common/api/models/MfaPreparationData'
import { MfaPrepareData } from '@fe/common/api/models/RequestModels/PrepareMfaRequest'
import { MfaActionTypeEnum } from '@fe/common/api/models/enums/MfaActionTypeEnum'
import { MfaTypeEnum } from '@fe/common/api/models/enums/MfaTypeEnum'
import { Button } from '@fe/common/components/Button'
import { DropdownOptions } from '@fe/common/components/inputs/CustomSelect'
import { RadioPicker } from '@fe/common/components/inputs/RadioPicker'
import { StyledInput } from '@fe/common/components/inputs/baseInputStyles'
import { opacityReveal, rotation } from '@fe/common/constants/animations'
import { mfaLabels } from '@fe/common/constants/enums'
import {
  BORDER,
  COLOR,
  FONT_SIZE,
  FONT_WEIGHT,
  SCREEN,
} from '@fe/common/constants/main'
import { getMfaTypeOptions } from '@fe/common/constants/options'
import { useCancelToken } from '@fe/common/hooks/useCancelToken'
import { useCountdown } from '@fe/common/hooks/useCountdown'
import Clock from '@fe/common/src/icons/clock.svg'
import Close from '@fe/common/src/icons/close.svg'
import Spinner from '@fe/common/src/icons/spinner.svg'
import { getLocalExpireAt } from '@fe/common/src/utils/dateUtils'
import { formatResponseError } from '@fe/common/utils/axiosUtils'
import { MfaApi } from 'src/api/MfaApi'
import { userSettingsSelector } from 'src/state/selectors/sessionSelectors'

const Container = styled.div<{ isWithTypes: boolean }>`
  position: relative;
  display: flex;
  justify-content: ${({ isWithTypes }) =>
    isWithTypes ? 'space-between' : 'center'};
  flex-wrap: wrap;
  padding: 3rem 3rem 4rem;
  border-radius: 6px;
  background: ${COLOR.BACKGROUND};

  ${SCREEN.ABOVE_MOBILE} {
    padding: 3rem 4rem 4rem;
  }
`

const MethodContainer = styled.div<{ isContainerWide: boolean }>`
  position: relative;
  max-width: 100%;
  margin-bottom: ${({ isContainerWide }) => !isContainerWide && '2rem'};
`

const Heading = styled.p`
  margin-bottom: 1rem;
  font-size: ${FONT_SIZE.SMALL};
  color: ${COLOR.GREY};
`

const CodeContainer = styled.div`
  position: relative;
  width: 100%;

  ${SCREEN.ABOVE_MOBILE} {
    width: 29rem;
    max-width: 100%;
  }
`

const InputContainer = styled.div`
  position: relative;
  display: none;

  ${SCREEN.ABOVE_MOBILE} {
    display: block;
  }
`

const Overlay = styled.div`
  display: flex;
  justify-content: space-between;
`

const Box = styled.div<{ withPlaceholder: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 6.5rem;
  background: ${COLOR.WHITE};
  border: ${BORDER.DEFAULT};
  border-radius: 3px;

  ::after {
    content: '•';
    font-size: ${FONT_SIZE.LARGE};
    font-weight: ${FONT_WEIGHT.SEMIBOLD};
    color: ${COLOR.GREY};
    opacity: ${({ withPlaceholder }) => !withPlaceholder && 0};
  }
`

const CodeInput = styled(StyledInput)`
  position: absolute;
  top: 0;
  width: 33rem;
  padding: 0 1.3rem;
  background: transparent;
  border: none;
  font-size: ${FONT_SIZE.LARGE};
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
  letter-spacing: 35px;

  @supports (-moz-appearance: none) {
    letter-spacing: 36px;
  }
`

const MobileCodeInput = styled(StyledInput)`
  ${SCREEN.ABOVE_MOBILE} {
    display: none;
  }
`

const SpinnerIcon = styled(Spinner)`
  position: absolute;
  top: 0.3rem;
  right: 0;
  animation: ${rotation} 1s infinite;
`

const Error = styled.span`
  position: absolute;
  top: 0.3rem;
  right: 0;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  background: ${COLOR.BACKGROUND};
  color: ${COLOR.RED};
  font-size: ${FONT_SIZE.SMALL};
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
`

const CrossIcon = styled(Close)`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
  fill: ${COLOR.RED};
  transform: scale(2.5);
`

const InfoRow = styled.div<{ isContainerWide: boolean }>`
  position: absolute;
  bottom: ${({ isContainerWide }) => (isContainerWide ? '-2rem' : '-3rem')};
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: ${FONT_SIZE.SMALL};
`

const TimeLeft = styled.div`
  display: flex;
`

const ClockIcon = styled(Clock)`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
  fill: ${COLOR.GREY};
`

const RequestButton = styled(Button)<{ isContainerWide: boolean }>`
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 1;
  animation: ${opacityReveal} 0.2s;

  ${({ isContainerWide }) =>
    isContainerWide &&
    css`
      top: unset;
      right: unset;
      bottom: 1.5rem;
      left: 4rem;
    `};
`

export type MfaData = {
  mfa_type: MfaTypeEnum
  mfa_code: string
  mfa_action_code?: string
  target?: string
}

interface MfaInputProps {
  actionType?: MfaActionTypeEnum
  prepareData?: MfaPrepareData
  isExternalLoading?: boolean
  externalError?: string
  onTypeChange?: (type: MfaTypeEnum) => void
  onRefocusInput?: () => void
  onCodeEnter?:
    | ((mfaData: MfaData) => Promise<void>)
    | ((mfaData: MfaData) => void) // Do not try/catch it outside!
  onMfaPrepare?: (selectedType: MfaTypeEnum) => Promise<MfaPreparationData> // Do not try/catch it outside!
  isFocusedOnMount?: boolean
  isPreparingOnMount?: boolean
  isWithTypes?: boolean
  prefilledType?: MfaTypeEnum
  externalTypes?: DropdownOptions<MfaTypeEnum>
  phone?: string
  className?: string
}

export const MfaInput: React.FC<MfaInputProps> = ({
  actionType,
  prepareData,
  isExternalLoading,
  externalError,
  onTypeChange,
  onRefocusInput,
  onCodeEnter,
  onMfaPrepare,
  isFocusedOnMount = true,
  isPreparingOnMount = true,
  isWithTypes = true,
  prefilledType,
  externalTypes,
  phone,
  className,
}) => {
  const { t } = useTranslation()
  const settings = useSelector(userSettingsSelector)
  const [mfaTypes, setMfaTypes] = useState<DropdownOptions<MfaTypeEnum>>(
    externalTypes || []
  )
  const [selectedType, setSelectedType] = useState<MfaTypeEnum>(prefilledType)
  const [code, setCode] = useState('')
  const [actionCode, setActionCode] = useState('')
  const [expireAt, setExpireAt] = useState<number>()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const { query } = useRouter()
  const containerRef = useRef<HTMLDivElement>()
  const inputRef = useRef<HTMLInputElement>()
  const { timeLeft } = useCountdown({ expireAt, setExpireAt })

  const cancelToken = useCancelToken()

  const handleMfaPrepare = useCallback(
    async (mfaType?: MfaTypeEnum) => {
      try {
        setIsLoading(true)

        if (onMfaPrepare) {
          const { ttl, mfa_action_code } = await onMfaPrepare(
            selectedType || mfaType
          )

          setExpireAt(getLocalExpireAt(ttl))
          setActionCode(mfa_action_code)
        } else if (actionType) {
          const {
            data: { ttl, mfa_action_code },
          } = await MfaApi.prepareMfa(
            {
              mfa_action_type: actionType,
              mfa_type: selectedType || mfaType,
              data: prepareData,
            },
            { cancelToken }
          )

          setExpireAt(getLocalExpireAt(ttl))
          setActionCode(mfa_action_code)
        }
      } catch (error) {
        const errorMessage = formatResponseError(error, t)

        setError(errorMessage)
      } finally {
        setIsLoading(false)
      }
    },
    [onMfaPrepare, selectedType, actionType, cancelToken]
  )

  useEffect(() => {
    const fetchMethods = async () => {
      try {
        const { data } = await MfaApi.getMfaTypes()

        const mfaSettings = Object.keys(data).map(mfaType => ({
          mfa_type: Number(mfaType) as MfaTypeEnum,
          is_default: false,
        }))

        const availableMethods = getMfaTypeOptions(mfaSettings, t)

        setMfaTypes(availableMethods)
      } catch (error) {
        const errorMessage = formatResponseError(error, t)

        setError(errorMessage)
      }
    }

    let defaultMethod: MfaTypeEnum = prefilledType

    if (!defaultMethod && !externalTypes) {
      if (settings) {
        const userMethods = settings.mfa_settings.map(type => {
          if (type.is_default) {
            defaultMethod = type.mfa_type
          }

          return {
            value: type.mfa_type,
            label: t(mfaLabels[type.mfa_type]),
          }
        })

        setMfaTypes(userMethods)
        setSelectedType(defaultMethod)
      } else {
        fetchMethods()
      }
    }

    if (
      isPreparingOnMount &&
      defaultMethod &&
      (defaultMethod === MfaTypeEnum.Sms || defaultMethod === MfaTypeEnum.Call)
    ) {
      handleMfaPrepare(defaultMethod)
    }
  }, [])

  useEffect(() => {
    if (isFocusedOnMount) {
      inputRef.current.focus()
    }
  }, [])

  useEffect(() => {
    if (onTypeChange) {
      onTypeChange(selectedType)
    }

    setCode('')
    setActionCode('')
    setExpireAt(null)
    setError('')
  }, [selectedType])

  useEffect(() => {
    if (isLoading) {
      setError('')
    }
  }, [isLoading])

  const handleMethodSelect = (value: MfaTypeEnum) => setSelectedType(value)

  const handleCodeSubmit = async (enteredCode: string) => {
    try {
      setIsLoading(true)

      const targetToUse =
        [MfaTypeEnum.Sms, MfaTypeEnum.Call].includes(selectedType) && phone
          ? phone.substring(1)
          : undefined

      await onCodeEnter({
        mfa_type: selectedType,
        mfa_code: enteredCode,
        target: targetToUse,
        mfa_action_code: actionCode,
      })
    } catch (error) {
      const errorMessage = formatResponseError(error, t)
      console.error(error)

      setError(errorMessage)
      setCode('')
    } finally {
      setIsLoading(false)
    }
  }

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedCode = e.target.value.trim()

    setCode(trimmedCode)

    if (error) {
      setError('')
    }

    if (trimmedCode.length === 6) {
      handleCodeSubmit(trimmedCode)

      e.target.blur()
    }
  }

  const handleCodeFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.value.length === 6) {
      setCode('')
      onRefocusInput?.()
    }
  }

  const isRequestButtonShown =
    (selectedType === MfaTypeEnum.Sms || selectedType === MfaTypeEnum.Call) &&
    (query.code ? phone : true) &&
    !error

  const isWide = containerRef.current?.clientWidth > 400

  return (
    <Container
      isWithTypes={isWithTypes}
      className={className}
      ref={containerRef}
    >
      {isRequestButtonShown && (
        <RequestButton
          isSmall
          isSecondary
          isContainerWide={isWide}
          onClick={() => handleMfaPrepare()}
        >
          {expireAt
            ? t('resend')
            : selectedType === MfaTypeEnum.Sms
            ? t('request-sms')
            : t('request-call')}
        </RequestButton>
      )}

      {isWithTypes && (
        <MethodContainer isContainerWide={isWide}>
          <Heading>{t('method')}</Heading>

          <RadioPicker
            name="type"
            options={mfaTypes}
            defaultOption={selectedType}
            onClick={handleMethodSelect}
          />
        </MethodContainer>
      )}

      <CodeContainer>
        <Heading>{t('verification-code')}</Heading>

        {(isExternalLoading || isLoading) && <SpinnerIcon />}

        {(error || externalError) && (
          <Error>
            <CrossIcon /> {error || externalError}{' '}
          </Error>
        )}

        <InputContainer>
          <Overlay>
            {[1, 2, 3, 4, 5, 6].map(length => (
              <Box withPlaceholder={code.length < length} key={length} />
            ))}
          </Overlay>

          <NumberFormat
            customInput={CodeInput}
            value={code}
            onChange={handleCodeChange}
            onFocus={handleCodeFocus}
            disabled={isLoading}
            format="######"
            getInputRef={inputRef}
            autoComplete="off"
            data-test-id="mfa_input"
          />
        </InputContainer>

        <MobileCodeInput
          value={code}
          type="number"
          onChange={handleCodeChange}
          onFocus={handleCodeFocus}
          maxLength={6}
          disabled={isLoading}
          autoComplete="off"
          data-test-id="mfa_input"
        />

        {(selectedType === MfaTypeEnum.Sms ||
          selectedType === MfaTypeEnum.Call) &&
          timeLeft && (
            <InfoRow isContainerWide={isWide}>
              <TimeLeft>
                <ClockIcon />
                <span>
                  {t('valid-for')} {timeLeft}
                </span>
              </TimeLeft>

              <span>
                {t('action-code')}: <b>{actionCode}</b>
              </span>
            </InfoRow>
          )}
      </CodeContainer>
    </Container>
  )
}
