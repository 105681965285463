import { Store } from '@reduxjs/toolkit'
import Axios, { AxiosRequestConfig, AxiosError } from 'axios'
import { default as ServerFormData } from 'form-data'
import Cookies from 'js-cookie'
import { apiUrl, serverApiUrl } from '@fe/common/constants/env'
import { AUTHORIZATION_HEADER } from '@fe/common/constants/headers'
import { formatRequestData } from '@fe/common/utils/axiosUtils'
import { getTokenCookie } from '@fe/common/utils/cookies'
import { isServer } from '@fe/common/utils/nextUtils'

export type RequestConfig = Pick<
  AxiosRequestConfig,
  Exclude<
    keyof AxiosRequestConfig,
    'method' | 'url' | 'data' | 'params' | 'responseType'
  >
>

export const configureAxiosInterceptors = (
  store: Store,
  deauthenticate: any
) => {
  const accessTokenRequestInterceptor = Axios.interceptors.request.use(
    config => {
      const token = getTokenCookie()

      if (token) {
        return {
          ...config,
          headers: {
            ...config.headers,
            [AUTHORIZATION_HEADER]: `Bearer ${token}`,
          },
        }
      }

      return config
    }
  )

  const responseInterceptor = Axios.interceptors.response.use(
    response => {
      const jsendStatus = response?.data?.status

      if (jsendStatus === 'fail' || jsendStatus === 'error') {
        return Promise.reject(response?.data)
      }

      return response
    },
    (error: AxiosError) => {
      if (error.response?.status === 401 && !isServer()) {
        store.dispatch(deauthenticate())
      }

      return Promise.reject(error)
    }
  )

  return {
    request: [accessTokenRequestInterceptor],
    response: [responseInterceptor],
  }
}

export const configureAxiosDefaults = () => {
  Axios.defaults.baseURL = (isServer() ? serverApiUrl : apiUrl) + '/v1'
  Axios.defaults.headers['Content-Type'] = 'application/json'
  Axios.defaults.timeout = 100000

  const testExampleCookie = Cookies.get('test_example')

  if (testExampleCookie) {
    Axios.defaults.headers['Prefer'] = 'example=' + testExampleCookie
  }

  Axios.defaults.transformResponse = (data, headers) => {
    if (
      headers['content-type'].includes('application/json') &&
      typeof data === 'string'
    ) {
      const parsedData = JSON.parse(data)

      const code = parsedData.code
      const status = parsedData.status

      if (
        code &&
        ((code === 422 &&
          Array.isArray(parsedData.data) &&
          !parsedData.data.length) ||
          (!String(code)?.startsWith('2') && code !== 422))
      ) {
        return { message: parsedData.message, status }
      }

      if (status === 'fail' || status === 'error') {
        return parsedData
      }

      return parsedData.data || parsedData
    }

    return data
  }

  Axios.defaults.transformRequest = data => {
    const isFormData = isServer
      ? data instanceof ServerFormData
      : data instanceof FormData

    if (typeof data === 'object' && !isFormData) {
      if (Array.isArray(data)) {
        for (const dataElement of data) {
          formatRequestData(dataElement)
        }
      } else {
        formatRequestData(data)
      }

      return JSON.stringify(data)
    } else if (typeof data === 'string') {
      return data.trimStart().trimEnd()
    }

    return data
  }
}
