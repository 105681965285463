import { useCallback, useState } from 'react'
import { CardOrder } from '@fe/common/api/models/CardOrder'
import { MfaProps } from '../MtDetails'
import { CardsStep } from './CardsStep'
import { MfaStep } from 'src/components/actionModals/ReceiveModal/RequestTypeDetails/CardDetails/MfaStep'

enum CardStepsEnum {
  Cards,
  Mfa,
  Status,
}

interface CardDetailsProps extends MfaProps {
  order: CardOrder
  setOrder: React.Dispatch<React.SetStateAction<CardOrder>>
  currentCurrencyCode: string
}

export const CardDetails: React.FC<CardDetailsProps> = ({
  setOrder,
  ...rest
}) => {
  const [step, setStep] = useState(CardStepsEnum.Cards)

  const incrementStep = useCallback(() => setStep(current => current + 1), [])

  const decrementStep = useCallback(() => {
    setOrder(null)
    setStep(CardStepsEnum.Cards)
  }, [])

  const renderStep = () => {
    switch (step) {
      case CardStepsEnum.Cards:
        return <CardsStep incrementStep={incrementStep} setOrder={setOrder} />

      case CardStepsEnum.Mfa:
        return <MfaStep decrementStep={decrementStep} {...rest} />
    }
  }

  return <>{renderStep()}</>
}
