import Router from 'next/router'
import { VerificationStatusEnum } from '@fe/common/api/models/enums/VerificationStatusEnum'
import { DASHBOARD, TRANSACTIONS } from 'src/constants/routes'
import {
  getAccounts,
  getRecentTransactions,
  getRecentStatements,
  getAccountDetails,
  getPendingCardOrders,
  updateTransaction,
  getFilteredTransactions,
} from 'src/state/reducers/globalDataReducer'
import { AppDispatch, AppState } from 'src/state/store'
import { TransactionsTabEnum } from 'src/types/enums/TransactionsTabEnum'

export const updateRecentTransactions = () => (dispatch: AppDispatch) => {
  dispatch(getRecentTransactions())
  dispatch(getRecentStatements())
  dispatch(getPendingCardOrders())
}

export const refreshAccountInfo =
  () => (dispatch: AppDispatch, getState: () => AppState) => {
    const {
      session: { currentEntityId, entities },
      globalData: { cards },
    } = getState()

    const isVerified =
      entities.find(({ id }) => id === currentEntityId)?.status ===
      VerificationStatusEnum.Verified

    if (!isVerified) {
      return
    }

    dispatch(getAccounts(undefined))
    dispatch(getAccountDetails())
    dispatch(getRecentTransactions())
    dispatch(getRecentStatements())

    const hasCards = cards.length

    if (hasCards) {
      dispatch(getPendingCardOrders())
    }
  }

export const globalUpdateTransaction =
  (request_id: number) => (dispatch: AppDispatch) => {
    if (Router.route === DASHBOARD) {
      dispatch(getRecentTransactions())
    }

    if (Router.route === TRANSACTIONS) {
      if (Router.query.tab[0] === TransactionsTabEnum.History) {
        dispatch(updateTransaction(request_id))
      }

      if (Router.query.tab[0] === TransactionsTabEnum.Statements) {
        dispatch(updateTransaction(request_id))
      }
    }
  }

export const updateTransations = () => (dispatch: AppDispatch) => {
  if (Router.route === DASHBOARD) {
    dispatch(getRecentTransactions())
  }

  if (Router.route === TRANSACTIONS) {
    const { tab, ...query } = Router.query

    if (tab[0] === TransactionsTabEnum.History) {
      dispatch(getFilteredTransactions(query))
    }
  }
}
