import { useTranslation } from 'next-i18next'
import React, { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import { Collapse } from '@fe/common/src/components/Collapse'
import { TipModal } from '@fe/common/src/components/TipModal'
import { Checkbox } from '@fe/common/src/components/inputs/Checkbox'
import { DatePicker } from '@fe/common/src/components/inputs/DatePicker'
import { SCREEN } from '@fe/common/src/constants/main'
import { SendData } from '../SendModal/RequestTypeSteps/stepsConfig'

const StyledCollapse = styled(Collapse)`
  margin-bottom: 4rem;

  ${SCREEN.ABOVE_MOBILE} {
    margin-bottom: 1.5rem;
  }
`

const Container = styled.div`
  ${SCREEN.ABOVE_MOBILE} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 2rem;
  }
`

const PreparedPaymentContainer = styled.div`
  display: flex;
  align-items: center;

  :first-child {
    > :last-child {
      margin-bottom: 2.5rem;
    }
  }

  :last-child {
    height: 70%;
  }
`

interface StandingInfoProps {
  hasPaymentDate: boolean
  minPaymentDate: Date
  maxPaymentDate: Date
  hasStanding: boolean
}

export const DeferredOptions: React.FC<StandingInfoProps> = ({
  hasPaymentDate,
  minPaymentDate,
  maxPaymentDate,
  hasStanding,
}) => {
  const { t } = useTranslation('transactions')

  const { setValue } = useFormContext<SendData>()

  const handleCollapse = useCallback(() => {
    setValue('payment_date', null)
    setValue('process_manager_id', null)
  }, [])

  return (
    <StyledCollapse
      title={t('deffered-title')}
      onCollapse={handleCollapse}
      indicators={
        <TipModal title={t('deferred-payment-header')}>
          <p>{t('deferred-payment-description')}</p>
        </TipModal>
      }
    >
      <Container>
        {hasPaymentDate && (
          <PreparedPaymentContainer>
            <DatePicker
              name="payment_date"
              label={t('payment-date')}
              minDate={minPaymentDate}
              maxDate={maxPaymentDate}
              isOptional
              isFullWidth
            />

            <TipModal title={t('prepared-payment-header')}>
              <p>{t('payment-date-description')}</p>
            </TipModal>
          </PreparedPaymentContainer>
        )}

        {hasStanding && (
          <PreparedPaymentContainer>
            <Checkbox name="process_manager_id" label={t('prepared-payment')} />
            <TipModal title={t('prepared-payment-header')}>
              <p>{t('prepared-payment-description')}</p>
            </TipModal>
          </PreparedPaymentContainer>
        )}
      </Container>
    </StyledCollapse>
  )
}
