import { AxiosError, AxiosResponse } from 'axios'
import format from 'date-fns/format'
import { TFunction } from 'next-i18next'
import {
  TOTAL_COUNT_HEADER,
  PAGE_COUNT_HEADER,
} from '@fe/common/constants/headers'

export const formatRequestData = (dataElement: Record<string, unknown>) => {
  for (const [key, value] of Object.entries(dataElement)) {
    if (value == null) {
      continue
    }

    if (value instanceof Date) {
      const dateString = format(value, 'yyyy-MM-dd')

      dataElement[key] = dateString

      continue
    }

    if (typeof value === 'object' && !(value instanceof Array)) {
      formatRequestData(value as Record<string, unknown>)

      dataElement[key] = value

      continue
    }

    if (value instanceof Array && !value[0]?.label && !value[0]?.value) {
      for (const element of value) {
        if (typeof element === 'string') {
          continue
        }

        formatRequestData(element)
      }

      dataElement[key] = value

      continue
    }

    if (typeof value === 'string' && !(dataElement instanceof Array)) {
      dataElement[key] = value.trimStart().trimEnd()
    }

    if (typeof value === 'boolean') {
      dataElement[key] = Number(value)
    }

    if (typeof value === 'string') {
      dataElement[key] = value.trimStart().trimEnd()
    }
  }
}

type ResponseWithCount<T> = {
  data: T & {
    total_count: number
    page_count: number
  }
} & Omit<AxiosResponse<T>, 'data'>

export const addTotalCount = <T>(
  response: AxiosResponse<T>
): ResponseWithCount<T> => {
  const { data, headers } = response

  const total_count = Number(headers[TOTAL_COUNT_HEADER])
  const page_count = Number(headers[PAGE_COUNT_HEADER])

  const modifiedData = { ...data, total_count, page_count }

  return { ...response, data: modifiedData }
}

export const formatResponseError = (error: AxiosError, t?: TFunction) => {
  const responseData = error.response?.data

  return (
    (responseData?.status === 'error' &&
      JSON.stringify(responseData.message)) ||
    (responseData?.status === 'fail' && responseData.data[0]?.message?.[0]) ||
    // responseData.data[0]?.message?.[0] || //Q.V. CL-586
    responseData?.message ||
    (Array.isArray(responseData) && responseData[0]?.message?.[0]) ||
    error.message ||
    t?.('common:unknown-error')
  )
}
