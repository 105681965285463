import { Dispatch, ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit'
import { Account } from '@fe/common/api/models/Account'
import { Entity } from '@fe/common/api/models/Entity'
import { MfaSetting } from '@fe/common/api/models/MfaSetting'
import { EntityTypeEnum } from '@fe/common/api/models/enums/EntityTypeEnum'
import { MfaTypeEnum } from '@fe/common/api/models/enums/MfaTypeEnum'
import { fileToBase64 } from '@fe/common/utils/binaryUtils'
import { AppState } from 'src/state/store'
import {
  B64Files,
  FormDocument,
  FormDocuments,
  PersistedDocument,
} from 'src/types/DocumentTypes'

export const getIndividualEntity = (entities: Array<Entity>): Entity => {
  if (entities?.length === 0) {
    return
  }

  return entities.find(entity => entity.type === EntityTypeEnum.Individual)
}

export const fileToB64Doc = async (
  document: FormDocument
): Promise<PersistedDocument> => {
  if (!document.files) {
    return { ...document, files: [] }
  }

  let b64Files: Array<B64Files> = []

  for (const file of document.files) {
    const b64String = await fileToBase64(file)

    b64Files = [
      ...b64Files,
      {
        data: b64String,
        filename: file.name,
      },
    ]
  }

  const b64Doc = {
    files: b64Files,
    id: document.id || null,
    uploadedCount: document.uploadedCount || null,
  }

  return b64Doc
}

export const filesToB64Docs = async (documents: FormDocuments) => {
  let b64Docs: Record<string, PersistedDocument> = {}

  if (!documents) {
    return
  }

  for (const [name, document] of Object.entries(documents)) {
    const b64Doc = await fileToB64Doc(document)

    b64Docs = {
      ...b64Docs,
      [name]: b64Doc,
    }
  }

  return b64Docs
}

export const persistDocuments = async (
  documents: FormDocuments,
  dispatch: Dispatch,
  addDocuments: ActionCreatorWithOptionalPayload<
    Record<string, PersistedDocument>,
    string
  >
) => {
  const b64Docs = await filesToB64Docs(documents)

  dispatch(addDocuments(b64Docs))
}

export const persistDocument = async (
  key: string,
  document: FormDocument,
  dispatch: Dispatch,
  addDocument: ActionCreatorWithOptionalPayload<
    { key: string; document: PersistedDocument },
    string
  >
) => {
  const b64Docs = await fileToB64Doc(document)

  dispatch(addDocument({ key, document: b64Docs }))
}

export const getCurrentAccount = (state: AppState): Account =>
  state.globalData.accounts?.find(
    account => account.id === state.session.currentAccountId
  )

export const getDefaultMfa = (mfaSettings: Array<MfaSetting>): MfaTypeEnum =>
  mfaSettings.find(({ is_default }) => is_default)?.mfa_type
