export enum MfaActionTypeEnum {
  Registration = 1,
  Login,
  RequestCreate,
  RequestConfirm,
  Exchange,
  AddCard,
  EnableMfaToken,
  EnableMfa,
  ChangeMfa,
  DisableMfa,
  CreateApiKey,
  ResetApiKey,
  DeleteApiKey,
  OrderRefund,
  PasswordRecovery,
  Receive,
  PasswordChange,
  AdminChangeMfa,
  AdminResetMfa,
  DepositByCardOrder,
  CancelCard,
}
