import Axios, { AxiosPromise } from 'axios'
import { Order } from '@fe/common/api/models/Order'
import { RequestRefund } from '@fe/common/api/models/RequestRefund'
import { RequestConfig } from '@fe/common/axiosConfig'

export class OrderApi {
  /**
   * Get Order Information
   * @summary Get Order

   * @param orderUUId
   * @param config
   */
  static getOrder = (
    orderUUId: string,
    config?: RequestConfig
  ): AxiosPromise<Order> =>
    Axios({
      ...config,
      url: `/orders/${orderUUId}`,
      method: 'GET',
    })

  /**
   * Refund Order
   * @summary Refund Order
   * @param {RequestRefund} body Refund Order

   * @param config
   */
  static postOrderRefund = (
    body: RequestRefund,
    config?: RequestConfig
  ): AxiosPromise<Order> =>
    Axios({
      ...config,
      url: '/orders/refund',
      method: 'POST',
      data: body,
    })
}
