import enGB from 'date-fns/locale/en-GB'
import { useTranslation } from 'next-i18next'
import ReactDatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker'
import {
  Controller,
  FieldValues,
  FormState,
  Control,
  useFormContext,
} from 'react-hook-form'
import styled from 'styled-components'
import { COLOR } from '@fe/common/constants/main'
import Calendar from '@fe/common/icons/calendar.svg'
import { Errors } from '@fe/common/types/Errors'
import { getMinEligibleDate } from '@fe/common/utils/dateUtils'
import { getErrorStatus } from '@fe/common/utils/formUtils'
import { InputError } from './InputError'
import {
  StatusBar,
  StyledInput,
  InputContainer,
  Label,
  OptionalTag,
} from './baseInputStyles'
registerLocale('en-GB', enGB)

const Container = styled(InputContainer)`
  :focus-within {
    svg {
      fill: ${COLOR.OFF_BLACK};
    }
  }
`

const CalendarIcon = styled(Calendar).withConfig({
  shouldForwardProp: prop => !['isSmall'].includes(prop as string),
})<{ isSmall: boolean }>`
  position: absolute;
  right: 2.8rem;
  top: ${({ isSmall }) => (isSmall ? '1.5rem' : '2rem')};
  pointer-events: none;
  cursor: pointer;

  fill: ${COLOR.GREY};
  transition: fill 0.2s;
`

const DatepickerLabel = styled(Label)<{ isShown: boolean }>`
  padding: 0;
  background: none;
  opacity: 1;

  > :first-child {
    padding: 0 0.3rem;
    background: ${COLOR.WHITE};
    opacity: ${({ isShown }) => (isShown ? 1 : 0)};
  }

  div:focus-within ~ &,
  div:hover ~ & {
    > :first-child {
      opacity: 1;
    }
  }
`

const DatepickerStatusBar = styled(StatusBar)`
  div:focus-within ~ & {
    width: 1rem;
  }
`

interface DatePickerProps
  extends Omit<ReactDatePickerProps, 'onChange' | 'onBlur'> {
  name: string
  label: string
  placeholder?: string
  isOptional?: boolean
  isFullWidth?: boolean
  isAge?: boolean
  onBlur?: (name?: string) => void
  isDisabled?: boolean
  isWithoutCalendar?: boolean
  autoComplete?: string
  isSmall?: boolean
  isLoading?: boolean
  control?: Control
  errors?: Errors
  formState?: FormState<FieldValues>
  className?: string
}

export const DatePicker: React.FC<DatePickerProps> = ({
  name,
  label,
  placeholder,
  isOptional,
  isFullWidth,
  isAge,
  onBlur,
  isDisabled,
  isWithoutCalendar,
  autoComplete,
  isSmall,
  isLoading,
  className,
  ...rest
}) => {
  const { t } = useTranslation()

  const formContext = useFormContext()

  const handleBlur = (controlOnBlur: () => void) => {
    controlOnBlur()

    if (onBlur) {
      onBlur(name)
    }
  }

  const handleChange = (
    date: Date | [Date, Date],
    e: React.SyntheticEvent<any, Event>,
    onChange: (date: Date | [Date, Date]) => void,
    controlOnBlur: () => void
  ) => {
    e.stopPropagation()

    onChange(date)
    controlOnBlur()

    if (onBlur) {
      onBlur(name)
    }
  }

  const getValue = (value: Date | string) =>
    typeof value === 'string' ? new Date(value) : value

  const errors = rest.errors || formContext?.errors
  const formState = rest.formState || formContext?.formState
  const control = rest.control || formContext?.control

  const { error, isErrorDisplayed, isSuccessfullyFilled } = getErrorStatus(
    name,
    errors,
    formState
  )

  return (
    <Container
      isFullWidth={isFullWidth}
      withError={!!errors}
      isLoading={isLoading}
      className={className}
    >
      <Controller
        name={name}
        control={control}
        render={({ value, onChange, onBlur: controlOnBlur }) => (
          <ReactDatePicker
            selected={getValue(value)}
            showPopperArrow={false}
            openToDate={isAge && getMinEligibleDate()}
            onChange={(date, e) =>
              handleChange(date, e, onChange, controlOnBlur)
            }
            onBlur={() => handleBlur(controlOnBlur)}
            dateFormat={['dd/MM/yyyy', 'dd.MM.yyyy', 'dd.MM.yy']}
            placeholderText={placeholder || label || 'Select a date'}
            customInput={
              <StyledInput
                isDisabled={isDisabled}
                hasError={isErrorDisplayed}
                data-test-id={name}
                isSmall={isSmall}
              />
            }
            autoComplete={autoComplete}
            portalId="datepicker"
            locale="en-GB"
            dropdownMode="select"
            showMonthDropdown
            showYearDropdown
            open={isWithoutCalendar ? false : undefined}
            popperPlacement="bottom-end"
            {...rest}
          />
        )}
      />

      <CalendarIcon isSmall={isSmall} />

      <DatepickerLabel
        htmlFor={name}
        hasError={isErrorDisplayed}
        isShown={!!control.getValues(name)}
      >
        <span>{label}</span>
        {isOptional && <OptionalTag>{t('common:optional')}</OptionalTag>}
      </DatepickerLabel>
      <DatepickerStatusBar
        isSuccessfullyFilled={isSuccessfullyFilled}
        isSmall={isSmall}
      />

      {isErrorDisplayed && <InputError name={name} error={error} />}
    </Container>
  )
}
