import {
  configureStore,
  combineReducers,
  ThunkAction,
  Action,
} from '@reduxjs/toolkit'
import localForage from 'localforage'
import { createWrapper } from 'next-redux-wrapper'
import { useDispatch } from 'react-redux'
import { Persistor, PersistState } from 'redux-persist'
import { PersistConfig } from 'redux-persist/es/types'
import { IS_TESTING } from '@fe/common/constants/env'
import { isServer } from '@fe/common/utils/nextUtils'
import corporateVerificationSlice, {
  initialCorporateVerificationState,
} from './reducers/corporateVerificationReducer'
import exchangeModalSlice, {
  initialExchangeModalState,
} from './reducers/exchangeModalReducer'
import globalDataSlice, {
  initialGlobalDataState,
} from './reducers/globalDataReducer'
import receiveModalSlice, {
  initialReceiveModalState,
} from './reducers/receiveModalReducer'
import sendModalSlice, {
  initialSendModalState,
} from './reducers/sendModalReducer'
import sessionSlice, { initialSessionState } from './reducers/sessionReducer'
import verificationSlice, {
  initialVerificationState,
} from './reducers/verificationReducer'

export const initialState = {
  session: initialSessionState,
  globalData: initialGlobalDataState,
  exchangeModal: initialExchangeModalState,
  receiveModal: initialReceiveModalState,
  sendModal: initialSendModalState,
  verification: initialVerificationState,
  corporateVerification: initialCorporateVerificationState,
}

const rootReducer = combineReducers({
  session: sessionSlice,
  globalData: globalDataSlice,
  exchangeModal: exchangeModalSlice,
  receiveModal: receiveModalSlice,
  sendModal: sendModalSlice,
  verification: verificationSlice,
  corporateVerification: corporateVerificationSlice,
})

export const persistConfig: PersistConfig<AppState> = {
  key: 'store',
  storage: localForage,
  blacklist: ['exchangeModal', 'receiveModal', 'sendModal'],
}

const makeStore = () => {
  if (isServer()) {
    //If it's on server side, create a store
    return configureStore({
      reducer: rootReducer,
      middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
          serializableCheck: false,
          thunk: {
            extraArgument: '',
          },
        }),
    })
  }

  const { persistStore, persistReducer } = require('redux-persist')

  const persistedReducer = persistReducer(persistConfig, rootReducer) // Create a new reducer with our existing reducer

  const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
        thunk: {
          extraArgument: '',
        },
      }),
    devTools: IS_TESTING, // Creating the store again
  })

  store.__persistor = persistStore(store) // This creates a persistor object & push that persisted object to .__persistor, so that we can avail the persistability feature

  return store
}

// Export the wrapper & wrap the pages/_app.js with this wrapper only
export const wrapper = createWrapper<AppStore>(makeStore)

export const useAppDispatch = () => useDispatch<AppDispatch>()

declare module '@reduxjs/toolkit' {
  export interface EnhancedStore {
    __persistor: Persistor
  }
}

// declare module 'redux' {
//   export interface Store {
//     __persistor: Persistor
//   }
// }

export type AppStore = ReturnType<typeof makeStore>
export type AppState = typeof initialState & { _persist?: PersistState }
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action
>

export type AppDispatch = ReturnType<typeof makeStore>['dispatch']
