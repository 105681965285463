import { unwrapResult } from '@reduxjs/toolkit'
import { CancelToken } from 'axios'
import Router from 'next/router'
import { TokenRequest } from '@fe/common/api/models/RequestModels/TokenRequest'
import { VerificationStatusEnum } from '@fe/common/api/models/enums/VerificationStatusEnum'
import { ACCESS_TOKEN_COOKIE_KEY } from '@fe/common/constants/cookies'
import { removeCookie, setCookie } from '@fe/common/utils/cookies'
import { refreshAccountInfo } from './globalDataActions'
import {
  LAST_USED_ACCOUNT_ID,
  LAST_USED_ENTITY_ID,
} from 'src/constants/cookies'
import { flushCorporate } from 'src/state/reducers/corporateVerificationReducer'
import { flushExchangeModal } from 'src/state/reducers/exchangeModalReducer'
import {
  flushGlobalData,
  getAccountDetails,
  getCountries,
  getEntityCards,
  getPendingCardOrders,
  getRecentStatements,
  getRecentTransactions,
} from 'src/state/reducers/globalDataReducer'
import { flushReceiveModal } from 'src/state/reducers/receiveModalReducer'
import { flushSendModal } from 'src/state/reducers/sendModalReducer'
import {
  flushSession,
  login,
  setCurrentAccountId,
  setCurrentEntityId,
  showLogoutWarning,
} from 'src/state/reducers/sessionReducer'
import { flushIndividual } from 'src/state/reducers/verificationReducer'
import { AppDispatch, AppState } from 'src/state/store'

export const flushStore = () => (dispatch: AppDispatch) => {
  dispatch(flushExchangeModal())
  dispatch(flushReceiveModal())
  dispatch(flushSendModal())
  dispatch(flushSession())
  dispatch(flushIndividual())
  dispatch(flushCorporate())
  dispatch(flushGlobalData())
}

export const deauthenticate = () => async (dispatch: AppDispatch) => {
  removeCookie(ACCESS_TOKEN_COOKIE_KEY)

  dispatch(flushStore())
  dispatch(showLogoutWarning(false))

  await Router.push('/login')
}

export const loadGlobalData =
  () => (dispatch: AppDispatch, getState: () => AppState) => {
    dispatch(getCountries())

    const currentEntity = getState().session.entities.find(
      ({ id }) => id === getState().session.currentEntityId
    )

    if (currentEntity.status === VerificationStatusEnum.Verified) {
      dispatch(getAccountDetails())
      dispatch(getRecentTransactions())
      dispatch(getRecentStatements())
      dispatch(getEntityCards())
      dispatch(getPendingCardOrders())
    }
  }

export const authenticate =
  (authData: TokenRequest & { cancelToken: CancelToken }) =>
  async (dispatch: AppDispatch) => {
    await dispatch(login(authData))
      .then(unwrapResult)
      .then(() => dispatch(loadGlobalData()))
  }

export const changeCurrentEntityData =
  (entityId: number, accountId?: number) =>
  (dispatch: AppDispatch, getState: () => AppState) => {
    const {
      session: { currentAccountId, currentEntityId, entities },
      globalData: { accounts },
    } = getState()

    const isCurrentEntity = entityId === currentEntityId
    const isCurrentAccount = accountId === currentAccountId

    setCookie(LAST_USED_ENTITY_ID, String(entityId), 30)
    setCookie(LAST_USED_ACCOUNT_ID, String(accountId), 30)

    const isVerified =
      entities.find(({ id }) => id === entityId).status ===
      VerificationStatusEnum.Verified

    if (entityId && !isCurrentEntity) {
      dispatch(setCurrentEntityId(entityId))

      if (isVerified) {
        dispatch(getEntityCards())
      }
    }

    if (accountId && !isCurrentAccount) {
      dispatch(setCurrentAccountId(accountId))
    }

    if (!accountId) {
      const defaultAccountId = accounts.find(
        ({ entity_id }) => entity_id === entityId
      )?.id

      dispatch(setCurrentAccountId(defaultAccountId))
    }

    if (isVerified) {
      dispatch(refreshAccountInfo())
    }
  }
