import { Rate as TRate } from '@fe/common/api/models/Rate'
import { Amount } from './Amount'

interface RateProps {
  rate: TRate
}

export const Rate: React.FC<RateProps> = ({ rate }) => (
  <span>
    <Amount value={rate.amount} code={rate.currency} />
    {' = '}
    <Amount
      value={rate.rate}
      decimals={rate.rate_precision}
      code={rate.rate_currency}
    />
  </span>
)
