import {
  createSlice,
  createAction,
  createAsyncThunk,
  PayloadAction,
} from '@reduxjs/toolkit'
import { AxiosError, CancelToken } from 'axios'
import cookie from 'js-cookie'
import { HYDRATE } from 'next-redux-wrapper'
import Router from 'next/router'
import { REHYDRATE } from 'redux-persist'
import { Entity } from '@fe/common/api/models/Entity'
import { TokenRequest } from '@fe/common/api/models/RequestModels/TokenRequest'
import { UserSettings } from '@fe/common/api/models/UserSettins'
import { EntityTypeEnum } from '@fe/common/api/models/enums/EntityTypeEnum'
import { VerificationStatusEnum } from '@fe/common/api/models/enums/VerificationStatusEnum'
import { AUTHORIZATION_HEADER } from '@fe/common/constants/headers'
import { getLocalExpireAt } from '@fe/common/src/utils/dateUtils'
import { formatResponseError } from '@fe/common/utils/axiosUtils'
import { setCookie, setTokenCookie } from '@fe/common/utils/cookies'
import { rercordEvent } from '@fe/common/utils/gaUtils'
import { getAccounts } from './globalDataReducer'
import { completeVerification } from './verificationReducer'
import { EntityApi } from 'src/api/EntityApi'
import { SettingsApi } from 'src/api/SettingsApi'
import { TokenApi } from 'src/api/TokenApi'
import {
  LAST_USED_ACCOUNT_ID,
  LAST_USED_ENTITY_ID,
} from 'src/constants/cookies'
import {
  CORPORATE_VERIFICATION,
  DASHBOARD,
  INTERNAL_CORPORATE_VERIFICATION,
  VERIFICATION,
} from 'src/constants/routes'
import { AppState } from 'src/state/store'
import { getIndividualEntity } from 'src/utils/stateUtils'

export const login = createAsyncThunk<
  Pick<
    SessionState,
    | 'accessToken'
    | 'userSettings'
    | 'entities'
    | 'currentEntityId'
    | 'userId'
    | 'expireAt'
    | 'currentAccountId'
  >,
  TokenRequest & { cancelToken: CancelToken },
  {
    rejectValue: { message: string; isMfaError?: boolean }
    state: AppState
  }
>(
  'session/login',
  async (authData, { dispatch, rejectWithValue, getState }) => {
    const { cancelToken, ...tokenRequestData } = authData

    try {
      const {
        data: {
          access_token: accessToken,
          ttl,
          user: { id: userId },
        },
      } = await TokenApi.getToken(tokenRequestData, {
        cancelToken,
      })

      const localExpireAt = getLocalExpireAt(ttl)

      const { data: userSettings } = await SettingsApi.getUserSetting({
        headers: { [AUTHORIZATION_HEADER]: `Bearer ${accessToken}` },
        cancelToken,
      })

      const { data: entities } = await EntityApi.getEntitiesList({
        headers: { [AUTHORIZATION_HEADER]: `Bearer ${accessToken}` },
        cancelToken,
      })

      await dispatch(getAccounts(accessToken))

      const individualEntity = getIndividualEntity(entities)
      const { status: individualVerificationStatus, id: individualEntityId } =
        individualEntity
      const accounts = getState().globalData.accounts

      const defaultAccountId = accounts.find(
        account => account.entity_id === individualEntityId
      )?.id

      const lastUsedEntityId = Number(cookie.get(LAST_USED_ENTITY_ID))
      const lastUsedAccountId = Number(cookie.get(LAST_USED_ACCOUNT_ID))

      if (
        !lastUsedEntityId ||
        !entities.find(({ id }) => id === lastUsedEntityId)
      ) {
        setCookie(LAST_USED_ENTITY_ID, String(individualEntityId), 30)
      }

      if (
        !lastUsedAccountId ||
        !accounts.find(({ id }) => id == lastUsedAccountId)
      ) {
        setCookie(LAST_USED_ACCOUNT_ID, String(defaultAccountId), 30)
      }

      const entityIdToUse = entities.find(({ id }) => id === lastUsedEntityId)
        ? lastUsedEntityId
        : individualEntityId
      const accountIdToUse = accounts.find(({ id }) => id === lastUsedAccountId)
        ? lastUsedAccountId
        : defaultAccountId

      const returnValues = {
        accessToken,
        expireAt: localExpireAt,
        userSettings,
        entities,
        userId,
        currentEntityId: entityIdToUse,
        currentAccountId: accountIdToUse,
      }

      setTokenCookie(accessToken, localExpireAt)
      rercordEvent('login')

      if (Router.query.redirect) {
        Router.push(decodeURI(Router.query.redirect as string))

        return returnValues
      }

      const lastUsedEntity = entities.find(({ id }) => id === lastUsedEntityId)

      const statusToCheck = lastUsedEntityId
        ? lastUsedEntity?.status
        : individualVerificationStatus
      const typeToCheck = lastUsedEntityId
        ? lastUsedEntity?.type
        : EntityTypeEnum.Individual

      const navigateToVerification = () => {
        if (typeToCheck === EntityTypeEnum.Individual) {
          Router.push(VERIFICATION)
        } else {
          Router.push(
            {
              pathname: INTERNAL_CORPORATE_VERIFICATION,
              query: { id: lastUsedEntityId || individualEntityId },
            },
            {
              pathname: CORPORATE_VERIFICATION,
              query: { id: lastUsedEntityId || individualEntityId },
            }
          )
        }
      }

      switch (statusToCheck) {
        case VerificationStatusEnum.New:
          navigateToVerification()

          return returnValues

        case VerificationStatusEnum.Verifying:
          dispatch(completeVerification())

          navigateToVerification()

          return returnValues

        case VerificationStatusEnum.Submitted:
          dispatch(completeVerification())
          navigateToVerification()

          return returnValues

        case VerificationStatusEnum.Blocked:
          if (entities.length === 1) {
            return rejectWithValue({ message: 'Your account has been blocked' })
          }

        default:
          Router.push(DASHBOARD)

          return returnValues
      }
    } catch (err) {
      console.error(err)

      const isMfaError = (err as AxiosError).response.status === 422

      const message = formatResponseError(err)

      return rejectWithValue({ message, isMfaError })
    }
  }
)

export const updateUserSettings = createAsyncThunk<UserSettings>(
  'session/updateUserSettings',
  async () => {
    try {
      const { data: userSettings } = await SettingsApi.getUserSetting()

      return userSettings
    } catch (error) {
      console.error(error)
    }
  }
)

export const createCorporateEntity = createAsyncThunk<
  Entity,
  string,
  {
    rejectValue: string
  }
>(
  'session/createCorporateEntity',
  async (name, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await EntityApi.createEntity({
        name,
      })

      await dispatch(getAccounts(undefined))

      Router.push(
        {
          pathname: '/verification/corporate/[[...step]]',
          query: { id: data.id },
        },
        {
          pathname: `/verification/corporate/1`,
          query: { id: data.id },
        }
      )

      return data
    } catch (err) {
      return rejectWithValue(err.response.data[0].message[0])
    }
  }
)

export const updateEntities = createAsyncThunk<
  Array<Entity>,
  null,
  {
    rejectValue: string
  }
>('session/updateEntities', async (_, { rejectWithValue }) => {
  try {
    const { data: newEntities } = await EntityApi.getEntitiesList()

    return newEntities
  } catch (err) {
    return rejectWithValue(err.response.data[0].message[0])
  }
})

export const refreshToken = createAsyncThunk<
  Pick<SessionState, 'accessToken' | 'expireAt'>,
  null,
  {
    state: AppState
    rejectValue: string
  }
>(
  'session/refreshToken',
  async (_, { rejectWithValue, getState }) => {
    const {
      session: { accessToken, userId },
    } = getState()

    try {
      const {
        data: { access_token: newAccessToken, ttl },
      } = await TokenApi.refreshToken(accessToken, userId)

      const localExpireAt = getLocalExpireAt(ttl)

      setTokenCookie(newAccessToken, localExpireAt)

      return { accessToken, expireAt: localExpireAt }
    } catch (err) {
      return rejectWithValue(err.response.data[0].message[0])
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        session: { isTokenRefreshing },
      } = getState()

      if (isTokenRefreshing) {
        return false
      }
    },
  }
)

const hydrate = createAction<AppState>(HYDRATE)
const rehydrate = createAction<AppState>(REHYDRATE)

export interface SessionState {
  accessToken: string
  expireAt: number
  isLogoutModalShown: boolean
  loginError: string
  isLoginMfaWrong: boolean
  isTokenRefreshing: boolean
  userId: number
  userSettings: UserSettings
  entities: Array<Entity>
  areEntitiesUpdating: boolean
  currentEntityId: number
  currentAccountId: number
}

export const initialSessionState: SessionState = {
  accessToken: '',
  expireAt: null,
  isLogoutModalShown: false,
  loginError: null,
  isLoginMfaWrong: null,
  isTokenRefreshing: false,
  userId: null,
  userSettings: null,
  entities: [],
  areEntitiesUpdating: false,
  currentEntityId: null,
  currentAccountId: null,
}

const sessionSlice = createSlice({
  name: 'session',
  initialState: initialSessionState,
  reducers: {
    clearError(state) {
      state.loginError = null
      state.isLoginMfaWrong = null
    },
    flushSession() {
      return initialSessionState
    },
    showLogoutWarning(state, action: PayloadAction<boolean>) {
      state.isLogoutModalShown = action.payload
    },
    setCurrentEntityId(state, action: PayloadAction<number>) {
      const nextId = action.payload

      state.currentEntityId = nextId
    },
    setCurrentAccountId(state, action: PayloadAction<number>) {
      state.currentAccountId = action.payload
    },
    setTokenData(
      state,
      action: PayloadAction<{ accessToken: string; expireAt: number }>
    ) {
      state.expireAt = action.payload.expireAt
      state.accessToken = action.payload.accessToken
    },
  },
  extraReducers: builder => {
    builder.addCase(login.fulfilled, (state, action) => ({
      ...state,
      accessToken: action.payload.accessToken,
      expireAt: action.payload.expireAt,
      userId: action.payload.userId,
      userSettings: action.payload.userSettings,
      entities: action.payload.entities,
      currentEntityId: action.payload.currentEntityId,
      currentAccountId: action.payload.currentAccountId,
      loginError: null,
    }))
    builder.addCase(login.rejected, (state, action) => ({
      ...state,
      loginError: action.payload.message,
      isLoginMfaWrong: action.payload.isMfaError,
    }))
    builder.addCase(updateUserSettings.fulfilled, (state, action) => ({
      ...state,
      userSettings: action.payload,
    }))
    builder.addCase(createCorporateEntity.fulfilled, (state, action) => ({
      ...state,
      entities: [...state.entities, action.payload],
      currentEntityId: action.payload.id,
    }))
    builder.addCase(updateEntities.pending, state => ({
      ...state,
      areEntitiesUpdating: true,
    }))
    builder.addCase(updateEntities.fulfilled, (state, action) => ({
      ...state,
      entities: action.payload,
      areEntitiesUpdating: false,
    }))
    builder.addCase(updateEntities.rejected, state => ({
      ...state,
      areEntitiesUpdating: false,
    }))
    builder.addCase(refreshToken.fulfilled, (state, action) => ({
      ...state,
      accessToken: action.payload.accessToken,
      expireAt: action.payload.expireAt,
      isTokenRefreshing: false,
      isLogoutModalShown: false,
    }))
    builder.addCase(refreshToken.pending, state => ({
      ...state,
      isTokenRefreshing: true,
    }))
    builder.addCase(refreshToken.rejected, state => ({
      ...state,
      isTokenRefreshing: false,
    }))
    builder.addCase(hydrate, state => state)
    builder.addCase(rehydrate, (_state, action) => {
      if (!action.payload) {
        return
      }

      return {
        ...action.payload?.session,
        isTokenRefreshing: false,
        isLogoutModalShown: false,
      }
    })
  },
})

const { actions, reducer } = sessionSlice

export const {
  clearError,
  flushSession,
  showLogoutWarning,
  setCurrentEntityId,
  setCurrentAccountId,
  setTokenData,
} = actions

export default reducer
