export const capitalizeFirst = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1)

export const formatAddress = (
  address: string,
  zip: string,
  city: string,
  country_id: number,
  countries: Record<number, string>
) =>
  (address ? address + ', ' : '') +
  (zip ? zip + ', ' : '') +
  (city ? city + ', ' : '') +
  (countries[country_id] || '')

export const removeSpaces = (string: string) => string?.replace(/\s+/g, '')

export const formatTestId = (string: string) =>
  string.toLowerCase().replace(/\s/g, '_')

export const formatCardNumber = (cardNumber: string) => {
  let formattedNumber = ''

  cardNumber.split('').forEach((char, index) => {
    if (index % 4 === 0) {
      formattedNumber += ' '
    }

    formattedNumber += char
  })

  return formattedNumber
}
