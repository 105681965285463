import { IS_PRODUCTION, gaTrackingId } from '@fe/common/constants/env'

export const REFERRAL_NAME = 'referral'

const gaId = gaTrackingId

export const recordPageview = (path: string) => {
  if (IS_PRODUCTION) {
    window.gtag('config', gaId, {
      page_path: path,
    })
  }
}

export const rercordEvent = (
  name: Gtag.EventNames,
  params?: Gtag.EventParams
) => {
  if (IS_PRODUCTION) {
    window.gtag('event', name, params)
  }
}
