import Axios, { AxiosPromise } from 'axios'
import { IdentityDocument } from '@fe/common/api/models/IdentityDocument'
import { IdentityDocRequest } from '@fe/common/api/models/RequestModels/IdentityDocRequest'
import { RequestConfig } from '@fe/common/axiosConfig'

export class IdentityDocsApi {
  static uploadDocument(
    documentData: IdentityDocRequest,
    config?: RequestConfig
  ): AxiosPromise<IdentityDocument> {
    return Axios({
      ...config,
      url: `/documents/identity`,
      method: 'POST',
      data: documentData,
    })
  }

  static updateDocument(
    id: number,
    documentData: IdentityDocRequest,
    config?: RequestConfig
  ): AxiosPromise<IdentityDocument> {
    return Axios({
      ...config,
      url: `/documents/identity/${id}`,
      method: 'PUT',
      data: documentData,
    })
  }

  static getDocuments(
    personId: number,
    config?: RequestConfig
  ): AxiosPromise<Array<IdentityDocument>> {
    return Axios({
      ...config,
      url: `/documents/identity/${personId}`,
      method: 'GET',
    })
  }
}
