import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'next-i18next'
import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { ExchangeOrderRequest } from '@fe/common/api/models/RequestModels/ExchangeOrderRequest'
import { MfaActionTypeEnum } from '@fe/common/api/models/enums/MfaActionTypeEnum'
import { Button } from '@fe/common/components/Button'
import { Modal } from '@fe/common/components/Modal'
import { Separator } from '@fe/common/components/Separator'
import { SmartForm } from '@fe/common/components/SmartForm'
import { ButtonContainer } from '@fe/common/components/formStyles'
import { useCancelToken } from '@fe/common/hooks/useCancelToken'
import { Shape } from '@fe/common/src/types/Shape'
import { formatResponseError } from '@fe/common/utils/axiosUtils'
import yup from '@fe/common/yup/yupConfig'
import { useOffer } from '../useOffer'
import { ExchangeAmountSeciton } from './ExchangeAmountSeciton'
import { ExchangeSuccess } from './ExchangeSuccess'
import { ExchangeApi } from 'src/api/ExchangeApi'
import { MfaData, MfaInput } from 'src/components/MfaInput'
import { closeExchangeModal } from 'src/state/reducers/exchangeModalReducer'
import {
  getRecentTransactions,
  updateBalances,
} from 'src/state/reducers/globalDataReducer'
import { ActionTypeEnum } from 'src/types/enums/ActionTypeEnum'

const MfaHeader = styled.h3`
  margin: 1rem 0 3rem;
  text-align: center;
`

const sendSchema = yup.object().shape<Shape<ExchangeData>>({
  amount_in: yup.number().required(),
  amount_out: yup.number().required(),
})

const defaultValues: ExchangeData = { amount_in: null, amount_out: null }

export interface ExchangeData {
  amount_in: number
  amount_out: number
}

export const ExchangeModal: React.FC = () => {
  const { t } = useTranslation('transactions')
  const [mfaData, setMfaData] = useState<MfaData>()
  const [mfaError, setMfaError] = useState('')
  const [isExchanged, setIsExchanged] = useState(false)

  const formMethods = useForm<ExchangeData>({
    resolver: yupResolver(sendSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldUnregister: false,
    defaultValues,
  })

  const { getValues, setValue, setError, clearErrors, reset, formState } =
    formMethods

  const offerMethods = useOffer({
    actionType: ActionTypeEnum.Exchange,
    getValues,
    setValue,
    setError,
    clearErrors,
  })

  const dispatch = useDispatch()
  const cancelToken = useCancelToken()

  const handleModalClose = useCallback(() => dispatch(closeExchangeModal()), [])

  const onSubmit = async () => {
    const orderRequest: ExchangeOrderRequest = {
      offer_id: offerMethods.offer.id,
      ...mfaData,
    }

    try {
      await ExchangeApi.getOrder(orderRequest, { cancelToken })

      reset(defaultValues)
      dispatch(getRecentTransactions())
      dispatch(updateBalances())
      setIsExchanged(true)
    } catch (error) {
      console.error(error)

      setMfaError(formatResponseError(error, t))
    }
  }

  if (isExchanged) {
    return <ExchangeSuccess handleRepeat={() => setIsExchanged(false)} />
  }

  return (
    <Modal
      title={t('exchange')}
      onClose={handleModalClose}
      hasWarningOnClose
      maxWidth="70rem"
    >
      <SmartForm formMethods={formMethods} onSubmit={onSubmit}>
        <ExchangeAmountSeciton offerMethods={offerMethods} />

        <Separator />

        <MfaHeader>{t('confirm-transaction')}</MfaHeader>

        <MfaInput
          actionType={MfaActionTypeEnum.Exchange}
          isFocusedOnMount={false}
          onCodeEnter={setMfaData}
          onRefocusInput={() => setMfaData(undefined)}
          externalError={mfaError}
          isPreparingOnMount={false}
        />

        <ButtonContainer isForModal isCentered>
          <Button
            isSubmit
            isDisabled={!mfaData || !offerMethods.offer}
            isLoading={formState.isSubmitting}
          >
            {t('confirm-exchange')}
          </Button>
        </ButtonContainer>
      </SmartForm>
    </Modal>
  )
}
