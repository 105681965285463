import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import {
  DropdownOption,
  DropdownOptions,
  CustomSelect,
  GroupedOptions,
} from './CustomSelect'
import { FormattedInput, FormattedInputProps } from './FormattedInput'

const Container = styled.div`
  position: relative;
`

const StyledFormattedInput = styled(FormattedInput)`
  input {
    padding-right: 9rem;
  }
`

const Select = styled(CustomSelect)<{
  isSelectDisabled: boolean
  isLoading: boolean
}>`
  && {
    position: absolute;
    right: 0.1rem;
    top: 0.25rem;
    width: 8rem;
    pointer-events: ${({ isSelectDisabled }) => isSelectDisabled && 'none'};

    .react-select__control {
      height: 6rem;
      padding-left: 0;
      padding-right: 1.5rem;
      border: none;
      background-color: transparent;
    }

    .react-select__control--is-disabled {
      background: none;
    }

    .react-select__placeholder {
      display: ${({ isLoading }) => isLoading && 'none'};
    }

    .react-select__dropdown-indicator {
      display: ${({ isSelectDisabled }) => isSelectDisabled && 'none'};
    }
  }
`

interface CurrencyDropdownInputProps
  extends Omit<FormattedInputProps, 'formState' | 'control' | 'errors'> {
  currencyOptions: DropdownOptions | GroupedOptions
  selectedCurrencyId: number
  onCurrencySelect: (value: number) => void
  isLoading?: boolean
}

export const CurrencyDropdownInput: React.FC<CurrencyDropdownInputProps> = ({
  currencyOptions,
  selectedCurrencyId,
  onCurrencySelect,
  isLoading,
  ...inputProps
}) => {
  const { formState, control, errors } = useFormContext()

  return (
    <Container>
      <StyledFormattedInput
        thousandSeparator
        isFloat
        allowNegative={false}
        formState={formState}
        control={control}
        errors={errors}
        {...inputProps}
      />
      <Select
        value={selectedCurrencyId}
        options={currencyOptions}
        onChange={({ value }: DropdownOption) => {
          onCurrencySelect(value as number)
        }}
        styles={{
          menuList: base => ({
            ...base,
            textAlign: 'center',
          }),
        }}
        placeholder="Curr."
        isSelectDisabled={currencyOptions?.length === 1 || isLoading}
        isLoading={isLoading}
        isSearchable={false}
      />
    </Container>
  )
}
