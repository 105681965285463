import { yupResolver } from '@hookform/resolvers/yup'
import { AxiosResponse } from 'axios'
import { Trans, useTranslation } from 'next-i18next'
import { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { CardOrder } from '@fe/common/api/models/CardOrder'
import { Order } from '@fe/common/api/models/Order'
import { PaymentSystem } from '@fe/common/api/models/PaymentSystem'
import { ConfirmCardOrderRequest } from '@fe/common/api/models/RequestModels/ConfirmCardOrderRequest'
import { MtReceiveRequest } from '@fe/common/api/models/RequestModels/MtReceiveRequest'
import { CardOrderStatusEnum } from '@fe/common/api/models/enums/CardOrderStatusEnum'
import { RequestTypeEnum } from '@fe/common/api/models/enums/RequestTypeEnum'
import { TransactionTypeEnum } from '@fe/common/api/models/enums/TransactionTypeEnum'
import { Button } from '@fe/common/components/Button'
import { IframeModal } from '@fe/common/components/IframeModal'
import { Modal } from '@fe/common/components/Modal'
import { Separator } from '@fe/common/components/Separator'
import { ButtonContainer } from '@fe/common/components/formStyles'
import { ErrorText } from '@fe/common/components/typography/ErrorText'
import { useCancelToken } from '@fe/common/hooks/useCancelToken'
import { useDidUpdateLayout } from '@fe/common/hooks/useDidUpdateLayout'
import { Amount } from '@fe/common/src/components/Amount'
import { SmartForm } from '@fe/common/src/components/SmartForm'
import { COLOR, FONT_SIZE, FONT_WEIGHT } from '@fe/common/src/constants/main'
import { formatResponseError } from '@fe/common/utils/axiosUtils'
import { getDefinedFields, jsonEqual } from '@fe/common/utils/objectUtils'
import { poll } from '@fe/common/utils/requestUtils'
import { ActionParams } from '../ActionParams'
import { getDefaultTransactionValues } from '../getDefaultTransactionValues'
import { ReceiveSuccess } from './ReceiveSuccess'
import { BankDetails } from './RequestTypeDetails/BankDetails'
import { CardDetails } from './RequestTypeDetails/CardDetails'
import { CryptoDetails } from './RequestTypeDetails/CryptoDetails'
import { InterbankDetails } from './RequestTypeDetails/InterbankDetails'
import { CashDetails } from './RequestTypeDetails/CashDetails'
import { MtDetails } from './RequestTypeDetails/MtDetails'
import { ReceiveContext, receiveSchema } from './receiveSchema'
import { CardsApi } from 'src/api/CardsApi'
import { DepositsApi } from 'src/api/DepositsApi'
import { MfaData } from 'src/components/MfaInput'
import { AmountSection } from 'src/components/actionModals/AmountSection'
import { useOffer } from 'src/components/actionModals/useOffer'
import { ReceiveStatus } from 'src/components/receiveStatus/ReceiveStatus'
import {
  getPendingCardOrders,
  getRecentTransactions,
  updateBalances,
} from 'src/state/reducers/globalDataReducer'
import { closeReceiveModal } from 'src/state/reducers/receiveModalReducer'
import {
  currencyDetailsSelector,
  detailsLoadingSelector,
} from 'src/state/selectors/globalDataSelectors'
import { selectedReceiveCurrIdSelector } from 'src/state/selectors/receiveModalSelectors'
import { ActionTypeEnum } from 'src/types/enums/ActionTypeEnum'

const StyledActionParams = styled(ActionParams)`
  margin-bottom: 2rem;
`

const MinWarning = styled.p`
  color: ${COLOR.RED};
  font-size: ${FONT_SIZE.SMALL};
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
  text-align: center;
`

export interface CryptoFields {
  crypto_address: string
}

export interface InterbankFields {
  interbank_id: number
}

export interface MtCashFields {
  mt_payment_system_id: number
  transfer_id: number
}

export interface MtCardsFields {
  entity_card_id: number
  csc: string
}

export interface ReceiveData {
  settings_id: number
  request_type: RequestTypeEnum
  amount_in: number
  amount_out?: number
  requestTypeFields:
    | CryptoFields
    | InterbankFields
    | MtCashFields
    | MtCardsFields
}

const partialDefaultValues: Omit<ReceiveData, 'settings_id' | 'request_type'> =
  {
    amount_in: null,
    amount_out: null,
    requestTypeFields: {
      crypto_address: null,
      interbank_id: null,
      mt_payment_system_id: null,
      transfer_id: null,
      entity_card_id: null,
      csc: null,
    },
  }

export const ReceiveModal: React.FC = () => {
  const { t } = useTranslation(['transactions', 'errors'])

  const currencyDetails = useSelector(currencyDetailsSelector, jsonEqual)
  const areDetailsLoading = useSelector(detailsLoadingSelector)
  const prefilledCurrencyId = useSelector(selectedReceiveCurrIdSelector)

  const [targetSettingsId, setTargetSettingsId] = useState<number>()
  const [paymentSystems, setPaymentSystems] = useState<Array<PaymentSystem>>([])
  const [order, setOrder] = useState<Order>()
  const [cardOrder, setCardOrder] = useState<CardOrder>()
  const [bankUrl, setBankUrl] = useState('')
  const [mfaData, setMfaData] = useState<MfaData>()
  const [mfaError, setMfaError] = useState('')
  const [isSent, setIsSent] = useState(false)
  const [error, setError] = useState('')

  const [context, setContext] = useState<ReceiveContext>({
    min_amount_in: null,
    max_amount_in: null,
  })

  const defaultValues = getDefaultTransactionValues<ReceiveData>(
    TransactionTypeEnum.Withdraw,
    currencyDetails,
    prefilledCurrencyId,
    areDetailsLoading,
    partialDefaultValues
  )

  const formMethods = useForm<ReceiveData>({
    resolver: yupResolver(receiveSchema),
    mode: 'onBlur',
    context,
    reValidateMode: 'onBlur',
    shouldUnregister: false,
    defaultValues,
  })

  const {
    watch,
    clearErrors,
    getValues,
    setValue,
    setError: setFormError,
    reset,
    formState,
  } = formMethods

  const { settings_id: selectedCurrencyId, request_type } = watch([
    'settings_id',
    'request_type',
  ])

  const dispatch = useDispatch()
  const cancelToken = useCancelToken()

  const offerMethods = useOffer({
    actionType: ActionTypeEnum.Deposit,
    getValues,
    setValue,
    setError: setFormError,
    clearErrors,
  })

  useDidUpdateLayout(() => {
    if (!selectedCurrencyId && !areDetailsLoading) {
      reset(defaultValues)
    }
  }, [areDetailsLoading])

  const handleModalClose = useCallback(() => dispatch(closeReceiveModal()), [])

  const handleSendComplete = useCallback(() => {
    setMfaData(undefined)
    setIsSent(true)
  }, [])

  const onSubmit = async (formData: ReceiveData) => {
    const { amount_in, requestTypeFields, ...restData } = formData

    const modifiedData = {
      amount: amount_in,
      ...restData,
      ...requestTypeFields,
      ...mfaData,
    }

    const relevantFields = getDefinedFields(modifiedData)

    try {
      switch (request_type) {
        case RequestTypeEnum.Crypto:
        case RequestTypeEnum.Cash:
        case RequestTypeEnum.Interbank:
          {
            const { data } = await DepositsApi.getOrder(offerMethods.offer.id, {
              cancelToken,
            })

            setOrder(data)
          }

          break

        case RequestTypeEnum.MtCash:
          const mt_payment_system_name = paymentSystems.find(
            ({ id }) =>
              id === (requestTypeFields as MtCashFields).mt_payment_system_id
          ).name

          const mtFields = {
            ...relevantFields,
            mt_payment_system_name,
          }

          await DepositsApi.receiveMt(mtFields as MtReceiveRequest, {
            cancelToken,
          })

          handleSendComplete()

          break

        case RequestTypeEnum.MtCards:
          {
            const { data: order } = await CardsApi.confirmCardDepositOrder(
              {
                order_id: cardOrder.id,
                ...relevantFields,
              } as ConfirmCardOrderRequest,
              {
                cancelToken,
              }
            )

            if (order.url_3d) {
              setBankUrl(order.url_3d)
            }

            const validateOrder = ({
              data: { status },
            }: AxiosResponse<CardOrder>) =>
              status === CardOrderStatusEnum.Accepted ||
              status === CardOrderStatusEnum.Rejected

            const {
              data: { status },
            } = await poll({
              requestFn: () => CardsApi.getOrderInfo(order.id, { cancelToken }),
              validate: validateOrder,
            })

            if (status === CardOrderStatusEnum.Rejected) {
              setError(t('errors:card-rejected'))
            }

            dispatch(getPendingCardOrders())
            setMfaData(undefined)
            setIsSent(true)
          }

          break
      }

      reset(defaultValues)
      dispatch(getRecentTransactions())
      dispatch(updateBalances())
    } catch (error) {
      setMfaError(formatResponseError(error, t))

      console.error(formatResponseError(error, t))
    }
  }

  const currentCurrencyDetails = useMemo(
    () => currencyDetails?.find(({ id }) => id === selectedCurrencyId),
    [currencyDetails, selectedCurrencyId]
  )

  const isSendAs = targetSettingsId !== currentCurrencyDetails?.id

  // Depending on request_type, use appropriate component to render in Receive modal,
  // after selecting currency and method (request type)
  const receiveDetails = useMemo(() => {
    switch (request_type) {
      case RequestTypeEnum.WireTransfer:
      case RequestTypeEnum.Sepa:
      case RequestTypeEnum.CzkPayments:
      case RequestTypeEnum.Internal:
        return <BankDetails />

      case RequestTypeEnum.Crypto:
        return <CryptoDetails currentCurrencyDetails={currentCurrencyDetails} />

      case RequestTypeEnum.Cash:
        return <CashDetails />

      case RequestTypeEnum.Interbank:
        return <InterbankDetails />

      case RequestTypeEnum.MtCash:
        return (
          <MtDetails
            isMfaFilled={!!mfaData}
            setMfaData={setMfaData}
            setPaymentSystems={setPaymentSystems}
            mfaError={mfaError}
          />
        )

      case RequestTypeEnum.MtCards:
        return (
          <CardDetails
            order={cardOrder}
            setOrder={setCardOrder}
            currentCurrencyCode={currentCurrencyDetails.code}
            isMfaFilled={!!mfaData}
            setMfaData={setMfaData}
            mfaError={mfaError}
          />
        )
    }
  }, [
    cardOrder,
    request_type,
    targetSettingsId,
    currentCurrencyDetails,
    mfaData,
    mfaError,
  ])

  if (bankUrl) {
    return (
      <IframeModal
        title={t('verify-transaction')}
        frameSrc={bankUrl}
        onClose={handleModalClose}
      />
    )
  }

  if (isSent) {
    return <ReceiveSuccess handleRepeat={() => setIsSent(false)} />
  }

  // If Order was created, render Order details
  if (order) {
    return (
      <Modal
        title={t('receive-status')}
        onClose={handleModalClose}
        maxWidth="70rem"
      >
        <ReceiveStatus initialOrder={order} />
      </Modal>
    )
  }

  return (
    <Modal
      title={t('receive-money')}
      onClose={handleModalClose}
      maxWidth="70rem"
      hasWarningOnClose
    >
      <SmartForm formMethods={formMethods} onSubmit={onSubmit}>
        <StyledActionParams
          partialDefaultValues={partialDefaultValues}
          isDisabled={!!cardOrder}
        />

        {!cardOrder && (
          <AmountSection
            setTargetSettingsId={setTargetSettingsId}
            transactionType={TransactionTypeEnum.Deposit}
            offerMethods={offerMethods}
            setContext={setContext}
          />
        )}

        {context.min_amount_in && request_type !== RequestTypeEnum.MtCards && (
          <MinWarning>
            <Trans
              t={t}
              i18nKey="min-warning"
              components={{
                amount: (
                  <Amount
                    value={context.min_amount_in}
                    code={currentCurrencyDetails.code}
                    decimals={currentCurrencyDetails.precision}
                  />
                ),
              }}
            />
          </MinWarning>
        )}

        {
          // If Send and Receive currencies are differs, show Accept Offer button, to proceed with Order creation
          isSendAs ? (
            <ButtonContainer isForModal isCentered>
              <Button
                isSubmit
                isDisabled={!offerMethods.offer}
                isLoading={formState.isSubmitting}
              >
                {t('accept-offer')}
              </Button>
            </ButtonContainer>
          ) : (
            <>
              {!cardOrder && <Separator />}

              {receiveDetails}
            </>
          )
        }
      </SmartForm>

      {error && (
        <ErrorText isAbsolute isForModal>
          {error}
        </ErrorText>
      )}
    </Modal>
  )
}
