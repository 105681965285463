import Axios, { AxiosPromise } from 'axios'
import { PersonRequest } from '@fe/common/api/models/RequestModels/PersonRequest'
import { Shareholder } from '@fe/common/api/models/Shareholder'
import { RequestConfig } from '@fe/common/axiosConfig'

export class ShareholdersApi {
  // creates a person too
  static createShareholder(
    shareholder: Omit<Shareholder, 'id' | 'person_id'> & PersonRequest,
    config?: RequestConfig
  ): AxiosPromise<Shareholder> {
    return Axios({
      ...config,
      url: `/entities/shareholders`,
      method: 'POST',
      data: shareholder,
    })
  }

  // creates a shareholder with an existing person
  static linkCreateShareholderForPerson(
    shareholder: Omit<Shareholder, 'id'>,
    config?: RequestConfig
  ): AxiosPromise<Array<Shareholder>> {
    return Axios({
      ...config,
      url: `/entities/shareholders/person/${shareholder.entity_id}/${shareholder.person_id}`,
      method: 'POST',
      data: shareholder,
    })
  }

  static updateShareholder(
    id: number,
    shareholder: Pick<
      Shareholder,
      'share' | 'director' | 'has_nominee' | 'is_nominee'
    >,
    config?: RequestConfig
  ): AxiosPromise<Shareholder> {
    return Axios({
      ...config,
      url: `/entities/shareholders/${id}`,
      method: 'PUT',
      data: shareholder,
    })
  }

  static getShareholders(
    entityId: number,
    config?: RequestConfig
  ): AxiosPromise<Array<Shareholder>> {
    return Axios({
      ...config,
      url: `/entities/shareholders/entity/${entityId}`,
      method: 'GET',
    })
  }
}
