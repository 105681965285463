import { ReactText, useState } from 'react'
import styled, { css } from 'styled-components'
import { opacityReveal } from '@fe/common/constants/animations'
import { COLOR } from '@fe/common/constants/main'
import Check from '@fe/common/src/icons/check.svg'
import Clipboard from '@fe/common/src/icons/clipboard.svg'
import useDeviceType from '../hooks/useDeviceType'

interface ClipboardStyleProps {
  isAbsolute?: boolean
}

const ClipboardIcon = styled(Clipboard).withConfig<ClipboardStyleProps>({
  shouldForwardProp: prop => 'isAbsolute' !== prop,
})<ClipboardStyleProps>`
  display: none;
  margin: 0 0 0.1rem 1rem;

  ${({ isAbsolute }) =>
    isAbsolute &&
    css`
      position: absolute;
      top: 0.2rem;
      right: 0;
    `};
`

const CheckIcon = styled(Check).withConfig<ClipboardStyleProps>({
  shouldForwardProp: prop => 'isAbsolute' !== prop,
})<ClipboardStyleProps>`
  width: 1.2rem;
  height: 1.2rem;
  margin: 0 0 0.3rem 1rem;
  color: ${COLOR.GREY};
  animation: ${opacityReveal} 0.2s ease-in-out;

  ${({ isAbsolute }) =>
    isAbsolute &&
    css`
      position: absolute;
      top: 0.2rem;
      right: 0;
    `};
`

const Container = styled.span<ClipboardStyleProps>`
  display: ${({ isAbsolute }) => (isAbsolute ? 'block' : 'flex')} !important;
  align-items: center;
  position: relative;
  padding-right: 2rem;
  cursor: pointer;

  :hover {
    ${ClipboardIcon} {
      display: flex;
    }
  }
`

interface ClipboardCopyProps {
  text: ReactText
  className?: string
  isAbsolute?: boolean
}

export const ClipboardCopy: React.FC<ClipboardCopyProps> = ({
  text,
  children,
  className,
  isAbsolute,
}) => {
  const [isClicked, setIsClicked] = useState(false)

  const { isMobile } = useDeviceType()

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isMobile) {
      return
    }

    e.stopPropagation()

    navigator.clipboard.writeText(text.toString())

    setIsClicked(true)
  }

  return (
    <Container
      onClick={handleClick}
      className={className}
      isAbsolute={isAbsolute}
      onMouseLeave={() => setIsClicked(false)}
    >
      {children}

      {isClicked ? (
        <CheckIcon isAbsolute={isAbsolute} />
      ) : (
        <ClipboardIcon isAbsolute={isAbsolute} />
      )}
    </Container>
  )
}
