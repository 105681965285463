import Axios, { AxiosPromise } from 'axios'
import { InternalLiabilityStatus } from '@fe/common/api/models/InternalLiabilityStatus'
import { RequestConfig } from '@fe/common/axiosConfig'
export class ValidationApi {
  static cryptoAddressByCode(
    currency_code: string,
    address: string,
    config?: RequestConfig
  ): AxiosPromise<null> {
    return Axios({
      ...config,
      url: `/validate/crypto-address-by-code`,
      method: 'GET',
      params: {
        currency_code,
        address,
      },
    })
  }

  static iban(iban: string, config?: RequestConfig): AxiosPromise<null> {
    return Axios({
      ...config,
      url: `/validate/iban`,
      method: 'GET',
      params: {
        iban,
      },
    })
  }

  static bicSwift(
    bicSwift: string,
    config?: RequestConfig
  ): AxiosPromise<null> {
    return Axios({
      ...config,
      url: `/validate/bic`,
      method: 'GET',
      params: {
        bic: bicSwift,
      },
    })
  }

  static czeNumber(
    cze_number: string,
    config?: RequestConfig
  ): AxiosPromise<null> {
    return Axios({
      ...config,
      url: `/validate/cze-number`,
      method: 'GET',
      params: {
        cze_number,
      },
    })
  }

  static czeCode(cze_code: string, config?: RequestConfig): AxiosPromise<null> {
    return Axios({
      ...config,
      url: `/validate/cze-code`,
      method: 'GET',
      params: {
        cze_code,
      },
    })
  }

  static internalNumber(
    settings_id: number,
    number: string,
    config?: RequestConfig
  ): AxiosPromise<InternalLiabilityStatus> {
    return Axios({
      ...config,
      url: `/validate/internal-number`,
      method: 'GET',
      params: {
        settings_id,
        number,
      },
    })
  }

  static cryptoAddress(
    settings_id: number,
    address: string,
    config?: RequestConfig
  ): AxiosPromise<null> {
    return Axios({
      ...config,
      url: `/validate/crypto-address`,
      method: 'GET',
      params: {
        settings_id,
        address,
      },
    })
  }

  static cardNumber(
    card_number: string,
    config?: RequestConfig
  ): AxiosPromise<null> {
    return Axios({
      ...config,
      url: `/validate/card-number`,
      method: 'GET',
      params: {
        card_number,
      },
    })
  }
}
