import { TFunction } from 'next-i18next'
import { AccountCurrency } from '@fe/common/api/models/AccountCurrency'
import { BaseCurrency } from '@fe/common/api/models/BaseCurrency'
import { MfaSetting } from '@fe/common/api/models/MfaSetting'
import { CompanyDocTypeEnum } from '@fe/common/api/models/enums/CompanyDocTypeEnum'
import { GenderEnum } from '@fe/common/api/models/enums/GenderEnum'
import { IdentityDocTypeEnum } from '@fe/common/api/models/enums/IdentityDocTypeEnum'
import { RequestStatusEnum } from '@fe/common/api/models/enums/RequestStatusEnum'
import { RequestTypeEnum } from '@fe/common/api/models/enums/RequestTypeEnum'
import { TransactionTypeEnum } from '@fe/common/api/models/enums/TransactionTypeEnum'
import {
  mfaLabels,
  requestStatusNames,
  requestTypeNames,
  transactionTypeNames,
} from '@fe/common/constants/enums'
import { getSymbolFromCurrency } from '@fe/common/utils/currencyUtils'
import { getEnumValues } from '@fe/common/utils/enumUtils'
import { getAmountFormatter } from '@fe/common/utils/formatterUtils'
import { DropdownOptions } from '../components/inputs/CustomSelect'
import { LocaleName } from '../types/LocaleName'

export const getGenderOptions = (t: TFunction) => [
  { label: t('common:female'), value: GenderEnum.Female },
  { label: t('common:male'), value: GenderEnum.Male },
]

export const booleanOptions = [
  { label: 'common:yes', value: 1 },
  { label: 'common:no', value: 0 },
]

export const getCompanyDocumentOptions = (
  t: TFunction
): DropdownOptions<CompanyDocTypeEnum> => [
  {
    label: t('inc-crtificate'),
    value: CompanyDocTypeEnum.CertificateOfIncorporation,
  },
  {
    label: t('memorandum'),
    value: CompanyDocTypeEnum.MemorandumOfAssociation,
  },
  {
    label: t('share-certificate'),
    value: CompanyDocTypeEnum.ShareCertificate,
  },
  {
    label: t('incumbency-certificate'),
    value: CompanyDocTypeEnum.CertificateOfIncumbency,
  },
  {
    label: t('attorney-power'),
    value: CompanyDocTypeEnum.PowerOfAttorney,
  },
  {
    label: t('minutes-meeting'),
    value: CompanyDocTypeEnum.MinutesOfTheFirstMeeting,
  },
  {
    label: t('register-certificate'),
    value: CompanyDocTypeEnum.CommercialRegisterCertificate,
  },
  {
    label: t('director-appointment'),
    value: CompanyDocTypeEnum.AppointmentOfFirstDirector,
  },
  {
    label: t('register-of-directors'),
    value: CompanyDocTypeEnum.RegisterOfDirectors,
  },
  {
    label: t('register-of-members'),
    value: CompanyDocTypeEnum.RegisterOfMembers,
  },
  {
    label: t('register-of-shareholders'),
    value: CompanyDocTypeEnum.RegisterOfShareholders,
  },
  {
    label: t('corporate-application'),
    value: CompanyDocTypeEnum.CorporateApplication,
  },
  {
    label: t('bank-statement'),
    value: CompanyDocTypeEnum.BankStatement,
  },
  {
    label: t('standing-certificate'),
    value: CompanyDocTypeEnum.CertificateOfGoodStanding,
  },
  {
    label: t('certificate-of-collation'),
    value: CompanyDocTypeEnum.CertificateOfCollation,
  },
  {
    label: t('change-of-director'),
    value: CompanyDocTypeEnum.ChangeOfDirector,
  },
  {
    label: t('change-of-shareholder'),
    value: CompanyDocTypeEnum.ChangeOfShareholder,
  },
  {
    label: t('aml-policy'),
    value: CompanyDocTypeEnum.AmlPolicy,
  },
  {
    label: t('licence'),
    value: CompanyDocTypeEnum.Licence,
  },
  {
    label: t('legal-opinion'),
    value: CompanyDocTypeEnum.LegalOpinion,
  },
  {
    label: t('change-of-company-name'),
    value: CompanyDocTypeEnum.ChangeOfCompanyName,
  },
  {
    label: t('tax-declaration'),
    value: CompanyDocTypeEnum.TaxDeclaration,
  },
  {
    label: t('audit-report'),
    value: CompanyDocTypeEnum.AuditReport,
  },
  {
    label: t('office-registration-certificate'),
    value: CompanyDocTypeEnum.OfficeRegistrationCertificate,
  },
  {
    label: t('register-of-ubos'),
    value: CompanyDocTypeEnum.RegisterOfUBOs,
  },

  {
    label: t('common:other'),
    value: CompanyDocTypeEnum.Other,
  },
]

export const getRequestTypeOptions = (
  t: TFunction,
  isCrypto?: boolean
): Array<{
  label: string
  value: number
}> =>
  getEnumValues(RequestTypeEnum)
    .map(value => ({
      label: t(requestTypeNames[value as RequestTypeEnum]),
      value: value as number,
    }))
    .filter(option => {
      if (
        isCrypto !== undefined &&
        !isCrypto &&
        option.value === RequestTypeEnum.Crypto
      ) {
        return false
      }

      return true
    })

export const getRequestStatusOptions = (t: TFunction) =>
  getEnumValues(RequestStatusEnum).map(value => ({
    label: t(requestStatusNames[value as RequestStatusEnum]),
    value: value,
  }))

export const getCurrencyOptions = <T extends Omit<BaseCurrency, 'precision'>>(
  currencies: Array<T>,
  withSymbol = true
) =>
  currencies.map(({ name, code, id }) => {
    let label = name

    if (withSymbol) {
      label = label + ` (${getSymbolFromCurrency(code)})`
    }

    return {
      label,
      value: id,
    }
  })

export const getCurrencyWithBalanceOptions = (
  currencies: Array<AccountCurrency>
) =>
  currencies.map(({ name, code, id, balances: { available } }) => {
    const formatter = getAmountFormatter(available.precision)

    const amount = formatter.format(available.amount)

    return {
      label: `${name} (${getSymbolFromCurrency(code) || code}${amount})`,
      value: id,
    }
  })

export const getTransactionTypeOptions = (t: TFunction) =>
  getEnumValues(TransactionTypeEnum).map(value => ({
    label: t(transactionTypeNames[value as TransactionTypeEnum]),
    value: value,
  }))

export const getIdDocTypeOptions = (t: TFunction) => [
  {
    label: t('passport'),
    value: IdentityDocTypeEnum.Passport,
  },
  {
    label: t('national-id'),
    value: IdentityDocTypeEnum.NationalId,
  },
  {
    label: t('residence-id'),
    value: IdentityDocTypeEnum.ResidenceId,
  },
  {
    label: t('driving-licence'),
    value: IdentityDocTypeEnum.DrivingLicence,
  },
]

export const getMfaTypeOptions = (
  mfaSettings: Array<MfaSetting>,
  t: TFunction
) =>
  mfaSettings.map(({ mfa_type }) => ({
    value: mfa_type,
    label: t(`common:${mfaLabels[mfa_type]}`),
  }))

export const languageOptions: DropdownOptions<LocaleName> = [
  { label: 'English', value: 'en' },
  { label: 'Český', value: 'cs' },
  // { label: 'Русский', value: 'ru' },
]
