import { MfaActionTypeEnum } from '@fe/common/api/models/enums/MfaActionTypeEnum'
import { MfaData, MfaInput } from 'src/components/MfaInput'

interface MfaStepProps {
  setMfaData: React.Dispatch<React.SetStateAction<MfaData>>
  errorMessage: string
  isOrder: boolean
}

export const MfaStep: React.FC<MfaStepProps> = ({
  setMfaData,
  errorMessage,
  isOrder,
}) => (
  <MfaInput
    actionType={
      isOrder ? MfaActionTypeEnum.Exchange : MfaActionTypeEnum.RequestCreate
    }
    onCodeEnter={setMfaData}
    onRefocusInput={() => setMfaData(undefined)}
    externalError={errorMessage}
  />
)
