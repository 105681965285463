import { useTranslation } from 'next-i18next'
import { useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { shallowEqual, useSelector } from 'react-redux'
import styled from 'styled-components'
import { PaymentSystem } from '@fe/common/api/models/PaymentSystem'
import { MfaActionTypeEnum } from '@fe/common/api/models/enums/MfaActionTypeEnum'
import { Button } from '@fe/common/components/Button'
import { ButtonContainer } from '@fe/common/components/formStyles'
import { DropdownOptions } from '@fe/common/components/inputs/CustomSelect'
import { Dropdown } from '@fe/common/components/inputs/Dropdown'
import { Input } from '@fe/common/components/inputs/Input'
import { SCREEN } from '@fe/common/constants/main'
import { formatDataToOptions } from '@fe/common/utils/arrayUtils'
import { formatResponseError } from '@fe/common/utils/axiosUtils'
import { ReceiveData } from '..'
import { DepositsApi } from 'src/api/DepositsApi'
import { MfaData, MfaInput } from 'src/components/MfaInput'
import { currentAccountSelector } from 'src/state/selectors/globalDataSelectors'

const MtInfoContainer = styled.div`
  ${SCREEN.ABOVE_MOBILE} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 2rem;
  }
`

const MfaHeader = styled.h3`
  margin: 1rem 0 3rem;
  text-align: center;
`

export interface MfaProps {
  isMfaFilled: boolean
  setMfaData: React.Dispatch<React.SetStateAction<MfaData>>
  mfaError: string
}

interface MtDetailsProps extends MfaProps {
  setPaymentSystems: React.Dispatch<React.SetStateAction<Array<PaymentSystem>>>
}

export const MtDetails: React.FC<MtDetailsProps> = ({
  isMfaFilled,
  setMfaData,
  mfaError,
  setPaymentSystems,
}) => {
  const { t } = useTranslation('transactions')
  const { id: currenctAccId } = useSelector(
    currentAccountSelector,
    shallowEqual
  )
  const [paymentSystemsOptions, setPaymentSystemsOptions] =
    useState<DropdownOptions>([])
  const [isLoading, setIsLoading] = useState(false)

  const { formState, errors, control, register } = useFormContext<ReceiveData>()

  const fetchPaymentSystems = useCallback(async () => {
    try {
      setIsLoading(true)
      const { data } = await DepositsApi.getPaymentSystems(currenctAccId)

      const systemOptions = formatDataToOptions(data)

      setPaymentSystems(data)
      setPaymentSystemsOptions(systemOptions)
    } catch (error) {
      console.error(formatResponseError(error, t))
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchPaymentSystems()
  }, [])

  return (
    <>
      <MtInfoContainer>
        <Dropdown
          name="requestTypeFields.mt_payment_system_id"
          label={t('payment-system')}
          options={paymentSystemsOptions}
          isFullWidth
          isLoading={isLoading}
          formState={formState}
          errors={errors}
          control={control}
        />

        <Input
          name="requestTypeFields.transfer_id"
          label={t('transfer-id')}
          isFullWidth
          formState={formState}
          errors={errors}
          ref={register}
        />
      </MtInfoContainer>

      <MfaHeader>{t('confirm-transaction')}</MfaHeader>

      <MfaInput
        actionType={MfaActionTypeEnum.Receive}
        isFocusedOnMount={false}
        onCodeEnter={setMfaData}
        onRefocusInput={() => setMfaData(undefined)}
        externalError={mfaError}
        isPreparingOnMount={false}
      />

      <ButtonContainer isForModal isCentered>
        <Button
          isSubmit
          isDisabled={!isMfaFilled}
          isLoading={formState.isSubmitting}
        >
          {t('receive')}
        </Button>
      </ButtonContainer>
    </>
  )
}
