import Axios, { AxiosPromise } from 'axios'
import { AddressDocument } from '@fe/common/api/models/AddressDocument'
import { AddressDocRequest } from '@fe/common/api/models/RequestModels/AddressDocRequest'
import { RequestConfig } from '@fe/common/axiosConfig'

export class AddressDocsApi {
  static uploadDocument(
    documentData: AddressDocRequest,
    config?: RequestConfig
  ): AxiosPromise<AddressDocument> {
    return Axios({
      ...config,
      url: `/documents/address`,
      method: 'POST',
      data: documentData,
    })
  }

  static updateDocument(
    id: number,
    documentData: AddressDocRequest,
    config?: RequestConfig
  ): AxiosPromise<AddressDocument> {
    return Axios({
      ...config,
      url: `/documents/address/${id}`,
      method: 'PUT',
      data: documentData,
    })
  }

  static getDocuments(
    personId: number,
    config?: RequestConfig
  ): AxiosPromise<Array<AddressDocument>> {
    return Axios({
      ...config,
      url: `/documents/address/${personId}`,
      method: 'GET',
    })
  }
}
