import Axios, { AxiosPromise } from 'axios'
import { CardOrder } from '@fe/common/api/models/CardOrder'
import { CardVerificationStatus } from '@fe/common/api/models/CardVerificationStatus'
import { EntityCard } from '@fe/common/api/models/EntityCard'
import { CancelCardRequest } from '@fe/common/api/models/RequestModels/CancelCardRequest'
import { CardOrderRequest } from '@fe/common/api/models/RequestModels/CardOrderRequest'
import { ConfirmCardOrderRequest } from '@fe/common/api/models/RequestModels/ConfirmCardOrderRequest'
import { SaveCardRequest } from '@fe/common/api/models/RequestModels/SaveCardRequest'
import { CardOrderTypeEnum } from '@fe/common/api/models/enums/CardOrderTypeEnum'
import { RequestConfig } from '@fe/common/axiosConfig'

export class CardsApi {
  static getCards = (
    entityId: number,
    config?: RequestConfig
  ): AxiosPromise<{ cards: Array<EntityCard> }> =>
    Axios({
      ...config,
      url: `/cards/${entityId}`,
      method: 'GET',
    })

  static getCardOrders = (
    accountId: number,
    orderType: CardOrderTypeEnum,
    page?: number,
    config?: RequestConfig
  ): AxiosPromise<Array<CardOrder>> =>
    Axios({
      ...config,
      url: `/cards/orders/${accountId}/${orderType}`,
      method: 'GET',
      params: {
        page,
      },
    })

  static saveCard = (
    cardData: SaveCardRequest,
    config?: RequestConfig
  ): AxiosPromise<{ card: EntityCard; order: CardOrder }> =>
    Axios({
      ...config,
      url: `/cards/save`,
      method: 'POST',
      data: cardData,
    })

  static createVerificationOrder = (
    entity_card_id: number,
    config?: RequestConfig
  ): AxiosPromise<{ card: EntityCard; order: CardOrder }> =>
    Axios({
      ...config,
      url: `/cards/verification/start`,
      method: 'POST',
      data: { entity_card_id },
    })

  static confirmVerification = (
    data: ConfirmCardOrderRequest,
    config?: RequestConfig
  ): AxiosPromise<CardOrder> =>
    Axios({
      ...config,
      url: `/cards/order/deposit/confirm`,
      method: 'POST',
      data,
    })

  static verifyCard = (
    entity_card_id: number,
    verification_code: string,
    config?: RequestConfig
  ): AxiosPromise<CardVerificationStatus> =>
    Axios({
      ...config,
      url: `/cards/verification/complete`,
      method: 'POST',
      data: { entity_card_id, verification_code },
    })

  static updateCardName = (
    cardId: number,
    card_name: string,
    config?: RequestConfig
  ): AxiosPromise<EntityCard> =>
    Axios({
      ...config,
      url: `/cards/${cardId}`,
      method: 'PUT',
      data: { card_name },
    })

  static cancelCard = (
    data: CancelCardRequest,
    config?: RequestConfig
  ): AxiosPromise<EntityCard> =>
    Axios({
      ...config,
      url: `/cards/cancel`,
      method: 'POST',
      data,
    })

  static cancelOrder = (
    order_id: number,
    config?: RequestConfig
  ): AxiosPromise<CardOrder> =>
    Axios({
      ...config,
      url: `/cards/order/cancel`,
      method: 'POST',
      data: { order_id },
    })

  static createCardDepositOrder(
    data: CardOrderRequest,
    config?: RequestConfig
  ): AxiosPromise<CardOrder> {
    return Axios({
      ...config,
      url: `/cards/order/deposit`,
      method: 'POST',
      data,
    })
  }

  static confirmCardDepositOrder(
    data: ConfirmCardOrderRequest,
    config?: RequestConfig
  ): AxiosPromise<CardOrder> {
    return Axios({
      ...config,
      url: `/cards/order/deposit/confirm`,
      method: 'POST',
      data,
    })
  }

  static getOrderInfo = (
    orderId: number,
    config?: RequestConfig
  ): AxiosPromise<CardOrder> =>
    Axios({
      ...config,
      url: `/cards/order/${orderId}`,
      method: 'GET',
    })
}
