import { useTranslation } from 'next-i18next'
import { useDispatch } from 'react-redux'
import { Button } from '@fe/common/components/Button'
import { SuccessModal } from '@fe/common/components/SuccessModal'
import { closeReceiveModal } from 'src/state/reducers/receiveModalReducer'

interface ReceiveSuccessProps {
  handleRepeat: () => void
}

export const ReceiveSuccess: React.FC<ReceiveSuccessProps> = ({
  handleRepeat,
}) => {
  const { t } = useTranslation('transactions')

  const dispatch = useDispatch()

  const handleClose = () => dispatch(closeReceiveModal())

  return (
    <SuccessModal
      successText={t('received-successfully')}
      handleClose={handleClose}
    >
      <Button isSecondary onClick={handleRepeat} testId="repeat_button">
        {t('another-transaction')}
      </Button>

      <Button onClick={handleClose} testId="close_button">
        {t('common:close')}
      </Button>
    </SuccessModal>
  )
}
