import { useTranslation } from 'next-i18next'
import styled, { keyframes } from 'styled-components'
import { ErrorText } from '@fe/common/components/typography/ErrorText'
import { FONT_WEIGHT } from '@fe/common/constants/main'
import { Error } from '@fe/common/types/Errors'
import { getErrorData } from '@fe/common/utils/formUtils'

const messageReveal = keyframes`
  0% {
    transform: translateY(-1rem);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
`

export const Container = styled(ErrorText)`
  position: absolute;
  bottom: 0.8rem;
  left: 1rem;
  text-align: start;
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
  animation: ${messageReveal} 0.2s ease-out;
`

interface InputErrorProps {
  name: string
  error: Error
}

export const InputError: React.FC<InputErrorProps> = ({ name, error }) => {
  const { t } = useTranslation('errors')

  const { type, message, params } = getErrorData(error)

  return (
    <Container data-test-id={`${name}_${type}_error`}>
      {t(message, { params })}
    </Container>
  )
}
