import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { Button } from '@fe/common/components/Button'
import {
  BORDER,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
} from '@fe/common/constants/main'
import CheckSuccess from '@fe/common/src/icons/check-success.svg'
import { DASHBOARD, RECEIVE_STATUS } from 'src/constants/routes'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border-bottom: ${BORDER.DEFAULT};
`

const SuccessText = styled.div`
  margin: ${PADDING.DESKTOP}rem 0;
  font-size: ${FONT_SIZE.LARGE};
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
`

const StyledButton = styled(Button)`
  width: 80%;
  max-width: 35rem;
  margin: ${PADDING.MOBILE}rem;
`

export const RefundSuccess = () => {
  const [t] = useTranslation('receive-status')
  const router = useRouter()

  const handleButtonClick = () => {
    if (router.pathname === RECEIVE_STATUS) {
      router.push(DASHBOARD)

      return
    }
  }

  return (
    <Container>
      <Content>
        <CheckSuccess />

        <SuccessText>{t('refund-requested-text')}</SuccessText>
      </Content>

      <StyledButton onClick={handleButtonClick}>
        {t('refund-requested-button')}
      </StyledButton>
    </Container>
  )
}
