import styled, { css } from 'styled-components'
import { COLOR, FONT_SIZE } from '@fe/common/constants/main'

export const ErrorText = styled.span<{
  isAbsolute?: boolean
  isForModal?: boolean
}>`
  ${({ isAbsolute, isForModal }) =>
    (isAbsolute || isForModal) &&
    css`
      position: absolute;
      left: 0;
      bottom: ${isForModal ? '0.7rem' : '1.5rem'};
    `};

  width: 100%;
  color: ${COLOR.RED};
  font-size: ${FONT_SIZE.EXTRA_SMALL};
  text-align: center;
`
