import { Trans, useTranslation } from 'next-i18next'
import { useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import { InterbankDepositDetails } from '@fe/common/api/models/InterbankDepositDetails'
import { Spinner } from '@fe/common/components/Spinner'
import { DropdownOptions } from '@fe/common/components/inputs/CustomSelect'
import { Dropdown } from '@fe/common/components/inputs/Dropdown'
import { FONT_WEIGHT } from '@fe/common/constants/main'
import { formatDataToOptions } from '@fe/common/utils/arrayUtils'
import { ReceiveData } from '..'
import { BanksApi } from 'src/api/BanksApi'
import { DepositsApi } from 'src/api/DepositsApi'

const StyledDropdown = styled(Dropdown)`
  margin-bottom: 4rem;
`

const BankLink = styled.a`
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
  text-decoration: underline;

  :hover {
    opacity: 0.7;
  }
`

const defaultDetails: InterbankDepositDetails = {
  uid: null,
  description: null,
  data: null,
  type: null,
}

export const InterbankDetails: React.FC = () => {
  const { t } = useTranslation('transactions')
  const [banks, setBanks] = useState<DropdownOptions>([])
  const [details, setDetails] = useState<InterbankDepositDetails>()
  const [areBanksLoading, setAreBanksLoading] = useState(false)
  const [areDetailsLoading, setAreDetailsLoading] = useState(false)

  const { description, data } = details || defaultDetails

  const { control, watch, setValue } = useFormContext<ReceiveData>()

  const settings_id = watch('settings_id')
  const amount = watch('amount_in')
  const interbank_id = watch('requestTypeFields.interbank_id') as number

  const fetchBanks = useCallback(async (settingsId: number) => {
    try {
      setAreBanksLoading(true)

      const { data } = await BanksApi.getBanks(settingsId)

      const bankOptions = formatDataToOptions(data)

      setBanks(bankOptions)
    } catch (error) {
      console.error(error)
    } finally {
      setAreBanksLoading(false)
    }
  }, [])

  const fetchDetials = useCallback(
    async (settingsId: number, interbankId: number, amount: number) => {
      try {
        setAreDetailsLoading(true)

        const { data } = await DepositsApi.getInterbankDetails(
          settingsId,
          interbankId,
          amount
        )

        setDetails(data)
      } catch (error) {
        console.error(error)
      } finally {
        setAreDetailsLoading(false)
      }
    },
    []
  )

  useEffect(() => {
    setValue('requestTypeFields.interbank_id', null)
    setDetails(null)

    fetchBanks(settings_id)
  }, [settings_id])

  useEffect(() => {
    if (!interbank_id) {
      return
    }

    fetchDetials(settings_id, interbank_id, amount)
  }, [interbank_id])

  return (
    <>
      <StyledDropdown
        name="requestTypeFields.interbank_id"
        label={t('bank-name')}
        options={banks}
        control={control}
        isLoading={areBanksLoading}
        isFullWidth
      />

      {areDetailsLoading ? (
        <Spinner />
      ) : details ? (
        description ? (
          <Trans
            defaults={description}
            t={t}
            values={{
              uid: data.uid,
              account_number: data.account_number,
              bank_name: data.bank_name,
              brand: data.brand,
              local_number: data.local_number,
              payment_link: data.payment_link,
              registration_url: data.registration_url,
            }}
            components={{
              p: <p />,
              ol: <ol />,
              li: <li />,
              b: <b />,
              a: <BankLink rel="noopener noreferrer" target="_blank" />,
            }}
          />
        ) : (
          <Trans
            i18nKey="interbank-instruction"
            t={t}
            values={{
              bank_name: data.bank_name,
              local_number: data.local_number,
              payment_details: data.account_number,
              registration_url: data.registration_url,
            }}
            components={{
              p: <p />,
              ol: <ol />,
              li: <li />,
              b: <b />,
              a: (
                <BankLink
                  href={data.registration_url}
                  rel="noopener noreferrer"
                  target="_blank"
                />
              ),
            }}
          />
        )
      ) : null}
    </>
  )
}
