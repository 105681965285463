import { useTranslation } from 'next-i18next'
import { getSymbolFromCurrency } from '@fe/common/utils/currencyUtils'
import { getAmountFormatter } from '@fe/common/utils/formatterUtils'

export interface AmountProps {
  value: number
  code?: string
  decimals?: number
  sign?: '+' | '-'
  testId?: string
  className?: string
}

export const Amount: React.FC<AmountProps> = ({
  value,
  code,
  decimals = 0,
  sign,
  testId,

  className,
}) => {
  const {
    i18n: { language },
  } = useTranslation()

  const formatter = getAmountFormatter(decimals, language)

  const signToUse = sign || (value < 0 ? '-' : '')
  const symbol = getSymbolFromCurrency(code)

  return (
    <span className={className} data-test-id={testId}>
      {signToUse && `${signToUse} `}
      {symbol}
      {value !== undefined ? formatter.format(value) : ''}
      {!symbol && ` ${code}`}
    </span>
  )
}
