import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import { Fee } from '@fe/common/api/models/Fee'
import { Offer } from '@fe/common/api/models/Offer'
import { Amount } from '@fe/common/components/Amount'
import { LoadingDots } from '@fe/common/components/LoadingDots'

const Label = styled.span`
  margin-right: 0.4rem;
`

interface FeeInfoProps {
  isFeeLoading: boolean
  fee: Fee
  offer: Offer
  feeCurrCode: string
  isWithdraw: boolean
}

export const FeeInfo: React.FC<FeeInfoProps> = ({
  isFeeLoading,
  fee,
  offer,
  feeCurrCode,
  isWithdraw,
}) => {
  const { t } = useTranslation('transactions')

  return (
    <span>
      <Label>{`${t('fee')}: `}</Label>

      {isFeeLoading ? (
        <LoadingDots />
      ) : fee ? (
        <Amount value={fee.fee} code={feeCurrCode} decimals={fee.precision} />
      ) : offer ? (
        <Amount
          value={isWithdraw ? offer?.fee_in : offer?.fee_out}
          code={isWithdraw ? offer?.currency_in : offer?.currency_out}
          decimals={isWithdraw ? offer?.precision_in : offer?.precision_out}
        />
      ) : (
        '-'
      )}
    </span>
  )
}
