import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import { opacityReveal } from '@fe/common/constants/animations'
import { COLOR } from '@fe/common/constants/main'
import CheckIcon from '@fe/common/icons/check-success.svg'
import { Button } from './Button'
import { Modal } from './Modal'
import { ButtonContainer } from './formStyles'

const StyledModal = styled(Modal)`
  min-width: 38rem;
  max-width: 42rem;
  width: fit-content;
  margin: 0 auto;
`

export const SuccessMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;

  h2 {
    margin-top: 1rem;
    animation: ${opacityReveal} 0.4s ease-in-out backwards 0.4s;
  }

  p {
    margin-top: 3rem;
    text-align: center;
    animation: ${opacityReveal} 0.4s ease-in-out 0.6s backwards;
  }
`

const Check = styled(CheckIcon)`
  width: 6rem;
  height: 6rem;
  margin-bottom: 2rem;
  color: ${COLOR.GREEN};
  animation: ${opacityReveal} 0.4s ease-in-out backwards;
`

interface SuccessModalProps {
  successText: string
  handleClose: () => void
  extraText?: string
}

export const SuccessModal: React.FC<SuccessModalProps> = ({
  successText,
  handleClose,
  extraText,
  children,
}) => {
  const { t } = useTranslation('transactions')

  return (
    <StyledModal onClose={handleClose}>
      <SuccessMessageContainer>
        <Check />

        <h2 data-test-id="success_text">{successText}</h2>

        {extraText && <p>{extraText}</p>}
      </SuccessMessageContainer>

      <ButtonContainer isCentered isForModal>
        {children || (
          <Button onClick={handleClose} isFullWidth testId="close_button">
            {t('common:close')}
          </Button>
        )}
      </ButtonContainer>
    </StyledModal>
  )
}
