import { useTranslation } from 'next-i18next'
import { useCallback, useRef, useState } from 'react'
import { UseFormMethods } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Offer } from '@fe/common/api/models/Offer'
import { DepositOfferRequest } from '@fe/common/api/models/RequestModels/DepositOfferRequest'
import { OfferRequest } from '@fe/common/api/models/RequestModels/OfferRequest'
import { WithdrawOfferRequest } from '@fe/common/api/models/RequestModels/WithdrawOfferRequest'
import { OfferTypeEnum } from '@fe/common/api/models/enums/OfferTypeEnum'
import { RequestTypeEnum } from '@fe/common/api/models/enums/RequestTypeEnum'
import { useCancelToken } from '@fe/common/hooks/useCancelToken'
import { useCountdown } from '@fe/common/hooks/useCountdown'
import { getLocalExpireAt } from '@fe/common/src/utils/dateUtils'
import { formatResponseError } from '@fe/common/utils/axiosUtils'
import { DepositsApi } from 'src/api/DepositsApi'
import { ExchangeApi } from 'src/api/ExchangeApi'
import { WithdrawApi } from 'src/api/WithdrawApi'
import { currentAccountSelector } from 'src/state/selectors/globalDataSelectors'
import { ActionTypeEnum } from 'src/types/enums/ActionTypeEnum'

export type OfferMethods = {
  offer: Offer
  resetOffer: () => void
  getOffer: (
    amount?: number,
    type?: OfferTypeEnum,
    settingsIdIn?: number,
    settingsIdOut?: number
  ) => Promise<void>
  isOfferLoading: boolean
  timeLeft: string
  previousRequest: WithdrawOfferRequest | DepositOfferRequest | OfferRequest
}

type OfferFields = {
  request_type?: RequestTypeEnum
  amount_in: number
  amount_out?: number
}

type UseOfferProps = {
  actionType: ActionTypeEnum
  getValues: UseFormMethods<OfferFields>['getValues']
  setValue: UseFormMethods<OfferFields>['setValue']
  setError: UseFormMethods<OfferFields>['setError']
  clearErrors: UseFormMethods<OfferFields>['clearErrors']
}

export const useOffer = ({
  actionType,
  getValues,
  setValue,
  setError,
  clearErrors,
}: UseOfferProps): OfferMethods => {
  const { t } = useTranslation('transactions')
  const { id: currentAccId } = useSelector(currentAccountSelector)
  const [offer, setOffer] = useState<Offer>()
  const [expireAt, setExpireAt] = useState<number>()
  const [isOfferLoading, setIsOfferLoading] = useState(false)

  const changeOffer = useCallback((newOffer: Offer) => {
    setOffer(newOffer)

    if (!newOffer) {
      setExpireAt(null)

      return
    }

    const localExpireAt = getLocalExpireAt(newOffer.timeout)

    setExpireAt(localExpireAt)
  }, [])

  const previousRequestRef = useRef<
    WithdrawOfferRequest | DepositOfferRequest | OfferRequest
  >()

  const cancelToken = useCancelToken()

  const getExhcangeOffer = useCallback(
    async (isIn: boolean, request: OfferRequest) => {
      try {
        const { data: offerData } = await ExchangeApi.getOffer(request, {
          cancelToken,
        })

        changeOffer(offerData)

        const amountToUse = isIn ? 'amount_out' : 'amount_in'

        setValue(amountToUse, offerData[amountToUse], {
          shouldDirty: true,
          shouldValidate: true,
        })
      } catch (error) {
        const errorField = isIn ? 'amount_in' : 'amount_out'

        throw { errorField, error }
      }
    },
    []
  )

  const getDepositOffer = useCallback(
    async (isIn: boolean, request: DepositOfferRequest) => {
      try {
        const { data: offerData } = await DepositsApi.getOffer(request, {
          cancelToken,
        })

        changeOffer(offerData)

        const amountToSet = isIn ? 'amount_in' : 'amount_out'
        const amountToUse = isIn ? 'amount_out' : 'amount_in'

        setValue(amountToSet, offerData[amountToUse], {
          shouldDirty: true,
          shouldValidate: true,
        })
      } catch (error) {
        const errorField = isIn ? 'amount_out' : 'amount_in'

        throw { errorField, error }
      }
    },
    []
  )

  const getWithdrawOffer = useCallback(
    async (isIn: boolean, request: WithdrawOfferRequest) => {
      try {
        const { data: offerData } = await WithdrawApi.getOffer(request, {
          cancelToken,
        })

        changeOffer(offerData)

        const amountToUse = isIn ? 'amount_out' : 'amount_in'

        setValue(amountToUse, offerData[amountToUse], {
          shouldDirty: true,
          shouldValidate: true,
        })
      } catch (error) {
        const errorField = isIn ? 'amount_in' : 'amount_out'

        throw { errorField, error }
      }
    },
    []
  )

  const getOffer = useCallback(
    async (
      amount?: number,
      type?: OfferTypeEnum,
      settings_id_in?: number,
      settings_id_out?: number
    ) => {
      if (!amount && type) {
        changeOffer(null)

        return
      }

      const isWithdraw = actionType === ActionTypeEnum.Withdraw
      const isDeposit = actionType === ActionTypeEnum.Deposit

      const offerRequest: Record<string, any> = {
        account_id: currentAccId,
        amount,
        type,
        request_type_in: undefined,
        request_type_out: undefined,
        settings_id_in,
        settings_id_out,
      }

      if (isWithdraw || isDeposit) {
        const requestTypeName = isWithdraw
          ? 'request_type_out'
          : 'request_type_in'

        const request_type = getValues('request_type')

        offerRequest[requestTypeName] = request_type
      }

      if (amount && type && settings_id_in && settings_id_out) {
        previousRequestRef.current = offerRequest as
          | WithdrawOfferRequest
          | DepositOfferRequest
      }

      const finalRequest = type ? offerRequest : previousRequestRef.current

      if (!finalRequest) {
        return
      }

      const isIn = finalRequest.type === OfferTypeEnum.In

      try {
        setIsOfferLoading(true)

        switch (actionType) {
          case ActionTypeEnum.Exchange:
            await getExhcangeOffer(isIn, finalRequest as OfferRequest)
            break

          case ActionTypeEnum.Deposit:
            await getDepositOffer(isIn, finalRequest as DepositOfferRequest)
            break

          case ActionTypeEnum.Withdraw:
            await getWithdrawOffer(isIn, finalRequest as WithdrawOfferRequest)
            break
        }

        clearErrors(['amount_in', 'amount_out'])
      } catch ({ errorField, error }) {
        changeOffer(null)
        setError(errorField, {
          message: formatResponseError(error, t),
          shouldFocus: true,
        })

        console.error(error)
      } finally {
        setIsOfferLoading(false)
      }
    },
    [
      offer,
      currentAccId,
      getValues,
      setValue,
      previousRequestRef.current,
      cancelToken,
    ]
  )

  const { timeLeft } = useCountdown({
    expireAt,
    setExpireAt: changeOffer,
    onExpire: getOffer,
  })

  return {
    offer,
    resetOffer: () => {
      changeOffer(null)
      previousRequestRef.current = null
    },
    getOffer,
    isOfferLoading,
    timeLeft,
    previousRequest: previousRequestRef.current,
  }
}
