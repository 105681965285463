import { useTranslation } from 'next-i18next'
import { useEffect, useCallback, useState, useRef, forwardRef } from 'react'
import { createPortal } from 'react-dom'
import styled, { css, keyframes } from 'styled-components'
import {
  COLOR,
  FONT_SIZE,
  FONT_WEIGHT,
  SCREEN,
  Z_INDEX,
} from '@fe/common/constants/main'
import Close from '@fe/common/src/icons/close.svg'
import { formatTestId } from '../utils/stringUtils'
import { cardStyle } from './CardContainer'
import { Separator } from './Separator'

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: ${Z_INDEX.MODAL};
`

const Backdrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: #00000021;
`

const Container = styled.div<{ maxWidth?: string }>`
  position: relative;
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth || '50rem'};
  margin-top: 4rem;

  ${SCREEN.ABOVE_MOBILE} {
    margin: 0;
  }
`

const warningReveal = keyframes`
  from {
    transform: translateY(7rem);
  }

  5% {
    transform: translateY(0);
  }

  95% {
    transform: translateY(0);
  }

  to {
    transform: translateY(7rem);
  }
`

const alertBase = css`
  position: absolute;
  top: -6rem;
  left: calc(50% - 18rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 36rem;
  height: 7rem;
  padding: 0 2rem;
  border-radius: 6px;
  color: ${COLOR.WHITE};
  font-size: ${FONT_SIZE.EXTRA_SMALL};
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
  text-align: center;
  will-change: transform;
  animation: ${warningReveal} 5.02s cubic-bezier(0.2, 0.32, 0.48, 1.4) backwards;
  z-index: ${Z_INDEX.BACKGROUND};

  ${SCREEN.ABOVE_MOBILE} {
    left: calc(50% - 20rem);
    width: 40rem;
    font-size: ${FONT_SIZE.SMALL};
  }
`

const Instruction = styled.div`
  ${alertBase}

  background: ${COLOR.ORANGE};
`

const Warning = styled.div`
  ${alertBase}

  background: ${COLOR.RED};

  > :last-child {
    color: ${COLOR.LIGHT_GREY};
    opacity: 0.7;
  }
`

const maxMobileHeight = '85vh'
const maxHeight = '78vh'

const StyledCardContainer = styled.div`
  width: auto;
  max-width: none;
  max-height: ${maxMobileHeight};
  margin: 0 1rem;
  padding: 0;
  white-space: pre-wrap;

  ${SCREEN.ABOVE_MOBILE} {
    max-height: ${maxHeight};
    margin: 0 auto;
  }
`

const StyledSeparator = styled(Separator)`
  margin: 0;
`

const CloseIcon = styled(Close)`
  position: absolute;
  top: 3rem;
  right: 2rem;
  fill: ${COLOR.BLACK};
  cursor: pointer;

  :hover {
    fill: ${COLOR.GREY};
  }
`

const headerHeight = 10

const Header = styled.div`
  ${cardStyle};

  border-radius: 8px 8px 0 0;
`

const Title = styled.h2`
  display: flex;
  align-items: center;
  height: ${headerHeight}rem;
  padding: 0 3rem;
`

const ScrollContainer = styled.div<{ isWithoutTitle?: boolean }>`
  max-height: calc(
    (100vh - (100vh - ${maxMobileHeight}) / 2) -
      ${({ isWithoutTitle }) => (isWithoutTitle ? 0 : headerHeight + 0.1)}rem
  );
  margin-bottom: -4rem;
  overflow: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  ${SCREEN.ABOVE_MOBILE} {
    max-height: calc(
      (100vh - (100vh - ${maxHeight}) / 2) -
        ${({ isWithoutTitle }) => (isWithoutTitle ? 0 : headerHeight + 0.1)}rem
    );
  }
`

const Content = styled.div<{
  isWithoutHeaderSeparator?: boolean
  isWithoutTitle?: boolean
}>`
  ${cardStyle};

  margin-bottom: 4rem;
  padding: ${({ isWithoutHeaderSeparator }) =>
    isWithoutHeaderSeparator ? '0 3rem 3rem' : '3rem'};
  padding-top: ${({ isWithoutTitle }) => isWithoutTitle && '4rem'};
  border-radius: ${({ isWithoutTitle }) =>
    isWithoutTitle ? '8px' : '0 0 8px 8px'};
  overflow-y: auto;
`

interface ModalProps {
  title?: string
  onClose?: () => void
  isWithoutHeaderSeparator?: boolean
  hasWarningOnClose?: boolean
  maxWidth?: string
  isLogoutWarning?: boolean
  className?: string
  children: React.ReactNode
}

export const Modal = forwardRef<HTMLDivElement, ModalProps>(
  (
    {
      title,
      onClose,
      isWithoutHeaderSeparator,
      hasWarningOnClose,
      maxWidth,
      isLogoutWarning,
      className,
      children,
    },
    contentRef
  ) => {
    const { t } = useTranslation()
    const [isInstructionShown, setIsInstructionShown] = useState(false)
    const [isWarningShown, setIsWarningShown] = useState(false)

    const timeoutRef = useRef<ReturnType<typeof setTimeout>>()

    useEffect(() => {
      document.documentElement.style.overflowY = 'hidden'
      document.body.getElementsByTagName('main')[0]?.classList.add('blured')

      if (onClose) {
        window.addEventListener('keydown', handleEscClose)
      }

      return () => {
        const areModalsPresent =
          document.getElementById('modal').children.length

        if (!areModalsPresent) {
          document.body
            .getElementsByTagName('main')[0]
            ?.classList.remove('blured')

          document.documentElement.style.removeProperty('overflow-y')
        }

        if (onClose) {
          window.removeEventListener('keydown', handleEscClose)
        }
      }
    }, [])

    useEffect(() => {
      if (isInstructionShown) {
        timeoutRef.current = setTimeout(() => {
          setIsInstructionShown(false)
        }, 5000)
      }

      return () => clearTimeout(timeoutRef.current)
    }, [isInstructionShown])

    useEffect(() => {
      if (isWarningShown) {
        timeoutRef.current = setTimeout(() => {
          setIsWarningShown(false)
        }, 5000)
      }

      return () => clearTimeout(timeoutRef.current)
    }, [isWarningShown])

    const handleClose = useCallback(
      (e?: React.MouseEvent<HTMLDivElement>) => {
        if (hasWarningOnClose && !isInstructionShown && !isWarningShown) {
          setIsInstructionShown(true)

          return
        } else if (hasWarningOnClose) {
          return
        }

        e?.stopPropagation()
        onClose?.()
      },
      [onClose, isWarningShown]
    )

    const handleCrossClick = useCallback(
      (e?: React.MouseEvent<SVGElement>) => {
        if (hasWarningOnClose && !isInstructionShown && !isWarningShown) {
          setIsWarningShown(true)

          return
        }

        e?.stopPropagation()
        onClose?.()
      },
      [onClose, isWarningShown]
    )

    const handleEscClose = useCallback(
      (e: KeyboardEvent) => {
        if (e.code === 'Escape') {
          handleClose()
        }
      },
      [handleClose, isInstructionShown]
    )

    const component = (
      <Wrapper>
        <Backdrop onClick={handleClose} />

        <Container maxWidth={maxWidth}>
          {isInstructionShown && (
            <Instruction>
              <p>{t('modal-leave-instruction')}</p>
            </Instruction>
          )}

          {isWarningShown && (
            <Warning>
              <p>{t('modal-leave-warning')}</p>
              <p>{t('modal-leave-confirm')} </p>
            </Warning>
          )}

          <StyledCardContainer className={className}>
            {title && (
              <Header>
                <Title>{title}</Title>
                {!isWithoutHeaderSeparator && <StyledSeparator />}
                {onClose && (
                  <CloseIcon
                    onClick={handleCrossClick}
                    data-test-id={`${formatTestId(title)}_modal_close_button`}
                  />
                )}
              </Header>
            )}

            <ScrollContainer isWithoutTitle={!title}>
              <Content
                isWithoutHeaderSeparator={isWithoutHeaderSeparator}
                isWithoutTitle={!title}
                ref={contentRef}
              >
                {children}
              </Content>
            </ScrollContainer>
          </StyledCardContainer>
        </Container>
      </Wrapper>
    )

    const portalId = isLogoutWarning ? 'warning-modal' : 'modal'

    const portalEl =
      typeof document !== `undefined` && document.getElementById(portalId)

    if (portalEl) {
      return createPortal(component, portalEl)
    }

    return null
  }
)
