import { DefaultTheme } from 'styled-components'

export const FF_NAME = 'fasfinance-com'
export const SMT_EU_NAME = 'sminti-eu'
export const POX_NAME = 'poxypay-com'

export const FF_THEME: DefaultTheme = {
  primary: '#2550E5',
  light: '#EAEEFD',
}

export const SMI_THEME = {
  primary: '#1ABB89',
  light: '#A5F3DA',
}

export const POX_THEME = {
  primary: '#FF4900',
  light: '#FFD073',
}
