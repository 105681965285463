import Axios, { AxiosPromise } from 'axios'
import { UserSettings } from '@fe/common/api/models/UserSettins'
import { RequestConfig } from '@fe/common/axiosConfig'

export class SettingsApi {
  static getUserSetting(config?: RequestConfig): AxiosPromise<UserSettings> {
    return Axios({
      ...config,
      url: `/users/settings`,
      method: 'GET',
    })
  }
}
