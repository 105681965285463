import { createSlice, createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { CancelToken } from 'axios'
import Cookies from 'js-cookie'
import { HYDRATE } from 'next-redux-wrapper'
import Router from 'next/router'
import { Account } from '@fe/common/api/models/Account'
import { AccountCurrency } from '@fe/common/api/models/AccountCurrency'
import { AccountDetails } from '@fe/common/api/models/AccountDetails'
import { CardOrder } from '@fe/common/api/models/CardOrder'
import { Config } from '@fe/common/api/models/Config'
import { Country } from '@fe/common/api/models/Country'
import { EntityCard } from '@fe/common/api/models/EntityCard'
import { History } from '@fe/common/api/models/History'
import { PendingOrders } from '@fe/common/api/models/PendingOrders'
import { HistoryRequest } from '@fe/common/api/models/RequestModels/HistoryRequest'
import { StatementRequest } from '@fe/common/api/models/RequestModels/StatementRequest'
import { Transaction } from '@fe/common/api/models/Transaction'
import { CardOrderTypeEnum } from '@fe/common/api/models/enums/CardOrderTypeEnum'
import { VerificationStatusEnum } from '@fe/common/api/models/enums/VerificationStatusEnum'
import { CONFIG } from '@fe/common/constants/cookies'
import { AUTHORIZATION_HEADER } from '@fe/common/constants/headers'
import { addTotalCount } from '@fe/common/utils/axiosUtils'
import { setCookie } from '@fe/common/utils/cookies'
import { getDefinedFields } from '@fe/common/utils/objectUtils'
import { AccountsApi } from 'src/api/AccountsApi'
import { CardsApi } from 'src/api/CardsApi'
import { ConfigApi } from 'src/api/ConfigApi'
import { CountriesApi } from 'src/api/CountriesApi'
import { HistoryApi } from 'src/api/HistoryApi'
import { HISTORY, STATEMENTS, TRANSACTIONS } from 'src/constants/routes'
import { AppState } from 'src/state/store'
import { Transactions } from 'src/types/Transactions'
import { getCurrentAccount } from 'src/utils/stateUtils'

export const getCountries = createAsyncThunk<Array<Country>>(
  'global/getCountries',
  async () => {
    const { data } = await CountriesApi.getCountries()

    return data
  }
)

export const getConfig = createAsyncThunk<Config>(
  'global/getConfig',
  async () => {
    try {
      const { data } = await ConfigApi.getConfig()

      setCookie(CONFIG, JSON.stringify(data))

      return data
    } catch (error) {
      const savedConfig = JSON.parse(Cookies.get(CONFIG))

      return savedConfig
    }
  }
)

export const getAccounts = createAsyncThunk<
  Array<Account>,
  undefined | string,
  {
    state: AppState
  }
>('global/getAccounts', async accessToken => {
  const config = accessToken && {
    headers: { [AUTHORIZATION_HEADER]: `Bearer ${accessToken}` },
  }

  try {
    const { data: accounts } = await AccountsApi.getAccounts(
      { page_size: 0 },
      config
    )

    return accounts
  } catch (error) {
    console.error(error)
  }
})

export const getEntityCards = createAsyncThunk<
  Array<EntityCard>,
  null,
  {
    state: AppState
  }
>('global/getEntityCards', async (_, { getState }) => {
  const {
    session: { currentEntityId, entities },
  } = getState()

  const isVerified =
    entities.find(({ id }) => id === currentEntityId)?.status ===
    VerificationStatusEnum.Verified

  if (!isVerified) {
    return []
  }

  try {
    const {
      data: { cards },
    } = await CardsApi.getCards(currentEntityId)

    return cards
  } catch (error) {
    console.error(error)
  }
})

export const updateBalances = createAsyncThunk<
  { id: number; balances: Array<AccountCurrency> },
  void,
  {
    state: AppState
  }
>('global/updateBalances', async (_, { getState }) => {
  const state = getState()

  const { id: currentAccId } = getCurrentAccount(state)

  try {
    const { data: accounts } = await AccountsApi.getAccounts({
      id: [currentAccId],
    })

    const balances = accounts[0].currencies

    return { id: currentAccId, balances }
  } catch (error) {
    console.error(error)
  }
})

export const getAccountDetails = createAsyncThunk<
  AccountDetails,
  void,
  {
    state: AppState
  }
>('global/getAccountDetails', async (_, { getState }) => {
  const {
    session: { currentAccountId },
  } = getState()

  try {
    const { data: accountDetails } = await AccountsApi.getAccountDetails(
      currentAccountId
    )

    return accountDetails
  } catch (error) {
    console.error(error)
  }
})

export const getRecentTransactions = createAsyncThunk<
  Transactions,
  void,
  {
    state: AppState
  }
>('global/getRecentTransactions', async (_, { getState }) => {
  const {
    session: { currentAccountId },
  } = getState()

  try {
    const { data: transactions } = await HistoryApi.getAccountHistory(
      currentAccountId
    )
      .then(response => ({
        ...response,
        data: { transactions: response.data },
      }))
      .then(addTotalCount)

    return transactions
  } catch (error) {
    console.error(error)
  }
})

export const getRecentStatements = createAsyncThunk<
  History,
  void,
  {
    state: AppState
  }
>('global/getRecentStatemtnts', async (_, { getState }) => {
  const state = getState()

  const { currencies } = getCurrentAccount(state)
  const defaultSettingsId = currencies[0]?.id

  try {
    const { data: statements } = await HistoryApi.getStatements(
      defaultSettingsId
    )

    return statements
  } catch (error) {
    console.error(error)
  }
})

export type TransactionsFilters = HistoryRequest & { settings_id?: number }

export const getFilteredTransactions = createAsyncThunk<
  Transactions,
  TransactionsFilters & { cancelToken?: CancelToken },
  {
    state: AppState
  }
>(
  'global/getFilteredTransactions',
  async ({ settings_id, cancelToken, ...requestParams }, { getState }) => {
    const state = getState()

    const { currencies } = getCurrentAccount(state)
    const defaultSettingsId = currencies[0].id

    try {
      let transactions

      if (settings_id) {
        const { data } = await HistoryApi.getHistory(
          settings_id || defaultSettingsId,
          requestParams,
          { cancelToken }
        ).then(addTotalCount)

        transactions = data
      } else {
        const {
          session: { currentAccountId },
        } = state

        const { data } = await HistoryApi.getAccountHistory(
          currentAccountId,
          requestParams,
          { cancelToken }
        )
          .then(response => ({
            ...response,
            data: { transactions: response.data },
          }))
          .then(addTotalCount)

        transactions = data
      }

      const query = { ...getDefinedFields({ ...requestParams, settings_id }) }

      Router.replace(
        {
          pathname: TRANSACTIONS,
          query,
        },
        {
          pathname: HISTORY,
          query,
        }
      )

      return transactions
    } catch (error) {
      console.error(error)
    }
  }
)

export const getFilteredTransactionsPage = createAsyncThunk<
  Array<Transaction>,
  number,
  {
    state: AppState
  }
>('global/getFilteredTransactionsPage', async (page, { getState }) => {
  const state = getState()
  const query = Router.query

  const filterParams = { ...query }
  delete filterParams.tab

  const requestParams = { ...filterParams, page }

  const settingsId = Number(filterParams.settings_id)

  try {
    let pageTransactions

    if (settingsId) {
      const { data } = await HistoryApi.getHistory(settingsId, requestParams)

      pageTransactions = data.transactions
    } else {
      const {
        session: { currentAccountId },
      } = state

      const { data } = await HistoryApi.getAccountHistory(
        currentAccountId,
        requestParams
      )

      pageTransactions = data
    }

    return pageTransactions || []
  } catch (error) {
    console.error(error)
  }
})

export const updateTransaction = createAsyncThunk<
  Transaction,
  number,
  {
    state: AppState
  }
>('global/updateTransaction', async (request_id, { getState }) => {
  const state = getState()

  const query = Router.query

  const settingsId = Number(query.settings_id)

  try {
    let updatedTransaction

    if (settingsId) {
      const { data } = await HistoryApi.getHistory(settingsId, { request_id })

      updatedTransaction = data.transactions[0]
    } else {
      const {
        session: { currentAccountId },
      } = state

      const { data } = await HistoryApi.getAccountHistory(currentAccountId, {
        request_id,
      })

      updatedTransaction = data[0]
    }

    return updatedTransaction
  } catch (error) {
    console.error(error)
  }
})

export type StatementsFilters = StatementRequest & { settings_id?: number }

export const getFilteredStatements = createAsyncThunk<
  History,
  StatementsFilters & { cancelToken: CancelToken },
  {
    state: AppState
  }
>(
  'global/getFilteredStatements',
  async ({ settings_id, cancelToken, ...requestParams }, { getState }) => {
    const state = getState()

    const { currencies } = getCurrentAccount(state)
    const defaultSettingsId = currencies[0].id

    try {
      const { data: statements } = await HistoryApi.getStatements(
        settings_id || defaultSettingsId,
        requestParams,
        { cancelToken: cancelToken }
      ).then(addTotalCount)

      const query = { ...getDefinedFields(requestParams), settings_id }

      Router.replace(
        {
          pathname: TRANSACTIONS,
          query,
        },
        {
          pathname: STATEMENTS,
          query,
        }
      )

      return statements
    } catch (error) {
      console.error(error)
    }
  }
)

export const getFilteredStatementsPage = createAsyncThunk<
  Array<Transaction>,
  number,
  {
    state: AppState
  }
>('global/getFilteredStatementsPage', async page => {
  const query = Router.query

  const filterParams = { ...query }
  delete filterParams.tab

  const requestParams = { ...filterParams, page }

  const settingsId = Number(filterParams.settings_id)

  try {
    const {
      data: { transactions: pageStatements },
    } = await HistoryApi.getStatements(settingsId, requestParams).then(
      addTotalCount
    )

    return pageStatements
  } catch (error) {
    console.error(error)
  }
})

export const getPendingCardOrders = createAsyncThunk<
  PendingOrders,
  null,
  {
    state: AppState
  }
>('global/getPendingCardOrders', async (_, { getState }) => {
  const {
    session: { currentAccountId },
  } = getState()

  try {
    const { data } = await CardsApi.getCardOrders(
      currentAccountId,
      CardOrderTypeEnum.Deposit
    )
      .then(response => ({
        ...response,
        data: { cardOrders: response.data },
      }))
      .then(addTotalCount)

    return data
  } catch (error) {
    console.error(error)
  }
})

export const getPendingCardOrdersPage = createAsyncThunk<
  Array<CardOrder>,
  number,
  {
    state: AppState
  }
>('global/getPendingCardOrdersPage', async (page, { getState }) => {
  const {
    session: { currentAccountId },
  } = getState()

  try {
    const { data } = await CardsApi.getCardOrders(
      currentAccountId,
      CardOrderTypeEnum.Deposit,
      page
    )

    return data
  } catch (error) {
    console.error(error)
  }
})

const hydrate = createAction<AppState>(HYDRATE)

export interface GlobalDataState {
  countries: Array<Country>
  config: Config
  accounts: Array<Account>
  areAccountsLoading: boolean
  accountDetails: AccountDetails
  areDetailsLoading: boolean
  areTransactionsLoading: boolean
  recentTransactions: Transactions
  recentStatements: History
  filteredTransactions?: Transactions
  filteredStatements?: History
  pendingOrders: PendingOrders
  cards: Array<EntityCard>
  areCardsLoading: boolean
}

export const initialGlobalDataState: GlobalDataState = {
  countries: [],
  config: {
    company_type: null,
    company_name: '',
    company_address: '',
    company_city: '',
    company_zip: '',
    company_country_code: '',
    company_email: '',
    company_phone: '',
    company_number: '',
    company_tax_number: '',
    bank_name: '',
    bank_address: '',
    bank_city: '',
    bank_zip: '',
    bank_country_code: '',
    bank_code: '',
    bank_account_number: '',
    logo_path: '',
    verification_mode: 1,
    brand: '',
  },
  accounts: [],
  areAccountsLoading: false,
  accountDetails: null,
  areDetailsLoading: false,
  areTransactionsLoading: false,
  recentTransactions: {
    transactions: [],
    total_count: 0,
    page_count: 1,
  },
  recentStatements: {
    balance_start: 0,
    balance_end: 0,
    turnover: 0,
    turnover_incoming: 0,
    turnover_outgoing: 0,
    precision: 0,
    transactions: [],
    total_count: 0,
    page_count: 1,
  },
  filteredTransactions: {
    transactions: null,
    total_count: 0,
    page_count: 1,
  },
  filteredStatements: {
    balance_start: 0,
    balance_end: 0,
    turnover: 0,
    turnover_incoming: 0,
    turnover_outgoing: 0,
    precision: 0,
    transactions: null,
    total_count: 0,
    page_count: 1,
  },
  pendingOrders: {
    cardOrders: [],
    page_count: 0,
    total_count: 0,
  },
  cards: [],
  areCardsLoading: false,
}

const globalDataSlice = createSlice({
  name: 'globalData',
  initialState: initialGlobalDataState,
  reducers: {
    flushGlobalData(state) {
      const newState = { ...initialGlobalDataState }

      delete newState.config

      return { ...newState, config: state.config }
    },
    resetFilteredData(state) {
      state.filteredTransactions = initialGlobalDataState.filteredTransactions
      state.filteredStatements = initialGlobalDataState.filteredStatements
    },
  },
  extraReducers: builder => {
    builder.addCase(getConfig.fulfilled, (state, { payload }) => ({
      ...state,
      config: payload,
    }))
    builder.addCase(getCountries.fulfilled, (state, { payload }) => ({
      ...state,
      countries: payload,
    }))
    builder.addCase(getAccounts.pending, state => ({
      ...state,
      areAccountsLoading: true,
    }))
    builder.addCase(getAccounts.fulfilled, (state, { payload }) => ({
      ...state,
      accounts: payload,
      areAccountsLoading: false,
    }))
    builder.addCase(getAccounts.rejected, state => ({
      ...state,
      areAccountsLoading: false,
    }))
    builder.addCase(updateBalances.fulfilled, (state, { payload }) => {
      const updatedAccounts = state.accounts.reduce((acc, account) => {
        if (account.id !== payload.id) {
          acc.push(account)
        } else {
          const updatedAccount: Account = {
            ...account,
            currencies: payload.balances,
          }

          acc.push(updatedAccount)
        }

        return acc
      }, [] as Array<Account>)

      return {
        ...state,
        accounts: updatedAccounts,
      }
    })
    builder.addCase(getAccountDetails.pending, state => ({
      ...state,
      areDetailsLoading: true,
    }))
    builder.addCase(getAccountDetails.fulfilled, (state, { payload }) => ({
      ...state,
      areDetailsLoading: false,
      accountDetails: payload,
    }))
    builder.addCase(getAccountDetails.rejected, state => ({
      ...state,
      areDetailsLoading: false,
    }))
    builder.addCase(getEntityCards.pending, state => ({
      ...state,
      areCardsLoading: true,
    }))
    builder.addCase(getEntityCards.fulfilled, (state, { payload }) => ({
      ...state,
      cards: payload || [],
      areCardsLoading: false,
    }))
    builder.addCase(getEntityCards.rejected, state => ({
      ...state,
      areCardsLoading: false,
    }))
    builder.addCase(getRecentTransactions.pending, state => ({
      ...state,
      areTransactionsLoading: true,
    }))
    builder.addCase(getRecentTransactions.fulfilled, (state, { payload }) => ({
      ...state,
      areTransactionsLoading: false,
      recentTransactions: payload || initialGlobalDataState.recentTransactions,
    }))
    builder.addCase(getRecentTransactions.rejected, state => ({
      ...state,
      areTransactionsLoading: false,
    }))
    builder.addCase(getFilteredTransactions.pending, state => ({
      ...state,
      areTransactionsLoading: true,
    }))
    builder.addCase(
      getFilteredTransactions.fulfilled,
      (state, { payload }) => ({
        ...state,
        areTransactionsLoading: false,
        filteredTransactions:
          payload || initialGlobalDataState.filteredTransactions,
      })
    )
    builder.addCase(getFilteredTransactions.rejected, state => ({
      ...state,
      areTransactionsLoading: false,
      filteredTransactions: initialGlobalDataState.filteredTransactions,
    }))
    builder.addCase(
      getFilteredTransactionsPage.fulfilled,
      (state, { payload }) => ({
        ...state,
        filteredTransactions: {
          ...state.filteredTransactions,
          transactions: [
            ...state.filteredTransactions.transactions,
            ...(payload || []),
          ],
        },
      })
    )
    builder.addCase(updateTransaction.fulfilled, (state, action) => {
      const updatedTransactions = state.filteredTransactions.transactions.map(
        transaction => {
          if (transaction.request_id === action.meta.arg) {
            return action.payload
          }

          return transaction
        }
      )

      return {
        ...state,
        filteredTransactions: {
          ...state.filteredTransactions,
          transactions: [...updatedTransactions],
        },
      }
    })
    builder.addCase(getRecentStatements.fulfilled, (state, { payload }) => ({
      ...state,
      recentStatements: payload || initialGlobalDataState.recentStatements,
    }))
    builder.addCase(getFilteredStatements.pending, state => ({
      ...state,
      areTransactionsLoading: true,
    }))
    builder.addCase(getFilteredStatements.fulfilled, (state, { payload }) => ({
      ...state,
      filteredStatements: payload || initialGlobalDataState.filteredStatements,
      areTransactionsLoading: false,
    }))
    builder.addCase(getFilteredStatements.rejected, state => ({
      ...state,
      areTransactionsLoading: false,
      filteredStatements: initialGlobalDataState.filteredStatements,
    }))
    builder.addCase(
      getFilteredStatementsPage.fulfilled,
      (state, { payload }) => ({
        ...state,
        areTransactionsLoading: false,
        filteredStatements: {
          ...state.filteredStatements,
          transactions: [
            ...state.filteredStatements.transactions,
            ...(payload || []),
          ],
        },
      })
    )
    builder.addCase(getPendingCardOrders.pending, state => ({
      ...state,
      areTransactionsLoading: true,
    }))
    builder.addCase(getPendingCardOrders.fulfilled, (state, { payload }) => ({
      ...state,
      areTransactionsLoading: false,
      pendingOrders: payload,
    }))
    builder.addCase(
      getPendingCardOrdersPage.fulfilled,
      (state, { payload }) => ({
        ...state,
        pendingOrders: {
          ...state.pendingOrders,
          cardOrders: { ...state.pendingOrders.cardOrders, ...payload },
        },
      })
    )
    builder.addCase(hydrate, (state, { payload }) => {
      if (payload.globalData.config.brand) {
        return {
          ...state,
          areTransactionsLoading: false,
          config: payload.globalData.config,
        }
      }

      return state
    })
  },
})

const { actions, reducer } = globalDataSlice

export const { flushGlobalData, resetFilteredData } = actions

export default reducer
