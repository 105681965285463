import { AnySchema, ObjectSchema, TestContext } from 'yup'
import { ObjectShape } from 'yup/lib/object'
import { RequestTypeEnum } from '@fe/common/api/models/enums/RequestTypeEnum'
import { Shape } from '@fe/common/src/types/Shape'
import { isDefined } from '@fe/common/src/utils/numberUtils'
import yup from '@fe/common/yup/yupConfig'
import { MtCardsFields, MtCashFields, ReceiveData } from '.'

export interface ReceiveContext {
  min_amount_in: number
  max_amount_in: number
}

type RequestTypeSchema<T> = Record<keyof T, AnySchema>

const mtCashSchema: RequestTypeSchema<MtCashFields> = {
  mt_payment_system_id: yup.number().required(),
  transfer_id: yup.number().required(),
}

const mtCardSchema: RequestTypeSchema<MtCardsFields> = {
  entity_card_id: yup.number().required(),
  csc: yup.string().length(3).required(),
}

const schemaMap: Record<number, ObjectShape> = {
  [RequestTypeEnum.WireTransfer]: {},
  [RequestTypeEnum.Sepa]: {},
  [RequestTypeEnum.CzkPayments]: {},
  [RequestTypeEnum.Internal]: {},
  [RequestTypeEnum.Cash]: {},
  [RequestTypeEnum.Crypto]: {},
  [RequestTypeEnum.Interbank]: {},
  [RequestTypeEnum.MtCards]: mtCardSchema,
  [RequestTypeEnum.MtCash]: mtCashSchema,
}

const requestEnumValues = Object.values(RequestTypeEnum).slice(
  Object.values(RequestTypeEnum).length / 2
) as unknown as Array<RequestTypeEnum>

export const receiveSchema = yup.object().shape<Shape<ReceiveData>>({
  settings_id: yup.number().required(),
  request_type: yup.mixed().oneOf(requestEnumValues).required(),
  amount_in: yup
    .number()
    .test(
      'maxAmount',
      'number.exceeds-max',
      (value, context: TestContext<ReceiveContext>) => {
        const maxAmountIn = context.options.context.max_amount_in

        if (isDefined(value) && isDefined(maxAmountIn)) {
          return value <= maxAmountIn
        }

        return true
      }
    )
    .test(
      'maxAmount',
      'number.below-min',
      (value, context: TestContext<ReceiveContext>) => {
        const minAmountIn = context.options.context.min_amount_in

        if (isDefined(value) && isDefined(minAmountIn)) {
          return value >= minAmountIn
        }

        return true
      }
    )
    .required(),
  amount_out: yup.number().nullable(),
  requestTypeFields: yup
    .object()
    .when(
      'request_type',
      (request_type: RequestTypeEnum, schema: ObjectSchema<ObjectShape>) =>
        schema.shape(schemaMap[request_type])
    ),
})
