import { Account } from '@fe/common/api/models/Account'
import { AccountDetails } from '@fe/common/api/models/AccountDetails'
import { Config } from '@fe/common/api/models/Config'
import { EntityCard } from '@fe/common/api/models/EntityCard'
import { History } from '@fe/common/api/models/History'
import { PendingOrders } from '@fe/common/api/models/PendingOrders'
import { DropdownOptions } from '@fe/common/components/inputs/CustomSelect'
import { CompanyTypeEnum } from '@fe/common/src/api/models/enums/CompanyTypeEnum'
import { AppState } from 'src/state/store'
import { AccountCurrencyWithDetails } from 'src/types/AccountCurrencyWithDetails'
import { Transactions } from 'src/types/Transactions'
import { getCurrentAccount } from 'src/utils/stateUtils'

export const countryOptionsSelector = (state: AppState): DropdownOptions =>
  state.globalData.countries.map(country => ({
    value: Number(country.id),
    label: country.name,
  }))

export const countriesSelector = (state: AppState): Record<number, string> =>
  state.globalData.countries.reduce((acc, country) => {
    acc[country.id] = country.name

    return acc
  }, {} as Record<number, string>)

export const countryNamedOptionsSelector =
  (withEu: boolean) =>
  (state: AppState): DropdownOptions<string> => {
    const namedCountryOptions = state.globalData.countries.map(country => ({
      value: country.iso3,
      label: country.name,
    }))

    if (withEu) {
      namedCountryOptions.unshift({
        value: 'EUUK',
        label: 'EU Countries',
      })
    }

    return namedCountryOptions
  }

export const configSelector = (state: AppState): Config =>
  state.globalData.config

export const isCryptoSelector = (state: AppState): boolean =>
  state.globalData.config.company_type === CompanyTypeEnum.Crypto

export const accountsSelector = (state: AppState): Array<Account> =>
  state.globalData.accounts

export const accountsLoadingSelector = (state: AppState): boolean =>
  state.globalData.areAccountsLoading

export const currentAccountSelector = (state: AppState): Account =>
  getCurrentAccount(state)

export const detailsLoadingSelector = (state: AppState): boolean =>
  state.globalData.areDetailsLoading

export const cardsSelector = (state: AppState): Array<EntityCard> =>
  state.globalData.cards

export const cardsLoadingSelector = (state: AppState): boolean =>
  state.globalData.areCardsLoading

export const actionPermissionsSelector = (
  state: AppState
): Pick<AccountDetails, 'can_exchange' | 'can_deposit' | 'can_withdraw'> => {
  const accountDetails = state.globalData.accountDetails

  if (!accountDetails) {
    return {
      can_exchange: undefined,
      can_deposit: undefined,
      can_withdraw: undefined,
    }
  }

  const { can_exchange, can_deposit, can_withdraw } = accountDetails

  return {
    can_exchange,
    can_deposit,
    can_withdraw,
  }
}

export const currencyDetailsSelector = (
  state: AppState
): Array<AccountCurrencyWithDetails> | null => {
  const currentAccount = getCurrentAccount(state)
  const currencies = currentAccount?.currencies
  const currenciesDetails = state.globalData.accountDetails?.settings

  if (!currencies || !currenciesDetails) {
    return null
  }

  const modifiedCurrencies = currencies.map(currency => {
    const currencyDetails = currenciesDetails.find(
      details => details.id === currency.id
    )

    return {
      ...currency,
      deposit_methods: currencyDetails?.deposit_methods,
      withdraw_methods: currencyDetails?.withdraw_methods,
    }
  })

  return modifiedCurrencies
}

export const transLoadingSteteSelector = (state: AppState): boolean =>
  state.globalData.areTransactionsLoading

export const recentTransactionsSelector = (state: AppState): Transactions =>
  state.globalData.recentTransactions

export const filteredTransactionsSelector = (
  state: AppState
): Transactions | null => state.globalData.filteredTransactions

export const recentStatementsSelector = (state: AppState): History =>
  state.globalData.recentStatements

export const filteredStatementsSelector = (state: AppState): History | null =>
  state.globalData.filteredStatements

export const pendingOrdersSelector = (state: AppState): PendingOrders | null =>
  state.globalData.pendingOrders
