import { NextPage } from 'next'
import { appWithTranslation, useTranslation } from 'next-i18next'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { useEffect, useRef } from 'react'
import { useStore, useDispatch, useSelector } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import styled, { ThemeProvider } from 'styled-components'
import 'react-datepicker/dist/react-datepicker.css'
import {
  configureAxiosDefaults,
  configureAxiosInterceptors,
} from '@fe/common/axiosConfig'
import { GlobalStyles } from '@fe/common/components/GlobalStyles'
import { IS_PRODUCTION } from '@fe/common/constants/env'
import { COLOR } from '@fe/common/src/constants/main'
import { getTokenCookie } from '@fe/common/utils/cookies'
import { recordPageview } from '@fe/common/utils/gaUtils'
import { isServer } from '@fe/common/utils/nextUtils'
import { polyfill } from '@fe/common/utils/polyfill'
import { getTheme } from '@fe/common/utils/themeUtils'
import { LogoutWarningModal } from 'src/components/LogoutWarningModal'
import { ExchangeModal } from 'src/components/actionModals/ExchangeModal'
import { ReceiveModal } from 'src/components/actionModals/ReceiveModal'
import { SendModal } from 'src/components/actionModals/SendModal'
import {
  CARD_ORDER_MESSAGE,
  LOGIN,
  PROFILE,
  UNAUTH_PAGES,
} from 'src/constants/routes'
import { useTabStateSync } from 'src/hooks/useTabStateSync'
import { refreshAccountInfo } from 'src/state/actions/globalDataActions'
import { deauthenticate } from 'src/state/actions/sessionActions'
import {
  getConfig,
  getEntityCards,
  getPendingCardOrders,
} from 'src/state/reducers/globalDataReducer'
import { refreshToken, updateEntities } from 'src/state/reducers/sessionReducer'
import { exchangeModalStateSelector } from 'src/state/selectors/exchangeModalSelectors'
import { receiveModalStateSelector } from 'src/state/selectors/receiveModalSelectors'
import { sendModalStateSelector } from 'src/state/selectors/sendModalSelectors'
import { accessTokenSelector } from 'src/state/selectors/sessionSelectors'
import { AppState, wrapper } from 'src/state/store'

const Placeholder = styled.div`
  width: 100vw;
  height: 100vh;
  background: ${COLOR.BACKGROUND};
`

interface MyAppProps extends AppProps {
  err: Error & {
    statusCode?: number
  }
}

const MyApp: NextPage<MyAppProps> = ({
  Component,
  router: { events, pathname },
  pageProps,
  err,
}) => {
  const {
    i18n: { language },
  } = useTranslation()
  const store = useStore<AppState>()
  const isRehydrated = store.getState()._persist?.rehydrated

  const isExchangeModalShown = useSelector(exchangeModalStateSelector)
  const isReceiveModalShown = useSelector(receiveModalStateSelector)
  const isSendModalShown = useSelector(sendModalStateSelector)
  const accessToken = useSelector(accessTokenSelector)

  const dispatch = useDispatch()
  const previousPath = useRef<string>()

  configureAxiosDefaults()
  configureAxiosInterceptors(store, deauthenticate)

  if (!isServer()) {
    polyfill()
  }

  useTabStateSync()

  useEffect(() => {
    const cookieToken = getTokenCookie()
    const stateToken = accessToken
    const isConfigLoaded = !!store.getState().globalData.config.brand

    if (!stateToken && !cookieToken && !isConfigLoaded) {
      dispatch(getConfig())
    }
  }, [])

  // useEffect(() => {
  //   if (recaptchaSiteKey && UNAUTH_PAGES.includes(pathname)) {
  //     loadReCaptcha(recaptchaSiteKey)
  //   } else {
  //     unloadReCaptcha(recaptchaSiteKey)
  //   }
  // }, [pathname])

  useEffect(() => {
    if (pathname.includes(CARD_ORDER_MESSAGE)) {
      return
    }

    if (!isRehydrated) {
      return
    }

    const cookieToken = getTokenCookie()
    const stateToken = accessToken

    if ((!cookieToken && stateToken) || (cookieToken && !stateToken)) {
      dispatch(deauthenticate())

      return
    }

    if (cookieToken && previousPath.current !== LOGIN) {
      dispatch(refreshAccountInfo())
      dispatch(refreshToken())
      dispatch(updateEntities())

      if (!pathname.includes('verification')) {
        dispatch(getEntityCards())

        if (pathname !== PROFILE) {
          dispatch(getPendingCardOrders())
        }
      }
    }

    previousPath.current = pathname
  }, [pathname, accessToken, isRehydrated])

  useEffect(() => {
    if (IS_PRODUCTION) {
      const handleRouteChange = (url: string) => {
        recordPageview(url)
      }

      events.on('routeChangeComplete', handleRouteChange)

      return () => {
        events.off('routeChangeComplete', handleRouteChange)
      }
    }
  }, [events])

  const theme = { ...getTheme(), lang: language }

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>

      <ThemeProvider theme={theme}>
        <PersistGate persistor={(store as any).__persistor} loading={null}>
          {accessToken || UNAUTH_PAGES.some(v => pathname.includes(v)) ? (
            <Component {...pageProps} err={err} />
          ) : (
            <Placeholder />
          )}
        </PersistGate>

        <LogoutWarningModal />

        {isExchangeModalShown && <ExchangeModal />}
        {isReceiveModalShown && <ReceiveModal />}
        {isSendModalShown && <SendModal />}

        <GlobalStyles />
      </ThemeProvider>
    </>
  )
}

// MyApp.getInitialProps = async appContext => {
//   const typedContext = (appContext as unknown) as AppContextType<Router>

//   const appProps = (await App.getInitialProps(typedContext)) as AppProps
//   // const dispatch = typedContext.ctx.store.dispatch

//   // await dispatch((getConfig() as unknown) as AnyAction)

//   return {
//     ...appProps,
//     err: typedContext.ctx.err,
//   }
// }

export default wrapper.withRedux(appWithTranslation(MyApp))
