import { CardOrderStatusEnum } from '@fe/common/api/models/enums/CardOrderStatusEnum'
import { CardStatusEnum } from '@fe/common/api/models/enums/CardStatusEnum'
import { MfaTypeEnum } from '@fe/common/api/models/enums/MfaTypeEnum'
import { RequestStatusEnum } from '@fe/common/api/models/enums/RequestStatusEnum'
import { RequestTypeEnum } from '@fe/common/api/models/enums/RequestTypeEnum'
import { TransactionTypeEnum } from '@fe/common/api/models/enums/TransactionTypeEnum'
import { IdentityDocTypeEnum } from '../api/models/enums/IdentityDocTypeEnum'

export const requestTypeNames: Record<RequestTypeEnum, string> = {
  [RequestTypeEnum.Cash]: 'common:cash',
  [RequestTypeEnum.Crypto]: 'common:crypto',
  [RequestTypeEnum.CzkPayments]: 'common:czk-payments',
  [RequestTypeEnum.Exchange]: 'common:exchange',
  [RequestTypeEnum.Fee]: 'common:fee',
  [RequestTypeEnum.Interbank]: 'common:interbank',
  [RequestTypeEnum.Internal]: 'common:internal',
  [RequestTypeEnum.MtCards]: 'common:card',
  [RequestTypeEnum.MtCash]: 'common:mt-cash',
  [RequestTypeEnum.ReferralBonus]: 'common:referral-bonus',
  [RequestTypeEnum.Refund]: 'common:refund',
  [RequestTypeEnum.Sepa]: 'common:sepa',
  [RequestTypeEnum.WireTransfer]: 'common:wire-trans',
}

export const requestStatusNames: Record<RequestStatusEnum, string> = {
  [RequestStatusEnum.Canceled]: 'canceled',
  [RequestStatusEnum.InProgress]: 'in-progress',
  [RequestStatusEnum.New]: 'new',
  [RequestStatusEnum.Pended]: 'pended',
  [RequestStatusEnum.Rejected]: 'rejected',
  [RequestStatusEnum.Completed]: 'completed',
  [RequestStatusEnum.Prepared]: 'prepared',
}

export const transactionTypeNames: Record<TransactionTypeEnum, string> = {
  [TransactionTypeEnum.Deposit]: 'received',
  [TransactionTypeEnum.Withdraw]: 'sent',
}

export const cardStatusNames: Record<CardStatusEnum, string> = {
  [CardStatusEnum.New]: 'new',
  [CardStatusEnum.BankApproved]: 'verifying',
  [CardStatusEnum.Verified]: 'active',
  [CardStatusEnum.Rejected]: 'rejected',
  [CardStatusEnum.Canceled]: 'canceled',
  [CardStatusEnum.Verifying]: 'verifying',
}

export const orderStatusNames: Record<CardOrderStatusEnum, string> = {
  [CardOrderStatusEnum.New]: 'new',
  [CardOrderStatusEnum.InProgress]: 'in-progress',
  [CardOrderStatusEnum.Sending]: 'sending',
  [CardOrderStatusEnum.Sent]: 'sent',
  [CardOrderStatusEnum.Completed]: 'completed',
  [CardOrderStatusEnum.Filled]: 'filled',
  [CardOrderStatusEnum.Rejected]: 'rejected',
  [CardOrderStatusEnum.Canceled]: 'canceled',
  [CardOrderStatusEnum.Accepted]: 'accepted',
  [CardOrderStatusEnum.Hold]: 'hold',
  [CardOrderStatusEnum.CancelRequested]: 'cancelled',
}

export const mfaLabels: Record<MfaTypeEnum, string> = {
  [MfaTypeEnum.Ga]: 'g-auth',
  [MfaTypeEnum.Sms]: 'sms',
  [MfaTypeEnum.Call]: 'call',
  [MfaTypeEnum.Pin]: 'pin',
}

export const idDocTypes: Record<IdentityDocTypeEnum, string> = {
  [IdentityDocTypeEnum.Passport]: 'passport',
  [IdentityDocTypeEnum.NationalId]: 'national-id',
  [IdentityDocTypeEnum.ResidenceId]: 'residence-id',
  [IdentityDocTypeEnum.DrivingLicence]: 'driving-licence',
}
