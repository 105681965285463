import { useCallback, useEffect, useRef, useState } from 'react'
import { UseFormMethods } from 'react-hook-form'
import { City } from '@fe/common/api/models/City'
import { Country } from '@fe/common/api/models/Country'
import { MtBranch } from '@fe/common/api/models/MtBranch'
import { PaymentSystem } from '@fe/common/api/models/PaymentSystem'
import { RequestTypeEnum } from '@fe/common/api/models/enums/RequestTypeEnum'
import { useDidUpdate } from '@fe/common/hooks/useDidUpdate'
import { SendData } from './RequestTypeSteps/stepsConfig'
import { WithdrawContext } from './sendSchema'
import { WithdrawApi } from 'src/api/WithdrawApi'

export type MtData = {
  cardsCountries: Array<Country>
  cashCountries: Array<Country>
  cashSystems: Array<PaymentSystem>
  cashCities: Array<City>
  cashBranches: Array<MtBranch>
}

export const useMtData = (
  targetCurrencyId: number | undefined,
  requestType: RequestTypeEnum,
  watch: UseFormMethods<SendData>['watch'],
  setValue: UseFormMethods<SendData>['setValue'],
  setError: UseFormMethods<SendData>['setError'],
  setContext: React.Dispatch<React.SetStateAction<WithdrawContext>>
): MtData => {
  const [cardsCountries, setCardsCountries] = useState<Array<Country>>([])
  const [cashCountries, setCashCountries] = useState<Array<Country>>([])
  const [cashSystems, setCashSystems] = useState<Array<PaymentSystem>>([])
  const [cashCities, setCashCities] = useState<Array<City>>([])
  const [cashBranches, setCashBranches] = useState<Array<MtBranch>>([])

  const isFirstRender = useRef(true)

  const watchResults = watch([
    'requestTypeFields.mt_country_id',
    'requestTypeFields.mt_payment_system_id',
    'requestTypeFields.mt_city_id',
    'requestTypeFields.mt_branch_id',
  ])

  const mt_country_id = (watchResults as any)['requestTypeFields.mt_country_id']
  const mt_payment_system_id = (watchResults as any)[
    'requestTypeFields.mt_payment_system_id'
  ]
  const mt_city_id = (watchResults as any)['requestTypeFields.mt_city_id']

  const fetchMtCardsCountries = useCallback(async (settingsId: number) => {
    if (!settingsId) {
      return
    }

    const { data } = await WithdrawApi.getMtCardsCountries(settingsId)

    setCardsCountries(data)
  }, [])

  const fetchMtCashCountries = useCallback(async (settingsId: number) => {
    if (!settingsId) {
      return
    }

    const { data } = await WithdrawApi.getMtCashCountries(settingsId)

    setCashCountries(data)
  }, [])

  const fetchMtCashSystems = useCallback(
    async (settingsId: number, countryId: number) => {
      try {
        const { data } = await WithdrawApi.getMtCashSystems(
          settingsId,
          countryId
        )

        setCashSystems(data)
      } catch (error) {
        setError('requestTypeFields.mt_country_id', {
          message: 'unavailable-country',
        })
      }
    },
    []
  )

  const fetchMtCashCities = useCallback(
    async (paymentSystemId: number, countryId: number) => {
      try {
        const { data } = await WithdrawApi.getMtCashCities(
          paymentSystemId,
          countryId
        )

        setCashCities(data)
      } catch {
        setError('requestTypeFields.mt_payment_system_id', {
          message: 'unavailable-system',
        })
      }
    },
    []
  )

  const fetchMtCashBranches = useCallback(async (cityId: number) => {
    try {
      const { data } = await WithdrawApi.getMtCashBranches(cityId)

      setCashBranches(data)
    } catch {
      setError('requestTypeFields.mt_city_id', {
        message: 'unavailable-city',
      })
    }
  }, [])

  useDidUpdate(() => {
    setValue('requestTypeFields.mt_country_id', null)
    setValue('requestTypeFields.mt_payment_system_id', null)
    setValue('requestTypeFields.mt_city_id', null)
    setValue('requestTypeFields.mt_branch_id', null)

    setCashCountries([])
    setCashSystems([])
    setCashCities([])
    setCashBranches([])

    setContext(current => ({
      ...current,
      isMtCityRequired: false,
      isMtBranchRequired: false,
    }))
  }, [targetCurrencyId])

  useEffect(() => {
    if (requestType !== RequestTypeEnum.MtCards) {
      if (cardsCountries) {
        setCardsCountries([])
      }

      return
    }

    fetchMtCardsCountries(targetCurrencyId)
  }, [setValue, targetCurrencyId, requestType])

  useEffect(() => {
    if (requestType !== RequestTypeEnum.MtCash) {
      if (cashCountries) {
        setCashCountries([])
        setCashSystems([])
        setCashCities([])
        setCashBranches([])
      }

      return
    }

    if (!mt_payment_system_id) {
      isFirstRender.current = false
    }

    fetchMtCashCountries(targetCurrencyId)
  }, [setValue, targetCurrencyId, requestType])

  useEffect(() => {
    if (requestType !== RequestTypeEnum.MtCash || !mt_country_id) {
      return
    }

    if (!isFirstRender.current) {
      setValue('requestTypeFields.mt_payment_system_id', null)
      setValue('requestTypeFields.mt_city_id', null)
      setValue('requestTypeFields.mt_branch_id', null)

      setCashSystems([])
      setCashCities([])
      setCashBranches([])

      setContext(current => ({
        ...current,
        isMtCityRequired: false,
        isMtBranchRequired: false,
      }))
    }

    if (!mt_city_id) {
      isFirstRender.current = false
    }

    fetchMtCashSystems(targetCurrencyId, mt_country_id)
  }, [setValue, mt_country_id])

  useEffect(() => {
    if (
      requestType !== RequestTypeEnum.MtCash ||
      !mt_payment_system_id ||
      !mt_country_id
    ) {
      return
    }

    if (!isFirstRender.current) {
      // if (cashCities && cashBranches) {
      setValue('requestTypeFields.mt_city_id', null)
      setValue('requestTypeFields.mt_branch_id', null)

      setCashCities([])
      setCashBranches([])

      setContext(current => ({
        ...current,
        isMtCityRequired: false,
      }))
    }

    const isMtCityRequired = cashSystems.find(
      ({ id }) => id == mt_payment_system_id
    )?.city_required

    if (isMtCityRequired) {
      setContext(current => ({
        ...current,
        isMtCityRequired,
      }))

      fetchMtCashCities(mt_payment_system_id, mt_country_id)
    } else if (!mt_payment_system_id) {
      isFirstRender.current = false
    }
  }, [setValue, mt_payment_system_id, mt_country_id, cashSystems])

  useEffect(() => {
    if (requestType !== RequestTypeEnum.MtCash || !mt_city_id) {
      return
    }

    if (!isFirstRender.current) {
      setValue('requestTypeFields.mt_branch_id', null)

      setContext(current => ({
        ...current,
        isMtBranchRequired: false,
      }))

      setCashBranches([])
    }

    const isMtBranchRequired = cashCities.find(
      ({ id }) => id == mt_city_id
    )?.branch_required

    if (isMtBranchRequired) {
      setContext(current => ({
        ...current,
        isMtBranchRequired,
      }))

      fetchMtCashBranches(mt_city_id)
    } else if (!mt_city_id) {
      isFirstRender.current = false
    }
  }, [setValue, mt_city_id, cashCities])

  return {
    cardsCountries,
    cashCountries,
    cashSystems,
    cashCities,
    cashBranches,
  }
}
