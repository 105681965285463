const currencyToSymbolMap = require('currency-symbol-map/map')

export function getSymbolFromCurrency(code: string): string | undefined {
  return {
    ...currencyToSymbolMap,
    USDT: '₮',
    BCH: '₿',
    USDC: '$',
    TRX: 'TRX',
    EURT: '₮',
  }[code]
}
