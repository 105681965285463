import { Entity } from '@fe/common/api/models/Entity'
import { UserSettings } from '@fe/common/api/models/UserSettins'
import { MfaTypeEnum } from '@fe/common/api/models/enums/MfaTypeEnum'
import { SessionState } from 'src/state/reducers/sessionReducer'
import { AppState } from 'src/state/store'
import { getDefaultMfa, getIndividualEntity } from 'src/utils/stateUtils'

export const accessTokenSelector = (state: AppState): string =>
  state.session.accessToken

export const expirationDateSelector = (state: AppState): number =>
  state.session.expireAt

export const logoutModalStateSelector = (state: AppState): boolean =>
  state.session.isLogoutModalShown

export const userSettingsSelector = (state: AppState): UserSettings =>
  state.session.userSettings

export const defaultMfaSelector = (state: AppState): MfaTypeEnum =>
  getDefaultMfa(state.session.userSettings.mfa_settings)

export const entitiesSelector = (state: AppState): Array<Entity> =>
  state.session.entities

export const individualEntitySelector = (state: AppState): Entity =>
  getIndividualEntity(state.session.entities)

export const refreshStateSelector = (state: AppState): boolean =>
  state.session.isTokenRefreshing

export const entitiesUpdateStateSelector = (state: AppState): boolean =>
  state.session.areEntitiesUpdating

export const currentEntitySelector = (state: AppState): Entity =>
  state.session.entities.find(
    entity => entity.id === state.session.currentEntityId
  )

export const loginStateSelector = (
  state: AppState
): Pick<SessionState, 'loginError' | 'isLoginMfaWrong'> => ({
  loginError: state.session.loginError,
  isLoginMfaWrong: state.session.isLoginMfaWrong,
})

export const isRehydratedSelector = (state: AppState): boolean =>
  state._persist?.rehydrated
