import Axios, { AxiosPromise } from 'axios'
import { EnableMfaTokenData } from '@fe/common/api/models/EnableMfaTokenData'
import { MfaPreparationData } from '@fe/common/api/models/MfaPreparationData'
import { MfaToken } from '@fe/common/api/models/MfaToken'
import { RecoverMfaRequest } from '@fe/common/api/models/RecoverMfaRequest'
import { DisableMfaRequest } from '@fe/common/api/models/RequestModels/DisableMfaRequest'
import { EnableMfaRequest } from '@fe/common/api/models/RequestModels/EnableMfaRequest'
import { PrepareEnableMfaRequest } from '@fe/common/api/models/RequestModels/PrepareEnableMfaRequest'
import { PrepareFirstMfaRequest } from '@fe/common/api/models/RequestModels/PrepareFirstMfaRequest'
import { PrepareMfaRequest } from '@fe/common/api/models/RequestModels/PrepareMfaRequest'
import { ResendEmailRequest } from '@fe/common/api/models/RequestModels/ResendEmailRequest'
import { VerifiedRequest } from '@fe/common/api/models/RequestModels/VerifiedRequest'
import { MfaTypeEnum } from '@fe/common/api/models/enums/MfaTypeEnum'
import { RequestConfig } from '@fe/common/axiosConfig'

export class MfaApi {
  static prepareRecoverMfa(
    requestData: PrepareFirstMfaRequest,
    config?: RequestConfig
  ): AxiosPromise<MfaPreparationData> {
    return Axios({
      ...config,
      url: `/users/mfa/prepare-recovering`,
      method: 'POST',
      data: requestData,
    })
  }

  static recoverMfa(
    requestData: RecoverMfaRequest,
    config?: RequestConfig
  ): AxiosPromise<MfaToken> {
    return Axios({
      ...config,
      url: `/users/mfa/recover`,
      method: 'POST',
      data: requestData,
    })
  }

  static prepareMfa(
    mfaData: PrepareMfaRequest,
    config?: RequestConfig
  ): AxiosPromise<MfaPreparationData> {
    return Axios({
      ...config,
      url: `/users/mfa/prepare`,
      method: 'POST',
      data: mfaData,
    })
  }

  static getMfaTypes(
    config?: RequestConfig
  ): AxiosPromise<Record<MfaTypeEnum, string>> {
    return Axios({
      ...config,
      url: `/users/mfa/types`,
      method: 'GET',
    })
  }

  static getEnablingToken(
    data: VerifiedRequest,
    config?: RequestConfig
  ): AxiosPromise<EnableMfaTokenData> {
    return Axios({
      ...config,
      url: `/users/mfa/enabling-token`,
      method: 'POST',
      data,
    })
  }

  static prepareEnablingMfa(
    data: PrepareEnableMfaRequest,
    config?: RequestConfig
  ): AxiosPromise<MfaPreparationData> {
    return Axios({
      ...config,
      url: `/users/mfa/prepare-enabling`,
      method: 'POST',
      data,
    })
  }

  static enableMfa(
    data: EnableMfaRequest,
    config?: RequestConfig
  ): AxiosPromise<null> {
    return Axios({
      ...config,
      url: `/users/mfa/enable`,
      method: 'POST',
      data,
    })
  }

  static disableMfa(
    data: DisableMfaRequest,
    config?: RequestConfig
  ): AxiosPromise<null> {
    return Axios({
      ...config,
      url: `/users/mfa/disable`,
      method: 'POST',
      data,
    })
  }

  static resendMfaRecovery(
    data: ResendEmailRequest,
    config?: RequestConfig
  ): AxiosPromise<null> {
    return Axios({
      ...config,
      url: `/users/mfa/resend-recovery`,
      method: 'POST',
      data,
    })
  }
}
