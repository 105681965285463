import cookie from 'js-cookie'
import { GetServerSidePropsContext } from 'next'
import { ACCESS_TOKEN_COOKIE_KEY } from '@fe/common/constants/cookies'
import { NextContext } from '@fe/common/types/NextContext'

export const setCookie = (
  key: string,
  value: string,
  expires: Date | number = 0.1
) => {
  if (process.browser) {
    cookie.set(key, value, {
      expires,
      path: '/',
    })
  }
}

export const setTokenCookie = (accessToken: string, expireAt: number) => {
  const tokenExpirationDate = new Date(expireAt)

  setCookie(ACCESS_TOKEN_COOKIE_KEY, accessToken, tokenExpirationDate)
}

export const getTokenCookie = (): string | null =>
  cookie.get(ACCESS_TOKEN_COOKIE_KEY)

export const isTokenCookieAlive = (
  ctx?: NextContext | GetServerSidePropsContext
) => {
  if (ctx?.req) {
    return ctx.req.headers.cookie?.includes(ACCESS_TOKEN_COOKIE_KEY)
  }

  if (ctx && !ctx.req) {
    return null
  }

  const encryptedAccessToken = cookie.get(ACCESS_TOKEN_COOKIE_KEY)

  return !!encryptedAccessToken
}

export const removeCookie = (key: string) => {
  if (process.browser) {
    cookie.remove(key)
  }
}
