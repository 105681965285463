import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'
import TooltipIcon from '@fe/common/src/icons/tooltip.svg'
import { Tooltip } from './Tooltip'

const StyledTooltipIcon = styled(TooltipIcon)`
  flex-shrink: 0;
  margin-left: 0.5rem;
  cursor: pointer;
`

interface TipProps {
  className?: string
}

export const Tip: React.FC<TipProps> = ({ className, children }) => {
  const uuid = uuidv4()

  return (
    <>
      <Tooltip id={uuid}>
        <span>{children}</span>
      </Tooltip>

      <StyledTooltipIcon data-tip data-for={uuid} className={className} />
    </>
  )
}
