import { useTranslation } from 'next-i18next'
import {
  Fragment,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { BankDepositDetails } from '@fe/common/api/models/BankDepositDetails'
import { InternalDepositDetails } from '@fe/common/api/models/InternalDepositDetails'
import { RequestTypeEnum } from '@fe/common/api/models/enums/RequestTypeEnum'
import { LabeldList } from '@fe/common/components/LabeldList'
import { LoadingDots } from '@fe/common/components/LoadingDots'
import { ReceiveData } from '../..'
import {
  getBankDetailsConfig,
  TypeWithBankDetails,
} from './getBankDetailsConfig'
import { DepositsApi } from 'src/api/DepositsApi'
import { countriesSelector } from 'src/state/selectors/globalDataSelectors'

export const BankDetails: React.FC = () => {
  const { t } = useTranslation('transactions')
  const countries = useSelector(countriesSelector)
  const [isLoading, setIsLoading] = useState(false)
  const [detailsData, setDetailsData] = useState<
    BankDepositDetails | InternalDepositDetails
  >()

  const { getValues, watch } = useFormContext<ReceiveData>()

  const requestType = watch('request_type') as TypeWithBankDetails

  const fetchDetails = useCallback(async (requestType: TypeWithBankDetails) => {
    try {
      setIsLoading(true)

      const { settings_id } = getValues()

      let details: InternalDepositDetails | BankDepositDetails

      if (requestType === RequestTypeEnum.Internal) {
        const { data } = await DepositsApi.getInternalDetails(settings_id)

        details = data
      } else {
        const { data } = await DepositsApi.getBankDetails(
          requestType,
          settings_id
        )

        details = data
      }

      setDetailsData(details)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }, [])

  useLayoutEffect(() => {
    fetchDetails(requestType)
  }, [requestType])

  const summaryFields = useMemo(
    () =>
      getBankDetailsConfig(detailsData, countries)[requestType].map(
        ({ label, value }) =>
          (value || isLoading) && (
            <Fragment key={label as string}>
              <span>{t(label as string)}</span>
              {isLoading ? <LoadingDots /> : <span>{value}</span>}
            </Fragment>
          )
      ),
    [isLoading, detailsData, requestType]
  )

  return <LabeldList>{summaryFields}</LabeldList>
}
