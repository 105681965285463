import { CancelToken } from 'axios'
import { StorageApi } from '@fe/common/api/StorageApi'
import { Document } from '@fe/common/api/models/Document'
import {
  CONTENT_TYPE,
  DOCUMENT_DATA_HEADER,
} from '@fe/common/constants/headers'
import { PersistedDocument, FormDocument } from '@fe/common/types/DocumentTypes'
import { awaitAll } from '@fe/common/utils/arrayUtils'
import { base64ToFile } from '@fe/common/utils/binaryUtils'
import { PartialKeys } from '../types/PartialKeys'

export const addFilePreviews = (files: Array<File>) =>
  files.map(file =>
    Object.assign(file, {
      preview: URL.createObjectURL(file),
    })
  )

export const formatPersistedFiles = <
  T extends Record<K, PersistedDocument>,
  K extends keyof T
>(
  initialDocuments: T
): Record<K, FormDocument> => {
  const documentFiles: Record<K, FormDocument> = {} as any

  for (const key in initialDocuments) {
    documentFiles[key as unknown as K] = {
      files: [],
      id: initialDocuments[key].id || null,
      uploadedCount: initialDocuments[key].uploadedCount || null,
    }
  }

  if (!initialDocuments) {
    return documentFiles
  }

  for (const [name, fileData] of Object.entries(initialDocuments)) {
    let files: Array<File> = []

    for (const { data, filename } of (fileData as PersistedDocument).files) {
      files = [...files, base64ToFile(data, filename)]
    }

    const filesWithPreviews = addFilePreviews(files)

    documentFiles[name as K].files = filesWithPreviews
  }

  return documentFiles
}

export const getFileName = (headers: Record<string, string>) => {
  const fileName = (
    headers[DOCUMENT_DATA_HEADER].split('filename=')[1] as string
  ).replaceAll('"', '')

  return fileName
}

export const downloadFile = (name: string, blob: Blob, extension?: string) => {
  const fileWithExtension = `${name}${extension || ''}`

  if (navigator && (navigator as any).msSaveBlob) {
    ;(navigator as any).msSaveBlob(blob, fileWithExtension)
  } else {
    const fileUri = URL.createObjectURL(blob)

    const downloadLink = document.createElement('a')

    downloadLink.download = fileWithExtension
    downloadLink.href = fileUri

    downloadLink.click()

    URL.revokeObjectURL(fileUri)
  }
}

export const fetchStorageFiles = async <
  T extends PartialKeys<Document, 'issue_date' | 'id'>
>(
  document: T,
  cancelToken?: CancelToken
): Promise<Array<File>> => {
  if (!document || document.files.length === 0) {
    return []
  }

  const downloadedFiles: Array<File> = []

  try {
    await awaitAll(document.files, async ({ url }) => {
      const { data: file, headers } = await StorageApi.downloadFile(url, {
        cancelToken,
      })

      const fileName = getFileName(headers)

      downloadedFiles.push(
        new File([file], fileName, { type: headers[CONTENT_TYPE] })
      )
    })
  } catch (error) {
    console.error(error)
  }

  return downloadedFiles
}
