import Axios, { AxiosPromise } from 'axios'
import { Entity } from '@fe/common/api/models/Entity'
import { RequestConfig } from '@fe/common/axiosConfig'

export class EntityApi {
  static getEntitiesList(
    config?: RequestConfig
  ): AxiosPromise<Array<Omit<Entity, 'uploaded_files'>>> {
    return Axios({
      ...config,
      url: `/entities`,
      method: 'GET',
    })
  }

  static createEntity(
    entity: Entity,
    config?: RequestConfig
  ): AxiosPromise<Omit<Entity, 'uploaded_files'>> {
    return Axios({
      ...config,
      url: `/entities`,
      method: 'POST',
      data: entity,
    })
  }

  static updateEntity(
    id: number,
    entity: Partial<
      Omit<Entity, 'type' | 'id'> & { has_eu_bank_account: boolean }
    >,
    config?: RequestConfig
  ): AxiosPromise<Omit<Entity, 'uploaded_files'>> {
    return Axios({
      ...config,
      url: `/entities/${id}`,
      method: 'PUT',
      data: entity,
    })
  }
}
