import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

export const {
  appName,
  serverApiUrl,
  apiUrl,
  recaptchaSiteKey,
  recaptchaSecret,
  gaTrackingId,
  domainLanding,
  sentryDsn,
} = publicRuntimeConfig

export const IS_PRODUCTION = process.env.NODE_ENV === 'production'
export const IS_TESTING = !['demo', 'production'].includes(
  process.env.PROJECT_ENV
)
