import {
  createAsyncThunk,
  createAction,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import { CancelToken } from 'axios'
import { HYDRATE } from 'next-redux-wrapper'
import { ApplicationQuestionTypeEnum } from '@fe/common/src/api/models/enums/ApplicationQuestionTypeEnum'
import {
  fetchStorageFiles,
  formatPersistedFiles,
} from '@fe/common/utils/fileUtils'
import { AddressDocsApi } from 'src/api/AddressDocsApi'
import { CompanyDocsApi } from 'src/api/CompanyDocsApi'
import { IdentityDocsApi } from 'src/api/IdentityDocsApi'
import { ShareholdersApi } from 'src/api/ShareholdersApi'
import { AmlData } from 'src/components/verification/AMLQuestionnaireStep'
import {
  DocumentTypesData,
  PeronalDocuments,
} from 'src/components/verification/DocumentsStep'
import { FundsInfoData } from 'src/components/verification/FundsInfoStep'
import { PersonalInfoData } from 'src/components/verification/PersonalInfoStep'
import { AppState } from 'src/state/store'
import { PersistedDocument } from 'src/types/DocumentTypes'
import { filesToB64Docs } from 'src/utils/stateUtils'

export const getDocuments = createAsyncThunk<
  {
    newB64Docs: IndividualB64Documents
    documentTypes: DocumentTypesData
    hasNoEuBank: boolean
  },
  CancelToken,
  {
    state: AppState
  }
>('verification/getDocuments', async (cancelToken, { getState }) => {
  const {
    session: { currentEntityId },
    verification: {
      documents: persistedDocuments,
      documentTypes: persistedTypes,
    },
  } = getState()

  const {
    data: [{ person_id }],
  } = await ShareholdersApi.getShareholders(currentEntityId, {
    cancelToken,
  })

  try {
    const {
      data: [bankStatement],
    } = await CompanyDocsApi.getDocuments(currentEntityId, {
      cancelToken,
    })

    const {
      data: [proofOfAddress],
    } = await AddressDocsApi.getDocuments(person_id, {
      cancelToken,
    })

    const {
      data: [idOne, idTwo],
    } = await IdentityDocsApi.getDocuments(person_id)

    const formattedInitialDocuments = formatPersistedFiles(persistedDocuments)
    const personalDocs: PeronalDocuments = { ...formattedInitialDocuments }

    const downloadedDocuments = {
      idOne,
      idTwo,
      proofOfAddress,
      bankStatement,
    }

    for (const [name, document] of Object.entries(downloadedDocuments)) {
      if (document?.files.length) {
        const typedName = name as keyof PeronalDocuments
        const docFiles = await fetchStorageFiles(document)

        const unuploadedFiles = personalDocs[typedName]?.files.slice(
          personalDocs[typedName].uploadedCount
        )

        personalDocs[typedName].files = [
          ...(docFiles || []),
          ...unuploadedFiles,
        ]
        personalDocs[typedName].id = document.id
        personalDocs[typedName].uploadedCount = docFiles.length
      }
    }

    const newB64Docs = (await filesToB64Docs(
      personalDocs
    )) as IndividualB64Documents

    const documentTypes = {
      ...persistedTypes,
    }

    idOne?.type && (documentTypes.idOne = idOne.type)
    idTwo?.type && (documentTypes.idTwo = idTwo.type)

    return {
      newB64Docs,
      documentTypes,
      hasNoEuBank: !personalDocs.bankStatement.uploadedCount,
    }
  } catch (error) {
    console.error(error)
  }
})

export enum VerificationStepEnum {
  PersonalInfo = 1,
  Documents,
  SelfieID,
  FundsInfo,
  AMLQuestionnaire,
  Declaration,
  Completed = 10,
}

export type IndividualB64Documents = {
  idOne: PersistedDocument
  idTwo: PersistedDocument
  proofOfAddress: PersistedDocument
  bankStatement: PersistedDocument
}

export interface VerificationState {
  currentStep: VerificationStepEnum
  availableStep: VerificationStepEnum
  isCompleted: boolean
  formValues: PersonalInfoData
  applicationValues: FundsInfoData
  areDocumentsLoading: boolean
  documents: IndividualB64Documents
  documentTypes: DocumentTypesData
  hasNoEuBank: boolean
  amlValues: Partial<AmlData>
}

export const initialVerificationState: VerificationState = {
  currentStep: VerificationStepEnum.PersonalInfo,
  availableStep: VerificationStepEnum.PersonalInfo,
  isCompleted: false,
  formValues: {
    name: '',
    birth_place: '',
    birth_country_id: null,
    birth_date: null,
    [ApplicationQuestionTypeEnum.TaxResidence]: [],
    citizenship: [],
    personal_code: '',
    residence: [
      {
        address: '',
        city: '',
        country_id: null,
        zip: '',
      },
    ],
    telephone: '',
    fatca: null,
    pep: null,
    pep_first_name: '',
    pep_last_name: '',
    pep_position: '',
    pep_organization_name: '',
    pep_wealth: '',
    pep_country_id: null,
    pep_relationship: '',
    pep_date_of_termination: null,
    personal_use: null,
  },
  applicationValues: {
    purpose: [],
    purpose_details: [],
    other_purpose: null,
    source: [],
    source_details: [],
    other_source: null,
    wealth: [],
    wealth_details: [],
    other_wealth: null,
    type: [],
    type_details: [],
  },
  areDocumentsLoading: false,
  documents: {
    idOne: { files: [], id: null, uploadedCount: null },
    idTwo: { files: [], id: null, uploadedCount: null },
    proofOfAddress: { files: [], id: null, uploadedCount: null },
    bankStatement: { files: [], id: null, uploadedCount: null },
  },
  documentTypes: {
    idOne: null,
    idTwo: null,
    idOneOneDoc: false,
    idTwoOneDoc: false,
  },
  hasNoEuBank: false,
  amlValues: {
    monthly_turnover: '',
    monthly_inward_turnover: '',
    monthly_outward_turnover: '',
    outward_transaction: '',
    inward_transaction: '',
    [ApplicationQuestionTypeEnum.OutgoingGeography]: [],
    [ApplicationQuestionTypeEnum.IncommingGeography]: [],
    [ApplicationQuestionTypeEnum.IncomingDescription]: '',
    [ApplicationQuestionTypeEnum.OutgoingDescription]: '',
  },
}

const hydrate = createAction<AppState>(HYDRATE)

const verificationSlice = createSlice({
  name: 'verification',
  initialState: initialVerificationState,
  reducers: {
    changeCurrentStep(state, action: PayloadAction<VerificationStepEnum>) {
      const nextStep = action.payload
      state.currentStep = nextStep
    },
    changeAvailableStep(state, action: PayloadAction<VerificationStepEnum>) {
      const nextStep = action.payload
      state.availableStep = nextStep
    },
    progressToStep(state, action: PayloadAction<VerificationStepEnum>) {
      const nextStep = action.payload

      state.currentStep = nextStep

      if (nextStep > state.availableStep) {
        state.availableStep = nextStep
      }
    },
    completeVerification() {
      return { ...initialVerificationState, isCompleted: true }
    },
    addFormValues(state, action: PayloadAction<PersonalInfoData>) {
      state.formValues = { ...state.formValues, ...action.payload }
    },
    addApplicationValues(state, action: PayloadAction<FundsInfoData>) {
      state.applicationValues = {
        ...state.applicationValues,
        ...action.payload,
      }
    },
    addDocuments(state, action: PayloadAction<IndividualB64Documents>) {
      state.documents = action.payload
    },
    addDocument(
      state,
      action: PayloadAction<{ key: string; document: PersistedDocument }>
    ) {
      state.documents = {
        ...state.documents,
        [action.payload.key]: action.payload.document,
      }
    },
    addDocumentTypes(state, action: PayloadAction<DocumentTypesData>) {
      state.documentTypes = action.payload
    },
    setHasNoEuBank(state, action: PayloadAction<boolean>) {
      state.hasNoEuBank = action.payload
    },
    addAmlValues(state, action: PayloadAction<AmlData>) {
      state.amlValues = action.payload
    },
    flushIndividual() {
      return initialVerificationState
    },
  },
  extraReducers: builder => {
    builder.addCase(getDocuments.pending, state => ({
      ...state,
      areDocumentsLoading: true,
    }))
    builder.addCase(getDocuments.fulfilled, (state, action) => ({
      ...state,
      areDocumentsLoading: false,
      documentTypes: action.payload?.documentTypes,
      documents: action.payload?.newB64Docs,
      hasNoEuBank: action.payload?.hasNoEuBank,
    }))
    builder.addCase(getDocuments.rejected, state => ({
      ...state,
      areDocumentsLoading: false,
      documentTypes: null,
      documents: null,
    }))
    builder.addCase(hydrate, state => state)
  },
})

const { actions, reducer } = verificationSlice

export const {
  changeCurrentStep,
  changeAvailableStep,
  progressToStep,
  completeVerification,
  addFormValues,
  addApplicationValues,
  addDocument,
  addDocuments,
  addDocumentTypes,
  setHasNoEuBank,
  addAmlValues,
  flushIndividual,
} = actions

export default reducer
