import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'
import { Transaction } from '@fe/common/api/models/Transaction'
import { AppState } from 'src/state/store'

const hydrate = createAction<AppState>(HYDRATE)

export interface SendModalState {
  isOpened: boolean
  selectedCurrencyId: number
  repeatTransaction: Transaction
}

export const initialSendModalState: SendModalState = {
  isOpened: false,
  selectedCurrencyId: null,
  repeatTransaction: null,
}

const sendModalSlice = createSlice({
  name: 'sendModal',
  initialState: initialSendModalState,
  reducers: {
    flushSendModal() {
      return initialSendModalState
    },
    openSendModal(
      state,
      {
        payload,
      }: PayloadAction<{
        transaction?: Transaction
        currencyId?: number
      }>
    ) {
      state.isOpened = true

      if (payload?.transaction) {
        state.repeatTransaction = payload.transaction
      }

      if (payload?.currencyId) {
        state.selectedCurrencyId = payload.currencyId
      }
    },
    closeSendModal(state) {
      state.isOpened = false
      state.repeatTransaction = null
      state.selectedCurrencyId = null
    },
  },
  extraReducers: builder => {
    builder.addCase(hydrate, state => state)
  },
})

const { actions, reducer } = sendModalSlice

export const { flushSendModal, openSendModal, closeSendModal } = actions

export default reducer
