import { RequestTypeEnum } from '@fe/common/api/models/enums/RequestTypeEnum'
import { DropdownOptions } from '@fe/common/components/inputs/CustomSelect'
import { formatAddress } from '@fe/common/utils/stringUtils'
import { MtData } from '../../useMtData'
import {
  CryptoFields,
  CzkFields,
  InterbankFields,
  InternalFields,
  MtCardFields,
  MtCashFields,
  SendableRequestTypes,
  SendData,
  SepaFields,
  WireFields,
} from '../stepsConfig'
import { SendStepsEnum } from 'src/types/enums/SendStepsEnum'

export const getSummaryConfig = (
  { requestTypeFields: values }: SendData,
  countries: Record<number, string>,
  bankOptions: DropdownOptions,
  mtData: MtData
): Record<
  SendableRequestTypes,
  Record<number, Array<{ label: string; value: string | number }>>
> => ({
  [RequestTypeEnum.WireTransfer]: {
    [SendStepsEnum.General]: [
      {
        label: 'account-number',
        value: (values as WireFields).bank_account_number,
      },
      {
        label: 'swift',
        value: (values as WireFields).bank_code,
      },
      {
        label: 'payment-details',
        value: (values as WireFields).payment_details,
      },
    ],
    [SendStepsEnum.Beneficiary]: [
      {
        label: 'beneficiary-name',
        value: (values as WireFields).beneficiary_name,
      },
      {
        label: 'beneficiary-address',
        value: formatAddress(
          (values as WireFields).address_line,
          (values as WireFields).zip,
          (values as WireFields).city,
          (values as WireFields).country_id,
          countries
        ),
      },
    ],
    [SendStepsEnum.Bank]: [
      {
        label: 'bank-name',
        value: (values as WireFields).bank_name,
      },
      {
        label: 'bank-address',
        value: formatAddress(
          (values as WireFields).bank_address,
          (values as WireFields).bank_zip,
          (values as WireFields).bank_city,
          (values as WireFields).bank_country_id,
          countries
        ),
      },
    ],
  },
  [RequestTypeEnum.Sepa]: {
    [SendStepsEnum.General]: [
      {
        label: 'IBAN',
        value: (values as SepaFields).bank_account_number,
      },
      {
        label: 'swift',
        value: (values as SepaFields).bank_code,
      },
      {
        label: 'payment-details',
        value: (values as SepaFields).payment_details,
      },
    ],
    [SendStepsEnum.Beneficiary]: [
      {
        label: 'beneficiary-name',
        value: (values as SepaFields).beneficiary_name,
      },
      {
        label: 'beneficiary-address',
        value: formatAddress(
          (values as SepaFields).address_line,
          (values as SepaFields).zip,
          (values as SepaFields).city,
          (values as SepaFields).country_id,
          countries
        ),
      },
    ],
  },
  [RequestTypeEnum.CzkPayments]: {
    [SendStepsEnum.General]: [
      {
        label: 'account-number',
        value: (values as CzkFields).bank_account_number,
      },
      {
        label: 'bank-code',
        value: (values as CzkFields).bank_code,
      },
      {
        label: 'beneficiary-name',
        value: (values as CzkFields).beneficiary_name,
      },
      {
        label: 'variable-symbol',
        value: (values as CzkFields).variable_symbol,
      },
      {
        label: 'constant-symbol',
        value: (values as CzkFields).constant_symbol || '-',
      },
      {
        label: 'specific-symbol',
        value: (values as CzkFields).specific_symbol || '-',
      },
      {
        label: 'payment-details',
        value: (values as CzkFields).payment_details || '-',
      },
    ],
  },
  [RequestTypeEnum.Internal]: {
    [SendStepsEnum.General]: [
      {
        label: 'account-number',
        value: (values as InternalFields).bank_account_number,
      },
      {
        label: 'payment-details',
        value: (values as InternalFields).payment_details,
      },
    ],
  },
  [RequestTypeEnum.Crypto]: {
    [SendStepsEnum.General]: [
      {
        label: 'address',
        value: (values as CryptoFields).address,
      },
      {
        label: 'payment-details',
        value: (values as CryptoFields).payment_details || '-',
      },
    ],
  },
  [RequestTypeEnum.Interbank]: {
    [SendStepsEnum.General]: [
      {
        label: 'bank-name',
        value: bankOptions.find(
          bank => bank.value === (values as InterbankFields).bank_id
        )?.label as string,
      },
      {
        label: 'account-number',
        value: (values as InterbankFields).bank_account_number,
      },
      {
        label: 'beneficiary-name',
        value: (values as InterbankFields).beneficiary_name,
      },
    ],
  },
  [RequestTypeEnum.MtCash]: {
    [SendStepsEnum.General]: [
      {
        label: 'beneficiary-name',
        value: `${(values as MtCashFields).first_name} ${
          (values as MtCashFields).last_name
        }`,
      },
      {
        label: 'beneficiary-phone',
        value: (values as MtCashFields).beneficiary_phone,
      },
      {
        label: 'payment-system',
        value: mtData.cashSystems.find(
          ({ id }) => id === (values as MtCashFields).mt_payment_system_id
        )?.name,
      },
      {
        label: 'branch-name',
        value:
          mtData.cashBranches.find(
            ({ id }) => id === (values as MtCashFields).mt_branch_id
          )?.name || '-',
      },
      {
        label: 'beneficiary-address',
        value: `${
          mtData.cashCities.find(
            ({ id }) => id === (values as MtCashFields).mt_city_id
          )?.name
        }, ${
          mtData.cashCountries.find(
            ({ id }) => id === (values as MtCashFields).mt_country_id
          )?.name
        }`,
      },
      {
        label: 'payment-purpose',
        value: (values as MtCashFields).mt_payment_purpose,
      },
    ],
  },
  [RequestTypeEnum.MtCards]: {
    [SendStepsEnum.General]: [
      {
        label: 'beneficiary-name',
        value: `${(values as MtCardFields).first_name} ${
          (values as MtCardFields).last_name
        }`,
      },
      {
        label: 'beneficiary-phone',
        value: (values as MtCardFields).beneficiary_phone,
      },
      {
        label: 'card-number',
        value: (values as MtCardFields).card_number,
      },
      {
        label: 'country',
        value: mtData.cardsCountries.find(
          ({ id }) => id === (values as MtCardFields).mt_country_id
        )?.name,
      },
      {
        label: 'payment-purpose',
        value: (values as MtCardFields).mt_payment_purpose,
      },
    ],
  },
})
