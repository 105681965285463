import format from 'date-fns/format'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import { OrderTransactions } from '@fe/common/api/models/OrderTransactions'
import { Amount } from '@fe/common/components/Amount'
import { ClipboardCopy } from '@fe/common/components/ClipboardCopy'
import { BORDER, COLOR, FONT_WEIGHT, SCREEN } from '@fe/common/constants/main'
import ArrowIcon from '@fe/common/src/icons/arrow.svg'
import { getSymbolFromCurrency } from '@fe/common/utils/currencyUtils'

const Container = styled.div`
  position: relative;
  margin-left: 0;
  padding-bottom: 10rem;

  ${SCREEN.ABOVE_MOBILE} {
    padding-bottom: 0;
  }

  ${SCREEN.ABOVE_TABLET} {
    margin-left: 2rem;
  }
`

const Item = styled.div`
  display: grid;
  grid-template-columns: auto auto 1fr 13rem;
  grid-template-rows: auto auto 1rem auto auto;
  grid-column-gap: 0;
  grid-row-gap: 0;
  padding: 1rem 0;

  :first-child {
    padding-top: 0;
  }

  :not(:last-child) {
    border-bottom: ${BORDER.DEFAULT};
  }

  ${SCREEN.ABOVE_TABLET} {
    grid-column-gap: 3rem;
    grid-template-rows: 1fr 1fr;
  }
`

const ArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 1;
  grid-row: 1 / span 5;
  padding: 0 1rem;

  ${SCREEN.ABOVE_TABLET} {
    grid-column: 1;
    grid-row: 1 / span 2;
  }
`

interface TransactionArrowProps {
  isPoitingRight: boolean
}

const TransactionArrow = styled(ArrowIcon).withConfig<TransactionArrowProps>({
  shouldForwardProp: prop => 'isPoitingRight' !== prop,
})<TransactionArrowProps>`
  transform: ${({ isPoitingRight }) =>
    isPoitingRight ? undefined : 'rotate(180deg)'};
`

const TransactionType = styled.div`
  grid-column: 2;
  grid-row: 1;
`

const Date = styled.div`
  grid-column: 2;
  grid-row: 2;
  color: ${COLOR.GREY};
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
`

const AddressContainer = styled.div`
  display: flex;
  grid-column: 2 / span 3;
  grid-row: 4;
  overflow: hidden;

  ${SCREEN.ABOVE_TABLET} {
    grid-column: 3;
    grid-row: 1;
  }
`

const StyledClipboardCopy = styled(ClipboardCopy)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const AddressPrefix = styled.div`
  color: ${COLOR.GREY};
`

const StyledIdContainer = styled.div`
  display: flex;
  grid-column: 2 / span 3;
  grid-row: 5;
  color: ${COLOR.GREY};
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
  overflow: hidden;

  ${SCREEN.ABOVE_TABLET} {
    grid-column: 3;
    grid-row: 2;
  }
`

const StyledAmount = styled(Amount)<{ isReceive: boolean }>`
  grid-column: 4;
  grid-row: 1;
  margin-right: 1rem;
  text-align: right;
  color: ${({ isReceive }) => isReceive && COLOR.GREEN};
`

const Confirmations = styled.div`
  grid-column: 4;
  grid-row: 2;
  margin-right: 1rem;
  text-align: right;
  color: ${COLOR.GREY};
`

const timestampToDateString = (timestamp: number) =>
  format(timestamp * 1000, 'dd.MM.yyyy, HH:mm')

enum TransactionTypeEnum {
  DEPOSIT = 1,
  WITHDRAW = 2,
}

interface TransactionListProps {
  transactions: OrderTransactions[]
  currency_in: string
  precision_in: number
}

export const TransactionList: React.FC<TransactionListProps> = ({
  transactions,
  currency_in,
  precision_in,
}) => {
  const [t] = useTranslation('receive-status')

  return (
    <Container>
      {transactions.map(
        (
          {
            amount,
            confirmations_have,
            confirmations_need,
            account_number,
            timestamp,
            txid,
            type,
          },
          index
        ) => (
          <Item key={index}>
            <ArrowContainer>
              <TransactionArrow
                isPoitingRight={type === TransactionTypeEnum.DEPOSIT}
              />
            </ArrowContainer>

            <TransactionType>
              {type === TransactionTypeEnum.DEPOSIT
                ? t('transaction-deposited-label', {
                    currency: getSymbolFromCurrency(currency_in),
                  })
                : t('transaction-received-label', {
                    currency: getSymbolFromCurrency(currency_in),
                  })}
            </TransactionType>
            <Date>{timestampToDateString(timestamp)}</Date>

            <AddressContainer>
              <AddressPrefix>{t('transaction-to-address-label')}</AddressPrefix>
              &nbsp;
              <StyledClipboardCopy text={txid} isAbsolute>
                {txid}
              </StyledClipboardCopy>
            </AddressContainer>
            <StyledIdContainer>
              {t('transaction-id-label')} &nbsp;
              <StyledClipboardCopy text={account_number} isAbsolute>
                {account_number}
              </StyledClipboardCopy>
            </StyledIdContainer>

            <StyledAmount
              value={amount}
              decimals={precision_in}
              code={currency_in}
              sign={type === TransactionTypeEnum.DEPOSIT ? undefined : '+'}
              isReceive={type === TransactionTypeEnum.WITHDRAW}
            />
            <Confirmations>
              {t('transaction-confirmations-label', {
                confirmations_have:
                  confirmations_have > confirmations_need
                    ? confirmations_need
                    : confirmations_have,
                confirmations_need,
              })}
            </Confirmations>
          </Item>
        )
      )}
    </Container>
  )
}
