import { BankDepositDetails } from '@fe/common/api/models/BankDepositDetails'
import { InternalDepositDetails } from '@fe/common/api/models/InternalDepositDetails'
import { RequestTypeEnum } from '@fe/common/api/models/enums/RequestTypeEnum'
import { DropdownOptions } from '@fe/common/components/inputs/CustomSelect'
import { formatAddress } from '@fe/common/utils/stringUtils'

export type TypeWithBankDetails =
  | RequestTypeEnum.WireTransfer
  | RequestTypeEnum.Sepa
  | RequestTypeEnum.Internal
  | RequestTypeEnum.CzkPayments
  | RequestTypeEnum.Internal

export const getBankDetailsConfig = (
  detailsData: BankDepositDetails | InternalDepositDetails,
  countries: Record<number, string>
): Record<TypeWithBankDetails, DropdownOptions> => ({
  [RequestTypeEnum.WireTransfer]: [
    {
      label: 'beneficiary-name',
      value: (detailsData as BankDepositDetails)?.beneficiary_name,
    },
    {
      label: 'beneficiary-address',
      value: formatAddress(
        (detailsData as BankDepositDetails)?.beneficiary_address,
        (detailsData as BankDepositDetails)?.beneficiary_zip,
        (detailsData as BankDepositDetails)?.beneficiary_city,
        (detailsData as BankDepositDetails)?.beneficiary_country_id,
        countries
      ),
    },
    {
      label: 'account-number',
      value: (detailsData as BankDepositDetails)?.bank_account_number,
    },
    {
      label: 'bank-name',
      value: (detailsData as BankDepositDetails)?.bank_name,
    },
    {
      label: 'bank-address',
      value: formatAddress(
        (detailsData as BankDepositDetails)?.bank_address,
        (detailsData as BankDepositDetails)?.bank_zip,
        (detailsData as BankDepositDetails)?.bank_city,
        (detailsData as BankDepositDetails)?.bank_country_id,
        countries
      ),
    },
    {
      label: 'bank-code',
      value: (detailsData as BankDepositDetails)?.bank_code,
    },
    {
      label: 'payment-details',
      value: (detailsData as BankDepositDetails)?.payment_details,
    },
  ],
  [RequestTypeEnum.Sepa]: [
    {
      label: 'beneficiary-name',
      value: (detailsData as BankDepositDetails)?.beneficiary_name,
    },
    {
      label: 'beneficiary-address',
      value: formatAddress(
        (detailsData as BankDepositDetails)?.beneficiary_address,
        (detailsData as BankDepositDetails)?.beneficiary_zip,
        (detailsData as BankDepositDetails)?.beneficiary_city,
        (detailsData as BankDepositDetails)?.beneficiary_country_id,
        countries
      ),
    },
    {
      label: 'IBAN',
      value: (detailsData as BankDepositDetails)?.bank_account_number,
    },
    {
      label: 'bank-code',
      value: (detailsData as BankDepositDetails)?.bank_code,
    },
    {
      label: 'bank-name',
      value: (detailsData as BankDepositDetails)?.bank_name,
    },
    {
      label: 'bank-address',
      value: formatAddress(
        (detailsData as BankDepositDetails)?.bank_address,
        (detailsData as BankDepositDetails)?.bank_zip,
        (detailsData as BankDepositDetails)?.bank_city,
        (detailsData as BankDepositDetails)?.bank_country_id,
        countries
      ),
    },

    {
      label: 'payment-details',
      value: (detailsData as BankDepositDetails)?.payment_details,
    },
  ],
  [RequestTypeEnum.CzkPayments]: [
    {
      label: 'beneficiary-name',
      value: (detailsData as BankDepositDetails)?.beneficiary_name,
    },
    {
      label: 'beneficiary-address',
      value: formatAddress(
        (detailsData as BankDepositDetails)?.beneficiary_address,
        (detailsData as BankDepositDetails)?.beneficiary_zip,
        (detailsData as BankDepositDetails)?.beneficiary_city,
        (detailsData as BankDepositDetails)?.beneficiary_country_id,
        countries
      ),
    },
    {
      label: 'account-number',
      value: (detailsData as BankDepositDetails)?.local_number,
    },
    {
      label: 'bank-code',
      value: (detailsData as BankDepositDetails)?.local_bank_code,
    },
    {
      label: 'bank-name',
      value: (detailsData as BankDepositDetails)?.bank_name,
    },
    {
      label: 'bank-address',
      value: formatAddress(
        (detailsData as BankDepositDetails)?.bank_address,
        (detailsData as BankDepositDetails)?.bank_zip,
        (detailsData as BankDepositDetails)?.bank_city,
        (detailsData as BankDepositDetails)?.bank_country_id,
        countries
      ),
    },

    {
      label: 'payment-details',
      value: (detailsData as BankDepositDetails)?.payment_details,
    },
  ],
  [RequestTypeEnum.Internal]: [
    {
      label: 'account-number',
      value: (detailsData as InternalDepositDetails)?.account_number,
    },
  ],
})
