import { css, keyframes } from 'styled-components'

export const opacityReveal = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

export const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const blinkFrames = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
`

export const blink = css`
  animation: ${blinkFrames} 1s infinite ease-in-out;
`
