import Axios, { AxiosPromise } from 'axios'
import { Config } from '@fe/common/api/models/Config'
import { RequestConfig } from '@fe/common/axiosConfig'

export class ConfigApi {
  static getConfig(config?: RequestConfig): AxiosPromise<Config> {
    return Axios({
      ...config,
      url: `/config`,
      method: 'GET',
    })
  }
}
