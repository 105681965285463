import { useTranslation } from 'next-i18next'
import styled, { css } from 'styled-components'
import { opacityReveal } from '@fe/common/constants/animations'
import { COLOR, FONT_SIZE, SHADOW } from '@fe/common/constants/main'
import { SendStepsEnum } from 'src/types/enums/SendStepsEnum'

const Container = styled.div`
  position: relative;
  top: -1rem;
  display: flex;
  width: min-content;
  margin: 0 auto;
  padding: 0.7rem 1rem;
  background: ${COLOR.WHITE};
  border-radius: 10px;
`

interface StepProps {
  isActive: boolean
  stepName: string
  isCompleted?: boolean
}

const Step = styled.div<StepProps>`
  width: 3rem;
  height: 0.6rem;
  background: ${({ theme }) => theme.primary};
  border-radius: 3px;
  opacity: ${({ isActive }) => !isActive && 0.2};
  will-change: transform;
  transition: transform 0.1s ease-in-out;
  cursor: ${({ isCompleted }) => isCompleted && 'pointer'};

  :not(:last-child) {
    margin-right: 1rem;
  }

  ${({ stepName, isCompleted }) =>
    isCompleted &&
    css`
      :hover {
        transform: scale(1.2);

        ::after {
          content: '${stepName}';
          position: absolute;
          top: -3rem;
          left: -1rem;
          padding: 0.2rem 1rem;
          border-radius: 6px;
          background: ${COLOR.WHITE};
          box-shadow: ${SHADOW.MAIN};
          font-size: ${FONT_SIZE.EXTRA_SMALL};
          white-space: nowrap;
          animation: ${opacityReveal} 0.2s ease-in-out;
        }
      }
    `};
`

export const stepNames: Record<number, string> = {
  [SendStepsEnum.General]: 'transaction-details',
  [SendStepsEnum.Beneficiary]: 'beneficiary-details',
  [SendStepsEnum.Bank]: 'bank-details',
  [SendStepsEnum.Summary]: 'summary',
  [SendStepsEnum.Mfa]: 'confirm-transaction',
}

interface StepperProps {
  steps: Array<SendStepsEnum>
  currentStep: number
  setStep: React.Dispatch<React.SetStateAction<SendStepsEnum>>
}

export const Stepper: React.FC<StepperProps> = ({
  steps,
  currentStep,
  setStep,
}) => {
  const { t } = useTranslation('transactions')

  const handleStepClick = (step: SendStepsEnum, currentStep: SendStepsEnum) => {
    if (step >= currentStep) {
      return
    }

    setStep(step)
  }

  return (
    <Container>
      {steps?.map(step => (
        <Step
          isCompleted={step < currentStep}
          isActive={step <= currentStep}
          stepName={t(stepNames[step])}
          onClick={() => handleStepClick(step, currentStep)}
          key={step}
        />
      ))}

      <Step
        isActive={SendStepsEnum.Summary <= currentStep}
        stepName={t(stepNames[SendStepsEnum.Summary])}
      />
    </Container>
  )
}
