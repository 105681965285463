import {
  FF_NAME,
  FF_THEME,
  SMI_THEME,
  SMT_EU_NAME,
  POX_NAME,
  POX_THEME,
} from '@fe/common/constants/themes'

export const getTheme = () => {
  let theme

  switch (process.env.NEXT_PUBLIC_PROJECT_NAME) {
    case FF_NAME:
      theme = FF_THEME
      break

    case SMT_EU_NAME:
      theme = SMI_THEME
      break

    case POX_NAME:
      theme = POX_THEME
      break

    default:
      theme = FF_THEME
      break
  }

  return theme
}
