import { useEffect, useRef, useState } from 'react'
import { isDefined } from '@fe/common/src/utils/numberUtils'
import { useDidUpdate } from './useDidUpdate'

const getTimerString = (milliseconds: number): string | null => {
  if (!isDefined(milliseconds)) {
    return null
  }

  const minutesLeft = Math.floor(milliseconds / 1000 / 60)
  const secondsLeft = Math.floor((milliseconds - minutesLeft * 60000) / 1000)

  const formattedMinutesLeft = String(minutesLeft).padStart(2, '0')
  const formattedSeconds = String(secondsLeft).padStart(2, '0')

  return `${formattedMinutesLeft}:${formattedSeconds}`
}

const getMillisecDiffFromNow = (timestamp: number): number => {
  const diff = timestamp - new Date().getTime()

  if (!diff || diff < 0) {
    return null
  }

  return diff
}

interface UseCountdownProps {
  expireAt: number
  setExpireAt?: React.Dispatch<React.SetStateAction<unknown>>
  onExpire?: () => void
}

export const useCountdown = ({
  expireAt,
  setExpireAt,
  onExpire,
}: UseCountdownProps) => {
  const [millisecodsLeft, setMillisecodsLeft] = useState(
    getMillisecDiffFromNow(expireAt)
  )

  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const previousTimeLeft = useRef<number>()

  useDidUpdate(
    () => setMillisecodsLeft(getMillisecDiffFromNow(expireAt)),
    [expireAt]
  )

  useEffect(() => {
    if (previousTimeLeft.current && !millisecodsLeft) {
      setExpireAt?.(null)
      setMillisecodsLeft(null)

      previousTimeLeft.current = null

      clearTimeout(timeoutRef.current)

      onExpire?.()

      return () => clearTimeout(timeoutRef.current)
    }

    previousTimeLeft.current = millisecodsLeft

    timeoutRef.current = setTimeout(() => {
      setMillisecodsLeft(() => {
        const nextDate = getMillisecDiffFromNow(expireAt)

        return nextDate
      })
    }, 1000)

    return () => clearTimeout(timeoutRef.current)
  }, [millisecodsLeft, expireAt])

  return {
    timeLeft: getTimerString(millisecodsLeft),
    millisecodsLeft,
    setMillisecodsLeft,
  }
}
