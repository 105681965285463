import format from 'date-fns/format'
import * as yup from 'yup'
import { ValidationApi } from '@fe/common/api/ValidationApi'
import { GenderEnum } from '@fe/common/api/models/enums/GenderEnum'
import { getMinEligibleDate } from '@fe/common/utils/dateUtils'
import { removeSpaces } from '@fe/common/utils/stringUtils'

yup.setLocale({
  string: {
    email: 'string.email',
    length: 'string.length',
    min: (params: { min: number }) => ({
      text: 'string.min',
      params,
    }),
    max: (params: { max: number }) => ({
      text: 'string.max',
      params,
    }),
  },
  number: {
    min: (params: { min: number }) => ({
      text: 'number.min',
      params,
    }),
    max: (params: { max: number }) => ({
      text: 'number.max',
      params,
    }),
  },
  date: {
    min: (params: { min: number }) => ({
      text: 'date.min',
      params: format(new Date(params.min), 'dd/MM/yyyy'),
    }),
    max: (params: { max: number }) => ({
      text: 'date.max',
      params: format(new Date(params.max), 'dd/MM/yyyy'),
    }),
  },
  mixed: {
    required: 'mixed.required',
    notType: (params: { type: string; max: number }) => {
      if (params.type === 'date') {
        return 'mixed.not-date'
      }

      if (params.type === 'number') {
        return 'mixed.not-number'
      }
    },
  },
})

yup.addMethod<yup.StringSchema>(yup.string, 'password', function () {
  return this.matches(
    /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+,.<>|=-])/,
    'string.password'
  )
    .min(12)
    .max(64)
})

yup.addMethod<yup.StringSchema>(
  yup.string,
  'repeatPassword',
  function (name: string) {
    return this.oneOf([yup.ref(name), '', null], 'string.repeatPassword')
  }
)

yup.addMethod<yup.StringSchema>(yup.string, 'phone', function () {
  return this.trim().matches(/^[+][0-9]{7,16}$|^$/, 'string.phone')
})

yup.addMethod<yup.StringSchema>(yup.string, 'mfaCode', function () {
  return this.test(
    'length',
    'string.mfa',
    val => (val.length === 6 && /^\d+$/.test(val)) || !val
  )
})

yup.addMethod<yup.StringSchema>(yup.string, 'gender', function () {
  return this.oneOf(
    [String(GenderEnum.Female), String(GenderEnum.Male), '', null],
    'string.gender'
  )
})

yup.addMethod<yup.StringSchema>(yup.string, 'iban', function () {
  return this.test('iban', 'string.iban', async value => {
    try {
      await ValidationApi.iban(removeSpaces(value))

      return true
    } catch (error) {
      return false
    }
  })
})

yup.addMethod<yup.StringSchema>(yup.string, 'bicSwift', function () {
  return this.test('bic-swift', 'string.bic-swift', async value => {
    try {
      await ValidationApi.bicSwift(removeSpaces(value))

      return true
    } catch (error) {
      return false
    }
  })
})

yup.addMethod<yup.StringSchema>(yup.string, 'czeNumber', function () {
  return this.test('cze-number', 'string.cze-number', async value => {
    try {
      await ValidationApi.czeNumber(removeSpaces(value))

      return true
    } catch (error) {
      return false
    }
  })
})

yup.addMethod<yup.StringSchema>(yup.string, 'czeCode', function () {
  return this.test('cze-code', 'string.cze-code', async value => {
    try {
      await ValidationApi.czeCode(removeSpaces(value))

      return true
    } catch (error) {
      return false
    }
  })
})

yup.addMethod<yup.StringSchema>(yup.string, 'internalNumber', function () {
  return this.test(
    'internal-number',
    'string.internal-number',
    async (value, context) => {
      const settingsId = (
        context as yup.TestContext & {
          from: { value: Record<string, any> }[]
        }
      ).from[1].value.settings_id

      try {
        await ValidationApi.internalNumber(settingsId, removeSpaces(value))

        return true
      } catch (error) {
        return false
      }
    }
  ).test(
    'receiver-liability',
    'string.receiver-liability',
    async (value, context) => {
      const settingsId = (
        context as yup.TestContext & {
          from: { value: Record<string, any> }[]
        }
      ).from[1].value.settings_id

      const hasUserAccepted = context.options.context.isLiabilityAccepted

      try {
        const {
          data: { sender_liability_warning },
        } = await ValidationApi.internalNumber(settingsId, removeSpaces(value))

        if (sender_liability_warning && !hasUserAccepted) {
          return false
        }

        return true
      } catch (error) {
        return false
      }
    }
  )
})

yup.addMethod<yup.StringSchema>(yup.string, 'cryptoAddress', function () {
  return this.test(
    'crypto-address',
    'string.crypto-address',
    async (value, context) => {
      const settingsId = context.options.context.targetSettingId

      try {
        await ValidationApi.cryptoAddress(settingsId, removeSpaces(value))

        return true
      } catch (error) {
        return false
      }
    }
  )
})

yup.addMethod<yup.StringSchema>(yup.string, 'cardNumber', function () {
  return this.test('card-number', 'string.card-number', async value => {
    try {
      await ValidationApi.cardNumber(removeSpaces(value))

      return true
    } catch (error) {
      return false
    }
  })
})

yup.addMethod<yup.DateSchema>(yup.date, 'birthDate', function () {
  return this.transform((val: Date) => {
    val.setHours(0)

    return val
  }).max(getMinEligibleDate(), 'date.birthDate')
})

export default yup
