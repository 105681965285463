import { RequestStatusEnum } from '@fe/common/api/models/enums/RequestStatusEnum'
import { TransactionTypeEnum } from '@fe/common/api/models/enums/TransactionTypeEnum'
import { requestStatusNames } from '@fe/common/constants/enums'

export const getStatusString = (
  status: RequestStatusEnum,
  transactionType: TransactionTypeEnum
) => {
  if (status === RequestStatusEnum.Completed) {
    const isDeposit = transactionType === TransactionTypeEnum.Deposit

    return isDeposit ? 'received' : 'sent'
  }

  return requestStatusNames[status]
}

export const getEnumValues = (
  enumToParse: Record<string, string | number>
): Array<string | number> => {
  const enumValues = Object.values(enumToParse)
  const formattedValues = enumValues.slice(enumValues.length / 2)

  return formattedValues
}
